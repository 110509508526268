/* eslint-disable */
import React, { useEffect, useState } from 'react';
import userService from 'services/user.service';
import { DataGrid } from '@mui/x-data-grid';
function CompletedTasks() {
    const [tasks, setTasks] = useState([]);
    useEffect(() => {
        userService.get_email_sent_log().then(
            (response) => {
                console.log(response.data);
                setTasks(response.data);
            },
            (error) => {
                const _errors =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(_errors);
            }
        );
    }, []);
    const [expandedRows, setExpandedRows] = useState({});

    const toggleExpandText = (taskId) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [taskId]: !prevExpandedRows[taskId], // Toggle the specific task's expansion state
        }));
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString(); // Format to 'MM/DD/YYYY, HH:MM:SS AM/PM'
    };
    const columns = [
        // { 
        //     field: 'rowNumber', 
        //     headerName: '#', 
        //     width: 50, 
        //     sortable: false, 
        //     renderCell: (params) => params.rowIndex + 1 // Adds row number dynamically
        // },
        // { field: 'id', headerName: 'Task ID', width: 50 },
        { field: 'run_at', headerName: 'Datetime', width: 180, 
            valueFormatter: ({ value }) => formatDate(value) 
        },
        { field: 'subject', headerName: 'Subject', width: 200 },
        { field: 'sender', headerName: 'Sender', width: 200 },
        { field: 'recipient', headerName: 'Recipient', width: 200, 
            renderCell: (params) => params.value.join(', ') 
        },
        { 
            field: 'txt', 
            headerName: 'Message', 
            width: 400, 
            renderCell: (params) => (
                <div
                    onClick={() => toggleExpandText(params.row.id)}
                    style={{
                        cursor: 'pointer',
                        whiteSpace: expandedRows[params.row.id] ? 'normal' : 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: expandedRows[params.row.id] ? '100%' : '200px',
                        overflowY: 'auto', // Make text scrollable
                        height: '100%', // Adjust height for scrolling
                    }}
                    title="Click to expand"
                >
                    {expandedRows[params.row.id] ? params.value : `${params.value.slice(0, 50)}...`}
                </div>
            )
        }
    ];

    return (
        <div style={{ height: 600, width: '100%' }}>
            <h5>Email Sent Log</h5>
            <DataGrid
                rows={tasks}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 20]}
                getRowId={(row) => row.id}
                pagination
            />
        </div>
    );
}

export default CompletedTasks;
