/* eslint-disable */
import {
    Body,
    Button,
    Container,
    Head,
    Heading,
    Hr,
    Html,
    Img,
    Link,
    Preview,
    Section,
    Column,
    Row,
    Text
    ///
} from '@react-email/components';
import * as React from 'react';

export const Email = () => (
    <Html>
        <Head />
        <Body style={main}>
            <Container style={container}>
                <Section style={box}>
                    <Heading style={heading}>PUMAS</Heading>
                    <Hr style={hr} />
                    <Text style={paragraph}>
                        Dear <strong>[Participant Name]</strong>,
                    </Text>
                    <Text style={paragraph}>
                        You are soon to participate in a peer learning event called <strong>[Event]</strong>. Here are some things you need
                        know.
                    </Text>
                    <Text style={paragraph}>
                        In the table below, you will find the details about your learning group. The combination was generated based on
                        maximising the diversity of skills that participants shared in their Smart Profile.
                    </Text>
                    {/* <Section style={global.defaultPadding}>[Group Table]</Section> */}
                    {/* <Section style={box}>[Group Table]</Section> */}
                    <Text style={paragraph}>[Group Table]</Text>
                    <Text style={paragraph}>
                        Please go directly to meet with them on <strong>[Table Number] </strong>
                        and get ready to work on the brief that your facilitator is ready to share.
                    </Text>
                    <Text style={paragraph}>Regards,</Text>
                    <Text style={paragraph}>The PUMAS team at Active Peers AI</Text>
                    <Hr style={hr} />
                    <div style={redBox}>
                        <p style={redBoxParagraph}>
                            Please do not reply to this email directly. <br/>
                            For any assistance, please fill out this form <a href="https://www.activepeers.ai/support-centre" style={{ color: "red" }}>here</a>.
                        </p>
                    </div>
                    <Text style={footer}>Hayes Culleton Group, Greenway Hub, TU Dublin, Grangegorman Lower, Dublin 7, Ireland</Text>
                </Section>
            </Container>
        </Body>
    </Html>
);

export default Email;

const main = {
    backgroundColor: '#f6f9fc',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif'
};

const container = {
    backgroundColor: '#ffffff',
    margin: '0 auto',
    padding: '20px 0 48px',
    marginBottom: '64px'
};

const box = {
    padding: '0 48px'
};

const hr = {
    borderColor: '#16a085',
    margin: '20px 0'
};

const paragraph = {
    color: '#525f7f',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left'
};

const anchor = {
    color: '#556cd6'
};

const footer = {
    color: '#8898aa',
    fontSize: '12px',
    lineHeight: '16px'
};
const heading = {
    fontSize: '24px',
    letterSpacing: '-0.5px',
    lineHeight: '1.3',
    fontWeight: '400',
    color: '#16a085',
    padding: '17px 0 0'
};
const paddingX = {
    paddingLeft: '40px',
    paddingRight: '40px'
};

const paddingY = {
    paddingTop: '22px',
    paddingBottom: '22px'
};

// const paragraph = {
//     margin: '0',
//     lineHeight: '2'
// };

const global = {
    paddingX,
    paddingY,
    defaultPadding: {
        ...paddingX,
        ...paddingY
    },
    paragraphWithBold: { ...paragraph, fontWeight: 'bold' },
    heading: {
        fontSize: '32px',
        lineHeight: '1.3',
        fontWeight: '700',
        textAlign: 'center',
        letterSpacing: '-1px'
    },
    text: {
        ...paragraph,
        color: '#747474',
        fontWeight: '500'
    },
    button: {
        border: '1px solid #929292',
        fontSize: '16px',
        textDecoration: 'none',
        padding: '10px 0px',
        width: '220px',
        display: 'block',
        textAlign: 'center',
        fontWeight: 500,
        color: '#000'
    },
    hr: {
        borderColor: '#E5E5E5',
        margin: '0'
    }
};

const track = {
    container: {
        padding: '22px 40px',
        backgroundColor: '#F7F7F7'
    },
    number: {
        margin: '12px 0 0 0',
        fontWeight: 500,
        lineHeight: '1.4',
        color: '#6F6F6F'
    }
};
const redBox = {
    border: '1px solid red',
    margin: '10px auto',
    padding: '5px',
    display: 'block',
    textAlign: 'center',
    width: '80%',
    boxSizing: 'border-box'
};

const redBoxParagraph = {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#8898aa',
    margin: '0'
};