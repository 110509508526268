/* eslint-disable */
import React, { useState, useEffect } from 'react';
import 'grapesjs/dist/css/grapes.min.css';
import gjsblockbasic from 'grapesjs-blocks-basic';
import gjspresetnewsletter from 'grapesjs-preset-newsletter';
import { Box, Button, ButtonGroup } from '@mui/material';
import { GrapesjsReact } from './GrapejsReact';
import UserService from 'services/user.service';
import Axios from 'axios';
if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
const GrapeJsEmailEdiorPage = ({ defaultTemplateId, onSave, onClose }) => {
    const [editorInstance, setEditorInstance] = useState(null);
    const [template0, setTemplate0] = React.useState('');
    const [template1, setTemplate1] = React.useState('');
    const [editorState0, setEditorState0] = useState(null);
    useEffect(() => {
        UserService.get_email_template(defaultTemplateId)
            .then((response) => {
                setTemplate0(response.data['template']);
            })
            .catch((error) => {
                const _error = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('_error', _error);
            });
    }, []);
    useEffect(() => {
        UserService.get_email_template_byType(defaultTemplateId + '0')
            .then((response) => {
                // console.log('response', response.data['template']);
                if (response.data['template'] != '') {
                    setTemplate1(response.data['template']);
                } else {
                    setTemplate1(editorState0);
                }
            })
            .catch((error) => {
                const _error = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('_error', _error);
            });
    }, [editorState0]);

    useEffect(() => {
        setEditorState0(template0);
    }, [template0]);

    const handleInit = (editor) => {
        setEditorInstance(editor);
        if (editorState0) {
          editor.setComponents(editorState0);
        }
        // Remove Image and Video blocks
        const blockManager = editor.BlockManager;
        const blocksToRemove = ['image', 'video', 'map', 'grid-items', 'list-items'];
        blocksToRemove.forEach((block) => blockManager.remove(block));
        // Customize the color picker
        const styleManager = editor.StyleManager;
        const colorPickerConfig = {
            appendTo: 'parent',
            offset: { top: 26, left: -166 },
        };
        styleManager.getConfig().colorPicker = colorPickerConfig;
    };

    useEffect(() => {
        if (editorInstance && editorState0) {
          setTimeout(() => {
            editorInstance.setComponents(editorState0);
          }, 600); // Delay to ensure editor is fully ready
        }
    }, [editorState0, editorInstance]);

    const handleSave = () => {
        let template_subject = '';
        let template_type = 999;
        if (defaultTemplateId == '23') {
            template_subject = 'Feedback Details for Stakeholders';
            template_type = 230;
        } else if (defaultTemplateId == '20') {
            template_subject = 'Feedback Details for Participants and Mentors';
            template_type = 200;
        } else if (defaultTemplateId == '11') {
            template_subject = 'Meeting Details for Participants';
            template_type = 110;
        } else if (defaultTemplateId == '12') {
            template_subject = 'Meeting Details for Mentors';
            template_type = 120;
        } else if (defaultTemplateId == '13') {
            template_subject = 'Meeting Details for Stakeholders';
            template_type = 130;
        }
        let fullHtml = '';
        if (editorInstance) {
            const html = editorInstance.getHtml();
            const css = editorInstance.getCss();
            fullHtml = `
                <html>
                    <head>
                        <style>${css}</style>
                    </head>
                    <body>${html}</body>
                </html>
            `;
            // Call the onSave callback with the full HTML
            onSave(fullHtml);
        }
        Axios.post(
            `${backend_base_url}/email_template/create`,
            {
                data: {
                    template_subject: template_subject,
                    // default_content: '',
                    updated_content: fullHtml,
                    template_type: template_type,
                    last_updated_by: JSON.parse(localStorage.getItem('user'))['id']
                    // last_updated_date = timezone.now(),
                }
            },
            { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
        )
            .then((response) => {
                if (response && response.data && response.data.message) {
                    //console.log(response.data);
                    alert(JSON.stringify(response.data.message));
                    if (response.data.success == true) {
                        onClose();
                    }
                } else {
                    //console.log(response);
                    alert(JSON.stringify(response));
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log(JSON.stringify(error));
                    alert(
                        'An error occurred while saving the email template (' + JSON.stringify(error.response.data) + '). Please try again later.'
                    );
                } else if (error.request) {
                    alert(JSON.stringify(error.request));
                    alert('No response from the server. Please try again later.');
                } else {
                    alert('An error occurred while setting up the request. Please try again later. ' + JSON.stringify(error));
                }
            });
    };
    const handleImportDefaultTemplate = () => {
        if (editorInstance) {
            editorInstance.setComponents(editorState0);
        }
    };

    const handleImportLastUpdate = () => {
        if (editorInstance) {
            editorInstance.setComponents(template1);
        }
    };
    const [copySuccess, setCopySuccess] = useState('');

    const handleCopyText = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => setCopySuccess('Copied to the clipboard'))
            .catch((error) => console.error('Failed to copy:', error));
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <ButtonGroup>
                    <Button variant="contained" size="small" onClick={handleImportDefaultTemplate}>
                        Import Default Template
                    </Button>
                    |
                    <Button variant="contained" size="small" onClick={handleImportLastUpdate}>
                        Import Last Update
                    </Button>
                </ButtonGroup>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <ButtonGroup>
                    <Button variant="outlined" onClick={() => handleCopyText('[Participant Name]')}>Copy [Participant Name]</Button>
                    <Button variant="outlined" onClick={() => handleCopyText('[Event]')}>Copy [Event]</Button>
                    {[11, 12].includes(defaultTemplateId) &&
                        <Button variant="outlined" onClick={() => handleCopyText('[Group Table]')}>Copy [Group Table]</Button>}
                    {[11, 12].includes(defaultTemplateId) &&
                        <Button variant="outlined" onClick={() => handleCopyText('[Table Number]')}>Copy [Table Number]</Button>}
                    {[13, 23].includes(defaultTemplateId) &&
                        <Button variant="outlined" onClick={() => handleCopyText('[Round Number]')}>Copy [Round Number]</Button>}
                    {[20].includes(defaultTemplateId) &&
                        <Button variant="outlined" onClick={() => handleCopyText('[Feedback Link]')}>Copy [Feedback Link]</Button>}
                </ButtonGroup>
                {copySuccess && <p>{copySuccess}</p>}
            </Box>
            
            <GrapesjsReact
                key={editorState0} // This will force GrapesjsReact to re-render when editorState0 changes
                id='grapesjs-react'
                plugins={[gjsblockbasic, gjspresetnewsletter]}
                onInit={handleInit}
            />
            <Button
                disableElevation
                fullWidth
                size="small"
                variant="contained"
                color="primary"
                onClick={handleSave}
                ///
            >
                Save
            </Button>
        </div>
    );
};

export default GrapeJsEmailEdiorPage;
