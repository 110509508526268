/* eslint-disable */
import React, { useState, useEffect } from 'react';
import UserService from 'services/user.service';
import {
    Box,
    NativeSelect,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    TextField
    //
} from '@mui/material';
import Fab from '@mui/material/Fab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const role = (localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))['role']) || 'guest';
export const ViewFeedback_from_peers = () => {
    const navigate = useNavigate();
    const { user: currentUser } = useSelector((state) => state.auth);
    const location = useLocation();
    const prevLocation = encodeURIComponent(window.location.origin + location.pathname + location.search);
    if (!currentUser) {
        navigate(`/login?redirectTo=${prevLocation}`);
    }
    const query = useQuery();
    // Get query parameters
    const projectId = parseInt(query.get('projectId'));
    const round = parseInt(query.get('round'));
    if (isNaN(projectId)) {
        navigate('/smart-profile');
    }
    const handleBackPage = () => {
        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate('/notification');
        }
    };
    const [errors, setErrors] = useState(null);
    let userId = currentUser.id;
    const userId_parse = parseInt(query.get('user'));
    if (userId_parse) {
        if (role == 1 || role == 2) {
            userId = userId_parse;
        } else {
            alert('You have no permission to view this page.');
            navigate('/smart-profile');
        }
    }
    const rating_list = UserService.rating_list;

    //Get feedback data
    const [feedbackData, setFeedback] = useState(null);
    useEffect(() => {
        UserService.getAllFeedbacks().then(
            (response) => {
                setFeedback(
                    JSON.parse(response.data.feedback_data).filter(
                        (p) =>
                            p.fields.project == projectId &&
                            p.fields.round == round &&
                            p.fields.created_by != userId &&
                            p.fields.teammates.find((t) => t.id == userId)
                    )
                );
            },
            (error) => {
                const _errors = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                setErrors('FAILED TO GET FEEDBACK DATA: ' + _errors);
            }
        );
    }, []);
    const [mentorFeedbackData, setMentorFeedback] = useState(null);
    useEffect(() => {
        UserService.getAllMentorFeedbacks().then(
            (response) => {
                setMentorFeedback(
                    JSON.parse(response.data.feedback_data).filter(
                        (p) =>
                            p.fields.project == projectId &&
                            p.fields.round == round &&
                            p.fields.created_by != userId &&
                            p.fields.mentees.find((t) => t.id == userId)
                    )
                );
            },
            (error) => {
                const _errors = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                setErrors('FAILED TO GET FEEDBACK DATA: ' + _errors);
            }
        );
    }, []);
    if (errors) {
        return (
                <Grid item xs={8} sm={12} alignitems="center">
                    <br />
                    <Box sx={{ height: 640, width: '100%' }}>
                        <div style={{color: 'red'}}>{errors}</div>
                    </Box>
                </Grid>
            )
    }
    if (feedbackData || mentorFeedbackData) {
        if ((feedbackData && feedbackData.length > 0) || (mentorFeedbackData && mentorFeedbackData.length > 0)) {
            return (
                <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                    <Box sx={{ height: '100%', maxWidth: '100%', width: '100%', p: 2 }}>
                        <Fab
                            variant="extended"
                            size="small"
                            color="primary"
                            onClick={() => handleBackPage()}
                            sx={{ mb: 2 }}
                        >
                            <ArrowBackIcon />
                            Back
                        </Fab>
                        {feedbackData && feedbackData.length > 0 ? (
                            <div>
                                <b>
                                    How your peers/mentees rate your proficiency in each of the skills listed on your Smart Profile or added by
                                    your peers/mentees?
                                </b>
                                {feedbackData.map((teammate, teammateIndex) => (
                                    <div>
                                        <b>Mentee's feedback {teammateIndex + 1}</b>
                                        <TableContainer style={{ width: '80%', margin: 'auto' }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>No</TableCell>
                                                        <TableCell>Skill</TableCell>
                                                        <TableCell>Rate/5</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {teammate.fields.teammates
                                                        .find((f) => f.id == userId)
                                                        .skills.map((skill, skillIndex) => (
                                                            <TableRow>
                                                                <TableCell>{skillIndex + 1}. </TableCell>
                                                                <TableCell>
                                                                    {skill.skill.trim()}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <NativeSelect
                                                                        defaultValue={skill.rate !== undefined ? skill.rate : ''}
                                                                        disabled
                                                                        //
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {rating_list.map((item, index) => (
                                                                            <option key={skillIndex + '-' + index} value={item}>
                                                                                {item}
                                                                            </option>
                                                                        ))}
                                                                    </NativeSelect>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                            <br></br>
                                            <i style={{ color: '#14a085', margin: '1em' }}>
                                                Example: "Zoe showed great enthusiasm when leading in discussions. However, it seemed like she often
                                                had difficulty staying on point."
                                            </i>
                                            <TextField
                                                sx={{ width: '100%' }}
                                                multiline
                                                rows={3}
                                                variant="outlined"
                                                name={`comments_${teammateIndex}`}
                                                id={`comments_${teammateIndex}`}
                                                type="text"
                                                placeholder="Your answer"
                                                inputProps={{ maxLength: 500 }}
                                                defaultValue={teammate.fields.teammates.find((f) => f.id == userId).comments}
                                                disabled
                                            />
                                        </TableContainer>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                        {mentorFeedbackData && mentorFeedbackData.length > 0 ? (
                            <div>
                                <br></br>
                                <b>
                                    How your mentor(s) rate your proficiency in each of the skills listed on your Smart Profile or added by your
                                    mentors?
                                </b>
                                {mentorFeedbackData.map((teammate, teammateIndex) => (
                                    <div>
                                        <br></br>
                                        <b>Mentor's feedback {teammateIndex + 1}</b>
                                        <TableContainer style={{ width: '80%', margin: 'auto' }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>No</TableCell>
                                                        <TableCell>Skill</TableCell>
                                                        <TableCell>Rate/5</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {teammate.fields.mentees
                                                        .find((f) => f.id == userId)
                                                        .skills.map((skill, skillIndex) => (
                                                            <TableRow>
                                                                <TableCell>{skillIndex + 1}. </TableCell>
                                                                <TableCell>
                                                                    {skill.skill.trim()}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <NativeSelect
                                                                        defaultValue={skill.rate !== undefined ? skill.rate : ''}
                                                                        disabled
                                                                        //
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {rating_list.map((item, index) => (
                                                                            <option key={skillIndex + '-' + index} value={item}>
                                                                                {item}
                                                                            </option>
                                                                        ))}
                                                                    </NativeSelect>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                            <br></br>
                                            <br></br>
                                            <b>Where have you seen evidence of their personal growth and development?</b>
                                            <TextField
                                                sx={{ width: '100%' }}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                name={`comment1_${teammateIndex}`}
                                                id={`comment1_${teammateIndex}`}
                                                type="text"
                                                placeholder="Your answer"
                                                inputProps={{ maxLength: 500 }}
                                                defaultValue={teammate.fields.mentees.find((f) => f.id == userId).comment1}
                                                disabled
                                            />
                                            <br></br>
                                            <br></br>
                                            <b>How can they develop these skills further?</b>
                                            <TextField
                                                sx={{ width: '100%' }}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                name={`comment2_${teammateIndex}`}
                                                id={`comment2_${teammateIndex}`}
                                                type="text"
                                                placeholder="Your answer"
                                                inputProps={{ maxLength: 500 }}
                                                defaultValue={teammate.fields.mentees.find((f) => f.id == userId).comment2}
                                                disabled
                                            />
                                            <br></br>
                                            <br></br>
                                            <b>What other skills have you seen them display, that they didn't put on their Smart Profile?</b>
                                            <TextField
                                                sx={{ width: '100%' }}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                name={`comment3_${teammateIndex}`}
                                                id={`comment3_${teammateIndex}`}
                                                type="text"
                                                placeholder="Your answer"
                                                inputProps={{ maxLength: 500 }}
                                                defaultValue={teammate.fields.mentees.find((f) => f.id == userId).comment3}
                                                disabled
                                            />
                                        </TableContainer>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </Box>
                </Box>
            );
        } else if (feedbackData?.length + mentorFeedbackData?.length == 0) {
            return (<Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    onClick={() => handleBackPage()}
                    sx={{ mb: 2 }}
                >
                    <ArrowBackIcon />
                    Back
                </Fab>
                <div>
                    <p>There is currently no feedback from your peers.</p>
                </div>
            </Box>);
        } else {
            return <div>Loading...</div>;
        }    
    } else {
        return <div>Loading...</div>;
    }
};
