// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

// const App = () => {
//     const navigate = useNavigate();
//     const { user: currentUser } = useSelector((state) => state.auth);
//     useEffect(() => {
//         // Redirect to login if currentUser is not available
//         if (!currentUser) {
//             navigate('/login');
//         }
//     }, [currentUser, navigate]);

//     return (
//         <ThemeCustomization>
//             <ScrollTop>
//                 <Routes />
//             </ScrollTop>
//         </ThemeCustomization>
//     );
// };
const App = () => (
    <ThemeCustomization>
        <ScrollTop>
            <Routes />
        </ScrollTop>
    </ThemeCustomization>
);

export default App;
