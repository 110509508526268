/* eslint-disable */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    GridToolbarDensitySelector
} from '@mui/x-data-grid';
import {
    Box,
    CircularProgress,
    Typography,
    Container
} from '@mui/material';

import { GridToolbarContainer } from '@mui/x-data-grid';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            {/* <GridToolbarColumnsButton /> */}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarQuickFilter />
            {/* <GridToolbarExport /> */}
        </GridToolbarContainer>
    );
}
export const ReSelectRecipients = ({ recipients, selectedRecipientIds, setSelectedRecipientIds }) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    if (!currentUser) {
        navigate('/login');
    }

    const columns = [
        {
            field: 'groupNumber',
            headerName: 'Group No',
            width: 70
        },
        { field: 'id', headerName: 'ID', width: 70, type: 'number' },
        { field: 'email', headerName: 'Email', width: 230 },
        {
            field: 'name',
            headerName: 'Name',
            width: 130
        },
        {
            field: 'role',
            headerName: 'Role',
            width: 90,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['Mentor', 'Participant', 'Stakeholder', 'Admin']
        },
        {
            field: 'company_or_school',
            headerName: 'Company or School',
            width: 200
        }
    ];


    const onRowsSelectionHandler = (ids) => {
        setSelectedRecipientIds(ids);
    };
    if (recipients.length > 0 && columns) {
        return (
            <Container>
                <br />
                <Box sx={{ height: 640, width: '100%' }}>
                    <br></br>
                    <DataGrid
                        rows={recipients}
                        columns={columns}
                        onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        rowSelectionModel={selectedRecipientIds}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 30 }
                            }
                        }}
                        pageSizeOptions={[5, 10, 20, 30, 40, 50]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        editMode="row"
                        slots={{ toolbar: CustomToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true
                            }
                        }}
                        // onFilterModelChange={(model) => setFilterModel(model)}
                    />
                </Box>
            </Container>
        );
    } else {
        return (
            <Container>
                <br />
                <Box sx={{ height: 640, width: '100%' }}>
                    <CircularProgress color="primary" />
                    <Typography>Loading ...</Typography>
                </Box>
            </Container>
        );
    }
};

export default ReSelectRecipients;
