/* eslint-disable */
import React, { useEffect, useState } from 'react';
import userService from 'services/user.service';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ProjectLogs = ({ projectId }) => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        userService.get_project_logs(projectId)
            .then((response) => {
                setLogs(response.data.results);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching project logs:', error);
                setLoading(false);
                setError(error);

            });
    }, [projectId]);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            {(!logs || logs.length === 0) ? (
                <>No data</>
            ) : (
                logs.map((log) => (
                    <Accordion key={log.timestamp}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                            {new Date(log.timestamp).toLocaleString()}
                        </AccordionSummary>
                        <AccordionDetails>
                            <strong>Project:</strong> {log.project_title} <br />
                            <strong>Changed by:</strong> {log.changed_by} <br />
                            <strong>Changes:</strong> <pre>{JSON.stringify(log.changes, null, 2)}</pre> <br />
                            <strong>Date:</strong> {new Date(log.timestamp).toLocaleString()}
                        </AccordionDetails>
                    </Accordion>
                ))
            )}
        </div>
    );
};

export default ProjectLogs;
