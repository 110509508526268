/* eslint-disable */
import {
    Body,
    Button,
    Container,
    Head,
    Heading,
    Hr,
    Html,
    Img,
    Link,
    Preview,
    Section,
    Column,
    Row,
    Text
    ///
} from '@react-email/components';
import * as React from 'react';

export const FeedbackEmail = () => (
    <Html>
        <Head />
        <Body style={main}>
            <Container style={container}>
                <Section style={box}>
                    <Heading style={heading}>PUMAS</Heading>
                    <Hr style={hr} />
                    <Text style={paragraph}>
                        Dear <strong>[Participant Name]</strong>,
                    </Text>
                    <Text style={paragraph}>
                        You have completed this peer learning session at <strong>[Event]</strong>. We would like to invite you to reflect on
                        your experience. Your reflection is valuable for everyone in your group including yourself and will have an impact
                        on your team members' Smart Profiles.
                    </Text>
                    <Text style={paragraph}>By giving this some real thought:</Text>
                    {/* <Text style={paragraph}> */}
                    <ul>
                        <li style={paragraph1}>
                            {/* <Text style={paragraph}> */}
                            You can benefit by learning more from the experience, be able to explain this better in an interview where
                            you're asked a question like "tell me about a time that ..." and your peers are likely to give you better
                            quality feedback.
                            {/* </Text> */}
                        </li>
                        <li style={paragraph1}>
                            {/* <Text style={paragraph}> */}
                            You help others by sharing insights supporting their growth and development, bettering team communication and
                            collaboration, and by identifying areas for improvement.
                            {/* </Text> */}
                        </li>
                    </ul>
                    {/* </Text> */}
                    <br />
                    <Button
                        pX={10}
                        pY={10}
                        style={button}
                        href="[Feedback Link]"
                        //
                    >
                        Fill Out The Feedback Form
                    </Button>
                    <Text style={paragraph}>Regards,</Text>
                    <Text style={paragraph}>The PUMAS team at Active Peers AI</Text>
                    <Hr style={hr} />
                    <div style={redBox}>
                        <p style={redBoxParagraph}>
                            Please do not reply to this email directly. <br/>
                            For any assistance, please fill out this form <a href="https://www.activepeers.ai/support-centre" style={{ color: "red" }}>here</a>.
                        </p>
                    </div>
                    <Text style={footer}>Hayes Culleton Group, Greenway Hub, TU Dublin, Grangegorman Lower, Dublin 7, Ireland</Text>
                </Section>
            </Container>
        </Body>
    </Html>
);

export default FeedbackEmail;

const main = {
    backgroundColor: '#f6f9fc',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif'
};

const container = {
    backgroundColor: '#ffffff',
    margin: '0 auto',
    padding: '20px 0 48px',
    marginBottom: '64px'
};

const box = {
    padding: '0 48px'
};

const hr = {
    borderColor: '#16a085',
    margin: '20px 0'
};

const paragraph = {
    color: '#525f7f',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left'
};
const paragraph1 = {
    color: '#525f7f',
    fontSize: '16px',
    lineHeight: '24px'
    // textAlign: 'left'
};

const button = {
    backgroundColor: '#16a085',
    borderRadius: '5px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    textDecoration: 'none',
    textAlign: 'center',
    display: 'block',
    width: '100%'
};

const footer = {
    color: '#8898aa',
    fontSize: '12px',
    lineHeight: '16px'
};
const heading = {
    fontSize: '24px',
    letterSpacing: '-0.5px',
    lineHeight: '1.3',
    fontWeight: '400',
    color: '#16a085',
    padding: '17px 0 0'
};
const redBox = {
    border: '1px solid red',
    margin: '10px auto',
    padding: '5px',
    display: 'block',
    textAlign: 'center',
    width: '80%',
    boxSizing: 'border-box'
};

const redBoxParagraph = {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#8898aa',
    margin: '0'
};