/* eslint-disable */
import React, { Component, useState, useRef, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import MainCard from 'components/MainCard';

import UserService from 'services/user.service';
import Axios from 'axios';
import DOMPurify from 'isomorphic-dompurify';
if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
const formatDate = (date) => {
    const today = new Date();
    const messageDate = new Date(date);

    // Check if the message date is today
    if (
        messageDate.getDate() === today.getDate() &&
        messageDate.getMonth() === today.getMonth() &&
        messageDate.getFullYear() === today.getFullYear()
    ) {
        // Calculate the time difference in milliseconds
        const timeDiff = today.getTime() - messageDate.getTime();
        const secondsDiff = Math.floor(timeDiff / 1000);
        const minutesDiff = Math.floor(secondsDiff / 60);
        const hoursDiff = Math.floor(minutesDiff / 60);

        // Return the formatted time difference
        if (hoursDiff > 0) {
            return `Today - ${hoursDiff} hour${hoursDiff > 1 ? 's' : ''} ago`;
        } else if (minutesDiff > 0) {
            return `Today - ${minutesDiff} minute${minutesDiff > 1 ? 's' : ''} ago`;
        } else {
            return `Today - Just now`;
        }
    } else {
        // If it's not today, display the full date
        return messageDate.toLocaleString();
    }
};

const MessageListItem = ({ message, index }) => {
    const [open_msg, setOpenMsg] = useState(false);

    const toggleCollapse = () => {
        setOpenMsg(!open_msg);
    };

    return (
        <>
            <ListItemButton component="div" onClick={toggleCollapse}>
                <Grid container>
                    <Grid item xs={2} sm={1}>{index + 1}. </Grid>
                    <Grid item xs={5} sm={6}>{message.fields.subject}</Grid>
                    <Grid item xs={5} sm={5}>{formatDate(message.fields.date_created)}</Grid>
                </Grid>
            </ListItemButton>
            <Collapse in={open_msg} timeout="auto" unmountOnExit>
                <iframe
                    title="Sanitized HTML Content"
                    frameBorder="0"
                    width="100%"
                    height={open_msg ? (message.fields.message_type === 0 ? '50px' : '700px') : '0px'}
                    srcDoc={`
                        <html>
                            <head>
                                <!-- You can include any additional styles here if necessary -->
                                <style>
                                /* Reset some default styles to avoid interference */
                                body, div, p {
                                    margin: 0;
                                    padding: 0;
                                }
                                </style>
                                <script>
                                    document.addEventListener('DOMContentLoaded', function () {
                                        // Add event listener to all anchor tags
                                        var links = document.querySelectorAll('a');
                                        links.forEach(function(link) {
                                            link.addEventListener('click', function(event) {
                                                event.preventDefault(); // Prevent the default link behavior
                                                window.open(link.href, '_blank'); // Open the link in a new window
                                            });
                                        });
                                    });
                                </script>
                            </head>
                            <body>
                                ${DOMPurify.sanitize(message.fields.html_content.replace('target="_blank"', 'target="_top"'))}
                            </body>
                        </html>
                    `}
                />
            </Collapse>
            <Divider />
        </>
    );
};
// const Notification = () => {
//     const [messages, setMessages] = useState([]);
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await UserService.getMessages();
//                 if (response == 'AxiosError: Request failed with status code 401') {
//                     window.location.href = '/logout';
//                 } else {
//                     setMessages(JSON.parse(response.data.message_data));
//                     const count = response.data.message_unread_count;
//                     if (count > 0) {
//                         const payload = {
//                             data: null
//                         };

//                         try {
//                             const updateResponse = await Axios.post(`${backend_base_url}/update_message_status`, payload, {
//                                 headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
//                             });

//                             console.log('Response from updating message status:', updateResponse.data);
//                         } catch (updateError) {
//                             console.error('Error while updating message status:', updateError);
//                         }
//                     }
//                 }
//             } catch (error) {
//                 console.error('Error fetching messages:', error);
//             }
//         };

//         fetchData();
//     }, []);
//     console.log('messages:', messages);
//     return (
//         <div>
//             {messages.length > 0 ? (
//                 messages.map((message, index) => (
//                     <ListItemButton component="a" href="#simple-list">
//                         <h6>
//                             {index + 1}. {message.fields.subject}
//                         </h6>
//                         <p>{new Date(message.fields.date_created).toLocaleString()}</p>
//                         {/* <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.fields.html_content.replace) }} /> */}
//                         <iframe
//                             title="Sanitized HTML Content"
//                             frameBorder="0"
//                             width="100%"
//                             height={message.fields.message_type == 0 ? '50px' : '700px'} // Adjust the height as needed
//                             srcDoc={`
//                                 <html>
//                                     <head>
//                                         <!-- You can include any additional styles here if necessary -->
//                                         <style>
//                                         /* Reset some default styles to avoid interference */
//                                         body, div, p {
//                                             margin: 0;
//                                             padding: 0;
//                                         }
//                                         </style>
//                                         <script>
//                                             document.addEventListener('DOMContentLoaded', function () {
//                                                 // Add event listener to all anchor tags
//                                                 var links = document.querySelectorAll('a');
//                                                 links.forEach(function(link) {
//                                                     link.addEventListener('click', function(event) {
//                                                         event.preventDefault(); // Prevent the default link behavior
//                                                         window.open(link.href, '_blank'); // Open the link in a new window
//                                                     });
//                                                 });
//                                             });
//                                         </script>
//                                     </head>
//                                     <body>
//                                         ${DOMPurify.sanitize(message.fields.html_content.replace('target="_blank"', 'target="_top"'))}
//                                     </body>
//                                 </html>
//                             `}
//                         />
//                         <Divider></Divider>
//                     </ListItemButton>
//                 ))
//             ) : (
//                 <div>Nothing to see here.</div>
//             )}
//         </div>
//     );
// };
const Notification = () => {
    const [messages, setMessages] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await UserService.getMessages();
                if (response == 'AxiosError: Request failed with status code 401') {
                    window.location.href = '/logout';
                } else {
                    setMessages(JSON.parse(response.data.message_data));
                    const count = response.data.message_unread_count;
                    if (count > 0) {
                        const payload = {
                            data: null
                        };

                        try {
                            const updateResponse = await Axios.post(`${backend_base_url}/update_message_status`, payload, {
                                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
                            });

                            console.log('Response from updating message status:', updateResponse.data);
                        } catch (updateError) {
                            console.error('Error while updating message status:', updateError);
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchData();
    }, []);
    return (
        <div>
            <h6>Notifications</h6>
            <MainCard content={false} sx={{ mt: 1.5 }}>
                <Box sx={{ pt: 1, pr: 2, overflow:"auto" }}>
                    {messages.length > 0 ? (
                        messages.map((message, index) => (
                            <MessageListItem key={index} message={message} index={index} />
                        ))
                    ) : (
                        <div>Nothing to see here.</div>
                    )}
                </Box>
            </MainCard>
        </div>
    );
};

export default Notification;
