/* eslint-disable */
import React from 'react';
import { useCallback } from 'react';
import { EmailVerification } from 'email/emailVerification';
import { render } from '@react-email/render';
// Default V2 theme
import 'survey-core/defaultV2.min.css';
// Modern theme
import { Model } from 'survey-core';
import { settings } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { registration_json } from './registration_json.js';
import showdown from 'showdown';
import Axios from 'axios';
import { ReactElementFactory } from 'survey-react-ui';
import userService from 'services/user.service.js';
settings.useCachingForChoicesRestful = false;
settings.specialChoicesOrder.otherItem = [-1];
settings.specialChoicesOrder.selectAllItem = [2];
//console.log(settings);

if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
class ItemTemplateComponent extends React.Component {
    render() {
        const item = this.props.item;

        return (
            <>
                <div className="my-list-item" style={{ display: 'flex' }}>
                    <span className="list-item_text">{item.title}</span>
                </div>
                {item.title === 'Student' || item.title === 'Other (describe)' ? <hr /> : null}
            </>
        );
    }
}
ReactElementFactory.Instance.registerElement('position-item', (props) => {
    return React.createElement(ItemTemplateComponent, props);
});
export const Register = () => {
    //render html
    const emailHtml = render(<EmailVerification />);
    var [res, setRes] = React.useState('');
    function validateDomain(_, options) {
        if (options.name === 'consent2' || options.name === 'consent1' || options.name === 'consent3' || options.name === 'consent4') {
            const consent = options.value;
            if (!consent) return;

            if (consent !== 'Yes') {
                options.error = 'Please choose Yes to complete your registration form!';
            }
        }
    }
    const registration = new Model(registration_json);
    const alertResults = useCallback((sender) => {
        const results = JSON.stringify(sender.data);
        alert(results);
    }, []);
    registration.onValidateQuestion.add(validateDomain);
    //Prevent the paste action on Re-type Email box
    const onAfterRenderQuestion = (sender, options) => {
        if (options.question.name === 'emailValidator') {
            options.htmlElement.querySelectorAll('input').forEach((el) => {
                if (el.getAttribute('aria-label') === 'Re-type Email') {
                    console.log('log4: ', el);
                    // Add a paste event listener to the input element
                    el.addEventListener('paste', function (e) {
                        e.preventDefault(); // Prevent the default paste action
                    });
                }
            });
        }
    };
    registration.onAfterRenderQuestion.add(onAfterRenderQuestion);

    //lazy loading
    function sendRequest(url, onloadSuccessCallback) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = () => {
            if (xhr.status === 200) {
                onloadSuccessCallback(JSON.parse(xhr.response));
            }
        };
        xhr.send();
    }
    registration.onChoicesLazyLoad.add((_, options) => {
        if (options.question.getType() === 'dropdown' && options.question.title === 'Company') {
            const url = `${backend_base_url}/get_company_list?skip=${options.skip}&take=${options.take}&filter=${options.filter}`;
            sendRequest(url, (data) => {
                options.setItems(data.companies, data.count);
            });
        }
    });
    registration.onChoicesLazyLoad.add((_, options) => {
        if (options.question.getType() === 'dropdown' && options.question.title === 'School') {
            const url = `${backend_base_url}/get_school_list_1?skip=${options.skip}&take=40&filter=${options.filter}`;
            sendRequest(url, (data) => {
                options.setItems(data.schools, data.total);
            });
        }
    });
    
    //Add confirmation box when enter email
    registration.onValueChanging.add(function (sender, options) {
        if (options?.value?.reUsername) {
            alert('Is the email ' + options.value.reUsername + ' correct?');
        }
    });

    registration.onComplete.add((sender, options) => {
        console.log(JSON.stringify(sender.data, null, 3));
        console.log(sender.data);
        var current_position = '';
        if (sender.data.current_position) {
            var current_position = sender.data.current_position;
            if (sender.data.current_position == 'other') {
                var current_position = sender.data['current_position-Comment'];
            }
        }
        var company_or_school = '';
        if (sender.data.companyOrSchool) {
            if (sender.data.company_or_school_1) {
                var company_or_school = sender.data.company_or_school_1;
                if (sender.data.company_or_school_1 == 'other') {
                    var company_or_school = sender.data['company_or_school_1-Comment'];
                }
            } else if (sender.data.company_or_school_2) {
                var company_or_school = sender.data.company_or_school_2;
                if (sender.data.company_or_school_2 == 'other') {
                    var company_or_school = sender.data['company_or_school_2-Comment'];
                }
            }
        }
        var group = '';
        if (sender.data.group) {
            var group = sender.data.group;
        }
        var role = '';
        var role_name = '';
        if (sender.data.role) {
            var role = sender.data.role;
            if (role == '2') {
                var role_name = 'Stakeholder';
            } else if (role == '3') {
                var role_name = 'Participant';
            } else if (role == '4') {
                var role_name = 'Mentor';
            }
        }
        var dream_job = '';
        if (sender.data.dream_job) {
            var dream_job = sender.data.dream_job;
        }
        var strong_skills = [];
        if (sender.data.strong_skills) {
            var strong_skills = sender.data.strong_skills;
        }
        var interest_skills = [];
        if (sender.data.interest_skills) {
            var interest_skills = sender.data.interest_skills;
        }
        var linkedIn_profile_url = '';
        if (sender.data.linkedIn_profile_url) {
            var linkedIn_profile_url = sender.data.linkedIn_profile_url;
        }
        if ('cvOrResume' in sender.data) {
            var cv_or_resume = sender.data.cvOrResume[0]['name'];
            var cv_or_resume_tmp = sender.data.cvOrResume[0]['content'].substring(28);
        } else {
            var cv_or_resume_tmp = '';
            var cv_or_resume = '';
        }
        Axios.post(`${backend_base_url}/users/create`, {
            user: {
                emailHtml: emailHtml,
                first_name: sender.data.first_name,
                last_name: sender.data.last_name,
                email: sender.data.emailValidator.username,
                password: sender.data.passwordValidator.password,
                gender: sender.data.gender,
                phone: 0,
                current_position: current_position,
                group: group,
                company_or_school: company_or_school,
                dream_job: dream_job,
                role: role,
                strong_skills: strong_skills,
                interest_skills: interest_skills,
                cvOrLinkedIn: sender.data.cvOrLinkedIn,
                linkedIn_profile_url: linkedIn_profile_url,
                cv_or_resume_tmp: cv_or_resume_tmp,
                cv_or_resume: cv_or_resume
            }
        }).then((response) => {
            console.log(response);
            console.log(response['data']['response']);
            if (response['data']['response'] == 'error') {
                // Display the "Error" message (pass a string value to display a custom message)
                options.showSaveError('An error occurred (' + response['data']['message'] + ') and we could not save the results.');
                registration.completedHtml = "<p style='font-size:24px;'>An error occurred!</p>";
            } else {
                // Display the "Success" message (pass a string value to display a custom message)
                //options.showSaveSuccess();
                //Alternatively, you can clear all messages:
                options.clearSaveMessages();
                registration.completedHtml =
                    "<p style='font-size:24px;'>Thank you for completing the registration form! <p><br><br><p>Your email has been successfully registered with PUMAS.<br>Now you can click <a href='https://pumas.activepeers.ai/login'>HERE<a> to login.</p>";
                try {
                    userService.airtableBase(process.env.REACT_APP_AIRTABLE_TABLE_ID).create(
                        [
                            {
                                fields: {
                                    ID: response['data']['created_userId'],
                                    Email: sender.data.emailValidator.username,
                                    'First Name': sender.data.first_name,
                                    'Last Name': sender.data.last_name,
                                    'Is Active': true,
                                    'Date Joined': response['data']['date_joined'],
                                    Gender: sender.data.gender,
                                    'Current Position': current_position,
                                    'Company or School': company_or_school,
                                    'Department or Group or Class': group,
                                    'Dream Job': dream_job,
                                    Role: role_name,
                                    'Strong Skills': strong_skills,
                                    'Interest Skills': interest_skills
                                }
                            }
                        ],
                        { typecast: true },
                        function (err, records) {
                            if (err) {
                                console.error(err);
                                return;
                            }
                            records.forEach(function (record) {
                                console.log(record.getId());
                            });
                        }
                    );
                } catch (error) {
                    console.log('error in creating a new row in Airtable: ', error);
                }
            }
        });
    });
    //Create showdown markdown converter
    var converter = new showdown.Converter();
    registration.onTextMarkdown.add(function (survey, options) {
        //convert the markdown text to html
        var str = converter.makeHtml(options.text);
        //remove root paragraphs <p></p>
        str = str.substring(3);
        str = str.substring(0, str.length - 4);
        //set html
        options.html = str;
    });
    return <Survey model={registration} />;
};
