/* eslint-disable */
import React, { createContext, useContext, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useSelector } from 'react-redux';
const DefaultProps = {
    unreadMessageCount: 0,
    connectionStatus: "Uninstantiated",
};

export const NotificationContext = createContext(DefaultProps);
export const useNotifications = () => useContext(NotificationContext);

if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url_ = '//pumas.activepeers.ai/ws';
    var websocket = 'wss';
} else {
    var backend_base_url_ = window.location.href.split(':')[1] + ':8001/ws';
    var websocket = 'ws';
}
const NotificationContextProvider = ({ children }) => {
    const authToken = localStorage.getItem('access_token');
    const { user: currentUser } = useSelector((state) => state.auth);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [unreadConversationCount, setUnreadConversations] = useState({});
    // console.log(unreadMessageCount);
    // console.log(unreadConversationCount);
    const { readyState } = useWebSocket(currentUser ? `${websocket}:${backend_base_url_}/notifications/` : null, {
        queryParams: {
            token: currentUser ? authToken : "",
        },
        onOpen: () => {
            console.log("Connected to Notifications!");
        },
        onClose: () => {
            console.log("Disconnected from Notifications!");
        },
        onMessage: (e) => {
            const data = JSON.parse(e.data);
            // console.log('data', data);
            switch (data.type) {
                // case "new_message_notification":
                //     setUnreadMessageCount((prevCount) => (prevCount += 1));
                //     break;
                case "total_unread_count":
                    setUnreadMessageCount(data.total_unread_count);
                    break;
                case "unread_count":
                    setUnreadConversations(prevState => ({
                        ...prevState,
                        [data.conversation_name]: data.unread_count
                    }));
                    break;
                default:
                    console.error("Unknown message type!");
                    break;
            }
        },
    });

    const connectionStatus = {
        [ReadyState.CONNECTING]: "Connecting",
        [ReadyState.OPEN]: "Open",
        [ReadyState.CLOSING]: "Closing",
        [ReadyState.CLOSED]: "Closed",
        [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    }[readyState];

    return (
        <NotificationContext.Provider value={{ unreadMessageCount, unreadConversationCount }}>
            {children}
        </NotificationContext.Provider>
    );
};

// Explicitly export NotificationContextProvider along with NotificationContext
export { NotificationContextProvider };
export default NotificationContextProvider;
