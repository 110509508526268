import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import {
    Alert,
    Container,
    Row,
    Col,
    Form,
    FormControl
    //
} from 'react-bootstrap';
import {
    // Alert,
    Card,
    Box,
    Button,
    Link
    //
} from '@mui/material';
import { PasswordResetEmail } from 'email/passwordReset';
import { render } from '@react-email/render';

if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailError: '',
            status: ''
        };
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSendClick = () => {
        this.setState({ emailError: '' });
        this.setState({ status: '' });
        //render html
        const emailHtml = render(<PasswordResetEmail />);

        const userData = {
            email: this.state.email,
            html_: emailHtml
        };
        // try {
        //     axios.post(`${backend_base_url}/auth/users/reset_password/`, userData).then((response) => {
        //         console.log('response', response);
        //         if (response.data.status === 200) {
        //             this.setState({ status: 'success' });
        //         } else {
        //             this.setState({ status: 'error', emailError: response.data.email });
        //         }
        //     });
        // } catch (err) {
        //     this.setState({ status: 'error', emailError: err.code });
        //     return alert(err.message);
        // }
        axios
            .post(`${backend_base_url}/auth/users/reset_password/`, userData)
            .then((response) => {
                console.log('response', response);
                if (response.data.status === 200) {
                    this.setState({ status: 'success' });
                } else {
                    this.setState({ status: 'error', emailError: response.data.email });
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response && error.response.data.hasOwnProperty('email')) {
                    console.log('error3');
                    this.setState({ emailError: error.response.data.email });
                } else {
                    console.log('error4: ' + error);
                    this.setState({ status: 'error', emailError: error.code });
                }
            });
    };
    render() {
        let errorAlert = (
            <Alert variant="danger">
                <Alert.Heading>Problem sending reset password email ({this.state.emailError}).</Alert.Heading>
                Please &nbsp;
                <Link variant="h6" href="/forgot-password" to="" color="primary">
                    try again
                </Link>
                &nbsp; or &nbsp;
                <Link
                    variant="h6"
                    // component={RouterLink}
                    href="https://airtable.com/appNm0943dsfrMAX9/shrwj4RjVSnpVvpin"
                    to=""
                    color="primary"
                    target="_blank"
                >
                    contact service support
                </Link>
                &nbsp; for further help.
            </Alert>
        );

        let successAlert = (
            <Alert variant="success">
                <Alert.Heading>Email sent </Alert.Heading>
                <p>
                    We've sent you an email with reset password link. Please check your email (<b>{this.state.email}</b>).
                </p>
                <p>
                    Please &nbsp;
                    <Link variant="h6" href="/forgot-password" to="" color="primary">
                        try again
                    </Link>
                    &nbsp; or &nbsp;
                    <Link
                        variant="h6"
                        // component={RouterLink}
                        href="https://airtable.com/appNm0943dsfrMAX9/shrwj4RjVSnpVvpin"
                        to=""
                        color="primary"
                        target="_blank"
                    >
                        contact us
                    </Link>
                    &nbsp; if you do not receive it within a few minutes.
                </p>
            </Alert>
        );

        let form = (
            <div>
                <Form>
                    <Form.Group controlId="emailId">
                        <Form.Label>Your Email</Form.Label>
                        <Form.Control
                            isInvalid={this.state.emailError}
                            type="text"
                            name="email"
                            placeholder="Enter email"
                            value={this.state.email}
                            onChange={this.onChange}
                        />
                        <FormControl.Feedback type="invalid">{this.state.emailError}</FormControl.Feedback>
                    </Form.Group>
                </Form>
                <br></br>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.onSendClick}
                    ///
                >
                    Send email with reset link
                </Button>
            </div>
        );

        let alert = '';
        if (this.state.status === 'error') {
            alert = errorAlert;
        } else if (this.state.status === 'success') {
            alert = successAlert;
        }

        return (
            <Container>
                <Row>
                    <Col md="6">
                        <h1>Reset Password</h1>
                        {alert}
                        {this.state.status !== 'success' && form}
                    </Col>
                </Row>
            </Container>
        );
    }
}

ResetPassword.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ResetPassword);
