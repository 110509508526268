/* eslint-disable */
import * as React from 'react';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Grid, Stack, Typography } from '@mui/material';
import AuthWrapper from './AuthWrapper';
export const Register_success = () => {
    return (
        <AuthWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                        <Typography variant="h3">Success</Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Alert
                icon={<CheckIcon />}
                variant="outlined"
                sx={{ bgcolor: 'background.paper', fontSize: 15 }} 
                // fontSize="40" color="#169f84"
            >
                <b>Thank you for completing the registration form! </b>
                <br></br><br></br>
                <p>You can &nbsp;
                    <a href='/login'>
                        LOGIN
                        <KeyboardDoubleArrowRightIcon />
                    </a>
                    &nbsp;now to see and amend your Smart Profile, review your feedback and read your notification.
                </p>
            </Alert>
        </AuthWrapper>
    )
};

