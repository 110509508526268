import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

import { Logout } from 'pages/authentication/Logout';
// ==============================|| AUTH ROUTING ||============================== //

const LogoutRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: 'logout',
            element: <Logout />
        }
    ]
};

export default LogoutRoutes;
