/* eslint-disable */
import React from 'react';
import {
    InputLabel,
    Button,
    Card,
    Box,
    CardContent,
    Grid,
    FormHelperText,
    Rating
} from '@mui/material';

import UserService from 'services/user.service';
import Select from 'react-select';
import NativeSelect from '@mui/material/NativeSelect';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';

const skill_list = UserService.skill_list;
const createOption = (label) => ({
    label
    //
});
const skill_options = skill_list.map(createOption); 
const rating_list = UserService.rating_list;
const labels = {
    1: '1 - Basic',
    2: '2 - Intermediate',
    3: '3 - Proficient',
    4: '4 - Advanced',
    5: '5 - Expert'
};
  
function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}
const DataTable = ({ skillsData, handleSkillChange, handleRateChange, handleDelete }) => {
    const [hover, setHover] = React.useState(-1);
    return (
        <Grid container spacing={2}>
            {skillsData?.map((skills, skillIndex) => (
                <Grid container item spacing={2} key={skillIndex}>
                    <Box
                        onMouseEnter={() => setHover(skillIndex)} // Set hover state on mouse enter
                        onMouseLeave={() => setHover(-1)} // Reset hover state on mouse leave
                        sx={{
                            minWidth: '100%',
                            border: 1,
                            borderRadius: '4px',
                            borderColor: 'primary.main',
                            position: 'relative'
                        }}
                    >
                        <CloseIcon
                            color="error"
                            onClick={() => handleDelete(skillIndex)}
                            sx={{ position: 'absolute', top: 0, right: 0 }}
                        />
                        <CardContent>
                        {/* <Grid item xs={5} sm={5}> */}
                            <InputLabel>
                                Skill
                            </InputLabel>
                            <Select
                                //labelid={`skill-${skillIndex}`}
                                //className="basic-single"
                                //classNamePrefix="select"
                                defaultInputValue={skills.skill}
                                isDisabled={false}
                                isLoading={false}
                                onChange={(e) =>
                                    handleSkillChange(skillIndex, e)
                                }
                                //isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                name="skill"
                                options={skill_options}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        //primary25: 'primary.light'
                                        primary: '#169f84'
                                    },
                                    backgroundColors: 'white'
                                })}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        color: state.isSelected ? 'grey' : 'red'
                                    })
                                }}
                                //fullWidth
                            />
                            {skills.skill === '' && (
                                <FormHelperText error>
                                    Skill is required
                                </FormHelperText>
                            )}
                            {skillsData.some((s, index) => s.skill !== '' && s.skill === skills.skill && index !== skillIndex) && (
                                <FormHelperText error>
                                    Duplicate skill found
                                </FormHelperText>
                            )}
                        {/* </Grid>
                        <Grid item xs={4} sm={4}> */}
                            <InputLabel variant="standard">
                                Rate/5
                            </InputLabel>
                            <NativeSelect
                                //labelid={`rate-${skillIndex}`}
                                //id={`rate-${skillIndex}`}
                                defaultValue={skills.rate}
                                onChange={(e) =>
                                    handleRateChange(skillIndex, e.target.value)
                                }
                                inputProps={{
                                    name: `rate-${skillIndex}`
                                    // id: `rate-${skillIndex}`,
                                }}
                                fullWidth
                            >
                                <option value=''>Select</option>
                                {rating_list.map((rate) =>
                                    <option key={rate}>{rate}</option>
                                )}
                            </NativeSelect>
                            {/* <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Rating
                                    onChangeActive={(event, newHover) => {
                                        setHover(newHover);
                                    }}
                                    getLabelText={getLabelText}
                                    //name={`rate-${skillIndex}`}
                                    value={skills.rate ? skills.rate : 0} // Convert the rate to integer
                                    onChange={(e, newValue) => handleRateChange(skillIndex, newValue)}
                                />
                                {skills.rate !== null && (
                                    <Box sx={{ ml: 2 }}>{labels[skills.rate]}</Box>
                                )}
                            </Box> */}
                            {skills.rate === '' && (
                                <FormHelperText error>
                                    Rate is required
                                </FormHelperText>
                            )}
                        {/* </Grid> */}
                        </CardContent>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default DataTable;