/* eslint-disable */
import React, { useState, useEffect } from 'react';
import userService from 'services/user.service' ;
import UserService from 'services/user.service';
import AsyncCreatableSelect from 'react-select/async-creatable';
const school_list =     [
    {
        text: "ABACAS KILBARRACK,  DUBLIN 5"
    },
    {
        text: "ABACAS SCHOOL,  LOUTH"
    },
    {
        text: "ABACAS SPECIAL SCHOOL FOR CHILDREN WITH AUTISM,  DUBLIN 24"
    },
    {
        text: "ÁBALTA SPECIAL SCHOOL,  GALWAY"
    },
    {
        text: "ABBEYCARTON NS,  ROSCOMMON"
    },
    {
        text: "ABBEY COMMUNITY COLLEGE,  ROSCOMMON"
    },
    {
        text: "ABBEY COMMUNITY COLLEGE,  KILKENNY"
    },
    {
        text: "ABBEYFEALE B N S 1,  LIMERICK"
    },
    {
        text: "ABBEYLEIX STH N S,  LAOIS"
    },
    {
        text: "ABBEYSTREWRY N S,  CORK"
    },
    {
        text: "ABBEY VOCATIONAL SCHOOL,  DONEGAL"
    },
    {
        text: "ACHADH NA SILEANN,  LEITRIM"
    },
    {
        text: "ACHILL SOUND CONVENT NS,  MAYO"
    },
    {
        text: "ADAMSTOWN CASTLE EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "ADAMSTOWN COMMUNITY COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "ADRIGOLE N S,  CORK"
    },
    {
        text: "AGHACASLA N S,  KERRY"
    },
    {
        text: "AGHARDS N S,  KILDARE"
    },
    {
        text: "AGHATUBRID N S,  KERRY"
    },
    {
        text: "AGLISH N S,  WATERFORD"
    },
    {
        text: "AIBHISTIN NAOFA,  GALWAY"
    },
    {
        text: "AINBHTHIN NAOFA,  GALWAY"
    },
    {
        text: "AINE NAOFA N S,  MEATH"
    },
    {
        text: "ALEXANDRA COLLEGE,  DUBLIN 6"
    },
    {
        text: "ALL SAINTS NATIONAL SCHOOL,  WICKLOW"
    },
    {
        text: "ALL SAINTS N S,  DUBLIN COUNTY"
    },
    {
        text: "ALMHAINE N S,  KILDARE"
    },
    {
        text: "AN BHREAC CHLUAIN B,  KERRY"
    },
    {
        text: "AN CHROI RO NAOFA N S,  WICKLOW"
    },
    {
        text: "AN CHROI RO NAOFA SOIS,  DUBLIN 24"
    },
    {
        text: "AN GHRIANAN N S,  WESTMEATH"
    },
    {
        text: "AN LINBH IOSA,  KILDARE"
    },
    {
        text: "AN MHODH SCOIL,  LIMERICK"
    },
    {
        text: "ANNACARTY N S,  TIPPERARY"
    },
    {
        text: "ANNACURRA N S,  WICKLOW"
    },
    {
        text: "ANNADUFF MXD N S,  LEITRIM"
    },
    {
        text: "ANNAGH HILL NS,  GALWAY"
    },
    {
        text: "ANNAGH N S,  CLARE"
    },
    {
        text: "ANNALITTEN N S,  MONAGHAN"
    },
    {
        text: "ANNYALLA NATIONAL SCHOOL,  MONAGHAN"
    },
    {
        text: "AN TAONAD REAMHSCOILE,  DUBLIN 1"
    },
    {
        text: "AN TEAGHLAIGH NAOFA GIRLS JUNIOR NATIONAL SCHOOL,  WATERFORD"
    },
    {
        text: "ARCHBISHOP MCHALE COLLEGE,  GALWAY"
    },
    {
        text: "ARDAGH BOYS SENIOR NATIONAL SCHOOL,  CORK"
    },
    {
        text: "ARDARA MIXED N S,  DONEGAL"
    },
    {
        text: "ARDEE COMMUNITY SCHOOL,  LOUTH"
    },
    {
        text: "ARDEE EDUCATE TOGETHER NS,  LOUTH"
    },
    {
        text: "ARDEE MONASTERY,  LOUTH"
    },
    {
        text: "ARDEN BOYS NS,  OFFALY"
    },
    {
        text: "ARDFERT CENTRAL N S,  KERRY"
    },
    {
        text: "ARDFINNAN N S,  TIPPERARY"
    },
    {
        text: "ARDGILLAN COMMUNITY COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "ARDKEERIN N S,  SLIGO"
    },
    {
        text: "ARDLIOS N S,  LAOIS"
    },
    {
        text: "ARD NA GCRAITH NS,  WESTMEATH"
    },
    {
        text: "ARDPATRICK N S,  LIMERICK"
    },
    {
        text: "ARD RÍ COMMUNITY NATIONAL SCHOOL,  MEATH"
    },
    {
        text: "ARDSCOIL CHIARÁIN NAOFA,  OFFALY"
    },
    {
        text: "ÁRDSCOIL LA SALLE,  DUBLIN 5"
    },
    {
        text: "ARDSCOIL MHUIRE,  GALWAY"
    },
    {
        text: "ARDSCOIL MHUIRE,  LIMERICK"
    },
    {
        text: "ARDSCOIL NA MARA,  WATERFORD"
    },
    {
        text: "ÁRDSCOIL NA MBRÁITHRE,  TIPPERARY"
    },
    {
        text: "ARDSCOIL NA NDÉISE,  WATERFORD"
    },
    {
        text: "ÁRDSCOIL NA TRIONÓIDE,  KILDARE"
    },
    {
        text: "ARDSCOIL PHADRAIG,  LONGFORD"
    },
    {
        text: "ARDSCOIL RATH IOMGHÁIN,  KILDARE"
    },
    {
        text: "ARDSCOIL RIS,  LIMERICK"
    },
    {
        text: "ÁRDSCOIL RÍS,  DUBLIN 9"
    },
    {
        text: "ÁRDSCOIL UÍ URMOLTAIGH,  CORK"
    },
    {
        text: "ARDVARNEY MXD N S,  LEITRIM"
    },
    {
        text: "ARKLOW CBS,  WICKLOW"
    },
    {
        text: "ASHBOURNE COMMUNITY NATIONAL SCHOOL,  MEATH"
    },
    {
        text: "ASHBOURNE COMMUNITY SCHOOL,  MEATH"
    },
    {
        text: "ASHBOURNE EDUCATE TOGETHER NATIONAL SCHOOL,  MEATH"
    },
    {
        text: "ASHTON SCHOOL,  CORK"
    },
    {
        text: "ASKEATON SENIOR NS,  LIMERICK"
    },
    {
        text: "ASSUMPTION JUNIOR SCHOOL,  DUBLIN 12"
    },
    {
        text: "ASSUMPTION SECONDARY SCHOOL,  DUBLIN 12"
    },
    {
        text: "ASTON VILLAGE EDUCATE TOGETHER NATIONAL SCHOOL,  LOUTH"
    },
    {
        text: "ATHBOY COMMUNITY SCHOOL,  MEATH"
    },
    {
        text: "ATHEA N SCHOOL,  LIMERICK"
    },
    {
        text: "ATHLACCA N S,  LIMERICK"
    },
    {
        text: "ATHLEAGUE N S,  ROSCOMMON"
    },
    {
        text: "ATHLONE COMMUNITY COLLEGE,  WESTMEATH"
    },
    {
        text: "ATHLONE N S,  WESTMEATH"
    },
    {
        text: "ATH TREASNA G N S,  CORK"
    },
    {
        text: "ATHY COMMUNITY COLLEGE,  KILDARE"
    },
    {
        text: "ATHY MODEL SCHOOL,  KILDARE"
    },
    {
        text: "ATTYMON N S,  GALWAY"
    },
    {
        text: "AUGHADREENA NATIONAL SCHOOL,  CAVAN"
    },
    {
        text: "AUGHAVAS NS,  LEITRIM"
    },
    {
        text: "AUGHAWILLAN NS,  LEITRIM"
    },
    {
        text: "AUGHRIM N S,  ROSCOMMON"
    },
    {
        text: "AVONDALE COMMUNITY COLLEGE,  WICKLOW"
    },
    {
        text: "AYR HILL N S RAMELTON,  DONEGAL"
    },
    {
        text: "BAILE AN CHUILINN N S,  CARLOW"
    },
    {
        text: "BAILE COIREIL N S,  WESTMEATH"
    },
    {
        text: "BAILE FALBACH,  DUBLIN COUNTY"
    },
    {
        text: "BAILE MOR SIOL ANMCADHA,  GALWAY"
    },
    {
        text: "BAILE NA GCAILLEACH N S,  WESTMEATH"
    },
    {
        text: "BAILE NA MONA,  CAVAN"
    },
    {
        text: "BAILIEBORO MODEL N S,  CAVAN"
    },
    {
        text: "BAILIEBORO N S,  CAVAN"
    },
    {
        text: "BAILIEBOROUGH COMMUNITY SCHOOL,  CAVAN"
    },
    {
        text: "BAINRION NA NAINGAL 2,  DUBLIN 10"
    },
    {
        text: "BALBRIGGAN COMMUNITY COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "BALBRIGGAN EDUCATE TOGETHER NS,  DUBLIN COUNTY"
    },
    {
        text: "BALLAGHAMEEHAN N S,  LEITRIM"
    },
    {
        text: "BALLAGHKEENE N S,  WEXFORD"
    },
    {
        text: "BALLANAGARE N S,  ROSCOMMON"
    },
    {
        text: "BALLAPOUSTA NS,  LOUTH"
    },
    {
        text: "BALLA SECONDARY SCHOOL,  MAYO"
    },
    {
        text: "BALLINABRANNA MXD N S,  CARLOW"
    },
    {
        text: "BALLINADEE N S,  CORK"
    },
    {
        text: "BALLINAGORE MIXED N S,  WESTMEATH"
    },
    {
        text: "BALLINAGREE MXD N S,  CORK"
    },
    {
        text: "BALLINAHOWN NS,  WESTMEATH"
    },
    {
        text: "BALLINAKILL MIXED N S,  LAOIS"
    },
    {
        text: "BALLINAMORE COMMUNITY SCHOOL,  LEITRIM"
    },
    {
        text: "BALLINCARRIGA MXD N S,  CORK"
    },
    {
        text: "BALLINCOLLIG COMMUNITY SCHOOL,  CORK"
    },
    {
        text: "BALLINDAGGIN N S,  WEXFORD"
    },
    {
        text: "BALLINDANGAN MIXED N S,  CORK"
    },
    {
        text: "BALLINDERREEN MXD N S,  GALWAY"
    },
    {
        text: "BALLINDERRY N S,  GALWAY"
    },
    {
        text: "BALLINDINE B N S,  MAYO"
    },
    {
        text: "BALLINLOUGH N S,  MEATH"
    },
    {
        text: "BALLINLOUGH N S,  ROSCOMMON"
    },
    {
        text: "BALLINODE COLLEGE,  SLIGO"
    },
    {
        text: "BALLINROBE COMMUNITY SCHOOL,  MAYO"
    },
    {
        text: "BALLINSPITTLE N S,  CORK"
    },
    {
        text: "BALLINTEER COMMUNITY SCHOOL,  DUBLIN 16"
    },
    {
        text: "BALLINTEER EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 14"
    },
    {
        text: "BALLINTEMPLE N S,  CORK"
    },
    {
        text: "BALLINTLEVA N S,  ROSCOMMON"
    },
    {
        text: "BALLINTOGHER N S,  SLIGO"
    },
    {
        text: "BALLINTOTAS N S,  CORK"
    },
    {
        text: "BALLINTUBBER N S,  MAYO"
    },
    {
        text: "BALLYADAMS N S,  LAOIS"
    },
    {
        text: "BALLYBAY CENTRAL NS,  MONAGHAN"
    },
    {
        text: "BALLYBAY COMMUNITY COLLEGE,  MONAGHAN"
    },
    {
        text: "BALLYBAY NATIONAL SCHOOL,  ROSCOMMON"
    },
    {
        text: "BALLYBOGHILL N S,  DUBLIN COUNTY"
    },
    {
        text: "BALLYCAHILL N S,  TIPPERARY"
    },
    {
        text: "BALLYCANEW N S,  WEXFORD"
    },
    {
        text: "BALLYCAR N S,  CLARE"
    },
    {
        text: "BALLYCLOUGH MIXED N S,  CORK"
    },
    {
        text: "BALLYCONEELY N S,  GALWAY"
    },
    {
        text: "BALLYCONELL CENTRAL N S,  CAVAN"
    },
    {
        text: "BALLYCONNELL MXD N S,  CAVAN"
    },
    {
        text: "BALLYCONNELL N S,  CARLOW"
    },
    {
        text: "BALLYCURRANE N S,  WATERFORD"
    },
    {
        text: "BALLYDOWD HIGH SUPPORT SPECIAL SCHOOL,  DUBLIN 20"
    },
    {
        text: "BALLYDREHID N S,  TIPPERARY"
    },
    {
        text: "BALLYDUFF B 2 N S,  WATERFORD"
    },
    {
        text: "BALLYDUFF NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "BALLYDUFF N S,  WATERFORD"
    },
    {
        text: "BALLYDUFF N S,  WEXFORD"
    },
    {
        text: "BALLYEA MIXED N S,  CLARE"
    },
    {
        text: "BALLYFORAN MIXED N S,  ROSCOMMON"
    },
    {
        text: "BALLYGARVAN N S,  CORK"
    },
    {
        text: "BALLYGOWN NS,  CORK"
    },
    {
        text: "BALLYGUILTENANE N S,  LIMERICK"
    },
    {
        text: "BALLYHAISE N S,  CAVAN"
    },
    {
        text: "BALLYHASS MIXED N S,  CORK"
    },
    {
        text: "BALLYHAUNIS COMMUNITY SCHOOL,  MAYO"
    },
    {
        text: "BALLYHEA N S,  CORK"
    },
    {
        text: "BALLYHEEDA N S,  CORK"
    },
    {
        text: "BALLYKILMURRY N S,  OFFALY"
    },
    {
        text: "BALLYLANDERS N S,  LIMERICK"
    },
    {
        text: "BALLYLOOBY N S,  TIPPERARY"
    },
    {
        text: "BALLYMACARBERRY N S,  WATERFORD"
    },
    {
        text: "BALLYMACELLIGOTT 1 N S,  KERRY"
    },
    {
        text: "BALLYMACWARD CENTRAL SC,  GALWAY"
    },
    {
        text: "BALLYMAHON VOCATIONAL SCHOOL,  LONGFORD"
    },
    {
        text: "BALLYMAKENNY COLLEGE,  LOUTH"
    },
    {
        text: "BALLYMANA N S,  GALWAY"
    },
    {
        text: "BALLYMANY JUNIOR NS,  KILDARE"
    },
    {
        text: "BALLYMARTIN N S,  LIMERICK"
    },
    {
        text: "BALLYNACARGY MIXED N S,  WESTMEATH"
    },
    {
        text: "BALLYNARRY N S,  CAVAN"
    },
    {
        text: "BALLYNEALE N S,  TIPPERARY"
    },
    {
        text: "BALLYOUGHTER N S,  WEXFORD"
    },
    {
        text: "BALLYOWEN MEADOWS,  DUBLIN COUNTY"
    },
    {
        text: "BALLYRAGGET NATIONAL SCHOOL,  KILKENNY"
    },
    {
        text: "BALLYROAN    B N S,  DUBLIN 16"
    },
    {
        text: "BALLYROAN PRIMARY SCHOOL,  LAOIS"
    },
    {
        text: "BALLYROE CENTRAL N S,  KILDARE"
    },
    {
        text: "BALLYSHANNON N S,  KILDARE"
    },
    {
        text: "BALLYSTEEN N S,  LIMERICK"
    },
    {
        text: "BALLYTARSNA N S,  TIPPERARY"
    },
    {
        text: "BALLYVARY CENTRAL N S,  MAYO"
    },
    {
        text: "BALLYVONGANE MIXED N S,  CORK"
    },
    {
        text: "BALSCADDEN N S,  DUBLIN COUNTY"
    },
    {
        text: "BALTYDANIEL N S,  CORK"
    },
    {
        text: "BANAGHER COLLEGE,  OFFALY"
    },
    {
        text: "BANDON BOYS NS,  CORK"
    },
    {
        text: "BANDON GRAMMAR SCHOOL,  CORK"
    },
    {
        text: "BANOGUE NATIONAL SCHOOL,  LIMERICK"
    },
    {
        text: "BANSHA N S,  CLARE"
    },
    {
        text: "BANSHA N S,  TIPPERARY"
    },
    {
        text: "BANTEER N S,  CORK"
    },
    {
        text: "BANTIARNA NA MBUANNA BOYS,  DUBLIN 9"
    },
    {
        text: "BANTIARNA NA MBUANNA GIRLS,  DUBLIN 9"
    },
    {
        text: "BANTRY BOYS SENIOR NATIONAL SCHOOL,  CORK"
    },
    {
        text: "BAREFIELD MIXED N S,  CLARE"
    },
    {
        text: "BARNACARROLL CENTRAL NS,  MAYO"
    },
    {
        text: "BARNADERG CENTRAL SCH,  GALWAY"
    },
    {
        text: "BARNANE N S,  TIPPERARY"
    },
    {
        text: "BARNATRA N S,  MAYO"
    },
    {
        text: "BARR NA SRUTHAN N S,  LAOIS"
    },
    {
        text: "BARRYROE NS,  CORK"
    },
    {
        text: "BARTLEMY N S,  CORK"
    },
    {
        text: "BEACAN MIXED N S,  MAYO"
    },
    {
        text: "BEARA COMMUNITY SCHOOL,  CORK"
    },
    {
        text: "BEAUFORT COLLEGE,  MEATH"
    },
    {
        text: "BEAUMONT HOSPITAL SPECIAL SCHOOL,  DUBLIN 9"
    },
    {
        text: "BEECH HILL COLLEGE,  MONAGHAN"
    },
    {
        text: "BEHEYMORE N S,  MAYO"
    },
    {
        text: "BELGARD HEIGHTS N S,  DUBLIN 24"
    },
    {
        text: "BELLURGAN N S,  LOUTH"
    },
    {
        text: "BELMAYNE EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 13"
    },
    {
        text: "BELMAYNE EDUCATE TOGETHER SECONDARY SCHOOL,  DUBLIN 13"
    },
    {
        text: "BELVEDERE COLLEGE S.J,  DUBLIN 1"
    },
    {
        text: "BENEAVIN DE LA SALLE COLLEGE,  DUBLIN 11"
    },
    {
        text: "BENINCASA SPECIAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "BENNEKERRY NATIONAL SCHOOL,  CARLOW"
    },
    {
        text: "BENNETTSBRIDGE NATIONAL SCHOOL,  KILKENNY"
    },
    {
        text: "BHRIDE N S,  LAOIS"
    },
    {
        text: "BILBOA N SCHOOL,  LIMERICK"
    },
    {
        text: "BILLIS N S,  CAVAN"
    },
    {
        text: "BIRDHILL N S,  TIPPERARY"
    },
    {
        text: "BISHOP FOLEY MEMORIAL SCHOOL BOYS SNR SCH,  CARLOW"
    },
    {
        text: "BISHOP GALVIN NS,  DUBLIN 16"
    },
    {
        text: "BISHOP HARTY NS,  TIPPERARY"
    },
    {
        text: "BISHOP MURPHY MEMORIAL BOYS SENIOR NATIONAL SCHOOL,  CORK"
    },
    {
        text: "BISHOP SHANAHAN NS,  DUBLIN 16"
    },
    {
        text: "BISHOPSTOWN COMMUNITY SCHOOL,  CORK"
    },
    {
        text: "BLACKROCK COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "BLACKROCK EDUCATE TOGETHER SECONDARY SCHOOL,  DUBLIN"
    },
    {
        text: "BLACKWATER COMMUNITY SCHOOL,  WATERFORD"
    },
    {
        text: "BLAKESTOWN COMMUNITY SCHOOL,  DUBLIN 15"
    },
    {
        text: "BLESSED OLIVER N S,  DUBLIN COUNTY"
    },
    {
        text: "BLESSINGTON 1 N S,  WICKLOW"
    },
    {
        text: "BLESSINGTON COMMUNITY COLLEGE,  WICKLOW"
    },
    {
        text: "BLESSINGTON EDUCATE TOGETHER NS,  WICKLOW"
    },
    {
        text: "BL OLIVER PLUNKETT N S,  WESTMEATH"
    },
    {
        text: "BODYKE N S,  CLARE"
    },
    {
        text: "BOHERBUE COMPREHENSIVE SCHOOL,  CORK"
    },
    {
        text: "BOHERBUE N S,  CORK"
    },
    {
        text: "BOHERMEEN N S,  MEATH"
    },
    {
        text: "BOHERMORE N S,  LIMERICK"
    },
    {
        text: "BOHER N S,  TIPPERARY"
    },
    {
        text: "BOHER N S,  WESTMEATH"
    },
    {
        text: "BOHESHILL MXD,  KERRY"
    },
    {
        text: "BOOLAVOGUE N S,  WEXFORD"
    },
    {
        text: "BOOTERSTOWN BOYS,  DUBLIN COUNTY"
    },
    {
        text: "BOOTERSTOWN N S,  DUBLIN COUNTY"
    },
    {
        text: "BORRIS MXD N S,  CARLOW"
    },
    {
        text: "BORRISOKANE COMMUNITY COLLEGE,  TIPPERARY"
    },
    {
        text: "BORRIS VOCATIONAL SCHOOL,  CARLOW"
    },
    {
        text: "BOSTON N S,  CLARE"
    },
    {
        text: "BOYNE COMMUNITY SCHOOL,  MEATH"
    },
    {
        text: "BRACKEN EDUCATE TOGETHER NS,  DUBLIN COUNTY"
    },
    {
        text: "BRACKENSTOWN SENIOR N S,  DUBLIN COUNTY"
    },
    {
        text: "BRACKLOON N S,  MAYO"
    },
    {
        text: "BRANNOXTOWN  COMMUNITY N S,  KILDARE"
    },
    {
        text: "BRAY SCHOOL PROJECT N S,  WICKLOW"
    },
    {
        text: "BREAFFY N S,  MAYO"
    },
    {
        text: "BREIFNE COLLEGE,  CAVAN"
    },
    {
        text: "BREMORE EDUCATE TOGETHER SECONDARY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "BRIDESWELL N S,  ROSCOMMON"
    },
    {
        text: "BRIDGETOWN COLLEGE,  WEXFORD"
    },
    {
        text: "BRIERFIELD N SCHOOL,  GALWAY"
    },
    {
        text: "BRIGHDE NAOFA,  DUBLIN COUNTY"
    },
    {
        text: "BRITTAS BAY MXD N S,  WICKLOW"
    },
    {
        text: "BROADFORD & KILBANE NATIONAL SCHOOL,  CLARE"
    },
    {
        text: "BROADMEADOW COMMUNITY NATIONAL SCHOOL,  DUBLIN"
    },
    {
        text: "BROOMBRIDGE EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 7"
    },
    {
        text: "BROWNEKNOWE N S,  DONEGAL"
    },
    {
        text: "BRUCKLESS N S,  DONEGAL"
    },
    {
        text: "BRUREE NATIONAL SCHOOL,  LIMERICK"
    },
    {
        text: "BULGADEN N S,  LIMERICK"
    },
    {
        text: "BUNCLODY COMMUNITY COLLEGE,  WEXFORD"
    },
    {
        text: "BUNSCOIL BHOTHAR NA NAOMH,  WATERFORD"
    },
    {
        text: "BUNSCOIL CHRIOST RI,  CORK"
    },
    {
        text: "BUNSCOIL GLEANN SIDHEAIN,  WATERFORD"
    },
    {
        text: "BUNSCOIL LORETO,  WEXFORD"
    },
    {
        text: "BUNSCOIL LOUIS NAOFA,  MONAGHAN"
    },
    {
        text: "BUNSCOIL MCAULEY RICE,  KILKENNY"
    },
    {
        text: "BUN SCOIL MUIRE,  CORK"
    },
    {
        text: "BUNSCOIL NA MBRAITHRE,  CLARE"
    },
    {
        text: "BUNSCOIL NA MBRÁITHRE CRÍOSTAÍ,  TIPPERARY"
    },
    {
        text: "BUNSCOIL NAOMH BRÍD AGUS PÁDRAÍG,  MAYO"
    },
    {
        text: "BUNSCOIL NAOMH CHUANA,  GALWAY"
    },
    {
        text: "BUNSCOIL NA TOIRBHIRTE,  CORK"
    },
    {
        text: "BUNSCOIL NIC AMHLAIDH,  WEXFORD"
    },
    {
        text: "BUNSCOIL RINN AN CHABHLAIGH,  CORK"
    },
    {
        text: "BUNSCOIL RIS,  WEXFORD"
    },
    {
        text: "BURNCHURCH NATIONAL SCHOOL,  KILKENNY"
    },
    {
        text: "BURNFORT N S,  CORK"
    },
    {
        text: "BURRANE N S,  CLARE"
    },
    {
        text: "BURRISCARRA N S,  MAYO"
    },
    {
        text: "BURROW N S,  DUBLIN 13"
    },
    {
        text: "BUSH POST PRIMARY SCHOOL,  LOUTH"
    },
    {
        text: "BUSHY PARK N S,  GALWAY"
    },
    {
        text: "BUTLERSBRIDGE NS,  CAVAN"
    },
    {
        text: "CABINTEELY COMMUNITY SCHOOL,  DUBLIN 18"
    },
    {
        text: "CABRA CENTRAL N S,  CAVAN"
    },
    {
        text: "CABRA COMMUNITY COLLEGE,  DUBLIN 7"
    },
    {
        text: "CAHERAGH NS,  CORK"
    },
    {
        text: "CAHERCIVEEN N S,  KERRY"
    },
    {
        text: "CAHERLINE N S,  LIMERICK"
    },
    {
        text: "CAHERMORE NEW CENTRAL SCHOOL,  CORK"
    },
    {
        text: "CAHIR B N S,  TIPPERARY"
    },
    {
        text: "CAHIR NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "CAISLEAIN NUA LIAMHNA,  DUBLIN COUNTY"
    },
    {
        text: "CALASANCTIUS COLLEGE,  GALWAY"
    },
    {
        text: "CALLYSTOWN MIXED N S,  LOUTH"
    },
    {
        text: "CAMCLOON N S,  ROSCOMMON"
    },
    {
        text: "CAMOLIN N S,  WEXFORD"
    },
    {
        text: "CAMROSS N S,  LAOIS"
    },
    {
        text: "CANAL WAY EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 8"
    },
    {
        text: "CANON SHEEHAN PRIMARY SCHOOL,  CORK"
    },
    {
        text: "CANOVEE MIXED N S,  CORK"
    },
    {
        text: "CAOIMHIN NAOFA N S,  WICKLOW"
    },
    {
        text: "CAPPATAGLE CENTRAL SCH,  GALWAY"
    },
    {
        text: "CAPPAWHITE N S,  TIPPERARY"
    },
    {
        text: "CARAGH N S,  KILDARE"
    },
    {
        text: "CARA JUNIOR SCHOOL,  CORK"
    },
    {
        text: "CARBURY NATIONAL SCHOOL,  SLIGO"
    },
    {
        text: "CARIGEEN N S,  KILKENNY"
    },
    {
        text: "CARITAS COLLEGE,  DUBLIN 10"
    },
    {
        text: "CARLOW EDUCATE TOGETHER NS,  CARLOW"
    },
    {
        text: "CARMONA SPECIAL NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "CARNAIN N.S.,  GALWAY"
    },
    {
        text: "CARNANE MXD N S,  LIMERICK"
    },
    {
        text: "CARNAROSS N S,  MEATH"
    },
    {
        text: "CARNDONAGH COMMUNITY SCHOOL,  DONEGAL"
    },
    {
        text: "CARNEW N S,  WICKLOW"
    },
    {
        text: "CARN N S,  SLIGO"
    },
    {
        text: "CARRACASTLE CENTRAL NS,  MAYO"
    },
    {
        text: "CARRAHOLLY N S,  MAYO"
    },
    {
        text: "CARRAKENNEDY N S,  MAYO"
    },
    {
        text: "CARRAROE N S,  SLIGO"
    },
    {
        text: "CARRICKBEG N S,  WATERFORD"
    },
    {
        text: "CARRICKERRY N S,  LIMERICK"
    },
    {
        text: "CARRICKLECK N S,  MEATH"
    },
    {
        text: "CARRICK MIXED N S,  ROSCOMMON"
    },
    {
        text: "CARRICK N S,  ROSCOMMON"
    },
    {
        text: "CARRIGAHOLT MIXED N S,  CLARE"
    },
    {
        text: "CARRIGALINE COMMUNITY SCHOOL,  CORK"
    },
    {
        text: "CARRIGALINE COMMUNITY SPECIAL SCHOOL,  CORK"
    },
    {
        text: "CARRIGALINE EDUCATE TOGETHER NS,  CORK"
    },
    {
        text: "CARRIGALLEN N S,  LEITRIM"
    },
    {
        text: "CARRIGALLEN VOCATIONAL SCHOOL,  LEITRIM"
    },
    {
        text: "CARRIGBOY N S,  CORK"
    },
    {
        text: "CARRIGDUFF NAT SCHOOL,  WEXFORD"
    },
    {
        text: "CARRIGNAFOY COMMUNITY COLLEGE,  CORK"
    },
    {
        text: "CARRIG N S,  TIPPERARY"
    },
    {
        text: "CARRIG N S,  TIPPERARY"
    },
    {
        text: "CARRIGTWOHILL COMMUNITY NATIONAL SCHOOL,  CORK"
    },
    {
        text: "CARRIGTWOHILL POST PRIMARY,  CORK"
    },
    {
        text: "CARYSFORT MXD N S,  WICKLOW"
    },
    {
        text: "CARYSFORT NS,  DUBLIN COUNTY"
    },
    {
        text: "CASA CATERINA S S,  DUBLIN 7"
    },
    {
        text: "CASHEL COMMUNITY SCHOOL,  TIPPERARY"
    },
    {
        text: "CASHEL DEANERY,  TIPPERARY"
    },
    {
        text: "CASHELSHANAGHAN N S,  DONEGAL"
    },
    {
        text: "CASTAHEANY EDUCATE TOGETHER NS,  DUBLIN 15"
    },
    {
        text: "CASTLEALACK N S,  CORK"
    },
    {
        text: "CASTLEBAR EDUCATE TOGETHER NATIONAL SCHOOL,  MAYO"
    },
    {
        text: "CASTLEBAR PRIMARY SCHOOL,  MAYO"
    },
    {
        text: "CASTLEBLAKENEY N S,  GALWAY"
    },
    {
        text: "CASTLEBLAYNEY CENT N S,  MONAGHAN"
    },
    {
        text: "CASTLEBLAYNEY COLLEGE,  MONAGHAN"
    },
    {
        text: "CASTLEBLAYNEY CONVENT INFANTS NATIONAL SCHOOL,  MONAGHAN"
    },
    {
        text: "CASTLEBLAYNEY CONVENT NATIONAL SCHOOL,  MONAGHAN"
    },
    {
        text: "CASTLEBRIDGE N S,  WEXFORD"
    },
    {
        text: "CASTLECONNELL N S,  LIMERICK"
    },
    {
        text: "CASTLEDERMOT NS MXD,  KILDARE"
    },
    {
        text: "CASTLEDRUM N S,  KERRY"
    },
    {
        text: "CASTLEGREGORY MXD N S,  KERRY"
    },
    {
        text: "CASTLE INY N S,  TIPPERARY"
    },
    {
        text: "CASTLEISLAND COMMUNITY COLLEGE,  KERRY"
    },
    {
        text: "CASTLEJORDAN CENTRAL NS,  OFFALY"
    },
    {
        text: "CASTLEKNOCK COLLEGE,  DUBLIN 15"
    },
    {
        text: "CASTLEKNOCK COMMUNITY COLLEGE,  DUBLIN 15"
    },
    {
        text: "CASTLEKNOCK EDUCATE TOGETHER NS,  DUBLIN 15"
    },
    {
        text: "CASTLELYONS B N S,  CORK"
    },
    {
        text: "CASTLEMAINE N S,  KERRY"
    },
    {
        text: "CASTLEMARTYR N S,  CORK"
    },
    {
        text: "CASTLEPLUNKETT N S,  ROSCOMMON"
    },
    {
        text: "CASTLEPOLLARD COMMUNITY COLLEGE,  WESTMEATH"
    },
    {
        text: "CASTLEPOLLARD MIXED N S,  WESTMEATH"
    },
    {
        text: "CASTLEPOLLARD PAROC.N S,  WESTMEATH"
    },
    {
        text: "CASTLERAHAN CENTRAL NS,  CAVAN"
    },
    {
        text: "CASTLEREA COMMUNITY SCHOOL,  ROSCOMMON"
    },
    {
        text: "CASTLEROCK N S,  SLIGO"
    },
    {
        text: "CASTLETARA N S,  CAVAN"
    },
    {
        text: "CASTLETOWN N S,  MEATH"
    },
    {
        text: "CASTLETOWN N.S,  LAOIS"
    },
    {
        text: "CASTLETOWN RD CONVENT,  LOUTH"
    },
    {
        text: "CASTLETOWNROCHE N S,  CORK"
    },
    {
        text: "CASTLETOWNSEND MXD N S,  CORK"
    },
    {
        text: "CASTLETROY COLLEGE,  LIMERICK"
    },
    {
        text: "CATHERINE MC AULEY N SC,  DUBLIN 2"
    },
    {
        text: "CATHERINE MC AULEY SP S,  LIMERICK"
    },
    {
        text: "CATHOLIC UNIVERSITY SCHOOL,  DUBLIN 2"
    },
    {
        text: "CAUSEWAY COMPREHENSIVE SCHOOL,  KERRY"
    },
    {
        text: "CAVAN 1 N S,  CAVAN"
    },
    {
        text: "C.B.S. JAMES STREET,  DUBLIN 8"
    },
    {
        text: "C.B.S. MOUNT SION, WATERFORD,  WATERFORD"
    },
    {
        text: "C.B.S. PRIMARY,  LOUTH"
    },
    {
        text: "CBS PRIMARY WEXFORD,  WEXFORD"
    },
    {
        text: "C.B.S. ROSCOMMON,  ROSCOMMON"
    },
    {
        text: "C.B.S. SECONDARY SCHOOL,  KERRY"
    },
    {
        text: "C.B.S. THURLES,  TIPPERARY"
    },
    {
        text: "C.B.S. WESTLAND ROW,  DUBLIN 2"
    },
    {
        text: "CEANN EITIGH N S,  OFFALY"
    },
    {
        text: "CEATHRU NA NGARRDHANTA,  GALWAY"
    },
    {
        text: "CELBRIDGE COMMUNITY SCHOOL,  KILDARE"
    },
    {
        text: "CENTRAL INFS SCHOOL,  DUBLIN 1"
    },
    {
        text: "CENTRAL REMEDIAL CLINIC,  DUBLIN 3"
    },
    {
        text: "CENTRAL SENIOR MXD N S,  DUBLIN 1"
    },
    {
        text: "CENTRAL TECHNICAL INSTITUTE, CLOMEL,  TIPPERARY"
    },
    {
        text: "CHANEL COLLEGE,  DUBLIN 5"
    },
    {
        text: "CHAPELIZOD N S,  DUBLIN 20"
    },
    {
        text: "CHARLEVILLE N S,  OFFALY"
    },
    {
        text: "CHEEVERSTOWN SP SCH,  DUBLIN 6W"
    },
    {
        text: "CHERRYWOOD ETNS,  DUBLIN 18"
    },
    {
        text: "CHRIOST RI,  CLARE"
    },
    {
        text: "CHRIST CHURCH N S,  WATERFORD"
    },
    {
        text: "CHRISTIAN BROTHERS COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "CHRISTIAN BROTHERS COLLEGE,  CORK"
    },
    {
        text: "CHRISTIAN BROTHERS SECONDARY SCHOOL,  CORK"
    },
    {
        text: "CHRISTIAN BROTHERS SECONDARY SCHOOL,  CORK"
    },
    {
        text: "CHRISTIAN BROTHERS SECONDARY SCHOOL,  WEXFORD"
    },
    {
        text: "CHRISTIAN BROTHERS SECONDARY SCHOOL,  WEXFORD"
    },
    {
        text: "CHRISTIAN BROTHERS, SYNGE ST.,  DUBLIN 8"
    },
    {
        text: "CHRIST KING GIRLS' SECONDARY SCHOOL,  CORK"
    },
    {
        text: "CHRIST THE KING B N S,  DUBLIN 7"
    },
    {
        text: "CHRIST THE KING GIRLS SENIOR SCHOOL,  DUBLIN 7"
    },
    {
        text: "CHRIST THE KING I G,  DUBLIN 7"
    },
    {
        text: "CHURCH HILL MIXED N S,  KILKENNY"
    },
    {
        text: "CHURCH STREET NATIONAL SCHOOL,  LIMERICK"
    },
    {
        text: "CHURCHTOWN N S,  KILDARE"
    },
    {
        text: "CIARAN NAOFA,  ROSCOMMON"
    },
    {
        text: "CILL AN IUBHAIR N S,  LAOIS"
    },
    {
        text: "CILL CLEITHE N S,  WESTMEATH"
    },
    {
        text: "CILL COSCAIN,  DUBLIN COUNTY"
    },
    {
        text: "CILL MHOR IORRAIS,  MAYO"
    },
    {
        text: "CISTERCIAN COLLEGE,  TIPPERARY"
    },
    {
        text: "CITY QUAY NS,  DUBLIN 2"
    },
    {
        text: "CITY VOCATIONAL SCHOOL,  KILKENNY"
    },
    {
        text: "CITY WEST EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 24"
    },
    {
        text: "CITYWEST & SAGGART COMMUNITY NATIONAL SCHOOL,  DUBLIN 24"
    },
    {
        text: "CLARA CONVENT N S,  OFFALY"
    },
    {
        text: "CLARECASTLE NATIONAL SCHOOL,  CLARE"
    },
    {
        text: "CLAREGALWAY EDUCATE TOGETHER NS,  GALWAY"
    },
    {
        text: "CLAREMORRIS BOYS NS,  MAYO"
    },
    {
        text: "CLÁRIN COLLEGE,  GALWAY"
    },
    {
        text: "CLIFFONEY N S,  SLIGO"
    },
    {
        text: "CLOCH AN TSIONNAIGH N S,  LAOIS"
    },
    {
        text: "CLOCHAR DAINGEAN,  KERRY"
    },
    {
        text: "CLOCHAR LORETO N S,  DUBLIN 14"
    },
    {
        text: "CLOCHAR LUGHAIDH CAILIN,  DUBLIN 6"
    },
    {
        text: "CLOCHAR LUGHAIDH NAOIDH,  DUBLIN 6"
    },
    {
        text: "CLOCHAR MUIRE N S,  WICKLOW"
    },
    {
        text: "CLOCHAR NA TOIRBHIRTE,  TIPPERARY"
    },
    {
        text: "CLOCHAR PADRAIG NAOFA,  DONEGAL"
    },
    {
        text: "CLOCHAR SAN DOMINIC,  DUBLIN COUNTY"
    },
    {
        text: "CLOGHANS HILL N S,  MAYO"
    },
    {
        text: "CLOGHANS N S,  MAYO"
    },
    {
        text: "CLOGHEEN CENTRAL N S,  TIPPERARY"
    },
    {
        text: "CLOGHEEN MXD N S,  CORK"
    },
    {
        text: "CLOGHER MXD N S,  KERRY"
    },
    {
        text: "CLOGHER NS,  MAYO"
    },
    {
        text: "CLOGHER ROAD COMMUNITY COLLEGE,  DUBLIN 12"
    },
    {
        text: "CLOGHOOLA MIXED N S,  CORK"
    },
    {
        text: "CLOGHROE MIXED N S,  CORK"
    },
    {
        text: "CLOHANBEG N S,  CLARE"
    },
    {
        text: "CLOHANES N S,  CLARE"
    },
    {
        text: "CLONAGHADOO N S,  OFFALY"
    },
    {
        text: "CLONAKILTY COMMUNITY COLLEGE,  CORK"
    },
    {
        text: "CLONASLEE COLLEGE,  LAOIS"
    },
    {
        text: "CLONBERNE CENTRAL SCH,  GALWAY"
    },
    {
        text: "CLONBULLOGUE N S,  OFFALY"
    },
    {
        text: "CLONDROHID N S,  CORK"
    },
    {
        text: "CLONEA NS,  WATERFORD"
    },
    {
        text: "CLONEYHURKE N S,  OFFALY"
    },
    {
        text: "CLONGEEN MXD N S,  WEXFORD"
    },
    {
        text: "CLONGOWES WOOD COLLEGE,  KILDARE"
    },
    {
        text: "CLONKEEN COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "CLONMORE N S,  TIPPERARY"
    },
    {
        text: "CLONOULTY CENTRAL N S,  TIPPERARY"
    },
    {
        text: "CLONOWN N S,  ROSCOMMON"
    },
    {
        text: "CLONPRIEST N S,  CORK"
    },
    {
        text: "CLONROCHE N S,  WEXFORD"
    },
    {
        text: "CLONTIBRET N S,  MONAGHAN"
    },
    {
        text: "CLONTUBRID MIXED N S,  KILKENNY"
    },
    {
        text: "CLONTURK COLLEGE,  DUBLIN 9"
    },
    {
        text: "CLOONACOOL N S,  SLIGO"
    },
    {
        text: "CLOONBONNIF N S,  ROSCOMMON"
    },
    {
        text: "CLOONCAGH N S,  ROSCOMMON"
    },
    {
        text: "CLOONDAFF N S,  MAYO"
    },
    {
        text: "CLOONEY N S,  CLARE"
    },
    {
        text: "CLOONFAD N S,  ROSCOMMON"
    },
    {
        text: "CLOONFOUR N S,  ROSCOMMON"
    },
    {
        text: "CLOONIQUIN N S,  ROSCOMMON"
    },
    {
        text: "CLOONLYON N S,  MAYO"
    },
    {
        text: "CLOONTAGH MIXED N S,  LONGFORD"
    },
    {
        text: "CLOONTUSKERT NATIONAL SCHOOL,  ROSCOMMON"
    },
    {
        text: "CLOUGHANOWER N S,  GALWAY"
    },
    {
        text: "CLOUGHDUV N S,  CORK"
    },
    {
        text: "CLOUGHJORDAN NO 1 N S,  TIPPERARY"
    },
    {
        text: "CLOUGHJORDAN N S,  TIPPERARY"
    },
    {
        text: "CLOVERFIELD N S,  LIMERICK"
    },
    {
        text: "CLOVER HILL N S,  ROSCOMMON"
    },
    {
        text: "CLOYNE NATIONAL SCHOOL,  CORK"
    },
    {
        text: "CLUAIN BUINNE N S,  WESTMEATH"
    },
    {
        text: "CLUAIN DRAIGNEACH,  CLARE"
    },
    {
        text: "CLUAIN EIDHNEACH N S,  LAOIS"
    },
    {
        text: "CLUAIN MAOLAIN N S,  WESTMEATH"
    },
    {
        text: "CNOC AN TEAMPAILL,  CAVAN"
    },
    {
        text: "CNOC MHUIRE,  LONGFORD"
    },
    {
        text: "COACHFORD COLLEGE,  CORK"
    },
    {
        text: "COACHFORD N S,  CORK"
    },
    {
        text: "COBH N S,  CORK"
    },
    {
        text: "COLÁISTE ABBÁIN,  WEXFORD"
    },
    {
        text: "COLÁISTE AILIGH,  DONEGAL"
    },
    {
        text: "COLÁISTE AINDRIÚ,  CARLOW"
    },
    {
        text: "COLÁISTE AN ÁTHA,  WEXFORD"
    },
    {
        text: "COLÁISTE AN CHRAOIBHÍN,  CORK"
    },
    {
        text: "COLÁISTE AN CHREAGÁIN,  GALWAY"
    },
    {
        text: "COLÁISTE AN CHROÍ NAOFA,  CORK"
    },
    {
        text: "COLÁISTE AN EACHRÉIDH,  GALWAY"
    },
    {
        text: "COLAISTE AN PHIARSAIGH,  CORK"
    },
    {
        text: "COLÁISTE AN SPIORAID NAOIMH,  CORK"
    },
    {
        text: "COLÁISTE BHAILE CHLÁIR,  GALWAY"
    },
    {
        text: "COLÁISTE BHRÍDE CARNEW,  WICKLOW"
    },
    {
        text: "COLÁISTE BRÍDE,  DUBLIN 22"
    },
    {
        text: "COLÁISTE BRÍDE,  WEXFORD"
    },
    {
        text: "COLAISTE CHIARAIN,  LIMERICK"
    },
    {
        text: "COLÁISTE CHIARÁIN,  ROSCOMMON"
    },
    {
        text: "COLÁISTE CHILLIAIN,  DUBLIN 22"
    },
    {
        text: "COLÁISTE CHILL MHANTÁIN,  WICKLOW"
    },
    {
        text: "COLAISTE CHOILM,  OFFALY"
    },
    {
        text: "COLÁISTE CHOILM,  DUBLIN COUNTY"
    },
    {
        text: "COLÁISTE CHOILM,  CORK"
    },
    {
        text: "COLÁISTE CHOLMÁIN,  CORK"
    },
    {
        text: "COLÁISTE CHOLMÁIN,  MAYO"
    },
    {
        text: "COLAISTE CHOLMCILLE,  GALWAY"
    },
    {
        text: "COLÁISTE CHOLMCILLE,  DONEGAL"
    },
    {
        text: "COLAISTE CHOMAIN,  MAYO"
    },
    {
        text: "COLAISTE CHRAOBH ABHANN,  WICKLOW"
    },
    {
        text: "COLÁISTE CHRÍOST RÍ,  CORK"
    },
    {
        text: "COLAISTE CHROI MHUIRE,  GALWAY"
    },
    {
        text: "COLÁISTE CHÚ CHULAINN,  LOUTH"
    },
    {
        text: "COLÁISTE CLAVIN,  MEATH"
    },
    {
        text: "COLÁISTE COIS LIFE,  DUBLIN COUNTY"
    },
    {
        text: "COLÁISTE COIS SIÚIRE,  KILKENNY"
    },
    {
        text: "COLÁISTE DAIBHÉID,  CORK"
    },
    {
        text: "COLÁISTE DE HÍDE,  DUBLIN 24"
    },
    {
        text: "COLÁISTE DÚN AN RÍ,  CAVAN"
    },
    {
        text: "COLAISTE DUN IASCAIGH,  TIPPERARY"
    },
    {
        text: "COLÁISTE ÉAMANN RÍS,  KILKENNY"
    },
    {
        text: "COLÁISTE ÉAMANN RÍS,  CORK"
    },
    {
        text: "COLÁISTE ÉANNA,  DUBLIN 16"
    },
    {
        text: "COLÁISTE EINDE,  GALWAY"
    },
    {
        text: "COLÁISTE EOIN,  DUBLIN COUNTY"
    },
    {
        text: "COLÁISTE EOIN,  DUBLIN 11"
    },
    {
        text: "COLÁISTE EOIN,  CARLOW"
    },
    {
        text: "COLÁISTE FIONNCHUA,  CORK"
    },
    {
        text: "COLÁISTE GHLÓR NA MARA,  DUBLIN COUNTY"
    },
    {
        text: "COLÁISTE GHOBNAIT,  GALWAY"
    },
    {
        text: "COLAISTE GHOBNATAN,  CORK"
    },
    {
        text: "COLÁISTE GLEANN LÍ POST PRIMARY SCHOOL,  KERRY"
    },
    {
        text: "COLÁISTE IASCAIGH,  SLIGO"
    },
    {
        text: "COLÁISTE ÍDE,  KERRY"
    },
    {
        text: "COLÁISTE ÍDE AGUS IOSAEF,  LIMERICK"
    },
    {
        text: "COLÁISTE IOGNÁID S.J.,  GALWAY"
    },
    {
        text: "COLÁISTE IÓSAEF,  LIMERICK"
    },
    {
        text: "COLÁISTE ÍOSAGÁIN,  DUBLIN COUNTY"
    },
    {
        text: "COLÁISTE ÍOSAGÁIN,  LAOIS"
    },
    {
        text: "COLAISTE LORCAIN,  KILDARE"
    },
    {
        text: "COLAISTE MHICHIL,  LIMERICK"
    },
    {
        text: "COLAISTE MHUIRE,  LIMERICK"
    },
    {
        text: "COLAISTE MHUIRE,,  WESTMEATH"
    },
    {
        text: "COLÁISTE MHUIRE,  DUBLIN 7"
    },
    {
        text: "COLÁISTE MHUIRE,  CLARE"
    },
    {
        text: "COLÁISTE MHUIRE,  GALWAY"
    },
    {
        text: "COLÁISTE MHUIRE,  MAYO"
    },
    {
        text: "COLÁISTE MHUIRE,  KILKENNY"
    },
    {
        text: "COLÁISTE MHUIRE CO-ED,  TIPPERARY"
    },
    {
        text: "COLÁISTE MHUIRLINNE/MERLIN COLLEGE,  GALWAY"
    },
    {
        text: "COLAISTE MUIRE,  CORK"
    },
    {
        text: "COLÁISTE MUIRE,  CORK"
    },
    {
        text: "COLÁISTE MUIRE,  SLIGO"
    },
    {
        text: "COLÁISTE MUIRE MÁTHAIR,  GALWAY"
    },
    {
        text: "COLÁISTE NA BPIARSACH,  GALWAY"
    },
    {
        text: "COLÁISTE NA CARRAIGE,  DONEGAL"
    },
    {
        text: "COLÁISTE NA COIRIBE,  GALWAY"
    },
    {
        text: "COLAISTE NA HINSE,  MEATH"
    },
    {
        text: "COLÁISTE NA MÍ,  MEATH"
    },
    {
        text: "COLÁISTE NANO NAGLE,  LIMERICK"
    },
    {
        text: "COLÁISTE NAOMH CORMAC,  OFFALY"
    },
    {
        text: "COLÁISTE NAOMH ÉINNE,  GALWAY"
    },
    {
        text: "COLÁISTE NAOMH EOIN,  GALWAY"
    },
    {
        text: "COLÁISTE NAOMH FEICHÍN,  GALWAY"
    },
    {
        text: "COLÁISTE NAOMH MHUIRE,  KILDARE"
    },
    {
        text: "COLÁISTE NA RÍOCHTA,  KERRY"
    },
    {
        text: "COLÁISTE NA SCEILGE,  KERRY"
    },
    {
        text: "COLÁISTE NA TOIRBHIRTE,  CORK"
    },
    {
        text: "COLAISTE NA TROCAIRE (MERCY COMMUNITY COLLEGE),  LIMERICK"
    },
    {
        text: "COLÁISTE OIRIALL,  MONAGHAN"
    },
    {
        text: "COLÁISTE PHÁDRAIG CBS,  DUBLIN COUNTY"
    },
    {
        text: "COLÁISTE PHOBAIL CHOLMCILLE,  DONEGAL"
    },
    {
        text: "COLAISTE PHOBÁIL ROS CRÉ,  TIPPERARY"
    },
    {
        text: "COLÁISTE POBAIL ACLA,  MAYO"
    },
    {
        text: "COLÁISTE POBAIL BHEANNTRAÍ,  CORK"
    },
    {
        text: "COLÁISTE POBAIL FÓLA,  DUBLIN D24"
    },
    {
        text: "COLAISTE POBAIL NAOMH MHUIRE,  CORK"
    },
    {
        text: "COLÁISTE POBAIL OSRAÍ,  KILKENNY"
    },
    {
        text: "COLÁISTE POBAIL RATH CAIRN,  MEATH"
    },
    {
        text: "COLÁISTE POBAIL SETANTA,  DUBLIN 15"
    },
    {
        text: "COLÁISTE RAITHÍN,  WICKLOW"
    },
    {
        text: "COLÁISTE RIOGA,  MEATH"
    },
    {
        text: "COLAISTE RÍS,  LOUTH"
    },
    {
        text: "COLAISTE SHEOSAIMH,  GALWAY"
    },
    {
        text: "COLÁISTE SHLIABH NA MBAN,  TIPPERARY"
    },
    {
        text: "COLÁISTE TREASA,  CORK"
    },
    {
        text: "COLEHILL MIXED N S,  LONGFORD"
    },
    {
        text: "COLLINSTOWN PARK COMMUNITY COLLEGE,  DUBLIN 22"
    },
    {
        text: "COLMCILLE MIXED N S,  KILKENNY"
    },
    {
        text: "COLUMBA COLLEGE,  WESTMEATH"
    },
    {
        text: "COMERAGH COLLEGE,  TIPPERARY"
    },
    {
        text: "COMHSCOIL CHONMHA S N,  DONEGAL"
    },
    {
        text: "COMMUNITY COLLEGE DUNSHAUGHLIN,  MEATH"
    },
    {
        text: "COMMUNITY COLLEGE KILLORGLIN,  KERRY"
    },
    {
        text: "COMMUNITY SCHOOL,  KILKENNY"
    },
    {
        text: "COMMUNITY SCHOOL,  LEITRIM"
    },
    {
        text: "CONFEY COMMUNITY COLLEGE,  KILDARE"
    },
    {
        text: "CONNOLLY N S,  CLARE"
    },
    {
        text: "CONVENT GIRLS SENIOR NATIONAL SCHOOL,  CORK"
    },
    {
        text: "CONVENT OF MERCY,  TIPPERARY"
    },
    {
        text: "CONVENT OF MERCY,  WEXFORD"
    },
    {
        text: "CONVENT OF MERCY NATIONAL SCHOOL,  CLARE"
    },
    {
        text: "CONVENT OF MERCY N S,  CAVAN"
    },
    {
        text: "CONVENT OF MERCY N S,  LIMERICK"
    },
    {
        text: "CONVENT OF MERCY N S,  MAYO"
    },
    {
        text: "COOLA POST PRIMARY SCHOOL,  SLIGO"
    },
    {
        text: "COOLARD MXD N S,  KERRY"
    },
    {
        text: "COOLBOCK N S,  SLIGO"
    },
    {
        text: "COOLCAPPA N S,  LIMERICK"
    },
    {
        text: "COOLDERRY CENTRAL NS,  OFFALY"
    },
    {
        text: "COOLE N.S,,  MEATH"
    },
    {
        text: "COOLFANCY N S,  WICKLOW"
    },
    {
        text: "COOLICK NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "COOLMEEN N S,  CLARE"
    },
    {
        text: "COOLOCK COMMUNITY COLLEGE,  DUBLIN 17"
    },
    {
        text: "COOMHOLA N S,  CORK"
    },
    {
        text: "COONEAL N.S.,  MAYO"
    },
    {
        text: "COORACLARE B N S,  CLARE"
    },
    {
        text: "COORE NATIONAL SCHOOL,  CLARE"
    },
    {
        text: "CORBALLA N S,  SLIGO"
    },
    {
        text: "CORCAGHAN N S,  MONAGHAN"
    },
    {
        text: "CORCLOUGH NS,  MAYO"
    },
    {
        text: "CORCREAGH N S,  MONAGHAN"
    },
    {
        text: "CORDUFF N S,  DUBLIN COUNTY"
    },
    {
        text: "CORK COLLEGE OF COMMERCE,  CORK"
    },
    {
        text: "CORK EDUCATE TOGETHER NATIONAL SCHOOL,  CORK"
    },
    {
        text: "CORK EDUCATE TOGETHER SECONDARY SCHOOL,  CORK"
    },
    {
        text: "CORK UNIVERSITY HOS SCHOOL,  CORK"
    },
    {
        text: "CORLEA N S,  CAVAN"
    },
    {
        text: "CORLISS N S,  CAVAN"
    },
    {
        text: "CORMAIC NFA,  MAYO"
    },
    {
        text: "CORMAIC SPECIAL SCHOOL,  TIPPERARY"
    },
    {
        text: "CORONEA N S,  CAVAN"
    },
    {
        text: "CORPUS CHRISTI N S,  DUBLIN 9"
    },
    {
        text: "CORPUS CHRISTI N S,  LIMERICK"
    },
    {
        text: "CORR A CHRAINN NATIONAL SCHOOL,  MONAGHAN"
    },
    {
        text: "CORRAN COLLEGE,  SLIGO"
    },
    {
        text: "CORR NA MADADH N S,  WESTMEATH"
    },
    {
        text: "COSBY N S,  LAOIS"
    },
    {
        text: "COURT N S,  WEXFORD"
    },
    {
        text: "CRAGGAGH N S,  MAYO"
    },
    {
        text: "CRANA COLLEGE,  DONEGAL"
    },
    {
        text: "CRANFORD N S,  DONEGAL"
    },
    {
        text: "CRANNOG NUA SPECIAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "CRATLOE N S,  CLARE"
    },
    {
        text: "CREAGH COLLEGE,  WEXFORD"
    },
    {
        text: "CREESLOUGH N S,  DONEGAL"
    },
    {
        text: "CREGGS CENTRAL N S,  GALWAY"
    },
    {
        text: "CRESCENT COLLEGE COMPREHENSIVE,  LIMERICK"
    },
    {
        text: "CRIMLIN N S,  MAYO"
    },
    {
        text: "CROAGH NATIONAL SCHOOL,  LIMERICK"
    },
    {
        text: "CROAGHROSS N S,  DONEGAL"
    },
    {
        text: "CROCHAN NAOFA N S,  KERRY"
    },
    {
        text: "CROINCHOILL N S,  OFFALY"
    },
    {
        text: "CROI RONAOFA MEASCAITHE,  GALWAY"
    },
    {
        text: "CROMCASTLE GREEN B N S,  DUBLIN 5"
    },
    {
        text: "CRONAN NAOFA N S,  WICKLOW"
    },
    {
        text: "CROOM NATIONAL SCHOOL,  LIMERICK"
    },
    {
        text: "CROSS AND PASSION COLLEGE,  KILDARE"
    },
    {
        text: "CROSSBRIDGE N S,  WICKLOW"
    },
    {
        text: "CROSSERLOUGH N S,  CAVAN"
    },
    {
        text: "CROSSKEYS CENTRAL N S,  CAVAN"
    },
    {
        text: "CROSSMOLINA N.S.,  MAYO"
    },
    {
        text: "CROSS N S,  CLARE"
    },
    {
        text: "CROWENSTOWN N S,  WESTMEATH"
    },
    {
        text: "CRUMLIN N S,  GALWAY"
    },
    {
        text: "CUAN NA GAILLIMHE COMMUNITY NATIONAL SCHOOL,  GALWAY"
    },
    {
        text: "CULLEN N S,  TIPPERARY"
    },
    {
        text: "CULLENS N S,  MAYO"
    },
    {
        text: "CULMORE N S,  MAYO"
    },
    {
        text: "CURRACLOE N S,  WEXFORD"
    },
    {
        text: "CURRAGHAGALLA N S,  CORK"
    },
    {
        text: "CURRAGH CAMP G N S,  KILDARE"
    },
    {
        text: "CURRAGH COMMUNITY COLLEGE,  KILDARE"
    },
    {
        text: "CURRAHEEN MXD N S,  KERRY"
    },
    {
        text: "CURRANES N S,  KERRY"
    },
    {
        text: "CURRIGLASS NS,  CORK"
    },
    {
        text: "DAINGEAN N S,  OFFALY"
    },
    {
        text: "DALKEY N S (2),  DUBLIN COUNTY"
    },
    {
        text: "DALKEY SCHOOL PROJECT,  DUBLIN COUNTY"
    },
    {
        text: "DALYSTOWN N S,  WESTMEATH"
    },
    {
        text: "DANGAN MIXED N S,  MEATH"
    },
    {
        text: "DANGAN NS,  ROSCOMMON"
    },
    {
        text: "DANU COMMUNITY SPECIAL SCHOOL,  DUBLIN 15"
    },
    {
        text: "DARLEY N S,  CAVAN"
    },
    {
        text: "DARNDALE NS JUNIOR,  DUBLIN 17"
    },
    {
        text: "DAVIDSTOWN PRIMARY SCHOOL,  WEXFORD"
    },
    {
        text: "DAVIS COLLEGE,  CORK"
    },
    {
        text: "DAVITT COLLEGE,  MAYO"
    },
    {
        text: "DEANSRATH COMMUNITY COLLEGE,  DUBLIN 22"
    },
    {
        text: "DEELE COLLEGE,  DONEGAL"
    },
    {
        text: "DE LACY COLLEGE,  MEATH"
    },
    {
        text: "DE LA SALLE COLLEGE,  DUBLIN 14"
    },
    {
        text: "DE LA SALLE COLLEGE,  CORK"
    },
    {
        text: "DE LA SALLE COLLEGE,  LOUTH"
    },
    {
        text: "DE LA SALLE COLLEGE,  WATERFORD"
    },
    {
        text: "DELGANY N S,  WICKLOW"
    },
    {
        text: "DERAVOY NATIONAL SCHOOL,  MONAGHAN"
    },
    {
        text: "DERRINACAHARA N S,  CORK"
    },
    {
        text: "DERRINAGREE N S,  CORK"
    },
    {
        text: "DERRYCREHA N S,  CORK"
    },
    {
        text: "DERRYQUAY MXD N S,  KERRY"
    },
    {
        text: "DESERTSERGES N S,  CORK"
    },
    {
        text: "DESMOND COLLEGE,  LIMERICK"
    },
    {
        text: "DIARMADA N S,  WESTMEATH"
    },
    {
        text: "DIFFEREEN N S,  LEITRIM"
    },
    {
        text: "DIVINE MERCY JUNIOR NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "DIVINE MERCY SENIOR NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "DOMINICAN COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "DOMINICAN COLLEGE,  DUBLIN 9"
    },
    {
        text: "DOMINICAN COLLEGE,  WICKLOW"
    },
    {
        text: "DOMINICAN COLLEGE,  GALWAY"
    },
    {
        text: "DOMINICAN CONVENT GIRLS SENIOR SCHOOL,  DUBLIN 7"
    },
    {
        text: "DONABATE COMMUNITY COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "DONABATE/PORTRANE EDUCATE TOGETHER NS,  DUBLIN COUNTY"
    },
    {
        text: "DONAGHMORE N S,  WICKLOW"
    },
    {
        text: "DONARD N S,  WICKLOW"
    },
    {
        text: "DON N S,  ROSCOMMON"
    },
    {
        text: "DONOUGHMORE N S,  LIMERICK"
    },
    {
        text: "DOOHAMLET N S,  MONAGHAN"
    },
    {
        text: "DOOISH N S,  DONEGAL"
    },
    {
        text: "DOOKINELLA  N.S.,  MAYO"
    },
    {
        text: "DOOLIN MIXED N S,  CLARE"
    },
    {
        text: "DOONAHA N S,  CLARE"
    },
    {
        text: "DOONBEG N S,  CLARE"
    },
    {
        text: "DOON CONVENT N S,  LIMERICK"
    },
    {
        text: "DOUGLAS B N S,  CORK"
    },
    {
        text: "DOUGLAS COMMUNITY SCHOOL,  CORK"
    },
    {
        text: "DOUGLAS ROCHESTOWN EDUCATE TOGETHER NATIONAL SCHOOL,  CORK"
    },
    {
        text: "DRIMNAGH CASTLE CBS N S,  DUBLIN 12"
    },
    {
        text: "DRIMOLEAGUE JUNIOR SCH,  CORK"
    },
    {
        text: "DRINAGH MIXED NS,  CORK"
    },
    {
        text: "DRIPSEY N S,  CORK"
    },
    {
        text: "DROGHEDA GRAMMAR SCHOOL,  LOUTH"
    },
    {
        text: "DROMAGH MIXED N S,  CORK"
    },
    {
        text: "DROMAILI S N,  CAVAN"
    },
    {
        text: "DROMCLOUGH N S,  KERRY"
    },
    {
        text: "DROMDHALLAGH N S,  CORK"
    },
    {
        text: "DROMINDOORA N.S.,  CLARE"
    },
    {
        text: "DROMIN N S,  LOUTH"
    },
    {
        text: "DROMISKIN MIXED N S,  LOUTH"
    },
    {
        text: "DROMLEIGH N S,  CORK"
    },
    {
        text: "DROMORE WEST CENTRAL,  SLIGO"
    },
    {
        text: "DROMTRASNA N S,  LIMERICK"
    },
    {
        text: "DRUIMNE N S,  CORK"
    },
    {
        text: "DRUMACRUTTIN N S,  MONAGHAN"
    },
    {
        text: "DRUMBARRAGH N S,  MEATH"
    },
    {
        text: "DRUMCLUGH N S,  CORK"
    },
    {
        text: "DRUMCONDRA MARINO DUBLIN 1 GAELSCOIL,  DUBLIN"
    },
    {
        text: "DRUMCONDRA N S,  DUBLIN 9"
    },
    {
        text: "DRUMCORRIN N S,  MONAGHAN"
    },
    {
        text: "DRUMEELA N S,  LEITRIM"
    },
    {
        text: "DRUMFAD N S,  DONEGAL"
    },
    {
        text: "DRUMGALLAGH N S,  MAYO"
    },
    {
        text: "DRUMGOSSETT N S,  MONAGHAN"
    },
    {
        text: "DRUMKEERIN CENTRAL NS,  LEITRIM"
    },
    {
        text: "DRUMKILLY NS,  CAVAN"
    },
    {
        text: "DRUMLEASE N S,  LEITRIM"
    },
    {
        text: "DRUMRANEY MIXED N S,  WESTMEATH"
    },
    {
        text: "DRUMSHANBO VOCATIONAL SCHOOL,  LEITRIM"
    },
    {
        text: "DRUNG NO 2 NS,  CAVAN"
    },
    {
        text: "DUALLA N S,  TIPPERARY"
    },
    {
        text: "DUBLIN 6 CLONSKEAGH AND DUBLIN 6W PRIMARY SCHOOL,  DUBLIN 6W"
    },
    {
        text: "DUBLIN 7 EDUCATE TOGETHER NS,  DUBLIN 7"
    },
    {
        text: "DUISKE COLLEGE,  KILKENNY"
    },
    {
        text: "DULARGY MIXED N S,  LOUTH"
    },
    {
        text: "DUNAMASE COLLEGE (COLÁISTE DHÚN MÁSC),  LAOIS"
    },
    {
        text: "DUNBOYNE JUNIOR N S,  MEATH"
    },
    {
        text: "DUNBOYNE SENIOR N S,  MEATH"
    },
    {
        text: "DUNDALK GRAMMAR SCHOOL,  LOUTH"
    },
    {
        text: "DUNDAR MHUIGHE N S,  CORK"
    },
    {
        text: "DUN DEALGAN N S,  LOUTH"
    },
    {
        text: "DUNFANAGHY N S,  DONEGAL"
    },
    {
        text: "DUNGARVAN COLLEGE-COLÁISTE DHÚN GARBHÁN,  WATERFORD"
    },
    {
        text: "DUNGOURNEY CENTRAL N SC,  CORK"
    },
    {
        text: "DUN LAOGHAIRE ETNS,  DUBLIN COUNTY"
    },
    {
        text: "DUNMANWAY BOYS SENIOR NATIONAL SCHOOL,  CORK"
    },
    {
        text: "DUNMANWAY CONVENT GIRLS SENIOR NATIONAL SCHOOL,  CORK"
    },
    {
        text: "DUNMANWAY CONVENT INF,  CORK"
    },
    {
        text: "DUNMANWAY MODEL N S,  CORK"
    },
    {
        text: "DUNMORE COMMUNITY SCHOOL,  GALWAY"
    },
    {
        text: "DUNSHAUGHLIN COMMUNITY NATIONAL SCHOOL,  MEATH"
    },
    {
        text: "DYSART NS,  WESTMEATH"
    },
    {
        text: "EARNAIN MXD N S,  WESTMEATH"
    },
    {
        text: "EAST GLENDALOUGH SCHOOL,  WICKLOW"
    },
    {
        text: "EDENDERRY 2 N S,  OFFALY"
    },
    {
        text: "EDENDERRY CONVENT N S,  OFFALY"
    },
    {
        text: "EDENMORE N.S.,  MONAGHAN"
    },
    {
        text: "EDMUND RICE COLLEGE,  CORK"
    },
    {
        text: "EDMUND RICE COLLEGE,  DUBLIN"
    },
    {
        text: "EDMUND RICE SECONDARY SCHOOL,  TIPPERARY"
    },
    {
        text: "EIDHNEACH N S,  CLARE"
    },
    {
        text: "EILTIN NAOFA,  KERRY"
    },
    {
        text: "ELLENFIELD COMMUNITY COLLEGE,  DUBLIN 9"
    },
    {
        text: "ELPHIN COMMUNITY COLLEGE,  ROSCOMMON"
    },
    {
        text: "EMO MIXED N S,  LAOIS"
    },
    {
        text: "ENABLE IRELAND SANDYMOUNT SCHOOL,  DUBLIN 4"
    },
    {
        text: "ENFIELD COMMUNITY COLLEGE,  MEATH"
    },
    {
        text: "ENNIS COMMUNITY COLLEGE,  CLARE"
    },
    {
        text: "ENNISCORTHY COMMUNITY COLLEGE,  WEXFORD"
    },
    {
        text: "ENNISCRONE N S,  SLIGO"
    },
    {
        text: "ENNIS EDUCATE TOGETHER NS,  CLARE"
    },
    {
        text: "ENNIS N S,  CLARE"
    },
    {
        text: "ENNISTYMON NATIONAL SCHOOL,  CLARE"
    },
    {
        text: "ENNISTYMON VOCATIONAL SCHOOL,  CLARE"
    },
    {
        text: "EOCHAILLE ARA N S,  TIPPERARY"
    },
    {
        text: "EOIN BAISDE B SOIS,  DUBLIN 3"
    },
    {
        text: "EOIN BAISDE C NAOIDH,  DUBLIN 3"
    },
    {
        text: "EOIN NAOFA N S,  WESTMEATH"
    },
    {
        text: "ERIU COMMUNITY COLLEGE,  DUBLIN 15"
    },
    {
        text: "ERRIGAL COLLEGE,  DONEGAL"
    },
    {
        text: "ESKER EDUCATE TOGETHER NS,  DUBLIN COUNTY"
    },
    {
        text: "ESKER N S,  GALWAY"
    },
    {
        text: "EUREKA SECONDARY SCHOOL,  MEATH"
    },
    {
        text: "FAHA NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "FAIRGREEN N S,  CAVAN"
    },
    {
        text: "FAIRYMOUNT N S,  ROSCOMMON"
    },
    {
        text: "FAITIMA N S,  LEITRIM"
    },
    {
        text: "FAUGHART COMMUNITY NATIONAL SCHOOL,  LOUTH"
    },
    {
        text: "FAYTHE CONVENT,  WEXFORD"
    },
    {
        text: "F.C.J.  SECONDARY SCHOOL,  WEXFORD"
    },
    {
        text: "FEDAMORE N S,  LIMERICK"
    },
    {
        text: "FERMOY ADAIR N S,  CORK"
    },
    {
        text: "FERMOY EDUCATE TOGETHER NS,  CORK"
    },
    {
        text: "FERMOYLE MIXED N S,  LONGFORD"
    },
    {
        text: "FIBOUGH NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "FINGAL COMMUNITY COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "FINN VALLEY COLLEGE,  DONEGAL"
    },
    {
        text: "FIONNABHAIR N S,  WATERFORD"
    },
    {
        text: "FIONNBARRA NAOFA B.N.S.,  DUBLIN 7"
    },
    {
        text: "FIONNTAIN NFA N S,  WEXFORD"
    },
    {
        text: "FIRHOUSE COMMUNITY COLLEGE,  DUBLIN 24"
    },
    {
        text: "FIRHOUSE EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 24"
    },
    {
        text: "FIRHOUSE EDUCATE TOGETHER SECONDARY SCHOOL,  DUBLIN 24"
    },
    {
        text: "FIRIES MXD N S,  KERRY"
    },
    {
        text: "FIRMOUNT MIXED N S,  CORK"
    },
    {
        text: "FLAGMOUNT CENTRAL NS,  CLARE"
    },
    {
        text: "FLOWERFIELD N S,  MEATH"
    },
    {
        text: "FORGLEANN N S,  CLARE"
    },
    {
        text: "FORGNEY N S,  LONGFORD"
    },
    {
        text: "FOUR MASTERS NS,  LEITRIM"
    },
    {
        text: "FOXFORD CENTRAL NS,  MAYO"
    },
    {
        text: "FRANCISCAN COLLEGE,  MEATH"
    },
    {
        text: "FRAOCH MOR N S,  LAOIS"
    },
    {
        text: "FR CULLEN MEMORIAL N S,  CARLOW"
    },
    {
        text: "FREASTOGAIL MHUIRE MXD,  KERRY"
    },
    {
        text: "FREEMOUNT MIXED N S,  CORK"
    },
    {
        text: "FROSSES N S,  DONEGAL"
    },
    {
        text: "GAELCHOLÁISTE AN PHIARSAIGH,  DUBLIN 16"
    },
    {
        text: "GAELCHOLÁISTE CHARRAIG UI LEIGHIN,  CORK"
    },
    {
        text: "GAELCHOLÁISTE CHEATHARLACH,  CARLOW"
    },
    {
        text: "GAELCHOLÁISTE CHIARRAÍ,  KERRY"
    },
    {
        text: "GAEL-CHOLÁISTE CHILL DARA,  KILDARE"
    },
    {
        text: "GAELCHOLÁISTE CHINEÁL EOGHAIN,  DONEGAL"
    },
    {
        text: "GAELCHOLÁISTE LUIMNIGH,  LIMERICK"
    },
    {
        text: "GAELCHOLÁISTE MHAIGH NUAD,  KILDARE"
    },
    {
        text: "GAELCHOLÁISTE MHUIRE,  CORK"
    },
    {
        text: "GAELCHOLÁISTE NA MARA,  WICKLOW"
    },
    {
        text: "GAELCHOLÁISTE PHORT LÁIRGE,  WATERFORD"
    },
    {
        text: "GAELCHOLÁISTE REACHRANN,  DUBLIN 13"
    },
    {
        text: "GAEL SC MHICHIL CHIOSOG,  CLARE"
    },
    {
        text: "GAELSCOIL ADHAMHNAIN,  DONEGAL"
    },
    {
        text: "GAELSCOIL AN BHRADÁIN FEASA,  MEATH"
    },
    {
        text: "GAELSCOIL AN CHAISLEÁIN,  CORK"
    },
    {
        text: "GAELSCOIL AN CHOILLÍN,  WESTMEATH"
    },
    {
        text: "GAELSCOIL AN CHUILINN,  DUBLIN 15"
    },
    {
        text: "GAELSCOIL AN DUINNINIGH,  DUBLIN COUNTY"
    },
    {
        text: "GAELSCOIL AN EISCIR RIADA,  OFFALY"
    },
    {
        text: "GAELSCOIL AN GHOIRT ALAINN,  CORK"
    },
    {
        text: "GAELSCOIL AN INBHIR MHOIR,  WICKLOW"
    },
    {
        text: "GAELSCOIL AN LONGFOIRT,  LONGFORD"
    },
    {
        text: "GAELSCOIL AN MHUILINN,  WESTMEATH"
    },
    {
        text: "GAELSCOIL AN RAITHIN,  LIMERICK"
    },
    {
        text: "GAELSCOIL AN TEAGHLAIGH NAOFA,  CORK"
    },
    {
        text: "GAELSCOIL AN TSLI DALA,  LAOIS"
    },
    {
        text: "GAELSCOIL AONACH,  TIPPERARY"
    },
    {
        text: "GAELSCOIL ÁTHA Í,  KILDARE"
    },
    {
        text: "GAELSCOIL BHAILE BRIGIN,  DUBLIN COUNTY"
    },
    {
        text: "GAELSCOIL BHAILE MUNNA,  DUBLIN 9"
    },
    {
        text: "GAELSCOIL BHARRA,  DUBLIN 7"
    },
    {
        text: "GAELSCOIL BHEANNTRAI,  CORK"
    },
    {
        text: "GAELSCOIL BHREIFNE,  CAVAN"
    },
    {
        text: "GAELSCOIL BHRIAN BÓROIMHE,  DUBLIN COUNTY"
    },
    {
        text: "GAELSCOIL BHUN CRANNACH,  DONEGAL"
    },
    {
        text: "GAELSCOIL CHALADH AN TREOIGH,  LIMERICK"
    },
    {
        text: "GAELSCOIL CHARRAIG NA SIUIRE,  TIPPERARY"
    },
    {
        text: "GAELSCOIL CHARRAIG UI LEIGHIN,  CORK"
    },
    {
        text: "GAELSCOIL CHILL DARA,  KILDARE"
    },
    {
        text: "GAELSCOIL CHILL MHANTÁIN,  WICKLOW"
    },
    {
        text: "GAELSCOIL CHIONN TSÁILE,  CORK"
    },
    {
        text: "GAELSCOIL CHLUAIN DOLCAIN,  DUBLIN 22"
    },
    {
        text: "GAELSCOIL CHLUAINÍN,  LEITRIM"
    },
    {
        text: "GAELSCOIL CHLUAIN MEALA,  TIPPERARY"
    },
    {
        text: "GAELSCOIL CHNOC LIAMHNA,  DUBLIN 16"
    },
    {
        text: "GAELSCOIL CHNOC NA RE,  SLIGO"
    },
    {
        text: "GAELSCOIL CHOIS FEABHAILL,  DONEGAL"
    },
    {
        text: "GAELSCOIL CHOLAISTE MHUIRE,  DUBLIN 1"
    },
    {
        text: "GAELSCOIL CHOLMCILLE,  DUBLIN 17"
    },
    {
        text: "GAELSCOIL CHORAIN,,  CORK"
    },
    {
        text: "GAELSCOIL DARA,  GALWAY"
    },
    {
        text: "GAELSCOIL DE HIDE,  CORK"
    },
    {
        text: "GAELSCOIL DE HIDE,  GALWAY"
    },
    {
        text: "GAELSCOIL DE HIDE,  ROSCOMMON"
    },
    {
        text: "GAELSCOIL DHUN DEALGAN,  LOUTH"
    },
    {
        text: "GAELSCOIL DONNCHA RUA,  CLARE"
    },
    {
        text: "GAELSCOIL DR M UI SHUILLEABHAIN,  CORK"
    },
    {
        text: "GAELSCOIL DROICHEAD NA BANNDAN,  CORK"
    },
    {
        text: "GAELSCOIL DURLAS EILE,  TIPPERARY"
    },
    {
        text: "GAELSCOIL ÉADAN DOIRE,  OFFALY"
    },
    {
        text: "GAELSCOIL EANNA,  MEATH"
    },
    {
        text: "GAELSCOILE COBH,  CORK"
    },
    {
        text: "GAELSCOIL EIRNE,  DONEGAL"
    },
    {
        text: "GAELSCOIL EISCIR RIADA,  DUBLIN COUNTY"
    },
    {
        text: "GAELSCOIL EOGHAIN UÍ THUAIRISC,  CARLOW"
    },
    {
        text: "GAELSCOIL EOIN,  DUBLIN D4"
    },
    {
        text: "GAELSCOIL ÉOIS,  MONAGHAN"
    },
    {
        text: "GAELSCOIL FAITHLEANN,  KERRY"
    },
    {
        text: "GAELSCOIL GHRÁINNE MHAOL,  DUBLIN D13"
    },
    {
        text: "GAELSCOIL IARFHLATHA,  GALWAY"
    },
    {
        text: "GAELSCOIL INIS CORTHAIDH,  WEXFORD"
    },
    {
        text: "GAELSCOIL INSE CHOR,  DUBLIN 8"
    },
    {
        text: "GAELSCOIL LAIGHEAN,  DUBLIN"
    },
    {
        text: "GAELSCOIL LIATROMA,  LEITRIM"
    },
    {
        text: "GAELSCOIL LIOS NA NOG,  DUBLIN 6"
    },
    {
        text: "GAELSCOIL LIOS TUATHAIL,  KERRY"
    },
    {
        text: "GAELSCOIL LIR,  DUBLIN D24"
    },
    {
        text: "GAELSCOIL LOCH GARMAN,  WEXFORD"
    },
    {
        text: "GAELSCOIL LORGAN,  MONAGHAN"
    },
    {
        text: "GAELSCOIL MHACHAN,  CORK"
    },
    {
        text: "GAELSCOIL MHAINISTIR NA CORANN,  CORK"
    },
    {
        text: "GAELSCOIL MHIC AMHLAIGH,  GALWAY"
    },
    {
        text: "GAELSCOIL MHIC AODHA,  KILDARE"
    },
    {
        text: "GAELSCOIL MHIC EASMAINN,  KERRY"
    },
    {
        text: "GAELSCOIL MHICHÍL UÍ CHOILEÁIN,  CORK"
    },
    {
        text: "GAELSCOIL MHOSHIOLOG,  WEXFORD"
    },
    {
        text: "GAELSCOIL MHUSCRAÍ,  CORK"
    },
    {
        text: "GAELSCOIL MIDE,  DUBLIN 5"
    },
    {
        text: "GAELSCOIL MOLOGA,  DUBLIN 6W"
    },
    {
        text: "GAELSCOIL NA BHFILI,  GALWAY"
    },
    {
        text: "GAELSCOIL NA BOINNE,  MEATH"
    },
    {
        text: "GAELSCOIL NA CAMOIGE,  DUBLIN 22"
    },
    {
        text: "GAELSCOIL NA CILLE,  MEATH"
    },
    {
        text: "GAELSCOIL NA CUAICHE,  MAYO"
    },
    {
        text: "GAELSCOIL NA FUINSEOIGE,  DUBLIN 14"
    },
    {
        text: "GAELSCOIL NA GCEITHRE MAISTRI,  DONEGAL"
    },
    {
        text: "GAELSCOIL NA GCEITHRE MAOL,  MAYO"
    },
    {
        text: "GAELSCOIL NA GCLOCH LIATH,  WICKLOW"
    },
    {
        text: "GAELSCOIL NA GIÚISE,  DUBLIN 24"
    },
    {
        text: "GAELSCOIL NA LAOCHRA,  OFFALY"
    },
    {
        text: "GAELSCOIL NA LOCHANNA,  WICKLOW"
    },
    {
        text: "GAELSCOIL NA MARA,  DUBLIN COUNTY"
    },
    {
        text: "GAELSCOIL NA MÍ,  MEATH"
    },
    {
        text: "GAELSCOIL NA NDEISE,  WATERFORD"
    },
    {
        text: "GAELSCOIL NAOMH AOGAIN,  KERRY"
    },
    {
        text: "GAELSCOIL NAOMH PADRAIG,  DUBLIN COUNTY"
    },
    {
        text: "GAELSCOIL NAOMH SÉAMAS,  KERRY"
    },
    {
        text: "GAELSCOIL NAS NA RIOGH,  KILDARE"
    },
    {
        text: "GAELSCOIL O DOGHAIR,  LIMERICK"
    },
    {
        text: "GAELSCOIL OSRAI,  KILKENNY"
    },
    {
        text: "GAELSCOIL PEIG SAYERS,  CORK"
    },
    {
        text: "GAELSCOIL PHADRAIG,  DUBLIN COUNTY"
    },
    {
        text: "GAELSCOIL PHILIB BARÚN,  WATERFORD"
    },
    {
        text: "GAELSCOIL PHORT LAIRGE,  WATERFORD"
    },
    {
        text: "GAELSCOIL PHORT LAOISE,  LAOIS"
    },
    {
        text: "GAELSCOIL RAIFTEIRÍ,  MAYO"
    },
    {
        text: "GAELSCOIL RIABHACH,  GALWAY"
    },
    {
        text: "GAELSCOIL RIADA,  GALWAY"
    },
    {
        text: "GAELSCOIL ROS EO,  DUBLIN COUNTY"
    },
    {
        text: "GAELSCOIL RUAIRÍ,  KILDARE"
    },
    {
        text: "GAELSCOIL SAIRSEAL,  LIMERICK"
    },
    {
        text: "GAELSCOIL SHEOIRSE,  LIMERICK"
    },
    {
        text: "GAELSCOIL SHLIABH RUA,  DUBLIN 18"
    },
    {
        text: "GAELSCOIL THAOBH NA COILLE,  DUBLIN 18"
    },
    {
        text: "GAELSCOIL THIOBRAID ARANN,  TIPPERARY"
    },
    {
        text: "GAEL SCOIL THOMAIS DAIBHIS,  CORK"
    },
    {
        text: "GAELSCOIL THULACH NA NÓG,  MEATH"
    },
    {
        text: "GAELSCOIL UI CHEADAIGH,  WICKLOW"
    },
    {
        text: "GAELSCOIL UI CHOIMIN,  CLARE"
    },
    {
        text: "GAELSCOIL UI DRISCEOIL,  CORK"
    },
    {
        text: "GAELSCOIL UI EARCAIN,  DUBLIN 11"
    },
    {
        text: "GAELSCOIL UI RIADA,  CORK"
    },
    {
        text: "GAEL SCOIL UI RIORDAIN,  CORK"
    },
    {
        text: "GAELSCOIL ULTAIN,  MONAGHAN"
    },
    {
        text: "GAILE N S,  TIPPERARY"
    },
    {
        text: "GAIRM SCOIL CHÚ ULADH,  DONEGAL"
    },
    {
        text: "GAIRMSCOIL MHIC DIARMADA,  DONEGAL"
    },
    {
        text: "GALLEN COMMUNITY SCHOOL,  OFFALY"
    },
    {
        text: "GALWAY CITY & ORANMORE EDUCATE TOGETHER SECONDARY SCHOOL,  GALWAY"
    },
    {
        text: "GALWAY COMMUNITY COLLEGE,  GALWAY"
    },
    {
        text: "GALWAY EDUCATE TOGETHER NS,  GALWAY"
    },
    {
        text: "GARDINER STREET CONVENT,  DUBLIN 1"
    },
    {
        text: "GARRACLOON N S,  MAYO"
    },
    {
        text: "GARRAFRAUNS CENTRAL SCH,  GALWAY"
    },
    {
        text: "GARRYDOOLIS N S,  LIMERICK"
    },
    {
        text: "GARTAN N S,  DONEGAL"
    },
    {
        text: "GEAROID UI GHRIOBHTHA,  LIMERICK"
    },
    {
        text: "GEASHILL 1 N S,  OFFALY"
    },
    {
        text: "GEESALA CENTRAL SCHOOL,  MAYO"
    },
    {
        text: "GILSON NATIONAL SCHOOL,  MEATH"
    },
    {
        text: "GLANBRIAN N S,  WEXFORD"
    },
    {
        text: "GLANMIRE COMMUNITY COLLEGE,  CORK"
    },
    {
        text: "GLASNEVIN EDUCATE TOGETHER NS,  DUBLIN 11"
    },
    {
        text: "GLASNEVIN N S,  DUBLIN 9"
    },
    {
        text: "GLEBE NS,  WICKLOW"
    },
    {
        text: "GLEBE NS,  DONEGAL"
    },
    {
        text: "GLEBE N S,  LEITRIM"
    },
    {
        text: "GLENAMADDY COMMUNITY SCHOOL,  GALWAY"
    },
    {
        text: "GLENAMADDY NS,  GALWAY"
    },
    {
        text: "GLENART COLLEGE,  WICKLOW"
    },
    {
        text: "GLENBEG N S,  WATERFORD"
    },
    {
        text: "GLENBROHANE N S,  LIMERICK"
    },
    {
        text: "GLENCORRIB N S,  MAYO"
    },
    {
        text: "GLENEALY 1 N S,  WICKLOW"
    },
    {
        text: "GLENEELY N S,  DONEGAL"
    },
    {
        text: "GLENFLESK N S,  KERRY"
    },
    {
        text: "GLENGURT N S,  LIMERICK"
    },
    {
        text: "GLENMAQUIN NO 2 N S,  DONEGAL"
    },
    {
        text: "GLEN NA SMOL N S,  DUBLIN 24"
    },
    {
        text: "GLENSTAL ABBEY SCHOOL,  LIMERICK"
    },
    {
        text: "GLENSWILLY N S,  DONEGAL"
    },
    {
        text: "GLENTOGHER CON N S,  DONEGAL"
    },
    {
        text: "GLINSK N S,  GALWAY"
    },
    {
        text: "GLOR NA MARA NS,  WATERFORD"
    },
    {
        text: "GLOUNAGUILLAGH N S,  KERRY"
    },
    {
        text: "GLOUNTHAUNE MIXED N.S.,  CORK"
    },
    {
        text: "GOATSTOWN EDUCATE TOGETHER SECONDARY SCHOOL,  DUBLIN D14"
    },
    {
        text: "GOATSTOWN STILLORGAN PRIMARY SCHOOL,  DUBLIN 18"
    },
    {
        text: "GOGGINSHILL N S,  CORK"
    },
    {
        text: "GOLDENBRIDGE CONVENT,  DUBLIN 8"
    },
    {
        text: "GONZAGA COLLEGE,  DUBLIN 6"
    },
    {
        text: "GOOD COUNSEL COLLEGE,  WEXFORD"
    },
    {
        text: "GORESBRIDGE N.S.,  KILKENNY"
    },
    {
        text: "GOREY CENTRAL SCHOOL,  WEXFORD"
    },
    {
        text: "GOREY COMMUNITY SCHOOL,  WEXFORD"
    },
    {
        text: "GOREY EDUCATE TOGETHER NS,  WEXFORD"
    },
    {
        text: "GOREY EDUCATE TOGETHER SECONDARY SCHOOL,  WEXFORD"
    },
    {
        text: "GORTANUMERA N S,  GALWAY"
    },
    {
        text: "GORT COMMUNITY SCHOOL,  GALWAY"
    },
    {
        text: "GORTHAGANNY N S,  ROSCOMMON"
    },
    {
        text: "GORTJORDAN N S,  MAYO"
    },
    {
        text: "GORTLETTERAGH CENTRAL,  LEITRIM"
    },
    {
        text: "GORTNACART N S,  DONEGAL"
    },
    {
        text: "GORT NATIONAL SCHOOL,  GALWAY"
    },
    {
        text: "GRACE PARK EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 9"
    },
    {
        text: "GRAIG NA MANACH BUAC,  KILKENNY"
    },
    {
        text: "GRANAGH NATIONAL SCHOOL,  LIMERICK"
    },
    {
        text: "GRANGE COMMUNITY COLLEGE,  DUBLIN 13"
    },
    {
        text: "GRANGE CON N S,  WICKLOW"
    },
    {
        text: "GRANGE FERMOY N S,  CORK"
    },
    {
        text: "GRANGEMOCKLER NS,  TIPPERARY"
    },
    {
        text: "GRANGE N S,  ROSCOMMON"
    },
    {
        text: "GRANGE POST PRIMARY SCHOOL,  SLIGO"
    },
    {
        text: "GRANLAHAN G N S,  ROSCOMMON"
    },
    {
        text: "GREENHILLS COLLEGE,  DUBLIN 12"
    },
    {
        text: "GREENMOUNT MONASTERY NS,  CORK"
    },
    {
        text: "GRENNAN COLLEGE,  KILKENNY"
    },
    {
        text: "GREYSTONES COMMUNITY COLLEGE,  WICKLOW"
    },
    {
        text: "GREYSTONES COMMUNITY NS,  WICKLOW"
    },
    {
        text: "GREYSTONES EDUCATE TOGETHER NATIONAL SCHOOL,  WICKLOW"
    },
    {
        text: "GRIFFEEN COMMUNITY COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "GRIFFEEN VALLEY EDUCATE TOGETHER NS,  DUBLIN COUNTY"
    },
    {
        text: "GRIFFITH BARRACKS MULTI D SCHOOL,  DUBLIN 8"
    },
    {
        text: "GURTAGARRY N S,  TIPPERARY"
    },
    {
        text: "GURTEEN CENTRAL NS,  GALWAY"
    },
    {
        text: "GUSSERANE N S,  WEXFORD"
    },
    {
        text: "HANSFIELD EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 15"
    },
    {
        text: "HANSFIELD ETSS,  DUBLIN 15"
    },
    {
        text: "HARCOURT TERRACE EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 2"
    },
    {
        text: "HAROLD BOYS N S,  DUBLIN COUNTY"
    },
    {
        text: "HAROLDS CROSS EDUCATE TOGETHER SECONDARY SCHOOL,  DUBLIN 6W"
    },
    {
        text: "HAROLDS CROSS N S,  DUBLIN 6W"
    },
    {
        text: "HARTSTOWN COMMUNITY SCHOOL,  DUBLIN 15"
    },
    {
        text: "HAZELWOOD COLLEGE,  LIMERICK"
    },
    {
        text: "HENRIETTA STREET SCHOOL,  DUBLIN 1"
    },
    {
        text: "HEWETSONS N S,  KILDARE"
    },
    {
        text: "HEYWOOD COMMUNITY SCHOOL,  LAOIS"
    },
    {
        text: "HIGH ST MIXED N S,  OFFALY"
    },
    {
        text: "HOLLYFORD N S,  TIPPERARY"
    },
    {
        text: "HOLLYWOOD N S,  WICKLOW"
    },
    {
        text: "HOLMPATRICK N S,  DUBLIN COUNTY"
    },
    {
        text: "HOLY CHILD COMMUNITY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "HOLY CHILD NATIONAL SCHOOL,  DUBLIN 9"
    },
    {
        text: "HOLY CHILD SECONDARY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "HOLY CROSS N S,  DUBLIN 14"
    },
    {
        text: "HOLYCROSS N.S.,  KILKENNY"
    },
    {
        text: "HOLY CROSS PRIMARY SCHOOL,  KERRY"
    },
    {
        text: "HOLY CROSS SCHOOL,  WATERFORD"
    },
    {
        text: "HOLY FAITH SECONDARY SCHOOL,  DUBLIN 3"
    },
    {
        text: "HOLY FAMILY,  KERRY"
    },
    {
        text: "HOLY FAMILY B N S,  CARLOW"
    },
    {
        text: "HOLY FAMILY COMMUNITY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "HOLY FAMILY GIRLS N S,  CARLOW"
    },
    {
        text: "HOLY FAMILY JUNIOR N S,  DUBLIN COUNTY"
    },
    {
        text: "HOLY FAMILY JUNIOR SCHOOL,  CLARE"
    },
    {
        text: "HOLY FAMILY JUNIOR SCHOOL,  LAOIS"
    },
    {
        text: "HOLY FAMILY NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "HOLY FAMILY NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "HOLY FAMILY NATIONAL SCHOOL,  DONEGAL"
    },
    {
        text: "HOLY FAMILY PRIMARY SCHOOL,  WESTMEATH"
    },
    {
        text: "HOLY FAMILY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "HOLY FAMILY SCHOOL,  SLIGO"
    },
    {
        text: "HOLY FAMILY SCHOOL FOR THE DEAF,  DUBLIN 7"
    },
    {
        text: "HOLY FAMILY SECONDARY SCHOOL,  KILDARE"
    },
    {
        text: "HOLY FAMILY SENIOR N S,  DUBLIN COUNTY"
    },
    {
        text: "HOLY FAMILY SENIOR SCHOOL,  LAOIS"
    },
    {
        text: "HOLY FAMILY SNR,  CLARE"
    },
    {
        text: "HOLY FAMILY S S,  CORK"
    },
    {
        text: "HOLY FAMILY S S,  CAVAN"
    },
    {
        text: "HOLY ROSARY COLLEGE,  GALWAY"
    },
    {
        text: "HOLY ROSARY PRIMARY SCHOOL,  DUBLIN 24"
    },
    {
        text: "HOLY SPIRIT B N S,  DUBLIN 11"
    },
    {
        text: "HOLY SPIRIT JUNIOR PRIMARY SCHOOL,  DUBLIN 12"
    },
    {
        text: "HOLY SPIRIT SENIOR PRIMARY SCHOOL,  DUBLIN 12"
    },
    {
        text: "HOLY TRINITY NATIONAL SCHOOL,  MAYO"
    },
    {
        text: "HOLY TRINITY NATIONAL SCHOOL,  DUBLIN 18"
    },
    {
        text: "HOLY TRINITY NATIONAL SCHOOL,  TIPPERARY"
    },
    {
        text: "HOLY TRINITY SEN N S,  DUBLIN 13"
    },
    {
        text: "HOLYWELL EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "HOLY WELL NATIONAL SCHOOL (SCOIL TOBAIR NAOFA),  CORK"
    },
    {
        text: "HOSPITAL NS,  LIMERICK"
    },
    {
        text: "HOSPITAL SCHOOL CHI AT TEMPLE STREET,  DUBLIN 1"
    },
    {
        text: "HOWTH RD MXD N S,  DUBLIN 3"
    },
    {
        text: "INCHICORE NS,  DUBLIN 10"
    },
    {
        text: "INCHICRONAN CENTRAL NS,  CLARE"
    },
    {
        text: "INCHIGEELA N S,  CORK"
    },
    {
        text: "INCH N S,  CLARE"
    },
    {
        text: "INISHBOFIN N S,  GALWAY"
    },
    {
        text: "INVER COLLEGE,  MONAGHAN"
    },
    {
        text: "INVER N S,  DONEGAL"
    },
    {
        text: "IOMAIR N S,  GALWAY"
    },
    {
        text: "IOSEF NAOFA,  CORK"
    },
    {
        text: "IRISHTOWN N S,  MAYO"
    },
    {
        text: "ISLAMIC NATIONAL SCHOOL,  DUBLIN 14"
    },
    {
        text: "JESUS & MARY SECONDARY SCHOOL,  GALWAY"
    },
    {
        text: "JESUS & MARY SECONDARY SCHOOL,  MAYO"
    },
    {
        text: "JESUS & MARY SECONDARY SCHOOL,  SLIGO"
    },
    {
        text: "J F K MEMORIAL SCHOOL,  LIMERICK"
    },
    {
        text: "JOHN PAUL II N S,  DUBLIN COUNTY"
    },
    {
        text: "JOHN SCOTTUS NS,  DUBLIN 4"
    },
    {
        text: "JOHN SCOTTUS SECONDARY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "JOHN THE BAPTIST COMMUNITY SCHOOL,  LIMERICK"
    },
    {
        text: "JONAH SPECIAL SCHOOL,  KILKENNY"
    },
    {
        text: "JONATHAN SWIFT NS,  WICKLOW"
    },
    {
        text: "KANTURK B N S,  CORK"
    },
    {
        text: "KEALKIL N S,  CORK"
    },
    {
        text: "KELLS PAROCHIAL N S,  MEATH"
    },
    {
        text: "KENNEDY COLLEGE,  WEXFORD"
    },
    {
        text: "KILADANGAN N S,  TIPPERARY"
    },
    {
        text: "KILMAGNER N S,  CORK"
    },
    {
        text: "KILADOOLEY MIXED N S,  LAOIS"
    },
    {
        text: "KILBARRON N S,  DONEGAL"
    },
    {
        text: "KILBARRY N S,  CORK"
    },
    {
        text: "KILBEG N S,  MEATH"
    },
    {
        text: "KILBEHENNY N S,  LIMERICK"
    },
    {
        text: "KILBERRY N S,  KILDARE"
    },
    {
        text: "KILBRIDE NATIONAL SCHOOL,  MEATH"
    },
    {
        text: "KILBRIDE N S,  WICKLOW"
    },
    {
        text: "KILBRITTAIN MIXED N S,  CORK"
    },
    {
        text: "KILCHREEST CENTRAL SCH,  GALWAY"
    },
    {
        text: "KILCOE NS,  CORK"
    },
    {
        text: "KILCOLGAN EDUCATE TOGETHER NS,  GALWAY"
    },
    {
        text: "KILCOLMAN N S,  CORK"
    },
    {
        text: "KILCOMMON N S,  WICKLOW"
    },
    {
        text: "KILCONLY N S,  GALWAY"
    },
    {
        text: "KILCOOLE PRIMARY SCHOOL,  WICKLOW"
    },
    {
        text: "KILCORNEY MIXED N S,  CORK"
    },
    {
        text: "KILCROHANE N S,  CORK"
    },
    {
        text: "KILCRUMRERAGH N S,  WESTMEATH"
    },
    {
        text: "KILDALLON N S,  CAVAN"
    },
    {
        text: "KILDARE PLACE  N S,  DUBLIN 6"
    },
    {
        text: "KILDARE TOWN COMMUNITY SCHOOL,  KILDARE"
    },
    {
        text: "KILDARE TOWN EDUCATE TOGETHER NS,  KILDARE"
    },
    {
        text: "KILDIMO NATIONAL SCHOOL,  LIMERICK"
    },
    {
        text: "KILDYSART N S,  CLARE"
    },
    {
        text: "KILFINANE CONVENT PRIMARY SCHOOL,  LIMERICK"
    },
    {
        text: "KILGARIFFE N S,  CORK"
    },
    {
        text: "KILGARVAN CENTRAL SCHL,  KERRY"
    },
    {
        text: "KILGLASS N S,  SLIGO"
    },
    {
        text: "KILGOBNET N S,  KERRY"
    },
    {
        text: "KILKENNY COLLEGE,  KILKENNY"
    },
    {
        text: "KILKENNY MIXED N S,  KILKENNY"
    },
    {
        text: "KILKENNY SCHOOL PROJECT,  KILKENNY"
    },
    {
        text: "KILKISHEN N S,  CLARE"
    },
    {
        text: "KILLAGHTEE N S,  DONEGAL"
    },
    {
        text: "KILLALA N S,  MAYO"
    },
    {
        text: "KILLALOE BOYS N S,  CLARE"
    },
    {
        text: "KILLARNEY COMMUNITY COLLEGE,  KERRY"
    },
    {
        text: "KILLASHEE MULTI-DENOMINATIONAL NS,  KILDARE"
    },
    {
        text: "KILLASONNA MIXED N S,  LONGFORD"
    },
    {
        text: "KILLASSER NS,  MAYO"
    },
    {
        text: "KILLAVILLE N S,  SLIGO"
    },
    {
        text: "KILLAVULLEN N S,  CORK"
    },
    {
        text: "KILLEA BOYS N S,  WATERFORD"
    },
    {
        text: "KILLEA N S,  TIPPERARY"
    },
    {
        text: "KILLEENDUFF N S,  SLIGO"
    },
    {
        text: "KILLEEVAN CENTRAL N S,  MONAGHAN"
    },
    {
        text: "KILLENAULE N S,  TIPPERARY"
    },
    {
        text: "KILLESHANDRA NATIONAL SCHOOL ( C OF I),  CAVAN"
    },
    {
        text: "KILLESTER RAHENY CLONTARF EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 13"
    },
    {
        text: "KILLINA PRESENTATION SECONDARY SCHOOL,  OFFALY"
    },
    {
        text: "KILLINARDEN COMMUNITY SCHOOL,  DUBLIN 24"
    },
    {
        text: "KILLINKERE N S,  CAVAN"
    },
    {
        text: "KILL N S,  CAVAN"
    },
    {
        text: "KILL-O'-THE-GRANGE NS,  DUBLIN COUNTY"
    },
    {
        text: "KILLURNEY N S,  TIPPERARY"
    },
    {
        text: "KILLUSTY N S,  TIPPERARY"
    },
    {
        text: "KILLYBEGS COMMON N S,  DONEGAL"
    },
    {
        text: "KILLYCONNAN N S,  CAVAN"
    },
    {
        text: "KILLYGARRY N S,  CAVAN"
    },
    {
        text: "KILLYGORDAN N S,  DONEGAL"
    },
    {
        text: "KILLYON N S,  MEATH"
    },
    {
        text: "KILMACANOGUE N S,  WICKLOW"
    },
    {
        text: "KILMAINHAM WOOD N S,  MEATH"
    },
    {
        text: "KILMALEY N S,  CLARE"
    },
    {
        text: "KILMEEDY N S,  LIMERICK"
    },
    {
        text: "KILMESSAN MXD N S,  MEATH"
    },
    {
        text: "KILMIHIL N.S.,  CLARE"
    },
    {
        text: "KILMOGANNY MIXED N S,  KILKENNY"
    },
    {
        text: "KILMORE CENTRAL N S,  CAVAN"
    },
    {
        text: "KILMOVEE N S,  MAYO"
    },
    {
        text: "KILMURRY MCMAHON NATIONAL SCHOOL,  CLARE"
    },
    {
        text: "KILMURRY NS,  MAYO"
    },
    {
        text: "KILMYSHALL N S,  WEXFORD"
    },
    {
        text: "KILNABOY N S,  CLARE"
    },
    {
        text: "KILNALECK MIXED N S,  CAVAN"
    },
    {
        text: "KILNAMANAGH COMMUNITY NATIONAL SCHOOL,  WEXFORD"
    },
    {
        text: "KILNAMONA N S,  CLARE"
    },
    {
        text: "KILPATRICK NS,  WESTMEATH"
    },
    {
        text: "KILRANE N S,  WEXFORD"
    },
    {
        text: "KILROSS N S,  SLIGO"
    },
    {
        text: "KILRUSH COMMUNITY SCHOOL,  CLARE"
    },
    {
        text: "KILSKYRE MIXED N S,  MEATH"
    },
    {
        text: "KILTALLAGH N S,  KERRY"
    },
    {
        text: "KILTEALY N S,  WEXFORD"
    },
    {
        text: "KILTEELY N S,  LIMERICK"
    },
    {
        text: "KILTEEVAN N S,  ROSCOMMON"
    },
    {
        text: "KILTEGAN N S,  WICKLOW"
    },
    {
        text: "KILTERNAN CHURCH OF IRELAND NS,  DUBLIN COUNTY"
    },
    {
        text: "KILTERNAN N S 1,  DUBLIN COUNTY"
    },
    {
        text: "KILTIMAGH CENTRAL,  MAYO"
    },
    {
        text: "KILTORMER N S,  GALWAY"
    },
    {
        text: "KINAFFE N S,  MAYO"
    },
    {
        text: "KINGSWOOD COMMUNITY COLLEGE,  DUBLIN 24"
    },
    {
        text: "KINSALE COMMUNITY SCHOOL,  CORK"
    },
    {
        text: "KINSALE N S,  CORK"
    },
    {
        text: "KISHOGE COMMUNITY COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "KNOCKACLARIG MXD N S,  KERRY"
    },
    {
        text: "KNOCKADERRY FARRANFORE NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "KNOCKANES MXD N S,  KERRY"
    },
    {
        text: "KNOCKANILLO N S,  MAYO"
    },
    {
        text: "KNOCKANORE N S,  WATERFORD"
    },
    {
        text: "KNOCKAVILLA N S,  TIPPERARY"
    },
    {
        text: "KNOCKBRIDGE MIXED N S,  LOUTH"
    },
    {
        text: "KNOCKCONNON N S,  MONAGHAN"
    },
    {
        text: "KNOCKEA NATIONAL SCHOOL,  LIMERICK"
    },
    {
        text: "KNOCKNACARRA EDUCATE TOGETHER NS,  GALWAY"
    },
    {
        text: "KNOCKNAGOSHEL NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "KNOCK N S,  MAYO"
    },
    {
        text: "KNOCKRAHA N S,  CORK"
    },
    {
        text: "KYLEMORE COLLEGE,  DUBLIN 10"
    },
    {
        text: "KYLE N S,  CORK"
    },
    {
        text: "LABASHEEDA CENTRAL N S,  CLARE"
    },
    {
        text: "LACKAMORE N S,  TIPPERARY"
    },
    {
        text: "LACKEN MXD N S,  WICKLOW"
    },
    {
        text: "LACKEN NATIONAL SCHOOL,  MAYO"
    },
    {
        text: "LADY OF GOOD COUNSEL BOYS SENIOR NS,  DUBLIN 12"
    },
    {
        text: "LADYSWELL N S,  DUBLIN 15"
    },
    {
        text: "LAKEVIEW SCHOOL,  GALWAY"
    },
    {
        text: "LAKYLE N S,  CLARE"
    },
    {
        text: "LANESBORO COMMUNITY COLLEGE,  LONGFORD"
    },
    {
        text: "LANESBOROUGH PRIMARY SCHOOL,  LONGFORD"
    },
    {
        text: "LARAGH N S,  CORK"
    },
    {
        text: "LARGY COLLEGE,  MONAGHAN"
    },
    {
        text: "LARKIN COMMUNITY COLLEGE,  DUBLIN 1"
    },
    {
        text: "LATNAMARD N S,  MONAGHAN"
    },
    {
        text: "LATTON,  MONAGHAN"
    },
    {
        text: "LAURAGH NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "LAUREL HILL COLÁISTE FCJ,  LIMERICK"
    },
    {
        text: "LAUREL HILL SECONDARY SCHOOL FCJ,  LIMERICK"
    },
    {
        text: "LAYTOWN & DROGHEDA EDUCATE TOGETHER SECONDARY SCHOOL,  MEATH"
    },
    {
        text: "LEAFFONEY N S,  SLIGO"
    },
    {
        text: "LEAMLARA MIXED N S,  CORK"
    },
    {
        text: "LECANVEY N S,  MAYO"
    },
    {
        text: "LECARROW COMMUNITY NATIONAL SCHOOL,  ROSCOMMON"
    },
    {
        text: "LE CHEILE EDUCATE TOGETHER NS,  LOUTH"
    },
    {
        text: "LE CHÉILE NATIONAL SCHOOL,  LIMERICK"
    },
    {
        text: "LE CHEILE SECONDARY SCHOOL,  DUBLIN 15"
    },
    {
        text: "LE CHEILE SECONDARY SCHOOL BALLINCOLLIG,  CORK"
    },
    {
        text: "LEHINCH N S,  MAYO"
    },
    {
        text: "LEITRIM MXD N S,  LEITRIM"
    },
    {
        text: "LEIXLIP COMMUNITY SCHOOL,  KILDARE"
    },
    {
        text: "LEIXLIP EDUCATE TOGETHER NATIONAL SCHOOL,  KILDARE"
    },
    {
        text: "LETTERKENNY EDUCATE TOGETHER NS,  DONEGAL"
    },
    {
        text: "LIGHT OF CHRIST NATIONAL SCHOOL,  WATERFORD"
    },
    {
        text: "LIMERICK CITY EAST EDUCATE TOGETHER NS,  LIMERICK"
    },
    {
        text: "LIMERICK CITY EAST SECONDARY SCHOOL,  LIMERICK"
    },
    {
        text: "LIMERICK SCHOOL PROJECT,  LIMERICK"
    },
    {
        text: "LINDSAY ROAD N S,  DUBLIN 9"
    },
    {
        text: "LINN DARA SCHOOLS,  DUBLIN 10"
    },
    {
        text: "LIOS MAIGHIR,  CORK"
    },
    {
        text: "LISANISKA N S,  MAYO"
    },
    {
        text: "LISANISKEY N S,  ROSCOMMON"
    },
    {
        text: "LISAVAIRD MXD N S,  CORK"
    },
    {
        text: "LISCARROL N S,  CORK"
    },
    {
        text: "LISDOONAN N S,  MONAGHAN"
    },
    {
        text: "LISDOONVARNA N S,  CLARE"
    },
    {
        text: "LISDOWNEY N S,  KILKENNY"
    },
    {
        text: "LISGRIFFIN N S,  CORK"
    },
    {
        text: "LISHEEN MIXED N S,  CORK"
    },
    {
        text: "LISMOIL N S,  ROSCOMMON"
    },
    {
        text: "LISNAMROCK N S,  TIPPERARY"
    },
    {
        text: "LISRONAGH N S,  TIPPERARY"
    },
    {
        text: "LISSIVIGEEN MXD N S,  KERRY"
    },
    {
        text: "LISSYCASEY N S,  CLARE"
    },
    {
        text: "LISTOWEL PRESENTATION PRIMARY,  KERRY"
    },
    {
        text: "LISVERNANE N S,  TIPPERARY"
    },
    {
        text: "LITTLE ANGELS SPEC SCH,  DONEGAL"
    },
    {
        text: "LITTLE ISLAND NS,  CORK"
    },
    {
        text: "LOCHAN AN BHEALAIGH N S,  WESTMEATH"
    },
    {
        text: "LOCH AN GHAIR N S,  WESTMEATH"
    },
    {
        text: "LOCH MOR MAIGH N S,  TIPPERARY"
    },
    {
        text: "LORETO ABBEY SECONDARY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "LORETO COLLEGE,  DUBLIN 12"
    },
    {
        text: "LORETO COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "LORETO COLLEGE,  DUBLIN 2"
    },
    {
        text: "LORETO COLLEGE,  CAVAN"
    },
    {
        text: "LORETO COLLEGE,  WESTMEATH"
    },
    {
        text: "LORETO COLLEGE FOXROCK,  DUBLIN 18"
    },
    {
        text: "LORETO COMMUNITY SCHOOL,  DONEGAL"
    },
    {
        text: "LORETO HIGH SCHOOL,  DUBLIN 14"
    },
    {
        text: "LORETO NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "LORETO SECONDARY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "LORETO SECONDARY SCHOOL,  KILKENNY"
    },
    {
        text: "LORETO SECONDARY SCHOOL,  WICKLOW"
    },
    {
        text: "LORETO SECONDARY SCHOOL,  CORK"
    },
    {
        text: "LORETO SECONDARY SCHOOL,  DONEGAL"
    },
    {
        text: "LORETO SECONDARY SCHOOL,  WEXFORD"
    },
    {
        text: "LORETO SECONDARY SCHOOL,  MEATH"
    },
    {
        text: "LORETO SECONDARY SCHOOL,  TIPPERARY"
    },
    {
        text: "LOUGH ALLEN COLLEGE,  LEITRIM"
    },
    {
        text: "LOUGHFOUDER N S,  KERRY"
    },
    {
        text: "LOUGHGUITANE N S,  KERRY"
    },
    {
        text: "LOWER GLANMIRE N S,  CORK"
    },
    {
        text: "LUCAN B N S,  DUBLIN COUNTY"
    },
    {
        text: "LUCAN COMMUNITY COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "LUCAN COMMUNITY NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "LUCAN EAST ETNS,  DUBLIN COUNTY"
    },
    {
        text: "LUCAN EDUCATE TOGETHER NS,  DUBLIN COUNTY"
    },
    {
        text: "LURGA N S,  GALWAY"
    },
    {
        text: "LUSK COMMUNITY COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "LUSK JUNIOR NATIONAL SCHOOL ST MACCULLINS,  DUBLIN COUNTY"
    },
    {
        text: "LUTTRELLSTOWN COMMUNITY COLLEGE,  DUBLIN 15"
    },
    {
        text: "LYRE A CROMPANE N S,  KERRY"
    },
    {
        text: "MACROOM BOYS SENIOR NATIONAL SCHOOL,  CORK"
    },
    {
        text: "MACROOM CONVENT N S,  CORK"
    },
    {
        text: "MAGH ENE COLLEGE,  DONEGAL"
    },
    {
        text: "MAGH MORA N S,  WESTMEATH"
    },
    {
        text: "MAHOONAGH N S,  LIMERICK"
    },
    {
        text: "MALAHIDE COMMUNITY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "MALAHIDE / PORTMARNOCK EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 17"
    },
    {
        text: "MALAHIDE & PORTMARNOCK SECONDARY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "MALLOW COMMUNITY NATIONAL SCHOOL,  CORK"
    },
    {
        text: "MANOR HOUSE SCHOOL,  DUBLIN 5"
    },
    {
        text: "MARIA IMMACULATA COMMUNITY COLLEGE,  CORK"
    },
    {
        text: "MARIAN COLLEGE,  DUBLIN 4"
    },
    {
        text: "MARINO COLLEGE,  DUBLIN 3"
    },
    {
        text: "MARINO SCHOOL N.S.,  WICKLOW"
    },
    {
        text: "MARIST COLLEGE,  WESTMEATH"
    },
    {
        text: "MARIST NATIONAL SCHOOL,  DUBLIN 12"
    },
    {
        text: "MARLEY GRANGE NS,  DUBLIN 16"
    },
    {
        text: "MARSHALSTOWN N S,  WEXFORD"
    },
    {
        text: "MARTINSTOWN N S,  LIMERICK"
    },
    {
        text: "MARYBORO N S,  LAOIS"
    },
    {
        text: "MARYFIELD COLLEGE,  DUBLIN 9"
    },
    {
        text: "MARY, HELP OF CHRISTIANS G.N.S.,  DUBLIN 7"
    },
    {
        text: "MARY IMMACULATE SECONDARY SCHOOL,  CLARE"
    },
    {
        text: "MARY MOTHER OF HOPE JUNIOR NATIONAL SCHOOL,  DUBLIN 15"
    },
    {
        text: "MARY MOTHER OF HOPE SENIOR NS,  DUBLIN 15"
    },
    {
        text: "MARY QUEEN OF IRELAND N S,  DUBLIN COUNTY"
    },
    {
        text: "MASTERSONS N S,  LEITRIM"
    },
    {
        text: "MAULATRAHANE CENTRAL NS,  CORK"
    },
    {
        text: "MAYFIELD COMMUNITY SCHOOL,  CORK"
    },
    {
        text: "MAYNOOTH B N S,  KILDARE"
    },
    {
        text: "MAYNOOTH COMMUNITY COLLEGE,  KILDARE"
    },
    {
        text: "MAYNOOTH EDUCATE TOGETHER NATIONAL SCHOOL,  KILDARE"
    },
    {
        text: "MAYNOOTH POST PRIMARY SCHOOL,  KILDARE"
    },
    {
        text: "MCEGAN COLLEGE,  CORK"
    },
    {
        text: "MEÁN SCOIL AN CHLOCHAIR,  WESTMEATH"
    },
    {
        text: "MEANSCOIL GHARMAN,  WEXFORD"
    },
    {
        text: "MEÁNSCOIL IOGNÁID RIS,  KILDARE"
    },
    {
        text: "MEANSCOIL IOGNÁID RÍS,  DUBLIN 12"
    },
    {
        text: "MEÁN SCOIL MUIRE,  LONGFORD"
    },
    {
        text: "MEÁNSCOIL NA MBRÁITHRE,  CLARE"
    },
    {
        text: "MEÁNSCOIL NA MBRÁITHRE CRIOSTAÍ,  KILKENNY"
    },
    {
        text: "MEANSCOIL NAOMH IOSEPH,  KERRY"
    },
    {
        text: "MEANSCOIL NUA AN LEITH TRIUIGH,  KERRY"
    },
    {
        text: "MEANSCOIL PHADRAIG NAOFA,  KERRY"
    },
    {
        text: "MEÁNSCOIL SAN NIOCLÁS,  WATERFORD"
    },
    {
        text: "MEELICKMORE N S,  MAYO"
    },
    {
        text: "MEENKILLY N S,  LIMERICK"
    },
    {
        text: "MERCY COLLEGE,  GALWAY"
    },
    {
        text: "MERCY COLLEGE,  SLIGO"
    },
    {
        text: "MERCY COLLEGE COOLOCK,  DUBLIN 5"
    },
    {
        text: "MERCY CONVENT KILLALOE,  CLARE"
    },
    {
        text: "MERCY CONVENT N S,  MEATH"
    },
    {
        text: "MERCY CONVENT PRIMARY SCHOOL,  KILDARE"
    },
    {
        text: "MERCY PRIMARY SCHOOL,  OFFALY"
    },
    {
        text: "MERCY SECONDARY SCHOOL,  DUBLIN 8"
    },
    {
        text: "MERCY SECONDARY SCHOOL,  LONGFORD"
    },
    {
        text: "MERCY SECONDARY SCHOOL MOUNTHAWK,  KERRY"
    },
    {
        text: "MERLIN WOODS PRIMARY SCHOOL,  GALWAY"
    },
    {
        text: "MHICHIL NAOFA,  CORK"
    },
    {
        text: "MHICHIL NAOFA N S,  WESTMEATH"
    },
    {
        text: "MHUIRE FATIMA N S,  LAOIS"
    },
    {
        text: "MHUIRE GRANSLA,  MONAGHAN"
    },
    {
        text: "MHUIRE IOSEF JUNIOR,  DUBLIN 13"
    },
    {
        text: "MHUIRE N S,  WESTMEATH"
    },
    {
        text: "MIDLETON COLLEGE,  CORK"
    },
    {
        text: "MIDLETON CONVENT N S,  CORK"
    },
    {
        text: "MIDLETON EDUCATE TOGETHER SCHOOL,  CORK"
    },
    {
        text: "MID WEST SCHOOL FOR THE DEAF,  LIMERICK"
    },
    {
        text: "MILFORD GRANGE N SCHOOL,  LIMERICK"
    },
    {
        text: "MILLSTREET COMMUNITY SCHOOL,  CORK"
    },
    {
        text: "MILLSTREET CONVENT N S,  CORK"
    },
    {
        text: "MILLTOWN N S,  CAVAN"
    },
    {
        text: "MILLTOWNPASS N S,  WESTMEATH"
    },
    {
        text: "MILTOWN MALBAY B N S,  CLARE"
    },
    {
        text: "MIN A GHABHANN N S,  DONEGAL"
    },
    {
        text: "MOATE COMMUNITY SCHOOL,  WESTMEATH"
    },
    {
        text: "MOCOMHOG N S,  CORK"
    },
    {
        text: "MOHILL COMMUNITY COLLEGE,  LEITRIM"
    },
    {
        text: "MOHILL N S,  LEITRIM"
    },
    {
        text: "MOIN AN BHEALAIGH N S,  WICKLOW"
    },
    {
        text: "MOL AN ÓIGE COMMUNITY NATIONAL SCHOOL,  CLARE"
    },
    {
        text: "MONAGEA NATIONAL SCHOOL,  LIMERICK"
    },
    {
        text: "MONAGHAN COLLEGIATE SCHOOL,  MONAGHAN"
    },
    {
        text: "MONAGHAN MODEL SCHOOL,  MONAGHAN"
    },
    {
        text: "MONARD N S,  TIPPERARY"
    },
    {
        text: "MONASEED N S,  WEXFORD"
    },
    {
        text: "MONASTEREVAN CONVENT,  KILDARE"
    },
    {
        text: "MONEYSTOWN N S,  WICKLOW"
    },
    {
        text: "MONKSTOWN EUCATE TOGETHER NS,  DUBLIN COUNTY"
    },
    {
        text: "MONKSTOWN N S,  CORK"
    },
    {
        text: "MONREAGH N S,  DONEGAL"
    },
    {
        text: "MOTHER OF DIVINE GRACE,  DUBLIN 11"
    },
    {
        text: "MOTHER OF FAIR LOVE SPEC SCHOOL,  KILKENNY"
    },
    {
        text: "MOUNT ANVILLE SECONDARY SCHOOL,  DUBLIN 14"
    },
    {
        text: "MOUNT BOLUS N S,  OFFALY"
    },
    {
        text: "MOUNT BRUIS N S,  TIPPERARY"
    },
    {
        text: "MOUNT CARMEL SECONDARY SCHOOL,  DUBLIN 1"
    },
    {
        text: "MOUNTMELLICK BOYS N S,  LAOIS"
    },
    {
        text: "MOUNTMELLICK COMMUNITY SCHOOL,  LAOIS"
    },
    {
        text: "MOUNT MERCY COLLEGE,  CORK"
    },
    {
        text: "NAOMH MICHEAL,  MONAGHAN"
    },
    {
        text: "MOUNT PALMER NATIONAL SCHOOL,  MAYO"
    },
    {
        text: "MOUNT PLEASANT N S,  MAYO"
    },
    {
        text: "MOUNTRATH COMMUNITY SCHOOL,  LAOIS"
    },
    {
        text: "MOUNT SACKVILLE SECONDARY SCHOOL,  DUBLIN 20"
    },
    {
        text: "MOUNT SESKIN COMMUNITY COLLEGE,  DUBLIN 24"
    },
    {
        text: "MOUNT SION CBS N S,  WATERFORD"
    },
    {
        text: "MOUNT ST MICHAEL,  CORK"
    },
    {
        text: "MOUNT ST MICHAEL,  MAYO"
    },
    {
        text: "MOUNT TALBOT N S,  ROSCOMMON"
    },
    {
        text: "MOUNT TEMPLE COMPREHENSIVE SCHOOL,  DUBLIN 3"
    },
    {
        text: "MOVEEN N S,  CLARE"
    },
    {
        text: "MOVILLE COMMUNITY COLLEGE,  DONEGAL"
    },
    {
        text: "MOVILLE N S,  DONEGAL"
    },
    {
        text: "MOYGLASS N S,  TIPPERARY"
    },
    {
        text: "MOYLE N S,  DONEGAL"
    },
    {
        text: "MOYLE PARK COLLEGE,  DUBLIN 22"
    },
    {
        text: "MOYLOUGH N S,  SLIGO"
    },
    {
        text: "MOYNE COLLEGE,  MAYO"
    },
    {
        text: "MOYNE COMMUNITY SCHOOL,  LONGFORD"
    },
    {
        text: "MUCKROSS PARK COLLEGE,  DUBLIN 4"
    },
    {
        text: "MUIRE GAN SMAL,  KERRY"
    },
    {
        text: "MUIRE GAN SMAL C,  CORK"
    },
    {
        text: "MUIRE NA DEA COIRLE GIRLS SENIOR,  DUBLIN 12"
    },
    {
        text: "MUIRE OG 2 LORETO CON,  DUBLIN 12"
    },
    {
        text: "MUIRISC NS,  MAYO"
    },
    {
        text: "MULLACH N S,  CLARE"
    },
    {
        text: "MULLAHORAN CENTRAL N S,  CAVAN"
    },
    {
        text: "MULLINGAR COMMUNITY COLLEGE,  WESTMEATH"
    },
    {
        text: "MULLINGAR EDUCATE TOGETHER NS,  WESTMEATH"
    },
    {
        text: "MULROY COLLEGE,  DONEGAL"
    },
    {
        text: "MUNGRET COMMUNITY COLLEGE,  LIMERICK"
    },
    {
        text: "MURHUR  N S,  KERRY"
    },
    {
        text: "MURROE NATIONAL SCHOOL,  DONEGAL"
    },
    {
        text: "MYNA N S,  MAYO"
    },
    {
        text: "NAAS COMMUNITY COLLEGE,  KILDARE"
    },
    {
        text: "NAAS COMMUNITY NATIONAL SCHOOL,  KILDARE"
    },
    {
        text: "NA CARRAIGE N S,  LAOIS"
    },
    {
        text: "NAGLE COMMUNITY COLLEGE,  CORK"
    },
    {
        text: "NAGLE RICE PRIMARY SCHOOL,  KERRY"
    },
    {
        text: "NAGLE RICE SECONDARY SCHOOL,  CORK"
    },
    {
        text: "NAISCOIL IDE,  DUBLIN 5"
    },
    {
        text: "NA MAIGHDINE MUIRE GIRL,  DUBLIN 16"
    },
    {
        text: "NA MINTEOGA N S,  KERRY"
    },
    {
        text: "NANO NAGLE COLLEGE,  CORK"
    },
    {
        text: "NANO NAGLE N SCHOOL,  KERRY"
    },
    {
        text: "NAOMH ADHAMHNAIN,  DONEGAL"
    },
    {
        text: "NAOMH ATRACHTA,  ROSCOMMON"
    },
    {
        text: "NAOMH BODAIN,  DONEGAL"
    },
    {
        text: "NAOMH BRIDHID,  DONEGAL"
    },
    {
        text: "NAOMH BRID N S,  WICKLOW"
    },
    {
        text: "NAOMH CAILLIN,  LEITRIM"
    },
    {
        text: "NAOMH CHARTHAIGH,  KERRY"
    },
    {
        text: "NAOMH CLAR N S,  WESTMEATH"
    },
    {
        text: "NAOMH COLMAN MAC DUAIGH,  GALWAY"
    },
    {
        text: "NAOMH DOMINIC N S,  LONGFORD"
    },
    {
        text: "NAOMH EARNAIN N S,  LONGFORD"
    },
    {
        text: "NAOMH GUASACHTA N S,  LONGFORD"
    },
    {
        text: "NAOMH IOSEF N S,  WESTMEATH"
    },
    {
        text: "NAOMH LORCAN O TUATHAIL SENIOR BOYS,  DUBLIN 1"
    },
    {
        text: "NAOMH MAODHOG N.S.,  WEXFORD"
    },
    {
        text: "NAOMH MHUIRE,  WESTMEATH"
    },
    {
        text: "NAOMH MHUIRE N S,  OFFALY"
    },
    {
        text: "NAOMH MICHEAL N S,  WESTMEATH"
    },
    {
        text: "NAOMH MUIRE N S,  WESTMEATH"
    },
    {
        text: "NAOMH OLIVER PLUNKETT,  MONAGHAN"
    },
    {
        text: "NAOMH PADRAIG BOYS,  DUBLIN 4"
    },
    {
        text: "NAOMH PADRAIG  JUNIOR,  TIPPERARY"
    },
    {
        text: "NAOMH PADRAIG MEASC,  KERRY"
    },
    {
        text: "NAOMH PADRAIG N S,  LONGFORD"
    },
    {
        text: "NAOMH PADRAIG N S,  LAOIS"
    },
    {
        text: "NAOMH PADRAIG  SENIOR,  TIPPERARY"
    },
    {
        text: "NAOMH PIUS X N S,  LAOIS"
    },
    {
        text: "NAOMH SEOSAMH,  LOUTH"
    },
    {
        text: "NAOMH TOLA,  CLARE"
    },
    {
        text: "NAOMH TOLA N S,  WESTMEATH"
    },
    {
        text: "NAOMH TOMAS N S,  WESTMEATH"
    },
    {
        text: "NAVAN EDUCATE TOGETHER NS,  MEATH"
    },
    {
        text: "NEILLSTOWN N S,  DUBLIN 22"
    },
    {
        text: "NENAGH COLLEGE,  TIPPERARY"
    },
    {
        text: "NENAGH COMMUNITY NATIONAL SCHOOL,  TIPPERARY"
    },
    {
        text: "NEWBAWN N S,  WEXFORD"
    },
    {
        text: "NEWBRIDGE COLLEGE,  KILDARE"
    },
    {
        text: "NEWBRIDGE EDUCATE TOGETHER NS,  KILDARE"
    },
    {
        text: "NEWCESTOWN N S,  CORK"
    },
    {
        text: "NEW COURT SCHOOL,  WICKLOW"
    },
    {
        text: "NEW CROSS COLLEGE,  DUBLIN 11"
    },
    {
        text: "NEW INN B N S,  TIPPERARY"
    },
    {
        text: "NEWMARKET B N S,  CORK"
    },
    {
        text: "NEWPARK COMPREHENSIVE SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "NEWPORT CENTRAL,  MAYO"
    },
    {
        text: "NEWPORT COLLEGE,  TIPPERARY"
    },
    {
        text: "NEW QUAY NS,  CLARE"
    },
    {
        text: "NEW ROSS EDUCATE TOGETHER NATIONAL SCHOOL,  WEXFORD"
    },
    {
        text: "NEWTOWN DUNLECKNEY MXD,  CARLOW"
    },
    {
        text: "NEWTOWNGORE N S,  LEITRIM"
    },
    {
        text: "NEWTOWN JUNIOR SCHOOL,  WATERFORD"
    },
    {
        text: "NEWTOWNMOUNTKENNEDY PRIMARY SCHOOL,  WICKLOW"
    },
    {
        text: "NEWTOWN NS,  KILDARE"
    },
    {
        text: "NEWTOWN NS,  GALWAY"
    },
    {
        text: "NEWTOWN N S,  MEATH"
    },
    {
        text: "NEWTOWN SCHOOL,  WATERFORD"
    },
    {
        text: "NEWTOWNWHITE EDUCATE TOGETHER NS,  MAYO"
    },
    {
        text: "NICKER N S,  LIMERICK"
    },
    {
        text: "NIOCHLAS N S,  GALWAY"
    },
    {
        text: "NIOCLAS NAOFA,  GALWAY"
    },
    {
        text: "N MICHEAL AGUS PADRAIG,  ROSCOMMON"
    },
    {
        text: "NORTH BAY EDUCATE TOGETHER NS,  DUBLIN 5"
    },
    {
        text: "NORTH DUBLIN MUSLIM NS,  DUBLIN 7"
    },
    {
        text: "NORTH DUBLIN NS PROJECT,  DUBLIN 9"
    },
    {
        text: "NORTH KILDARE EDUCATE TOGETHER NS,  KILDARE"
    },
    {
        text: "NORTH MONASTERY SECONDARY SCHOOL,  CORK"
    },
    {
        text: "NORTH PRESENTATION PRIMARY SCHOOL,  CORK"
    },
    {
        text: "NORTH WICKLOW  EDUCATE TOGETHER SECONDARY SCHOOL,  WICKLOW"
    },
    {
        text: "NUNS CROSS N S,  WICKLOW"
    },
    {
        text: "OAKLANDS COMMUNITY COLLEGE,  OFFALY"
    },
    {
        text: "OATLANDS COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "O CALLAGHANS MILLS N S,  CLARE"
    },
    {
        text: "O'CAROLAN COLLEGE,  MEATH"
    },
    {
        text: "O'CONNELL SCHOOL,  DUBLIN 1"
    },
    {
        text: "ODHRAN NAOFA N S,  WESTMEATH"
    },
    {
        text: "OFFALY SCHOOL OF SPECIAL EDUCATION,  OFFALY"
    },
    {
        text: "Ó FIAICH COLLEGE,  LOUTH"
    },
    {
        text: "OGONNELLOE NATIONAL SCHOOL,  CLARE"
    },
    {
        text: "O'GROWNEY NATIONAL SCHOOL,  MEATH"
    },
    {
        text: "OLD BAWN COMMUNITY SCHOOL,  DUBLIN 24"
    },
    {
        text: "OOLA NATIONAL SCHOOL,  LIMERICK"
    },
    {
        text: "OULART N S,  WEXFORD"
    },
    {
        text: "OUR LADY GOOD COUNSEL GNS,  DUBLIN COUNTY"
    },
    {
        text: "OUR LADY IMMAC SEN N S,  DUBLIN 17"
    },
    {
        text: "OUR LADY OF CONSOLATION NS,  DUBLIN 5"
    },
    {
        text: "OUR LADY OF FATIMA SP S,  WEXFORD"
    },
    {
        text: "OUR LADY OF GOOD COUNSEL BOYS N S,  DUBLIN COUNTY"
    },
    {
        text: "OUR LADY OF GOOD COUNSEL GNS,  WATERFORD"
    },
    {
        text: "OUR LADY OF GOOD COUNSEL NS,  CORK"
    },
    {
        text: "OUR LADY OF GOOD COUNSEL SCHOOL,  DUBLIN 12"
    },
    {
        text: "OUR LADY OF HOPE SCHOOL,  DUBLIN D12"
    },
    {
        text: "OUR LADY OF LOURDES,  CORK"
    },
    {
        text: "OUR LADY OF LOURDES N S,  LIMERICK"
    },
    {
        text: "OUR LADY OF LOURDES SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "OUR LADY OF LOURDES SECONDARY SCHOOL,  WEXFORD"
    },
    {
        text: "OUR LADY OF MERCY COLLEGE,  DUBLIN 9"
    },
    {
        text: "OUR LADY OF MERCY CONVENT SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "OUR LADY OF MERCY NS,  MEATH"
    },
    {
        text: "OUR LADY OF MERCY N S,  CORK"
    },
    {
        text: "OUR LADY OF MERCY N S,  WATERFORD"
    },
    {
        text: "OUR LADY OF MERCY N S,  SLIGO"
    },
    {
        text: "OUR LADY OF  MERCY SECONDARY SCHOOL,  WATERFORD"
    },
    {
        text: "OUR LADY OF MERCY SECONDARY SCHOOL,  DUBLIN 12"
    },
    {
        text: "OUR LADY OF MERCY SENIOR P.S.,  WATERFORD"
    },
    {
        text: "OUR LADY OF VICTORIES INFANT N S,  DUBLIN 9"
    },
    {
        text: "OUR LADY OF WAYSIDE N S,  DUBLIN 12"
    },
    {
        text: "OUR LADY QUEEN OF APOSTLES,  DUBLIN 22"
    },
    {
        text: "OUR LADY QUEEN OF PEACE SCHOOL,  LIMERICK"
    },
    {
        text: "OUR LADYS ABBEY,  LIMERICK"
    },
    {
        text: "OUR LADY'S BOWER,  WESTMEATH"
    },
    {
        text: "OUR LADY'S COLLEGE,  GALWAY"
    },
    {
        text: "OUR LADY'S COLLEGE,  LOUTH"
    },
    {
        text: "OUR LADY'S GROVE PRIMARY SCHOOL,  DUBLIN 14"
    },
    {
        text: "OUR LADY'S GROVE SECONDARY SCHOOL,  DUBLIN 14"
    },
    {
        text: "OUR LADYS MEADOW NATIONAL SCHOO,  LAOIS"
    },
    {
        text: "OUR LADY'S NATIONAL SCHOOL,  CARLOW"
    },
    {
        text: "OUR LADY'S NATIONAL SCHOOL,  SLIGO"
    },
    {
        text: "OUR LADY'S NATIONAL SCHOOL,  TIPPERARY"
    },
    {
        text: "OUR LADYS N S,  DUBLIN 14"
    },
    {
        text: "OUR LADYS SCHOOL,  DUBLIN 6W"
    },
    {
        text: "OUR LADY'S SECONDARY SCHOOL,  MAYO"
    },
    {
        text: "OUR LADY'S SECONDARY SCHOOL,  MONAGHAN"
    },
    {
        text: "OUR LADYS SECONDARY SCHOOL,  TIPPERARY"
    },
    {
        text: "OVENS N S,  CORK"
    },
    {
        text: "OWENABUE ETNS,  CORK"
    },
    {
        text: "OWENBEG N S,  SLIGO"
    },
    {
        text: "OWNING NATIONAL SCHOOL,  KILKENNY"
    },
    {
        text: "PADDOCK N S,  LAOIS"
    },
    {
        text: "PADRAIG NAOFA N S,  WICKLOW"
    },
    {
        text: "PADRAIG NAOFA N S,  WICKLOW"
    },
    {
        text: "PALLASKENRY N S,  LIMERICK"
    },
    {
        text: "PAROCHIAL N S,  GALWAY"
    },
    {
        text: "PASSAGE EAST N S,  WATERFORD"
    },
    {
        text: "PATRICIAN ACADEMY,  CORK"
    },
    {
        text: "PATRICIAN HIGH SCHOOL,  MONAGHAN"
    },
    {
        text: "PATRICIAN PRESENTATION,  TIPPERARY"
    },
    {
        text: "PATRICIAN SECONDARY SCHOOL,  KILDARE"
    },
    {
        text: "PEARSE COLLEGE - COLAISTE AN PHIARSAIGH,  DUBLIN 12"
    },
    {
        text: "PELLETSTOWN ETNS,  DUBLIN 15"
    },
    {
        text: "PHOBAILSCOIL IOSOLDE,  DUBLIN 20"
    },
    {
        text: "PHOENIX PARK SPECIALIST SCHOOL,  DUBLIN 8"
    },
    {
        text: "PIPER'S HILL COLLEGE,  KILDARE"
    },
    {
        text: "PLUNKET COLLEGE,  DUBLIN 9"
    },
    {
        text: "POBALSCOIL CHLOICH CHEANNFHAOLA,  DONEGAL"
    },
    {
        text: "POBALSCOIL CHORCA DHUIBHNE,  KERRY"
    },
    {
        text: "POBALSCOIL GHAOTH DOBHAIR,  DONEGAL"
    },
    {
        text: "POBALSCOIL INBHEAR SCÉINE,  KERRY"
    },
    {
        text: "POBALSCOIL NA TRÍONÓIDE,  CORK"
    },
    {
        text: "POBALSCOIL NEASÁIN,  DUBLIN 13"
    },
    {
        text: "PORTLAOISE COLLEGE,  LAOIS"
    },
    {
        text: "PORTLAOISE EDUCATE TOGETHER NS,  LAOIS"
    },
    {
        text: "PORTLAW N.S.,  WATERFORD"
    },
    {
        text: "PORTLEAN N S,  DONEGAL"
    },
    {
        text: "PORTMARNOCK COMMUNITY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "PORTROE N S,  TIPPERARY"
    },
    {
        text: "PORTUMNA COMMUNITY SCHOOL,  GALWAY"
    },
    {
        text: "POULACAPPLE N S,  TIPPERARY"
    },
    {
        text: "POWERSCOURT N S,  WICKLOW"
    },
    {
        text: "POWERSTOWN EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 15"
    },
    {
        text: "POWERSTOWN N S,  TIPPERARY"
    },
    {
        text: "PRESENTATION BROTHERS COLLEGE,  CORK"
    },
    {
        text: "PRESENTATION COLLEGE,  DUBLIN 8"
    },
    {
        text: "PRESENTATION COLLEGE,  CARLOW"
    },
    {
        text: "PRESENTATION COLLEGE,  WICKLOW"
    },
    {
        text: "PRESENTATION COLLEGE,  GALWAY"
    },
    {
        text: "PRESENTATION COLLEGE,  GALWAY"
    },
    {
        text: "PRESENTATION COLLEGE,  GALWAY"
    },
    {
        text: "PRESENTATION COMMUNITY COLLEGE,  DUBLIN 6W"
    },
    {
        text: "PRESENTATION CONVENT,  LOUTH"
    },
    {
        text: "PRESENTATION CONVENT,  CORK"
    },
    {
        text: "PRESENTATION CONVENT (JNR),  WESTMEATH"
    },
    {
        text: "PRESENTATION CONVENT NATIONAL SCHOOL,  KILKENNY"
    },
    {
        text: "PRESENTATION / DE LA SALLE COLLEGE,  CARLOW"
    },
    {
        text: "PRESENTATION GIRLS PRIMARY SCHOOL,  KILDARE"
    },
    {
        text: "PRESENTATION PRIMARY SCHOOL,  DUBLIN 7"
    },
    {
        text: "PRESENTATION PRIMARY SCHOOL,  TIPPERARY"
    },
    {
        text: "PRESENTATION PRIMARY SCHOOL,  DUBLIN 8"
    },
    {
        text: "PRESENTATION PRIMARY SCHOOL,  DUBLIN 6W"
    },
    {
        text: "PRESENTATION PRIMARY SCHOOL,  LAOIS"
    },
    {
        text: "PRESENTATION PRIMARY SCHOOL,  TIPPERARY"
    },
    {
        text: "PRESENTATION PRIMARY SCHOOL,  WATERFORD"
    },
    {
        text: "PRESENTATION PRIMARY SCHOOL,  LIMERICK"
    },
    {
        text: "PRESENTATION SECONDARY SCHOOL,  CORK"
    },
    {
        text: "PRESENTATION SECONDARY SCHOOL,  KERRY"
    },
    {
        text: "PRESENTATION SECONDARY SCHOOL,  KERRY"
    },
    {
        text: "PRESENTATION SECONDARY SCHOOL,  KERRY"
    },
    {
        text: "PRESENTATION SECONDARY SCHOOL,  KILKENNY"
    },
    {
        text: "PRESENTATION SECONDARY SCHOOL,  CORK"
    },
    {
        text: "PRESENTATION SECONDARY SCHOOL,  WEXFORD"
    },
    {
        text: "PRESENTATION SECONDARY SCHOOL,  WATERFORD"
    },
    {
        text: "PRESENTATION SECONDARY SCHOOL,  TIPPERARY"
    },
    {
        text: "PRESENTATION SECONDARY SCHOOL,  TIPPERARY"
    },
    {
        text: "PRESENTATION SECONDARY SCHOOL,  TIPPERARY"
    },
    {
        text: "PRIMROSE HILL NS,  KILDARE"
    },
    {
        text: "PROSPEROUS N S,  KILDARE"
    },
    {
        text: "QUEEN OF ANGELS PRIMARY SCHOOL,  DUBLIN 16"
    },
    {
        text: "QUEEN OF UNIVERSE N S,  CARLOW"
    },
    {
        text: "QUIGNAMANGER N S,  MAYO"
    },
    {
        text: "QUILTY NS,  CLARE"
    },
    {
        text: "RADHARC NA MARA NATIONAL SCHOOL,  GALWAY"
    },
    {
        text: "RAHEEN NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "RAHEEN WOOD COMMUNITY NATIONAL SCHOOL,  CLARE"
    },
    {
        text: "RAITHIN AN UISCE N S,  LAOIS"
    },
    {
        text: "RAMSGRANGE CENTRAL N S,  WEXFORD"
    },
    {
        text: "RAMSGRANGE COMMUNITY SCHOOL,  WEXFORD"
    },
    {
        text: "RANELAGH MULTI DENOM NS,  DUBLIN 6"
    },
    {
        text: "RANSBORO NEW CENTRAL,  SLIGO"
    },
    {
        text: "RAPHOE CENTRAL N S,  DONEGAL"
    },
    {
        text: "RATHANGAN B N S,  KILDARE"
    },
    {
        text: "RATHANGAN N S,  WEXFORD"
    },
    {
        text: "RATHBANE N S,  MAYO"
    },
    {
        text: "RATHCOFFEY N S,  KILDARE"
    },
    {
        text: "RATHCOOLE ETNS,  DUBLIN 24"
    },
    {
        text: "RATHCORMACK N S,  SLIGO"
    },
    {
        text: "RATHCORMAC N S,  CORK"
    },
    {
        text: "RATHCOYLE N S,  WICKLOW"
    },
    {
        text: "RATH DARA COMMUNITY COLLEGE,  DUBLIN 15"
    },
    {
        text: "RATHDOMHNAIGH N S,  LAOIS"
    },
    {
        text: "RATHDOWN SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "RATHDRUM BOYS N S,  WICKLOW"
    },
    {
        text: "RATH EOGHAN N S,  WESTMEATH"
    },
    {
        text: "RATHFARNHAM EDUCATE TOGETHER,  DUBLIN 14"
    },
    {
        text: "RATHFARNHAM PARISH N S,  DUBLIN 14"
    },
    {
        text: "RATHGAR N S,  DUBLIN 6"
    },
    {
        text: "RATHGAROGUE N S,  WEXFORD"
    },
    {
        text: "RATHGORMACK B N S,  WATERFORD"
    },
    {
        text: "RATHLEE N S,  SLIGO"
    },
    {
        text: "RATHMICHAEL N S,  DUBLIN 18"
    },
    {
        text: "RATHMINES COLLEGE,  DUBLIN 6"
    },
    {
        text: "RATH MIXED N S,  LAOIS"
    },
    {
        text: "RATHMORE N S,  CORK"
    },
    {
        text: "RATHMULLAN N S,  LOUTH"
    },
    {
        text: "RATHMULLEN N S,  DONEGAL"
    },
    {
        text: "RATHOE NS,  CARLOW"
    },
    {
        text: "RATOATH COLLEGE,  MEATH"
    },
    {
        text: "RATOATH JUNIOR N S,  MEATH"
    },
    {
        text: "RATOATH SENIOR NS,  MEATH"
    },
    {
        text: "RAVENSWELL PRIMARY SCHOOL,  WICKLOW"
    },
    {
        text: "RAY N S,  DONEGAL"
    },
    {
        text: "REALT NA MAIDINE,  CORK"
    },
    {
        text: "REALT NA MAIDNE,  KERRY"
    },
    {
        text: "RÉALT NA MARA NATIONAL SCHOOL,  WATERFORD"
    },
    {
        text: "RÉALT NA MARA SCHOOL,  LOUTH"
    },
    {
        text: "REAR N S,  TIPPERARY"
    },
    {
        text: "RED DOOR SPECIAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "RED HILL SCHOOL,  LIMERICK"
    },
    {
        text: "REGINA MUNDI COLLEGE,  CORK"
    },
    {
        text: "RICE COLLEGE,  CLARE"
    },
    {
        text: "RICE COLLEGE,  MAYO"
    },
    {
        text: "RINEEN N S,  CLARE"
    },
    {
        text: "RINGASKIDDY LOWER HARBOUR N S,  CORK"
    },
    {
        text: "RINGSEND COLLEGE,  DUBLIN 4"
    },
    {
        text: "RINGVILLE NATIONAL SCHOOL,  KILKENNY"
    },
    {
        text: "RIVERCHAPEL N S,  WEXFORD"
    },
    {
        text: "RIVERSTOWN N S,  CORK"
    },
    {
        text: "RIVER VALLEY CNS,  DUBLIN"
    },
    {
        text: "RIVERVIEW EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 12"
    },
    {
        text: "ROBEEN CENTRAL N S,  MAYO"
    },
    {
        text: "ROBERTSON N S,  DONEGAL"
    },
    {
        text: "ROBERTSON N S,  DONEGAL"
    },
    {
        text: "ROCKBROOK PARK SCHOOL,  DUBLIN 16"
    },
    {
        text: "ROCKCHAPEL N S,  CORK"
    },
    {
        text: "ROCKCORRY N S,  MONAGHAN"
    },
    {
        text: "ROCKFIELD N S,  DONEGAL"
    },
    {
        text: "ROCKFIELD N S,  SLIGO"
    },
    {
        text: "ROCKFORD MANOR SECONDARY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "ROCKMOUNT MIXED N S,  CLARE"
    },
    {
        text: "ROCKWELL COLLEGE,  TIPPERARY"
    },
    {
        text: "ROOSKEY N S,  ROSCOMMON"
    },
    {
        text: "ROSARY COLLEGE,  DUBLIN 12"
    },
    {
        text: "ROSCARBERY CON N S,  CORK"
    },
    {
        text: "ROSCOMMON COMMUNITY COLLEGE,  ROSCOMMON"
    },
    {
        text: "ROSCREA N S NO 2,  TIPPERARY"
    },
    {
        text: "ROSEDALE SCHOOL,  GALWAY"
    },
    {
        text: "ROSEGREEN N S,  TIPPERARY"
    },
    {
        text: "ROSEMONT SCHOOL,  DUBLIN 18"
    },
    {
        text: "ROSENALLIS N S,  LAOIS"
    },
    {
        text: "ROSMINI COMMUNITY SCHOOL,  DUBLIN 9"
    },
    {
        text: "ROSSES COMMUNITY SCHOOL,  DONEGAL"
    },
    {
        text: "ROSSMORE NATIONAL SCHOOL,  TIPPERARY"
    },
    {
        text: "ROXBORO N S,  ROSCOMMON"
    },
    {
        text: "ROYAL SCHOOL CAVAN,  CAVAN"
    },
    {
        text: "RUAN CENTRAL NS,  CLARE"
    },
    {
        text: "RUSHALL N S,  LAOIS"
    },
    {
        text: "RUSH AND LUSK EDUCATE TOGETHER NS,  DUBLIN COUNTY"
    },
    {
        text: "RUSHEEN NS,  CORK"
    },
    {
        text: "RUSHNACAHARA N S,  CORK"
    },
    {
        text: "RUSH NS,  DUBLIN COUNTY"
    },
    {
        text: "RUTLAND NATIONAL SCHOOL,  DUBLIN 1"
    },
    {
        text: "RYLANE N S,  CORK"
    },
    {
        text: "SACRED HEART NATIONAL SCHOOL, CAIM,  WEXFORD"
    },
    {
        text: "SACRED HEART N S,  DUBLIN 15"
    },
    {
        text: "SACRED HEART N S,  DUBLIN 22"
    },
    {
        text: "SACRED HEART PRIMARY SCHOOL,  TIPPERARY"
    },
    {
        text: "SACRED HEART SCHOOL,  MAYO"
    },
    {
        text: "SACRED HEART SECONDARY SCHOOL,  CORK"
    },
    {
        text: "SACRED HEART SECONDARY SCHOOL,  LOUTH"
    },
    {
        text: "SACRED HEART SECONDARY SCHOOL,  OFFALY"
    },
    {
        text: "SAINT MARYS N S LAHINCH,  CLARE"
    },
    {
        text: "SAINT MELS,  LONGFORD"
    },
    {
        text: "SAINT MICHAELS N S,  MAYO"
    },
    {
        text: "SALESIAN COLLEGE,  KILDARE"
    },
    {
        text: "SALESIAN PRIMARY SCHOOL,  LIMERICK"
    },
    {
        text: "SALESIAN SECONDARY COLLEGE,  LIMERICK"
    },
    {
        text: "SALLYNOGGIN EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "SAMHTHANN N S,  LONGFORD"
    },
    {
        text: "SAN CARLO JUNIOR NS,  KILDARE"
    },
    {
        text: "SAN CARLO SENIOR N S,  KILDARE"
    },
    {
        text: "SANCTA MARIA C B S,  DUBLIN 8"
    },
    {
        text: "SANCTA MARIA COLLEGE,  DUBLIN 16"
    },
    {
        text: "SANCTA MARIA COLLEGE,  MAYO"
    },
    {
        text: "SANDFORD PARISH NATIONAL SCHOOL,  DUBLIN 6"
    },
    {
        text: "SANDFORD PARK SCHOOL LTD,  DUBLIN 6"
    },
    {
        text: "SANDYMOUNT PARK EDUCATE TOGETHER SECONDARY SCHOOL,  DUBLIN 4"
    },
    {
        text: "SAN ISADOIR,  TIPPERARY"
    },
    {
        text: "SAN NIOCLAS MYRA,  DUBLIN 17"
    },
    {
        text: "SANTA SABINA DOMINICAN COLLEGE,  DUBLIN 13"
    },
    {
        text: "SAPLINGS CARLOW SPECIAL SCHOOL,  CARLOW"
    },
    {
        text: "SAPLINGS SPECIAL SCHOOL,  DUBLIN 16"
    },
    {
        text: "SAPLINGS SPECIAL SCHOOL,  WESTMEATH"
    },
    {
        text: "SAPLINGS SPECIAL SCHOOL,  KILDARE"
    },
    {
        text: "SAPLINGS SPECIAL SCHOOL,  KILKENNY"
    },
    {
        text: "SCARIFF CENTRAL N S,  CLARE"
    },
    {
        text: "SCARIFF COMMUNITY COLLEGE,  CLARE"
    },
    {
        text: "SCARTAGLIN NEW CENTRAL SCHOOL,  KERRY"
    },
    {
        text: "SC CIARAIN,  WESTMEATH"
    },
    {
        text: "SCHOOL OF THE DIVINE CHILD (SCOIL AN LINBH ÍOSA),  CORK"
    },
    {
        text: "SCHOOL OF THE HOLY SPIRIT SPECIAL SCHOOL,  KILKENNY"
    },
    {
        text: "SCHULL COMMUNITY COLLEGE,  CORK"
    },
    {
        text: "SCOIL ABÁN NAOFA,  CORK"
    },
    {
        text: "SCOIL ABBAIN CILLIN,  LAOIS"
    },
    {
        text: "SCOIL ADHAMHNAIN,  DONEGAL"
    },
    {
        text: "SCOIL AENGHUSA JUN NS,  DUBLIN 24"
    },
    {
        text: "SCOIL AILBHE,  TIPPERARY"
    },
    {
        text: "SCOIL AILBHE,  LIMERICK"
    },
    {
        text: "SCOIL AINE,  GALWAY"
    },
    {
        text: "SCOIL AINE CONVENT SENIOR,  DUBLIN 5"
    },
    {
        text: "SCOIL AINE NAOFA,  LIMERICK"
    },
    {
        text: "SCOIL AINE NAOFA,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL AIREAGAIL,  KILKENNY"
    },
    {
        text: "SCOIL AISEIRI CHRIOST,  CORK"
    },
    {
        text: "SCOIL AISLING,  CORK"
    },
    {
        text: "SCOIL AN AINGIL CHOIMHEADAI,  DONEGAL"
    },
    {
        text: "SCOIL AN ATHAIR TADHG O MURCHU,  CORK"
    },
    {
        text: "SCOIL AN ATHAR TADHG,  CORK"
    },
    {
        text: "SCOIL AN CHLOCHAIR CILL BHEAGAN,  WESTMEATH"
    },
    {
        text: "SCOIL AN CHOROIN MHUIRE,  WICKLOW"
    },
    {
        text: "SCOIL AN CHROI NAOFA,  GALWAY"
    },
    {
        text: "SCOIL AN CHROI RO NAOFA,  LAOIS"
    },
    {
        text: "SCOIL AN CHROÍ RÓ-NAOFA,  KILKENNY"
    },
    {
        text: "SCOIL AN CROI RO NAOFA,  CORK"
    },
    {
        text: "SCOIL AN FHAILL MOR,  KERRY"
    },
    {
        text: "SCOIL AN FHIRTEARAIGH,  KERRY"
    },
    {
        text: "SCOIL ANGELA,  TIPPERARY"
    },
    {
        text: "SCOIL AN GHLEANNA POBAL SCOIL NÁISI,  KERRY"
    },
    {
        text: "SCOIL AN LEINBH IOSA BOYS SENIORS,  DUBLIN 9"
    },
    {
        text: "SCOIL AN LINBH IOSA,  GALWAY"
    },
    {
        text: "SCOIL AN LINBH ÍOSA,  DONEGAL"
    },
    {
        text: "SCOIL AN MAIGHDINE MHUIRE BOYS,  DUBLIN 16"
    },
    {
        text: "SCOIL AN SPIORAID NAOIMH,  MEATH"
    },
    {
        text: "SCOIL AN SPIORAID NAOMH,  LIMERICK"
    },
    {
        text: "SCOIL AN SPIORAID NAOMH (BOYS),  CORK"
    },
    {
        text: "SCOIL AN SRAITH  S N,  CLARE"
    },
    {
        text: "SCOIL AN TSEACHTAR LAOCH,  DUBLIN 11"
    },
    {
        text: "SCOIL AN TSTRATHA MHÓIR,  DONEGAL"
    },
    {
        text: "SCOIL AODH RUA&NUALA,  DONEGAL"
    },
    {
        text: "SCOIL AOIFE CNS,  DUBLIN 24"
    },
    {
        text: "SCOIL AONGHUSA,  TIPPERARY"
    },
    {
        text: "SCOIL ASICUS,  SLIGO"
    },
    {
        text: "SCOIL ASSAIM BOYS SENIORS,  DUBLIN 5"
    },
    {
        text: "SCOIL ATHAIN,  LIMERICK"
    },
    {
        text: "SCOIL ATHA NA MBLATH,  KERRY"
    },
    {
        text: "SCOIL BAILE AN AIRD,  LIMERICK"
    },
    {
        text: "SCOIL BARRA,  CORK"
    },
    {
        text: "SCOIL BARR DUBH,  KERRY"
    },
    {
        text: "SCOIL BHREANAINN,  KERRY"
    },
    {
        text: "SCOIL BHREANDAIN,  DUBLIN 5"
    },
    {
        text: "SCOIL BHREANDAIN NAOFA,  GALWAY"
    },
    {
        text: "SCOIL BHRIDE,  LAOIS"
    },
    {
        text: "SCOIL BHRIDE,  CORK"
    },
    {
        text: "SCOIL BHRIDE,  CORK"
    },
    {
        text: "SCOIL BHRIDE,  CAVAN"
    },
    {
        text: "SCOIL BHRIDE,  CAVAN"
    },
    {
        text: "SCOIL BHRIDE,  GALWAY"
    },
    {
        text: "SCOIL BHRIDE,  LOUTH"
    },
    {
        text: "SCOIL BHRIDE,  OFFALY"
    },
    {
        text: "SCOIL BHRIDE,  DONEGAL"
    },
    {
        text: "SCOIL BHRIDE,  KILDARE"
    },
    {
        text: "SCOIL BHRIDE,  DUBLIN 13"
    },
    {
        text: "SCOIL BHRIDE,  LAOIS"
    },
    {
        text: "SCOIL BHRIDE,  LAOIS"
    },
    {
        text: "SCOIL BHRÍDE,  KERRY"
    },
    {
        text: "SCOIL BHRÍDE,  LONGFORD"
    },
    {
        text: "SCOIL BHRÍDE,  MEATH"
    },
    {
        text: "SCOIL BHRÍDE,  KILDARE"
    },
    {
        text: "SCOIL BHRÍDE,  ROSCOMMON"
    },
    {
        text: "SCOIL BHRIDE B7C,  KILKENNY"
    },
    {
        text: "SCOIL BHRIDE MIXED N S,  LOUTH"
    },
    {
        text: "SCOIL BHRIDE NS,  LAOIS"
    },
    {
        text: "SCOIL BHRIDE N S,  KILDARE"
    },
    {
        text: "SCOIL BHRÍDE PRIMARY SCHOOL,  OFFALY"
    },
    {
        text: "SCOIL BHRIGE AGUS BHREANDAIN NAOFA,  GALWAY"
    },
    {
        text: "SCOIL BHRIGHDE,  DONEGAL"
    },
    {
        text: "SCOIL BHRIGHDE,  MONAGHAN"
    },
    {
        text: "SCOIL BHRUGH THUINNE,  CORK"
    },
    {
        text: "SCOIL BRÍD,  KILDARE"
    },
    {
        text: "SCOIL BRIDE,  KILDARE"
    },
    {
        text: "SCOIL BRIDE,  GALWAY"
    },
    {
        text: "SCOIL BRIDE,  MONAGHAN"
    },
    {
        text: "SCOIL BRIDE B,  DUBLIN 15"
    },
    {
        text: "SCOIL BRIDE C,  DUBLIN 15"
    },
    {
        text: "SCOIL BRIDE C,  DUBLIN 20"
    },
    {
        text: "SCOIL CAITLIN NAOFA,  KERRY"
    },
    {
        text: "SCOIL CAITRIONA NA MBRAITHRE,  DUBLIN 2"
    },
    {
        text: "SCOIL CAOIMHIN NAOFA,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL CARMEL,  DUBLIN 24"
    },
    {
        text: "SCOIL CHAITIGHEIRN,  CORK"
    },
    {
        text: "SCOIL CHAITLIN MAUDE,  DUBLIN 24"
    },
    {
        text: "SCOIL CHAITRIONA,  CORK"
    },
    {
        text: "SCOIL CHAITRIONA,  DUBLIN 9"
    },
    {
        text: "SCOIL CHAITRÍONA,  LIMERICK"
    },
    {
        text: "SCOIL CHAOIMHIN,  DUBLIN 1"
    },
    {
        text: "SCOIL CHARTHA NAOFA,  DONEGAL"
    },
    {
        text: "SCOIL CHIARAIN,  DUBLIN 5"
    },
    {
        text: "SCOIL CHLANN NAOFA,  LEITRIM"
    },
    {
        text: "SCOIL CHLOCHAIR MHUIRE NATIONAL SCHOOL,  CORK"
    },
    {
        text: "SCOIL CHÓCA NAOFA,  KILDARE"
    },
    {
        text: "SCOIL CHOILL MHIC THOMAISIN,  WATERFORD"
    },
    {
        text: "SCOIL CHOILMCILLE,  DONEGAL"
    },
    {
        text: "SCOIL CHOILM COMMUNITY NS,  DUBLIN 15"
    },
    {
        text: "SCOIL CHOLMAIN NAOFA,  WESTMEATH"
    },
    {
        text: "SCOIL CHOLMCHILLE,  DONEGAL"
    },
    {
        text: "SCOIL CHOLMCILLE,  DONEGAL"
    },
    {
        text: "SCOIL CHOLMCILLE,  MAYO"
    },
    {
        text: "SCOIL CHOLMCILLE,  MEATH"
    },
    {
        text: "SCOIL CHOLMCILLE,  DONEGAL"
    },
    {
        text: "SCOIL CHOLMCILLE,  DONEGAL"
    },
    {
        text: "SCOIL CHOLMCILLE,  DONEGAL"
    },
    {
        text: "SCOIL CHOLMCILLE JUNIOR NS,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL CHOLMCILLE NAOFA,  DONEGAL"
    },
    {
        text: "SCOIL MHUIRE NAOFA,  GALWAY"
    },
    {
        text: "SCOIL CHOLUIM,  DONEGAL"
    },
    {
        text: "SCOIL CHOMAIN NAOFA,  MAYO"
    },
    {
        text: "SCOIL CHOMHGAIN NAOFA,  LAOIS"
    },
    {
        text: "SCOIL CHONGLAIS,  WICKLOW"
    },
    {
        text: "SCOIL CHONNLA PHADRAIG,  KILDARE"
    },
    {
        text: "SCOIL CHORBAIN,  KILDARE"
    },
    {
        text: "SCOIL CHORMAIC,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL CHORP CHRÍOST,  KERRY"
    },
    {
        text: "SCOIL CHRIOST AN SLANAITHEOIR,  LIMERICK"
    },
    {
        text: "SCOIL CHRIOST RI,  KERRY"
    },
    {
        text: "SCOIL CHRIOST RI,  LAOIS"
    },
    {
        text: "SCOIL CHRIOST RI B,  LIMERICK"
    },
    {
        text: "SCOIL CHROÍ ÍOSA,  CORK"
    },
    {
        text: "SCOIL CHROI NAOFA,  GALWAY"
    },
    {
        text: "SCOIL CHROI NAOFA,  WESTMEATH"
    },
    {
        text: "SCOIL CHRÓINE,  DONEGAL"
    },
    {
        text: "SCOIL CHROÍ RÓ NAOFA BEANNCHOR IORRAIS,  MAYO"
    },
    {
        text: "SCOIL CHRONAIN,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL CHUALANN,  WICKLOW"
    },
    {
        text: "SCOIL CHUIL  AODHA  BARR D INSE,  CORK"
    },
    {
        text: "SCOIL CHUIMÍN & CAITRÍONA,  GALWAY"
    },
    {
        text: "SCOIL CHUIMSITHEACH CHIARÁIN,  GALWAY"
    },
    {
        text: "SCOIL CILL CHURNAIN,  LIMERICK"
    },
    {
        text: "SCOIL CILL COLMAIN,  LIMERICK"
    },
    {
        text: "SCOIL CNOC LOINGE  B,  LIMERICK"
    },
    {
        text: "SCOIL CNOC MHUIRE JUNIOR,  DUBLIN 24"
    },
    {
        text: "SCOIL CNOC MHUIRE SIN,  DUBLIN 24"
    },
    {
        text: "SCOIL COLMAIN NAOFA,  OFFALY"
    },
    {
        text: "SCOIL COLMCILLE,  CORK"
    },
    {
        text: "SCOIL COLMCILLE NAOFA,  DUBLIN 16"
    },
    {
        text: "SCOIL COLMCILLE SENIOR,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL CRE CUMHRA,  LIMERICK"
    },
    {
        text: "SCOIL CROI IOSA,  GALWAY"
    },
    {
        text: "SCOIL CROI NAOFA,  SLIGO"
    },
    {
        text: "SCOIL C.UI DHALAIGH,  KILDARE"
    },
    {
        text: "SCOIL CUILM CILLE,  DONEGAL"
    },
    {
        text: "SCOIL DAIRBHRE MIXED,  LOUTH"
    },
    {
        text: "SCOIL DARA,  KILDARE"
    },
    {
        text: "SCOIL DEAN CUSSEN,  LIMERICK"
    },
    {
        text: "SCOIL DHAIRBHRE,  CORK"
    },
    {
        text: "SCOIL EANNA,  CORK"
    },
    {
        text: "SCOIL ÉANNA,  MONAGHAN"
    },
    {
        text: "SCOIL EASA DHUIBHE,  KERRY"
    },
    {
        text: "SCOIL EINNE,  GALWAY"
    },
    {
        text: "SCOIL ENDA,  MONAGHAN"
    },
    {
        text: "SCOIL EOGHAN,  DONEGAL"
    },
    {
        text: "SCOIL EOIN,  KERRY"
    },
    {
        text: "SCOIL EOIN,  DUBLIN 12"
    },
    {
        text: "SCOIL EOIN,  DUBLIN 5"
    },
    {
        text: "SCOIL EOIN BAISTE,  WEXFORD"
    },
    {
        text: "SCOIL EOIN BAISTE,  DONEGAL"
    },
    {
        text: "SCOIL EOIN BÁISTE,  MEATH"
    },
    {
        text: "SCOIL EOIN NAOFA,  SLIGO"
    },
    {
        text: "SCOIL EOIN PHOIL,  KILDARE"
    },
    {
        text: "SCOIL FHIACHRA SOISIR,  DUBLIN 5"
    },
    {
        text: "SCOIL FHIONÁIN,  DONEGAL"
    },
    {
        text: "SCOIL FHURSA,  LOUTH"
    },
    {
        text: "SCOIL FIONNBARRA,  CORK"
    },
    {
        text: "SCOIL FREASTOGAIL MUIRE,  CORK"
    },
    {
        text: "SCOIL GEARBHAIN,  WATERFORD"
    },
    {
        text: "SCOIL GHOBNATAN,  CORK"
    },
    {
        text: "SCOIL GHORMÁIN NAOFA,  WEXFORD"
    },
    {
        text: "SCOIL GHRAINNE COMMUNITY NATIONAL SCHOOL,  DUBLIN 15"
    },
    {
        text: "SCOIL IARLATHA NAOFA,  GALWAY"
    },
    {
        text: "SCOIL IDE,  GALWAY"
    },
    {
        text: "SCOIL IDE,  LIMERICK"
    },
    {
        text: "SCOIL IDE NAOFA,  LIMERICK"
    },
    {
        text: "SCOIL IOGNAID DE RIS,  KILKENNY"
    },
    {
        text: "SCOIL IOSA,  DUBLIN 24"
    },
    {
        text: "SCOIL IOSA,  MAYO"
    },
    {
        text: "SCOIL ÍOSA,  MAYO"
    },
    {
        text: "SCOIL IOSAGAIN,  LIMERICK"
    },
    {
        text: "SCOIL IOSAGAIN,  CORK"
    },
    {
        text: "SCOIL IOSAGAIN,  TIPPERARY"
    },
    {
        text: "SCOIL IOSAGAIN,  DONEGAL"
    },
    {
        text: "SCOIL ÍOSAGÁIN,  KERRY"
    },
    {
        text: "SCOIL IOSAGAIN BOYS SENIOR,  DUBLIN 12"
    },
    {
        text: "SCOIL LACHTAIN,  KILKENNY"
    },
    {
        text: "SCOIL LACHTAIN NAOFA,  CORK"
    },
    {
        text: "SCOIL LORCÁIN,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL LORCAIN B,  DUBLIN 20"
    },
    {
        text: "SCOIL LORCAIN BNS,  WATERFORD"
    },
    {
        text: "SCOIL MAELRUAIN JUNIOR,  DUBLIN 24"
    },
    {
        text: "SCOIL MAELRUAIN SENIOR,  DUBLIN 24"
    },
    {
        text: "SCOIL MARIA ASSUMPTA,  CORK"
    },
    {
        text: "SCOIL MATHAIR DE,  LIMERICK"
    },
    {
        text: "SCOIL MHÁIRTIN,  CORK"
    },
    {
        text: "SCOIL MHICHIL,  MONAGHAN"
    },
    {
        text: "SCOIL MHICHIL,  CLARE"
    },
    {
        text: "SCOIL MHICHIL NA BUACHAILLI,  MEATH"
    },
    {
        text: "SCOIL MHICHIL NAOFA,  KILKENNY"
    },
    {
        text: "SCOIL MHICHIL NAOFA,  KILDARE"
    },
    {
        text: "SCOIL MHOLAINGE LISTERLIN,  KILKENNY"
    },
    {
        text: "SCOIL MHUIRE,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL MHUIRE,  MONAGHAN"
    },
    {
        text: "SCOIL MHUIRE,  MONAGHAN"
    },
    {
        text: "SCOIL MHUIRE,  GALWAY"
    },
    {
        text: "SCOIL MHUIRE,  DUBLIN 4"
    },
    {
        text: "SCOIL MHUIRE,  GALWAY"
    },
    {
        text: "SCOIL MHUIRE,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL MHUIRE,  KERRY"
    },
    {
        text: "SCOIL MHUIRE,  LIMERICK"
    },
    {
        text: "SCOIL MHUIRE,  LAOIS"
    },
    {
        text: "SCOIL MHUIRE,  CAVAN"
    },
    {
        text: "SCOIL MHUIRE,  LONGFORD"
    },
    {
        text: "SCOIL MHUIRE,  DONEGAL"
    },
    {
        text: "SCOIL MHUIRE,  GALWAY"
    },
    {
        text: "SCOIL MHUIRE,  DUBLIN 20"
    },
    {
        text: "SCOIL MHUIRE,  GALWAY"
    },
    {
        text: "SCOIL MHUIRE,  OFFALY"
    },
    {
        text: "SCOIL MHUIRE,  ROSCOMMON"
    },
    {
        text: "SCOIL MHUIRE,  DONEGAL"
    },
    {
        text: "SCOIL MHUIRE,  GALWAY"
    },
    {
        text: "SCOIL MHUIRE,  LIMERICK"
    },
    {
        text: "SCOIL MHUIRE,  KERRY"
    },
    {
        text: "SCOIL MHUIRE,  DONEGAL"
    },
    {
        text: "SCOIL MHUIRE,  MONAGHAN"
    },
    {
        text: "SCOIL MHUIRE,  TIPPERARY"
    },
    {
        text: "SCOIL MHUIRE,  CORK"
    },
    {
        text: "SCOIL MHUIRE,  DONEGAL"
    },
    {
        text: "SCOIL MHUIRE,  WESTMEATH"
    },
    {
        text: "SCOIL MHUIRE,  TIPPERARY"
    },
    {
        text: "SCOIL MHUIRE,  GALWAY"
    },
    {
        text: "SCOIL MHUIRE,  DUBLIN 22"
    },
    {
        text: "SCOIL MHUIRE,  LIMERICK"
    },
    {
        text: "SCOIL MHUIRE,  DONEGAL"
    },
    {
        text: "SCOIL MHUIRE,  KILDARE"
    },
    {
        text: "SCOIL MHUIRE,  KILDARE"
    },
    {
        text: "SCOIL MHUIRE,  DUBLIN 16"
    },
    {
        text: "SCOIL MHUIRE,  DONEGAL"
    },
    {
        text: "SCOIL MHUIRE,  GALWAY"
    },
    {
        text: "SCOIL MHUIRE,  LEITRIM"
    },
    {
        text: "SCOIL MHUIRE,  WEXFORD"
    },
    {
        text: "SCOIL MHUIRE,  WATERFORD"
    },
    {
        text: "SCOIL MHUIRE,  TIPPERARY"
    },
    {
        text: "SCOIL MHUIRE,  WEXFORD"
    },
    {
        text: "SCOIL MHUIRE,  LAOIS"
    },
    {
        text: "SCOIL MHUIRE,  MEATH"
    },
    {
        text: "SCOIL MHUIRE,  DONEGAL"
    },
    {
        text: "SCOIL MHUIRE,  KILKENNY"
    },
    {
        text: "SCOIL MHUIRE,  GALWAY"
    },
    {
        text: "SCOIL MHUIRE,  GALWAY"
    },
    {
        text: "SCOIL MHUIRE,  TIPPERARY"
    },
    {
        text: "SCOIL MHUIRE,  LEITRIM"
    },
    {
        text: "SCOIL MHUIRE,  KILKENNY"
    },
    {
        text: "SCOIL MHUIRE,  CLARE"
    },
    {
        text: "SCOIL MHUIRE,  CORK"
    },
    {
        text: "SCOIL MHUIRE,  CORK"
    },
    {
        text: "SCOIL MHUIRE,  MEATH"
    },
    {
        text: "SCOIL MHUIRE,  ROSCOMMON"
    },
    {
        text: "SCOIL MHUIRE,  TIPPERARY"
    },
    {
        text: "SCOIL MHUIRE,  CORK"
    },
    {
        text: "SCOIL MHUIRE,,  ROSCOMMON"
    },
    {
        text: "SCOIL MHUIRE AGUS CHORMAIC,  OFFALY"
    },
    {
        text: "SCOIL MHUIRE AGUS EOIN,  CORK"
    },
    {
        text: "SCOIL MHUIRE AGUS IOSAF,  SLIGO"
    },
    {
        text: "SCOIL MHUIRE AGUS N.TREASA,  KERRY"
    },
    {
        text: "SCOIL MHUIRE ALLENWOOD NATIONAL SCHOOL,  KILDARE"
    },
    {
        text: "SCOIL MHUIRE AR CHNOC HAOINE,  CORK"
    },
    {
        text: "SCOIL MHUIRE B,  KERRY"
    },
    {
        text: "SCOIL MHUIRE B & C,  DONEGAL"
    },
    {
        text: "SCOIL MHUIRE B&C,  KERRY"
    },
    {
        text: "SCOIL MHUIRE B&C,  DONEGAL"
    },
    {
        text: "SCOIL MHUIRE BNS,  MONAGHAN"
    },
    {
        text: "SCOIL MHUIRE BOYS,  DUBLIN 14"
    },
    {
        text: "SCOIL MHUIRE COMMUNITY SCHOOL,  KILDARE"
    },
    {
        text: "SCOIL MHUIRE COOLCOTTS,  WEXFORD"
    },
    {
        text: "SCOIL MHUIRE FATIMA BOYS SENIOR,  CORK"
    },
    {
        text: "SCOIL MHUIRE GAN SMAL,  GALWAY"
    },
    {
        text: "SCOIL MHUIRE GAN SMAL,  ROSCOMMON"
    },
    {
        text: "SCOIL MHUIRE GAN SMAL,  KERRY"
    },
    {
        text: "SCOIL MHUIRE GAN SMAL,  SLIGO"
    },
    {
        text: "SCOIL MHUIRE GAN SMAL,  CORK"
    },
    {
        text: "SCOIL MHUIRE GAN SMÁL,  MAYO"
    },
    {
        text: "SCOIL MHUIRE GAN SMÁL,  TIPPERARY"
    },
    {
        text: "SCOIL MHUIRE GAN SMÁL,  LOUTH"
    },
    {
        text: "SCOIL MHUIRE GAN SMAL B,  CORK"
    },
    {
        text: "SCOIL MHUIRE GAN SMÁL, CLONDULANE,  CORK"
    },
    {
        text: "SCOIL MHUIRE & ÍDE,  LIMERICK"
    },
    {
        text: "SCOIL MHUIRE LOURDES,  CORK"
    },
    {
        text: "SCOIL MHUIRE MUIGHEO,  LAOIS"
    },
    {
        text: "SCOIL MHUIRE MXD,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL MHUIRE NA DEA COMHAIRLE,  GALWAY"
    },
    {
        text: "SCOIL MHUIRE NAISIUNTA,  CLARE"
    },
    {
        text: "SCOIL MHUIRE NA MAINISTREACH,  KERRY"
    },
    {
        text: "SCOIL MHUIRE NA NAIRD,  WICKLOW"
    },
    {
        text: "SCOIL MHUIRE NA NGAEL,  LOUTH"
    },
    {
        text: "SCOIL MHUIRE NA NGRAST,  CORK"
    },
    {
        text: "SCOIL MHUIRE NAOFA,  CORK"
    },
    {
        text: "SCOIL MHUIRE NAOFA CASTLECUFFE,  LAOIS"
    },
    {
        text: "SCOIL MHUIRE NA TROCAIRE,  CORK"
    },
    {
        text: "SCOIL MHUIRE NS,  MEATH"
    },
    {
        text: "SCOIL MHUIRE N S,  LONGFORD"
    },
    {
        text: "SCOIL MHUIRE N.S.,,  CORK"
    },
    {
        text: "SCOIL MHUIRE OGH 1,  DUBLIN 12"
    },
    {
        text: "SCOIL MHUIRE SECONDARY SCHOOL,  DONEGAL"
    },
    {
        text: "SCOIL MHUIRE SIN,  DUBLIN 15"
    },
    {
        text: "SCOIL MICHEAL NAOFA,  ROSCOMMON"
    },
    {
        text: "SCOIL MICHIL NAOFA,  GALWAY"
    },
    {
        text: "SCOIL MOBHI,  DUBLIN 9"
    },
    {
        text: "SCOIL MOCHEALLÓG,  LIMERICK"
    },
    {
        text: "SCOIL MOCHUA,  DUBLIN 22"
    },
    {
        text: "SCOIL MOIBHI,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL MOIN A LIN,  LIMERICK"
    },
    {
        text: "SCOIL MUIRE AGUS PADRAIG,  MAYO"
    },
    {
        text: "SCOIL  MUIRE GAN SMAL,  ROSCOMMON"
    },
    {
        text: "SCOIL MUIRE GAN SMAL,  CARLOW"
    },
    {
        text: "SCOIL NA CORÓINE MHUIRE,  WICKLOW"
    },
    {
        text: "SCOIL NA CROI NAOFA,  DUBLIN 11"
    },
    {
        text: "SCOIL NA CROISE NAOFA,  CORK"
    },
    {
        text: "SCOIL NA GCEITHRE MAISTRI,  WESTMEATH"
    },
    {
        text: "SCOIL NA GCOILLINI,  MAYO"
    },
    {
        text: "SCOIL NA GCREAGACHA DUBHA,  LOUTH"
    },
    {
        text: "SCOIL NAIS AITINN BHUI,  MEATH"
    },
    {
        text: "SCOIL NAIS BHRIDE,  CARLOW"
    },
    {
        text: "SCOIL NAIS CAITRIONA,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL NAIS CATHAIR CHINN LIS,  LIMERICK"
    },
    {
        text: "SCOIL NAIS CNOC AINE,  LIMERICK"
    },
    {
        text: "SCOIL NAIS DEAGLAIN,  MEATH"
    },
    {
        text: "SCOIL NAIS FININ NAOFA,  MEATH"
    },
    {
        text: "SCOIL NAIS IDE CAILINI,  DUBLIN 5"
    },
    {
        text: "SCOIL NAIS IOSEF NAOFA,  CARLOW"
    },
    {
        text: "SCOIL NAISIUNTA AN DEA AOIRE,  DUBLIN 14"
    },
    {
        text: "SCOIL NAISIUNTA BALLA ALUINN,  MAYO"
    },
    {
        text: "SCOIL NÁISIÚNTA BHANTIARNA LOURDES,  WEXFORD"
    },
    {
        text: "SCOIL NAISIUNTA BHRIDE,  KILDARE"
    },
    {
        text: "SCOIL NÁISIÚNTA CÚIL AN TSÚDAIRE 2,  LAOIS"
    },
    {
        text: "SCOIL NAISIUNTA EIRC,  KERRY"
    },
    {
        text: "SCOIL NÁISIÚNTA MUIRE GAN SMÁL,  DONEGAL"
    },
    {
        text: "SCOIL NÁISÍUNTA NAOMH FEICHÍN,  LOUTH"
    },
    {
        text: "SCOIL NÁISIÚNTA NAOMH PÁDRAIG,  KILDARE"
    },
    {
        text: "SCOIL NAISIUNTA NAOMH PHEADAR,  KILDARE"
    },
    {
        text: "SCOIL NÁISIÚNTA RÓIS,  GALWAY"
    },
    {
        text: "SCOIL NAISIUNTA STRATFORD,  DUBLIN 6"
    },
    {
        text: "SCOIL NAISIUNTA THOLA,  MAYO"
    },
    {
        text: "SCOIL NÁISIÚNTA THUAR MHIC ÉADAIGH,  MAYO"
    },
    {
        text: "SCOIL NAIS MHICHIL,  CARLOW"
    },
    {
        text: "SCOIL NAIS MHUIRE,  CARLOW"
    },
    {
        text: "SCOIL NAIS MHUIRE NAOFA,  MEATH"
    },
    {
        text: "SCOIL NAIS MHUIRE SOIS,  DUBLIN 15"
    },
    {
        text: "SCOIL NAIS MOLAISE,  CARLOW"
    },
    {
        text: "SCOIL NAIS NAOMH AINE,  MEATH"
    },
    {
        text: "SCOIL NAIS REALTA NA MARA,  WEXFORD"
    },
    {
        text: "SCOIL NAISUNTA CHNUACHA,  DUBLIN 15"
    },
    {
        text: "SCOIL NAITHI,  DUBLIN 16"
    },
    {
        text: "SCOIL NA MAIGHDINE MHUIRE,  WESTMEATH"
    },
    {
        text: "SCOIL NA MAIGHDINE MHUIRE,  CLARE"
    },
    {
        text: "SCOIL PHADRAIG,  LAOIS"
    },
    {
        text: "SCOIL NA MAINISTREACH,  KILDARE"
    },
    {
        text: "SCOIL NA MAINISTREACH QUIN DANGAN,  CLARE"
    },
    {
        text: "SCOIL NA MBEARNAN,  LIMERICK"
    },
    {
        text: "SCOIL NA MBRAITHRE,  KERRY"
    },
    {
        text: "SCOIL NA MBRAITHRE,  LIMERICK"
    },
    {
        text: "SCOIL NA MBRAITHRE,  TIPPERARY"
    },
    {
        text: "SCOIL NA MBRAITHRE,  CORK"
    },
    {
        text: "SCOIL NA MBRAITHRE,  WESTMEATH"
    },
    {
        text: "SCOIL NA MBRAITHRE,  WATERFORD"
    },
    {
        text: "SCOIL NA MBRAITHRE BOYS SENIOR SCHOOL,  CORK"
    },
    {
        text: "SCOIL NA MBRAITHRE BOYS SNR SCH,  CORK"
    },
    {
        text: "SCOIL NA MBRÁITHRE CHRÍOSTAÍ,  CORK"
    },
    {
        text: "SCOIL NA MBRAITHRE SN,  LOUTH"
    },
    {
        text: "SCOIL NA MBRATHAR,  DUBLIN 8"
    },
    {
        text: "SCOIL NA MBRATHAR BOYS SENIOR SCHOOL,  DUBLIN 7"
    },
    {
        text: "SCOIL NA MBUACHAILLI,  CORK"
    },
    {
        text: "SCOIL NA NAINGEAL,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL NA NAINGEAL NAOFA,  ROSCOMMON"
    },
    {
        text: "SCOIL NA NAOINEAN,  LIMERICK"
    },
    {
        text: "SCOIL NA NAOMH UILIG,  KILDARE"
    },
    {
        text: "SCOIL NA NGASUR,  GALWAY"
    },
    {
        text: "SCOIL NA NOG,  CORK"
    },
    {
        text: "SCOIL NANO NAGLE,  DUBLIN 22"
    },
    {
        text: "SCOIL NAOIMH EANNA,  MONAGHAN"
    },
    {
        text: "SCOIL NAOMH ABBAN,  LAOIS"
    },
    {
        text: "SCOIL NAOMH AINE,  DUBLIN 22"
    },
    {
        text: "SCOIL NAOMH AINE GIRLS SENIOR SCHOOL,  CORK"
    },
    {
        text: "SCOIL NAOMH AODAIN,  SLIGO"
    },
    {
        text: "SCOIL NAOMH BARRA,  MEATH"
    },
    {
        text: "SCOIL NAOMH BREANDAN,  CORK"
    },
    {
        text: "SCOIL NAOMH BRID,  MAYO"
    },
    {
        text: "SCOIL NAOMH BRID,  KILDARE"
    },
    {
        text: "SCOIL NAOMH BRID,  CAVAN"
    },
    {
        text: "SCOIL NAOMH BRID,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH BRID C,  CORK"
    },
    {
        text: "SCOIL NAOMH BRIDE,  MEATH"
    },
    {
        text: "SCOIL NAOMH CAITRIONA,  CORK"
    },
    {
        text: "SCOIL NAOMH CAOIMHGHIN,  WICKLOW"
    },
    {
        text: "SCOIL NAOMH CARTHACH,  KERRY"
    },
    {
        text: "SCOIL NAOMH CHAITRIONA,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH CHOLMCILLE,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH CHOLMCILLE,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH CHUAN,  GALWAY"
    },
    {
        text: "SCOIL NAOMH COLMCHILLE,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH COLMCILLE,  DUBLIN 13"
    },
    {
        text: "SCOIL NAOMH CUALÁN,  TIPPERARY"
    },
    {
        text: "SCOIL NAOMH DEAGHA,  MONAGHAN"
    },
    {
        text: "SCOIL NAOMH DUBHTHACH,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH EANNA,  GALWAY"
    },
    {
        text: "SCOIL NAOMH EARNAN,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH EINDE,  GALWAY"
    },
    {
        text: "SCOIL NAOMH ELTIN,  CORK"
    },
    {
        text: "SCOIL NAOMH EOIN,  CORK"
    },
    {
        text: "SCOIL NAOMH EOIN,  MEATH"
    },
    {
        text: "SCOIL NAOMH EOIN BAISTE,  CORK"
    },
    {
        text: "SCOIL NAOMH EOIN DEA,  KILKENNY"
    },
    {
        text: "SCOIL NAOMH EOIN LE DIA,  WATERFORD"
    },
    {
        text: "SCOIL NAOMH ERC,  KERRY"
    },
    {
        text: "SCOIL NAOMH FAINCHE,  LOUTH"
    },
    {
        text: "SCOIL NAOMH FEICHIN,  MAYO"
    },
    {
        text: "SCOIL NAOMH FIACHRA,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH FIONAN,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH GOBNAIT,  WATERFORD"
    },
    {
        text: "SCOIL NAOMH IDE,  LIMERICK"
    },
    {
        text: "SCOIL NAOMH IDE,  DUBLIN 22"
    },
    {
        text: "SCOIL NAOMH IOSAF,  LIMERICK"
    },
    {
        text: "SCOIL NAOMH IOSAF,  WICKLOW"
    },
    {
        text: "SCOIL NAOMH IÓSAF,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH IOSEF,  GALWAY"
    },
    {
        text: "SCOIL NAOMH IOSEF,  GALWAY"
    },
    {
        text: "SCOIL NAOMH IOSEF,  LIMERICK"
    },
    {
        text: "SCOIL NAOMH IOSEF,  KERRY"
    },
    {
        text: "SCOIL NAOMH IOSEPH,  WEXFORD"
    },
    {
        text: "SCOIL NAOMH IOSEPH,  KILKENNY"
    },
    {
        text: "SCOIL NAOMH LUCAIS,  DUBLIN 15"
    },
    {
        text: "SCOIL NAOMH MEARNOG,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL NAOMH MHUIRE,  CORK"
    },
    {
        text: "SCOIL NAOMH MHUIRE,  LIMERICK"
    },
    {
        text: "SCOIL NAOMH MHUIRE,  GALWAY"
    },
    {
        text: "SCOIL NAOMH MHUIRE,  GALWAY"
    },
    {
        text: "SCOIL NAOMH MHUIRE,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH MHUIRE,  MONAGHAN"
    },
    {
        text: "SCOIL NAOMH MHUIRE C,  CORK"
    },
    {
        text: "SCOIL NAOMH MHUIRE NATIONAL SCHOOL,  LIMERICK"
    },
    {
        text: "SCOIL NAOMH MICHEAL,  LONGFORD"
    },
    {
        text: "SCOIL NAOMH MICHEAL,  KERRY"
    },
    {
        text: "SCOIL NAOMH MICHEAL,  CORK"
    },
    {
        text: "SCOIL NAOMH MOLAISE,  SLIGO"
    },
    {
        text: "SCOIL NAOMH PADRAIG,  GALWAY"
    },
    {
        text: "SCOIL NAOMH PADRAIG,  MONAGHAN"
    },
    {
        text: "SCOIL NAOMH PADRAIG,  GALWAY"
    },
    {
        text: "SCOIL NAOMH PADRAIG,  KILDARE"
    },
    {
        text: "SCOIL NAOMH PADRAIG BOYS,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH PEADAR,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH PIO,  MEATH"
    },
    {
        text: "SCOIL NAOMH PROINNSEAS,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH SEOSAMH,  CORK"
    },
    {
        text: "SCOIL NAOMH SEOSAMH,  WATERFORD"
    },
    {
        text: "SCOIL NAOMH SEOSAMH,  DONEGAL"
    },
    {
        text: "SCOIL NAOMH SEOSAMH,  DUBLIN 22"
    },
    {
        text: "SCOIL NAOMH TREASA C,  DONEGAL"
    },
    {
        text: "SCOIL NA RITHE,  MEATH"
    },
    {
        text: "SCOIL NA TRIONOIDE NAOFA,  GALWAY"
    },
    {
        text: "SCOIL NA TRÍONÓIDE NAOFA,  LIMERICK"
    },
    {
        text: "SCOIL N. BREANDAN,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL NEASAIN,  DUBLIN 5"
    },
    {
        text: "SCOIL NEASSAIN,  LIMERICK"
    },
    {
        text: "SCOIL NÉIFINN,  MAYO"
    },
    {
        text: "SCOIL NIOCLAIS,  CORK"
    },
    {
        text: "SCOIL N MOLING-GLYNN,  CARLOW"
    },
    {
        text: "SCOIL NUACHABHAIL,  KERRY"
    },
    {
        text: "SCOIL O CURAIN B,  LIMERICK"
    },
    {
        text: "SCOIL OILIBHEIR,  CORK"
    },
    {
        text: "SCOIL OILIBHEIR NAOFA,  MEATH"
    },
    {
        text: "SCOIL OILIBHEIR NAOFA,  MEATH"
    },
    {
        text: "SCOIL OILIBHÉIR NAOFA,  LOUTH"
    },
    {
        text: "SCOIL PADRAIG,  LAOIS"
    },
    {
        text: "SCOIL PADRAIG NAOFA,  WICKLOW"
    },
    {
        text: "SCOIL PADRAIG NAOFA B,  LIMERICK"
    },
    {
        text: "SCOIL PADRAIG NAOFA B,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL PADRAIG NAOFA C,  LIMERICK"
    },
    {
        text: "SCOIL PADRE PIO N S,  CORK"
    },
    {
        text: "SCOIL PHADRAIG,  DONEGAL"
    },
    {
        text: "SCOIL PHADRAIG,  DONEGAL"
    },
    {
        text: "SCOIL PHADRAIG,  WESTMEATH"
    },
    {
        text: "SCOIL PHADRAIG,  MONAGHAN"
    },
    {
        text: "SCOIL PHADRAIG,  MONAGHAN"
    },
    {
        text: "SCOIL PHADRAIG,  MAYO"
    },
    {
        text: "SCOIL PHÁDRAIG,  DONEGAL"
    },
    {
        text: "SCOIL PHADRAIG NAOFA,  KILDARE"
    },
    {
        text: "SCOIL PHADRAIG NAOFA,  LOUTH"
    },
    {
        text: "SCOIL PHADRAIG NAOFA,  CORK"
    },
    {
        text: "SCOIL PHOBAIL,  GALWAY"
    },
    {
        text: "SCOIL PHOBAIL CHUIL MHIN,  DUBLIN 15"
    },
    {
        text: "SCOIL PHOBAIL MHIC DARA,  GALWAY"
    },
    {
        text: "SCOIL PHOBAIL SLIABH LUACHRA,  KERRY"
    },
    {
        text: "SCOIL PHOIL NAOFA,  LIMERICK"
    },
    {
        text: "SCOIL PÓL,  LIMERICK"
    },
    {
        text: "SCOIL REALT NA MARA,  KERRY"
    },
    {
        text: "SCOIL REALT NA MARA,  CORK"
    },
    {
        text: "SCOIL REALT NA MARA,  CLARE"
    },
    {
        text: "SCOIL RÉALT NA MARA,  DUBLIN COUNTY"
    },
    {
        text: "SCOIL ROIS,  MONAGHAN"
    },
    {
        text: "SCOIL ROISIN,  DONEGAL"
    },
    {
        text: "SCOIL RONAIN,  GALWAY"
    },
    {
        text: "SCOIL RUAIN,  TIPPERARY"
    },
    {
        text: "SCOIL SAN EOIN,  WICKLOW"
    },
    {
        text: "SCOIL SAN LIONARD,  KILKENNY"
    },
    {
        text: "SCOIL SANTAIN,  DUBLIN 24"
    },
    {
        text: "SCOIL SEANAIN NAOFA,  CLARE"
    },
    {
        text: "SCOIL SEAN TSRAIDE,  LIMERICK"
    },
    {
        text: "SCOIL SINEAD NS,  DUBLIN 11"
    },
    {
        text: "SCOIL TEAMPALL TOINNE,  TIPPERARY"
    },
    {
        text: "SCOIL THOMAIS,  DUBLIN 15"
    },
    {
        text: "SCOIL TÍGHEARNACH NAOFA,  LAOIS"
    },
    {
        text: "SCOIL TOBAR PADRAIG,  LIMERICK"
    },
    {
        text: "SCOIL TREASA FIRHOUSE,  DUBLIN 24"
    },
    {
        text: "SCOIL TREASA NAOFA,  DONEGAL"
    },
    {
        text: "SCOIL TREASA NAOFA,  DUBLIN 8"
    },
    {
        text: "SCOIL TRIEST,  CORK"
    },
    {
        text: "SCOIL UI CHONAILL BOYS SENIORS,  DUBLIN 1"
    },
    {
        text: "SCOIL UI FHIAICH,  KILDARE"
    },
    {
        text: "SCOIL UÍ GHRAMHNAIGH,  MEATH"
    },
    {
        text: "SCOIL UI MHUIRI,  LOUTH"
    },
    {
        text: "SCOIL UÍ RIADA,  KILDARE"
    },
    {
        text: "SCOIL ÚNA NAOFA,  DUBLIN 12"
    },
    {
        text: "SCOIL URSULA,  CORK"
    },
    {
        text: "SEAMOUNT COLLEGE,  GALWAY"
    },
    {
        text: "SEARCOG I N S,  CAVAN"
    },
    {
        text: "SEIR KIERANS N S,  OFFALY"
    },
    {
        text: "SELSKAR COLLEGE (COLÁISTE SHEILSCIRE),  WEXFORD"
    },
    {
        text: "SETANTA SPECIAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "SHANAGARRY N S,  CORK"
    },
    {
        text: "SHANAGOLDEN N S,  LIMERICK"
    },
    {
        text: "SHANBALLY N S,  CORK"
    },
    {
        text: "SHANDRUM NATIONAL SCHOOL,  CORK"
    },
    {
        text: "SHANGANAMORE N S,  LAOIS"
    },
    {
        text: "SHANNON AIRPORT 1 N S,  CLARE"
    },
    {
        text: "SHANNON AIRPORT  NO 2 NS,  CLARE"
    },
    {
        text: "SHANNON COMPREHENSIVE SCHOOL,  CLARE"
    },
    {
        text: "SHELLYBANKS EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 4"
    },
    {
        text: "SHIELBEGGAN CONVENT,  WEXFORD"
    },
    {
        text: "SHILLELAGH NO 1 N S,  WICKLOW"
    },
    {
        text: "SHINCHILL N S,  OFFALY"
    },
    {
        text: "SHINRONE MIXED N S,  OFFALY"
    },
    {
        text: "SHRONELL N S,  TIPPERARY"
    },
    {
        text: "SILVERMINES N S,  TIPPERARY"
    },
    {
        text: "SIXMILEBRIDGE N S,  CLARE"
    },
    {
        text: "SKEAGHVASTHEEN N S,  KILKENNY"
    },
    {
        text: "SKERRIES COMMUNITY COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "SKERRIES EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "SKIBBEREEN COMMUNITY SCHOOL,  CORK"
    },
    {
        text: "SLATTA N S,  ROSCOMMON"
    },
    {
        text: "SLIEVEARDAGH N S,  TIPPERARY"
    },
    {
        text: "SLIEVERUE MIXED N S,  KILKENNY"
    },
    {
        text: "SLIGO GRAMMAR SCHOOL,  SLIGO"
    },
    {
        text: "SLIGO PROJECT SCHOOL,  SLIGO"
    },
    {
        text: "S N ABBAIN,  WEXFORD"
    },
    {
        text: "S N ACHADH BOLG,  CORK"
    },
    {
        text: "S N ACHADH EOCHAILLE,  CORK"
    },
    {
        text: "S N ACHAIDH AN GHLAISIN,  MAYO"
    },
    {
        text: "S N ADHAMHNAIN,  DONEGAL"
    },
    {
        text: "S N AENGHUSA,  DUBLIN 24"
    },
    {
        text: "S N AIBHISTIN NAOFA,  CLARE"
    },
    {
        text: "S N AILBHE,  LIMERICK"
    },
    {
        text: "S N AINDREIS NAOFA,  GALWAY"
    },
    {
        text: "S N AINDREIS NAOFA,  MEATH"
    },
    {
        text: "S N AINE NAOFA,  GALWAY"
    },
    {
        text: "S N AINE NFA SEAFIELD,  WATERFORD"
    },
    {
        text: "SN AN AILL BHREACH,  GALWAY"
    },
    {
        text: "S N AN AIRD,  CORK"
    },
    {
        text: "S N AN ARD MHOIR,  GALWAY"
    },
    {
        text: "S N AN BAILE NUA,  WATERFORD"
    },
    {
        text: "S N AN BHAIN MHOIR,  GALWAY"
    },
    {
        text: "S N AN BHREACAIGH,  DONEGAL"
    },
    {
        text: "SN AN BR M O CLEIRIGH,  DONEGAL"
    },
    {
        text: "S N AN CARRAIG LIATH,  WATERFORD"
    },
    {
        text: "S N AN CHAISEAL,  DONEGAL"
    },
    {
        text: "S N AN CHILLIN,  DONEGAL"
    },
    {
        text: "S N AN CHLAIS MHOR,  WATERFORD"
    },
    {
        text: "S N AN CHLOCHAIR,  CORK"
    },
    {
        text: "S N AN CHLOCHAN,  KERRY"
    },
    {
        text: "S N AN CHOIMIN,  DONEGAL"
    },
    {
        text: "S N AN CHRIOCH,  CLARE"
    },
    {
        text: "S N AN CHROI NAOFA,  KERRY"
    },
    {
        text: "S N AN CHUIL,  WESTMEATH"
    },
    {
        text: "S N AN CHUILLEANAIG,  KERRY"
    },
    {
        text: "S N AN CILLIN,  GALWAY"
    },
    {
        text: "S N AN CILLIN,  TIPPERARY"
    },
    {
        text: "S N AN COILL MHOR,  MAYO"
    },
    {
        text: "S N AN CROI RO NAOFA,  GALWAY"
    },
    {
        text: "S N AN CROI RO NAOFA,  DUBLIN 24"
    },
    {
        text: "S N AN CUSAN,  WESTMEATH"
    },
    {
        text: "S N AN DROIM MOR,  DONEGAL"
    },
    {
        text: "S N AN DROMA,  GALWAY"
    },
    {
        text: "S N AN DROMA,  TIPPERARY"
    },
    {
        text: "S N AN DROMA MHOIR,  CORK"
    },
    {
        text: "SN AN FHAIRCHE,  GALWAY"
    },
    {
        text: "S N AN FHOSSA,  KERRY"
    },
    {
        text: "S N AN GARRAIN BHAIN,  WATERFORD"
    },
    {
        text: "S N AN GHABHAILIN,  TIPPERARY"
    },
    {
        text: "S N AN GHARRAIN,  CORK"
    },
    {
        text: "S N AN GHLEANNA,  WEXFORD"
    },
    {
        text: "SN AN GHLEANNA,  KERRY"
    },
    {
        text: "S N AN GHOILIN,  CORK"
    },
    {
        text: "S N AN GLEANN DUIBH,  ROSCOMMON"
    },
    {
        text: "S N AN GRAINSEACH,  TIPPERARY"
    },
    {
        text: "S N AN IORBALL RIABAIGH,  DONEGAL"
    },
    {
        text: "S N AN LEANA MHOIR,  KERRY"
    },
    {
        text: "S N AN LEANA MOR,  LONGFORD"
    },
    {
        text: "S N AN LEATH BHAILE,  GALWAY"
    },
    {
        text: "SN AN LEINBH IOSA,  DONEGAL"
    },
    {
        text: "S N AN MHAOILINN,  CORK"
    },
    {
        text: "S N AN MOININ RUA,  KILKENNY"
    },
    {
        text: "S N AN PHAIRC,  CORK"
    },
    {
        text: "SN AN PHAIRTIN MIXED,  CLARE"
    },
    {
        text: "S N AN PHAROISTE,  DUBLIN 11"
    },
    {
        text: "S N AN RATH MHOR,  MEATH"
    },
    {
        text: "SN AN SPIORAID NAIOMH C,  DUBLIN 11"
    },
    {
        text: "S N AN SPIORAID NAOMH C,  CORK"
    },
    {
        text: "S N AN TEAGHLAIGH,  MAYO"
    },
    {
        text: "SN AN TRIAN LAIR,  MAYO"
    },
    {
        text: "S N AN TSAILEAIN,  GALWAY"
    },
    {
        text: "S N AN TSLANAITHEORA B,  LOUTH"
    },
    {
        text: "S N AN TSLANAITHEORA C,  LOUTH"
    },
    {
        text: "S N AN TSRAITH,  MAYO"
    },
    {
        text: "S N AODAIN,  SLIGO"
    },
    {
        text: "S N AODHAIN NAOFA,  CAVAN"
    },
    {
        text: "S N AODHA NAOFA,  WESTMEATH"
    },
    {
        text: "S N AONGHUSA,  LOUTH"
    },
    {
        text: "SN ARAINN MHOR I,  DONEGAL"
    },
    {
        text: "SN ARAINN MHOR II,  DONEGAL"
    },
    {
        text: "S N ARD,  GALWAY"
    },
    {
        text: "S N ARD CIANAIN,  ROSCOMMON"
    },
    {
        text: "S N ARD CROINE,  TIPPERARY"
    },
    {
        text: "S N ARD MHUIRE C,  LOUTH"
    },
    {
        text: "S N ARD RAITHIN,  GALWAY"
    },
    {
        text: "S N ATHAIR MAITIU C,  CORK"
    },
    {
        text: "S N ATH AN MHUILLINN,  CORK"
    },
    {
        text: "S N ATH EASCRACH CHUAIN,  GALWAY"
    },
    {
        text: "S N ATH FHADA,  CORK"
    },
    {
        text: "SN ATH NA LIONTA,  CORK"
    },
    {
        text: "S N ATHRACHT NFA,  MAYO"
    },
    {
        text: "S N BAILE A MHOININ,  GALWAY"
    },
    {
        text: "S N BAILE AN ATHA,  TIPPERARY"
    },
    {
        text: "S N BAILE AN BHAILSIG,  DONEGAL"
    },
    {
        text: "S N BAILE AN BHUITLÉIRIGH,  WATERFORD"
    },
    {
        text: "S N BAILE AN CAISLEAN,  DONEGAL"
    },
    {
        text: "S N BAILE AN DROICHID,  CLARE"
    },
    {
        text: "S N BAILE AN FHASAIGH,  KILKENNY"
    },
    {
        text: "S N BAILE AN IUBHAIR,  TIPPERARY"
    },
    {
        text: "S N BAILE AN LEASA,  GALWAY"
    },
    {
        text: "S N BAILE AN LUIG,  SLIGO"
    },
    {
        text: "SN BAILE AN MHUILINN,  GALWAY"
    },
    {
        text: "S N BAILE AN PHIARSAIGH,  WEXFORD"
    },
    {
        text: "S N BAILE AN PHIULL,  KILKENNY"
    },
    {
        text: "S N BAILE ATHA N RIOGH,  GALWAY"
    },
    {
        text: "S N BAILE CHEANT,  MEATH"
    },
    {
        text: "S N BAILE CUISIN,  WEXFORD"
    },
    {
        text: "SN BAILE CUISIN,  MAYO"
    },
    {
        text: "S N BAILE DEASMUMHAN,  CORK"
    },
    {
        text: "S N BAILE FADA,  WEXFORD"
    },
    {
        text: "S N BAILE FINN,  LAOIS"
    },
    {
        text: "S N BAILE MHIC ADAIM,  KILDARE"
    },
    {
        text: "SN BAILE MHIC AIRT,  WATERFORD"
    },
    {
        text: "S N BAILE MOR,  DONEGAL"
    },
    {
        text: "S N BAILE MUINE,  CORK"
    },
    {
        text: "S N BAILE MUIRNE,  WEXFORD"
    },
    {
        text: "S N BAILE NA CAILLI,  CLARE"
    },
    {
        text: "S N BAILE NA CILLE,  GALWAY"
    },
    {
        text: "S N BAILE NA HINSE,  TIPPERARY"
    },
    {
        text: "S N BAILE NORA,  CORK"
    },
    {
        text: "S N BAILE NUA,  GALWAY"
    },
    {
        text: "S N BAILE RAIGHIN,  DONEGAL"
    },
    {
        text: "S N BAILE ROIBEAIRD,  KILDARE"
    },
    {
        text: "S N BAILE SLUAGH,  TIPPERARY"
    },
    {
        text: "SN BAILE THOMAIS,  WEXFORD"
    },
    {
        text: "S N BAILE UI CHROININ,  CORK"
    },
    {
        text: "S N BAILE UI COILEAIN,  WEXFORD"
    },
    {
        text: "S N BAILE UI FHIDHNE,  ROSCOMMON"
    },
    {
        text: "S N BAILE UI GHIBLIN,  CORK"
    },
    {
        text: "S N BANRION NA NAINGEAL 1,  DUBLIN 10"
    },
    {
        text: "S N BARRA NAOFA,  CORK"
    },
    {
        text: "S N BARRA NAOFA BHUACH,  CORK"
    },
    {
        text: "S N BARRA NAOFA CAILINI,  CORK"
    },
    {
        text: "S N B CURNANOOL,  MAYO"
    },
    {
        text: "S N BEAL AN MHUIRTHEAD,  MAYO"
    },
    {
        text: "S N BEAL ATHA GREAN,  LIMERICK"
    },
    {
        text: "SN BEAL ATHA NA HEIN,  MAYO"
    },
    {
        text: "S N BEAL ATHA NA NEACH,  CAVAN"
    },
    {
        text: "S N BEAL CARADH,  MAYO"
    },
    {
        text: "S N BEARNA NA HAILLE,  WEXFORD"
    },
    {
        text: "S N BHAILE AN CHROSAIGH,  KERRY"
    },
    {
        text: "S N BHAILE EAMONN,  DUBLIN 14"
    },
    {
        text: "S N BHAILE MHUIRNE,  WEXFORD"
    },
    {
        text: "S N BHEANAIN,  GALWAY"
    },
    {
        text: "S N BHEININ NAOFA C,  MEATH"
    },
    {
        text: "S N BHEINN NAOFA B,  MEATH"
    },
    {
        text: "S N BHIORAINN,  CORK"
    },
    {
        text: "S N BHREANDAIN NAOFA,  KILKENNY"
    },
    {
        text: "S N BHREANNDAIN C,  CORK"
    },
    {
        text: "S N BHRIDE,  WESTMEATH"
    },
    {
        text: "S N BHRIDE,  GALWAY"
    },
    {
        text: "S N BHRIDE,  GALWAY"
    },
    {
        text: "S N BHRIDE,  TIPPERARY"
    },
    {
        text: "S N BHRIDE,  SLIGO"
    },
    {
        text: "S N BHRIDE,  LOUTH"
    },
    {
        text: "S N BHRIDE,  OFFALY"
    },
    {
        text: "S N BHRIDHE,  KILKENNY"
    },
    {
        text: "S N BHRIGHDE,  MEATH"
    },
    {
        text: "S N BHRIGHDE,  KILKENNY"
    },
    {
        text: "S N BHRIGHDE,  MEATH"
    },
    {
        text: "S N BLAITHIN IOSA,  MONAGHAN"
    },
    {
        text: "S N BREANDAIN NAOFA,  GALWAY"
    },
    {
        text: "S N BREANDAIN NAOFA,  GALWAY"
    },
    {
        text: "S N BREANDAN NAOFA,  KERRY"
    },
    {
        text: "S N BREANDAN NAOFA,  CORK"
    },
    {
        text: "S N BREANDAN NAOFA,  GALWAY"
    },
    {
        text: "S N BREANDAN NAOFA,  GALWAY"
    },
    {
        text: "S N BRIDE,  KILDARE"
    },
    {
        text: "S N BRIDE,  KILDARE"
    },
    {
        text: "S N BRIDE NAOFA,  GALWAY"
    },
    {
        text: "S N BRIGHDE,  KILDARE"
    },
    {
        text: "S N BRIGHDE,  MAYO"
    },
    {
        text: "S N BRIGHDE,  KILDARE"
    },
    {
        text: "S N BRIGHDE,  GALWAY"
    },
    {
        text: "S N BRIGHDE,  KILDARE"
    },
    {
        text: "S N BRIGHDE,  DUBLIN 6"
    },
    {
        text: "S N BRIGHDHE NAOFA,  GALWAY"
    },
    {
        text: "S N BRIOCAIN,  GALWAY"
    },
    {
        text: "S N BRIOTAS,  DUBLIN COUNTY"
    },
    {
        text: "S N BRUSNA,  ROSCOMMON"
    },
    {
        text: "S N B TOGHER CORK,  CORK"
    },
    {
        text: "S N BUIRGHEAS,  TIPPERARY"
    },
    {
        text: "S N BUN AN TSABHAIRNE,  CORK"
    },
    {
        text: "S N CAIPIN,  CORK"
    },
    {
        text: "S N CAISEAL NA GCORR,  DONEGAL"
    },
    {
        text: "S N CAISLEAN AN CUMAIR,  KILKENNY"
    },
    {
        text: "S N CAISLEAN DOCHRAILL,  WEXFORD"
    },
    {
        text: "SN CAITRIONA GIRLS,  DUBLIN 5"
    },
    {
        text: "S N CAITRIONA NAIONAIN,  DUBLIN 5"
    },
    {
        text: "S N CAITRIONA NAOFA,  GALWAY"
    },
    {
        text: "S N CAITRIONA NAOFA,  MEATH"
    },
    {
        text: "SN CAITRIONA SINSEAR,  GALWAY"
    },
    {
        text: "S N CAITRIONA SOIS,  GALWAY"
    },
    {
        text: "S N CALADH NA MUC,  GALWAY"
    },
    {
        text: "S N CAOIMHIN NAOFA,  OFFALY"
    },
    {
        text: "S N CAOMHAIN,  GALWAY"
    },
    {
        text: "S N CARRAIG A BRUIS,  CAVAN"
    },
    {
        text: "S N CARRAIG AN IME,  CORK"
    },
    {
        text: "S N CATHAIR GEAL,  GALWAY"
    },
    {
        text: "S N CATRIONA NFA,  WEXFORD"
    },
    {
        text: "SN CEANN TRA MEASCAITHE,  KERRY"
    },
    {
        text: "SN CEARN MOR,  GALWAY"
    },
    {
        text: "S N CEATHARLACH,  CARLOW"
    },
    {
        text: "S N CEATHRU AN CHLOCHAR,  MAYO"
    },
    {
        text: "S N CEATHRU CAOL,  DONEGAL"
    },
    {
        text: "SN CEATHRU NA LAITHIGH,  GALWAY"
    },
    {
        text: "S N CHAOIMHGHIN,  TIPPERARY"
    },
    {
        text: "S N CHAOIMHIN NAOFA,  LOUTH"
    },
    {
        text: "S N CHAOMHAIN TORRCHOILL,  WEXFORD"
    },
    {
        text: "S N CHARTAIGH NAOFA,  OFFALY"
    },
    {
        text: "S N CHATHAIR AODHA,  CLARE"
    },
    {
        text: "S N CHIARAIN NAOFA,  OFFALY"
    },
    {
        text: "S N CHIARAIN NAOFA,  OFFALY"
    },
    {
        text: "S N CHIARAN NAOFA,  KILKENNY"
    },
    {
        text: "S N CHIARDA NAOFA,  TIPPERARY"
    },
    {
        text: "S N CHILL COINNIGH,  DONEGAL"
    },
    {
        text: "SN CHILL SARAIN,  LOUTH"
    },
    {
        text: "S N CHLAIR NA GAILLIMHE,  GALWAY"
    },
    {
        text: "S N CHLUAININ,  TIPPERARY"
    },
    {
        text: "S N CHLUAIN LUIFIN,  MAYO"
    },
    {
        text: "S N CHOBH CHIONN TSAILE,  CORK"
    },
    {
        text: "S N CHOILM CHILLE,  GALWAY"
    },
    {
        text: "S N CHOLMCILLE,  KILKENNY"
    },
    {
        text: "S N CHOLMCILLE,  MONAGHAN"
    },
    {
        text: "S N CHOLMCILLE,  DONEGAL"
    },
    {
        text: "S N CHOLMCILLE C,  DUBLIN COUNTY"
    },
    {
        text: "S N CHOLMILLE B,  DUBLIN COUNTY"
    },
    {
        text: "SN CHONAILL,  DONEGAL"
    },
    {
        text: "S N CHRION CHOILL,  KILKENNY"
    },
    {
        text: "S N CHRUIMIN NAOFA,  WESTMEATH"
    },
    {
        text: "S N CHUAN DOIR,  CORK"
    },
    {
        text: "S N CHUIRT DOIGHTE,  TIPPERARY"
    },
    {
        text: "S N CIANOG NAOFA,  KILDARE"
    },
    {
        text: "S N CIARAIN,  LIMERICK"
    },
    {
        text: "S N CIARAIN NAOFA,  GALWAY"
    },
    {
        text: "S N CILL BARFHIONN,  TIPPERARY"
    },
    {
        text: "S N CILL BHRIAIN,  WATERFORD"
    },
    {
        text: "S N CILL BHRIGHDE,  MEATH"
    },
    {
        text: "S N CILL BONAIN,  CORK"
    },
    {
        text: "S N CILL CAE,  KILDARE"
    },
    {
        text: "S N CILL CHUIMIN,  TIPPERARY"
    },
    {
        text: "S N CILL CONAILL,  GALWAY"
    },
    {
        text: "S.N. CILL CONLA,  KERRY"
    },
    {
        text: "S N CILL CRIODAIN,  CORK"
    },
    {
        text: "S N CILL CRUAIN,  GALWAY"
    },
    {
        text: "S N CILL CRUINN,  KERRY"
    },
    {
        text: "S N CILL CUILE,  GALWAY"
    },
    {
        text: "S N CILL CUIMIN,  KERRY"
    },
    {
        text: "S N CILL DAINGIN,  KILDARE"
    },
    {
        text: "SN CILL DAIRBHRE,  CORK"
    },
    {
        text: "S N CILL DAMHAIN,  CARLOW"
    },
    {
        text: "S N CILL FHEICIN,  GALWAY"
    },
    {
        text: "S N CILLIN,  CAVAN"
    },
    {
        text: "S N CILLINI DIOMA,  GALWAY"
    },
    {
        text: "S N CILLININ,  GALWAY"
    },
    {
        text: "S N CILLIN LIATH,  KERRY"
    },
    {
        text: "S N CILL LACHTAIN,  LIMERICK"
    },
    {
        text: "S N CILL LÚRAIGH,  KERRY"
    },
    {
        text: "SN CILL MHIC ABHAIDH,  CORK"
    },
    {
        text: "S N CILL MHUIRE B,  CORK"
    },
    {
        text: "S N CILLMIN,  CORK"
    },
    {
        text: "S N CILL MUIRE,  CLARE"
    },
    {
        text: "S N CILL RICHILL,  GALWAY"
    },
    {
        text: "S N CILL RONAIN,  ROSCOMMON"
    },
    {
        text: "S N CILL ROSANTA,  WATERFORD"
    },
    {
        text: "S N CILL RUADHAIN,  CORK"
    },
    {
        text: "S N CILL RUADHAIN,  TIPPERARY"
    },
    {
        text: "S N CILL SIOLAIN,  TIPPERARY"
    },
    {
        text: "S N CILL SOLAIN,  GALWAY"
    },
    {
        text: "S N CILL TARTAIN,  GALWAY"
    },
    {
        text: "S N CILL TROSTAIN,  ROSCOMMON"
    },
    {
        text: "S N CLEIRE,  CORK"
    },
    {
        text: "S N CLEIREACHAIN,  TIPPERARY"
    },
    {
        text: "S N CLOCHAR MHUIRE,  WEXFORD"
    },
    {
        text: "S N CLOCHAR MHUIRE,  WEXFORD"
    },
    {
        text: "S N CLOCHOG,  SLIGO"
    },
    {
        text: "S N CLOCH RINNCE,  KILDARE"
    },
    {
        text: "S N CLOGACH,  CORK"
    },
    {
        text: "S N CLUAIN AN ATHA,  CLARE"
    },
    {
        text: "S N CLUAIN MUINGE,  CLARE"
    },
    {
        text: "S N CLUAIN NA CILLE,  ROSCOMMON"
    },
    {
        text: "S N CLUAIN NA NGALL,  CARLOW"
    },
    {
        text: "S N C NAOMH PADRAIG,  DUBLIN COUNTY"
    },
    {
        text: "S N CNOC AINBHIL,  DUBLIN COUNTY"
    },
    {
        text: "S N CNOC AN BHILE,  CORK"
    },
    {
        text: "S N CNOC AN CHROCAIRE,  ROSCOMMON"
    },
    {
        text: "S N CNOC AN EIN,  CLARE"
    },
    {
        text: "S N CNOC AN MHARCAIGH,  LONGFORD"
    },
    {
        text: "S N CNOC AN SAMHRAIDH,  ROSCOMMON"
    },
    {
        text: "S N CNOC DOIRE,  CLARE"
    },
    {
        text: "S N CNOCH A DEAGA,  LIMERICK"
    },
    {
        text: "S N CNOC MACHAN,  WATERFORD"
    },
    {
        text: "S N CNOC MIONNA,  SLIGO"
    },
    {
        text: "S N CNOC NA CROIGHE,  CORK"
    },
    {
        text: "S N CNOC NA MANACH,  CORK"
    },
    {
        text: "S N CNOC NA NAOMH,  DONEGAL"
    },
    {
        text: "S N CNOC RUSCAIGHE,  MAYO"
    },
    {
        text: "S N CNOC SCEACH,  CORK"
    },
    {
        text: "S N COILL AN BHAILE,  MAYO"
    },
    {
        text: "S N COILL AN IARAINN,  WEXFORD"
    },
    {
        text: "S N COILL DUBH,  KILDARE"
    },
    {
        text: "S N COILL MOR,  MAYO"
    },
    {
        text: "S N COILM CILLE,  GALWAY"
    },
    {
        text: "S N COLMAIN,  KILKENNY"
    },
    {
        text: "S N COLMAIN NAOFA,  TIPPERARY"
    },
    {
        text: "S N COLMCILLE,  GALWAY"
    },
    {
        text: "S N COLMCILLE,  MEATH"
    },
    {
        text: "S N COLM CILLE,  CLARE"
    },
    {
        text: "SN COLMCILLE,  GALWAY"
    },
    {
        text: "S N COLMCILL NAOFA,  KILDARE"
    },
    {
        text: "S N COLM NAOFA,  MAYO"
    },
    {
        text: "S N COLUMBAIN,  MEATH"
    },
    {
        text: "S N COLUMCILLE,  LOUTH"
    },
    {
        text: "S N COMHGHALL,  MONAGHAN"
    },
    {
        text: "S N CONNLAODH NAOFA N,  KILDARE"
    },
    {
        text: "S N COR AN DROMA,  GALWAY"
    },
    {
        text: "S N COR NA FOLA B,  ROSCOMMON"
    },
    {
        text: "S N CORRABHA,  CAVAN"
    },
    {
        text: "S N CORR LORGAN,  CAVAN"
    },
    {
        text: "S N  CRANNAIGHE BUIDHE,  DONEGAL"
    },
    {
        text: "S N CREACHMHAOIL,  GALWAY"
    },
    {
        text: "SN CROI MUIRE,  MAYO"
    },
    {
        text: "S N CRONAIN,  GALWAY"
    },
    {
        text: "S N CRONAIN NAOFA,  OFFALY"
    },
    {
        text: "S N CRONAIN NFA AN CARN,  CLARE"
    },
    {
        text: "S N CROS BAN,  MEATH"
    },
    {
        text: "SN CROS TSEAIN,  CORK"
    },
    {
        text: "S N CRUABANAI,  CAVAN"
    },
    {
        text: "S N CRUACHAIN,  ROSCOMMON"
    },
    {
        text: "S N CUAN,  CLARE"
    },
    {
        text: "S N CUILINN UI CAOIMH,  CORK"
    },
    {
        text: "S N CUL AN MHUILINN,  MEATH"
    },
    {
        text: "S N CUL FADA,  SLIGO"
    },
    {
        text: "S N CUL GREINE,  WEXFORD"
    },
    {
        text: "S N DAR EARCA,  KERRY"
    },
    {
        text: "SN DEAGHAN O CEALLAIGH,  WESTMEATH"
    },
    {
        text: "S N DEAGLAN,  WATERFORD"
    },
    {
        text: "S N DHROM ATHAIN,  CORK"
    },
    {
        text: "S N DHUBHTHAIGH,  DONEGAL"
    },
    {
        text: "S N DHUMHA THUAMA,  MAYO"
    },
    {
        text: "S N DOIRE NA CEISE,  CAVAN"
    },
    {
        text: "S N DOMHNACH MOR,  DONEGAL"
    },
    {
        text: "S N DOMHNACH PADRAIG,  GALWAY"
    },
    {
        text: "S N DONAIGH,  DONEGAL"
    },
    {
        text: "S N DROICHEAD NA BANDAN,  CORK"
    },
    {
        text: "S N DROM CNAMH,  CAVAN"
    },
    {
        text: "S N DRUIM SLAOD,  MAYO"
    },
    {
        text: "S N DUBH RATH,  CLARE"
    },
    {
        text: "S N DUCHORAIDH,  DONEGAL"
    },
    {
        text: "S N DUMHACH,  MAYO"
    },
    {
        text: "S N  DUMHACH BEAG,  DONEGAL"
    },
    {
        text: "S N DUN AILL,  WATERFORD"
    },
    {
        text: "SN DUN CEANNFHAOLAIDH,  DONEGAL"
    },
    {
        text: "S N DUN NA MAINISTREACH,  WATERFORD"
    },
    {
        text: "S N DUN UABHAIR,  MEATH"
    },
    {
        text: "S N EACHLEIME,  MAYO"
    },
    {
        text: "SN EADAN FHIONNFHAOICH,  DONEGAL"
    },
    {
        text: "S N EANNA,  GALWAY"
    },
    {
        text: "SN EOIN BAISDE BOYS SENIOR SCHOOL,  DUBLIN 3"
    },
    {
        text: "S N EOIN BAISDE GIRLS SENIOR,  DUBLIN 3"
    },
    {
        text: "S N EOIN BAISTE,  CLARE"
    },
    {
        text: "S N EOIN BAISTE,  KERRY"
    },
    {
        text: "S.N EOIN BAISTE,  LOUTH"
    },
    {
        text: "S N EOIN BOSCO BUACH,  DUBLIN 7"
    },
    {
        text: "S N EOIN BOSCO NAI BUAC,  DUBLIN 7"
    },
    {
        text: "S N EOIN NAOFA,  SLIGO"
    },
    {
        text: "S N EOIN POL II,  GALWAY"
    },
    {
        text: "S N FAICHE LIAG,  WATERFORD"
    },
    {
        text: "S N FAITCHE,  MAYO"
    },
    {
        text: "S N FEARGHAIL NAOFA,  CORK"
    },
    {
        text: "SN FHIACHNA,  CORK"
    },
    {
        text: "S N FHIONNTRA,  DONEGAL"
    },
    {
        text: "S N FHOIRTCHEIRN/FHININ,  CARLOW"
    },
    {
        text: "S N FHURSA,  GALWAY"
    },
    {
        text: "S N FHURSA,  CORK"
    },
    {
        text: "S N FIACH,  MEATH"
    },
    {
        text: "S N FIODHNACH,  LIMERICK"
    },
    {
        text: "S N FIONAN NA REANNA,  CORK"
    },
    {
        text: "S N FIONDALBHA,  MAYO"
    },
    {
        text: "S N FIONNTAIN,  WEXFORD"
    },
    {
        text: "S N FIONNTAN NAOFA,  LAOIS"
    },
    {
        text: "S N FIONTAIN NAOFA,  CARLOW"
    },
    {
        text: "S N FLANNAIN NAOFA,  TIPPERARY"
    },
    {
        text: "S N FOTHAR,  DONEGAL"
    },
    {
        text: "S N GALLBHAILE,  LIMERICK"
    },
    {
        text: "S N GARRAN AN EASAIGH,  CORK"
    },
    {
        text: "S N GLASAIN,  DONEGAL"
    },
    {
        text: "SN GLEANN A CHAISIL,  MAYO"
    },
    {
        text: "S N GLEANN BEITHE,  KERRY"
    },
    {
        text: "S N GLEANN COIMHEAD,  DONEGAL"
    },
    {
        text: "S N GLEANN GCREABHAR,  LIMERICK"
    },
    {
        text: "S N GLEANN GUAIL,  TIPPERARY"
    },
    {
        text: "S N GLEANN NA GCAORACH INÍON LÉINÍN,  DUBLIN COUNTY"
    },
    {
        text: "SN GLEANN NA HULADH,  CORK"
    },
    {
        text: "S N GLEANN NA MUAIDHE,  MAYO"
    },
    {
        text: "S N GNIOMH GO LEITH B,  KERRY"
    },
    {
        text: "S N GORT AN CHOIRCE,  DONEGAL"
    },
    {
        text: "S N GORT AN EADAIN,  MAYO"
    },
    {
        text: "S N GORT NA GAOITHE,  GALWAY"
    },
    {
        text: "S N GORT NA HUAIGHE,  TIPPERARY"
    },
    {
        text: "S N GORT NA LEIME,  GALWAY"
    },
    {
        text: "S N GORT SCEICHE,  MAYO"
    },
    {
        text: "S N GREACH RATHAIN,  CAVAN"
    },
    {
        text: "S N IDE,  KILDARE"
    },
    {
        text: "S N IDE NAOFA,  GALWAY"
    },
    {
        text: "SN INBHEAR,  MAYO"
    },
    {
        text: "S N INIS,  CORK"
    },
    {
        text: "S N INIS CEALTRACH,  CLARE"
    },
    {
        text: "S N INIS EOGHANAIN,  CORK"
    },
    {
        text: "S N INIS MEADHOIN,  GALWAY"
    },
    {
        text: "S N IOGNAID,  GALWAY"
    },
    {
        text: "S N IOMAIR NAOFA,  GALWAY"
    },
    {
        text: "S N IOSAGAIN,  CORK"
    },
    {
        text: "S N IOSEF NAOFA,  CORK"
    },
    {
        text: "S N IOSEF NAOFA,  TIPPERARY"
    },
    {
        text: "S N IOSEF NAOFA,  CLARE"
    },
    {
        text: "S N IOSEF NAOFA,  TIPPERARY"
    },
    {
        text: "S N IOSEF NAOFA,  TIPPERARY"
    },
    {
        text: "S N IOSEPH,  CORK"
    },
    {
        text: "S N LAINN CILLE,  MAYO"
    },
    {
        text: "S N LASAIR NAOFA,  ROSCOMMON"
    },
    {
        text: "S N LATHRACH,  CAVAN"
    },
    {
        text: "S N LATHRACH 2,  CAVAN"
    },
    {
        text: "S N LEAMHACH,  TIPPERARY"
    },
    {
        text: "S N LEATH ARDAN,  MAYO"
    },
    {
        text: "S N LEITIR,  CAVAN"
    },
    {
        text: "S N LEITIRGEIS,  GALWAY"
    },
    {
        text: "S N LEITIR MEALLAIN,  GALWAY"
    },
    {
        text: "S N LEITIR MHIC AN  BHAIRD,  DONEGAL"
    },
    {
        text: "S N LIOS A CUILL M,  ROSCOMMON"
    },
    {
        text: "S N LIOS AN HALLA,  TIPPERARY"
    },
    {
        text: "S N LIOSCEANUIR,  CLARE"
    },
    {
        text: "S N LIOS MOR MOCHUDA,  WATERFORD"
    },
    {
        text: "S N LIOS NA GROI,  LIMERICK"
    },
    {
        text: "S N LIOS TEILIC,  KERRY"
    },
    {
        text: "S N LISNAFUNCHIN,  KILKENNY"
    },
    {
        text: "S N LISSARA NAOFA,  SLIGO"
    },
    {
        text: "S N LOCH AN IUBHAIR,  DONEGAL"
    },
    {
        text: "S N LOCH GUIR,  LIMERICK"
    },
    {
        text: "S N LOMCHLUANA,  OFFALY"
    },
    {
        text: "S N LORCAIN NAOFA,  GALWAY"
    },
    {
        text: "S N LUA NAOFA,  TIPPERARY"
    },
    {
        text: "S N LUSCA,  DUBLIN COUNTY"
    },
    {
        text: "S N MAINISTIR MUIGHEO,  MAYO"
    },
    {
        text: "S N MAINISTIR O DTORNA,  KERRY"
    },
    {
        text: "SN M AN CROI GAN SMAL,  GALWAY"
    },
    {
        text: "S N MAODHOG,  CAVAN"
    },
    {
        text: "S N MA RUA,  LIMERICK"
    },
    {
        text: "S N MA SHEASTA,  CLARE"
    },
    {
        text: "S N MHANACHAIN,  OFFALY"
    },
    {
        text: "S N MHAODHOIG,  WEXFORD"
    },
    {
        text: "SN MHAOLCHEADAIR,  KERRY"
    },
    {
        text: "S N MHARCUIS B,  CORK"
    },
    {
        text: "SN MHIC DARA,  GALWAY"
    },
    {
        text: "S N MHIC DIARMADA,  LEITRIM"
    },
    {
        text: "S N MHICHIL NAOFA,  KILKENNY"
    },
    {
        text: "S N MHICHIL NAOFA,  MEATH"
    },
    {
        text: "S N MHIN TEINEADH DE,  DONEGAL"
    },
    {
        text: "S N MHUIRE,  CLARE"
    },
    {
        text: "S N MHUIRE,  CAVAN"
    },
    {
        text: "S N MHUIRE,  GALWAY"
    },
    {
        text: "S N MHUIRE,  WEXFORD"
    },
    {
        text: "S N MHUIRE,  GALWAY"
    },
    {
        text: "S N MHUIRE,  SLIGO"
    },
    {
        text: "S N MHUIRE,  MEATH"
    },
    {
        text: "S N MHUIRE,  CORK"
    },
    {
        text: "S N MHUIRE,  MEATH"
    },
    {
        text: "S N MHUIRE,  DUBLIN COUNTY"
    },
    {
        text: "S N MHUIRE,  CORK"
    },
    {
        text: "S N MHUIRE,  WEXFORD"
    },
    {
        text: "S N MHUIRE,  GALWAY"
    },
    {
        text: "S N MHUIRE,  CORK"
    },
    {
        text: "S N MHUIRE,  WEXFORD"
    },
    {
        text: "S N MHUIRE,  MEATH"
    },
    {
        text: "S N MHUIRE,  LOUTH"
    },
    {
        text: "S N MHUIRE,  MEATH"
    },
    {
        text: "S N MHUIRE,  KILDARE"
    },
    {
        text: "S N MHUIRE,  OFFALY"
    },
    {
        text: "S N MHUIRE,  GALWAY"
    },
    {
        text: "S N MHUIRE,  DONEGAL"
    },
    {
        text: "S N MHUIRE,  MEATH"
    },
    {
        text: "S N MHUIRE,  MEATH"
    },
    {
        text: "S N MHUIRE,  LONGFORD"
    },
    {
        text: "S N MHUIRE,  OFFALY"
    },
    {
        text: "SN MHUIRE,  WEXFORD"
    },
    {
        text: "SN MHUIRE,  CORK"
    },
    {
        text: "S N MHUIRE BAINRIOGHAN,  OFFALY"
    },
    {
        text: "SN MHUIRE BNS,  CORK"
    },
    {
        text: "SN MHUIRE BOYS SENIOR SCHOOL,  CAVAN"
    },
    {
        text: "S N MHUIRE DE LOURDES,  KERRY"
    },
    {
        text: "S N MHUIRE LOURDES,  ROSCOMMON"
    },
    {
        text: "S N MHUIRE MILIUC,  CLARE"
    },
    {
        text: "S N MHUIRE MXD,  CORK"
    },
    {
        text: "S N MHUIRE NA MBRAITHRE,  TIPPERARY"
    },
    {
        text: "S N MHUIRE NA MBRAITHRE,  KERRY"
    },
    {
        text: "S N MHUIRE NA MBRATHAR,  DUBLIN 9"
    },
    {
        text: "SN MHUIRE NA NAINGEAL,  TIPPERARY"
    },
    {
        text: "S N MHUIRE NAOFA,  MEATH"
    },
    {
        text: "S N MHUIRE NAOFA,  OFFALY"
    },
    {
        text: "S N MHUIRE NA TROCAIRE,  TIPPERARY"
    },
    {
        text: "S N MHUIRE NA TROCAIRE,  LOUTH"
    },
    {
        text: "S N MHUIRE SENIOR SCHOOL,  WICKLOW"
    },
    {
        text: "S N MICHEAL NAOFA,  TIPPERARY"
    },
    {
        text: "S N MICHIL NAOFA,  ROSCOMMON"
    },
    {
        text: "S N MICHIL NAOFA,  TIPPERARY"
    },
    {
        text: "S N MIN AN AOIRE,  DONEGAL"
    },
    {
        text: "S N MOING NA MIOL,  CORK"
    },
    {
        text: "S N MOIN NA GCAOR,  WEXFORD"
    },
    {
        text: "S N MOIN RUADH MIXED,  KILKENNY"
    },
    {
        text: "S N MOLAISE,  LAOIS"
    },
    {
        text: "S N MOLUA B,  LIMERICK"
    },
    {
        text: "SN  MUINE CHONALLAIN,  MAYO"
    },
    {
        text: "SN MUINE MHEA,  GALWAY"
    },
    {
        text: "S N MUIRE,  GALWAY"
    },
    {
        text: "S N MUIRE,  WICKLOW"
    },
    {
        text: "S N MUIRE,  WICKLOW"
    },
    {
        text: "S N MUIRE,  KILKENNY"
    },
    {
        text: "S N MUIRE,  LOUTH"
    },
    {
        text: "S N MUIRE,  MEATH"
    },
    {
        text: "S N MUIRE AGUS IOSEF,  DUBLIN 13"
    },
    {
        text: "S N MUIRE AN PORT MOR,  WATERFORD"
    },
    {
        text: "S N MUIRE AN SEAN BAILE,  DUBLIN COUNTY"
    },
    {
        text: "S N MUIRE GAN SMAL,  KERRY"
    },
    {
        text: "S N MUIRE GAN SMAL,  GALWAY"
    },
    {
        text: "S N MUIRE GAN SMAL,  MAYO"
    },
    {
        text: "S N MUIRE GAN SMAL,  GALWAY"
    },
    {
        text: "S N MUIRE GAN SMAL,  KILKENNY"
    },
    {
        text: "S N MUIRE GAN SMAL,  SLIGO"
    },
    {
        text: "S N MUIRE GAN SMAL,  GALWAY"
    },
    {
        text: "S N MUIRE GAN SMAL,  LOUTH"
    },
    {
        text: "S N MUIRE GAN SMAL,  DONEGAL"
    },
    {
        text: "S N MUIRE GAN SMAL,  DONEGAL"
    },
    {
        text: "S N MUIRE GAN SMAL,  CARLOW"
    },
    {
        text: "S N MUIRE GAN SMAL,  SLIGO"
    },
    {
        text: "S N MUIRE GAN SMAL,  GALWAY"
    },
    {
        text: "SN MUIRE GAN SMAL,  SLIGO"
    },
    {
        text: "S N MUIRE GAN SMAL B,  DUBLIN 8"
    },
    {
        text: "S N MUIRE IS GEARARD,  WICKLOW"
    },
    {
        text: "S N MUIRE LOURDES,  CARLOW"
    },
    {
        text: "S N MUIRE MAGH DEILGE,  WATERFORD"
    },
    {
        text: "S N MUIRE MXD,  WICKLOW"
    },
    {
        text: "S N MUIRE NA DOIRINI,  CORK"
    },
    {
        text: "S N MUIRE NA FREASTOGALA GIRLS SENIOR SCHOOL,  DUBLIN 12"
    },
    {
        text: "S N MUIRE NA HEIREANN,  LIMERICK"
    },
    {
        text: "S N MUIRE NAOFA,  OFFALY"
    },
    {
        text: "S N MULLACH BUI,  LOUTH"
    },
    {
        text: "S N MULLACH RUA,  SLIGO"
    },
    {
        text: "S N NA BHFIODH,  WATERFORD"
    },
    {
        text: "S N NA BHFORBACHA,  GALWAY"
    },
    {
        text: "S N NA CARRAIGE,  DONEGAL"
    },
    {
        text: "S N NA CEALLTRAIGHE,  GALWAY"
    },
    {
        text: "S N NA CILLE,  WATERFORD"
    },
    {
        text: "S N NA CLOICHE MOIRE,  KILDARE"
    },
    {
        text: "S N NA COILLE GLAISE,  GALWAY"
    },
    {
        text: "SN NA CRANNAIGHE,  CLARE"
    },
    {
        text: "S N NA CROISE NAOFA,  DONEGAL"
    },
    {
        text: "S N NA FUAR COILLEADH,  GALWAY"
    },
    {
        text: "S N NA GCLUAINTE,  DONEGAL"
    },
    {
        text: "S N NA HACRAI,  DONEGAL"
    },
    {
        text: "S N NA HAILLE,  DUBLIN COUNTY"
    },
    {
        text: "S N NA HAILLE,  TIPPERARY"
    },
    {
        text: "SN NA HAILLE,  MAYO"
    },
    {
        text: "S N NA HEAGLAISE,  GALWAY"
    },
    {
        text: "S N NA HINSE,  TIPPERARY"
    },
    {
        text: "S N NAITHI NAOFA,  SLIGO"
    },
    {
        text: "S N NA LANAI GLASA,  DUBLIN 3"
    },
    {
        text: "S N NA LEANAI,  WATERFORD"
    },
    {
        text: "S N NA MAIGHDINE MHUIRE,  KILDARE"
    },
    {
        text: "S N NA MAIGHDINE MUIRE B,  DUBLIN 9"
    },
    {
        text: "S N NA MAIGHNE,  TIPPERARY"
    },
    {
        text: "S N NA MBUACHAILLI,  TIPPERARY"
    },
    {
        text: "S N NA MONA FLICHE,  CORK"
    },
    {
        text: "S N NA NAOMH UILE,  GALWAY"
    },
    {
        text: "S N NA NAOMH UILE,  WESTMEATH"
    },
    {
        text: "S N NA NDUN,  WESTMEATH"
    },
    {
        text: "S N NAOMH ANTAINE,  CORK"
    },
    {
        text: "S N NAOMH ANTOINE,  GALWAY"
    },
    {
        text: "S N NAOMH AONGHUS,  DONEGAL"
    },
    {
        text: "S N NAOMH BAOITHIN,  DONEGAL"
    },
    {
        text: "S N NAOMH BRID,  KERRY"
    },
    {
        text: "S N NAOMH BRID,  GALWAY"
    },
    {
        text: "S N NAOMH BRID,  WICKLOW"
    },
    {
        text: "S N NAOMH BRID,  DONEGAL"
    },
    {
        text: "S N NAOMH BRID C,  MAYO"
    },
    {
        text: "S N NAOMH BRIGHDE BUACH,  OFFALY"
    },
    {
        text: "S N NAOMH BRIGHID,  LEITRIM"
    },
    {
        text: "S N NAOMH BROGAIN,  OFFALY"
    },
    {
        text: "S N NAOMH CAITRIONA,  CORK"
    },
    {
        text: "S N NAOMH CALLIN,  OFFALY"
    },
    {
        text: "S N NAOMH CEITHEACH,  ROSCOMMON"
    },
    {
        text: "S N NAOMH CHIARAIN,  KILKENNY"
    },
    {
        text: "S N NAOMH CHOLUMCHILLE,  OFFALY"
    },
    {
        text: "S N NAOMH CIANAIN,  MEATH"
    },
    {
        text: "S N NAOMH CIARAIN,  GALWAY"
    },
    {
        text: "S N NAOMH CIARAN,  ROSCOMMON"
    },
    {
        text: "S N NAOMH CILLIN,  DUBLIN 12"
    },
    {
        text: "S N NAOMH COLMAIN,  KILKENNY"
    },
    {
        text: "S N NAOMH COLMAIN,  GALWAY"
    },
    {
        text: "S N NAOMH COLMCHILLE,  DONEGAL"
    },
    {
        text: "S N NAOMH COLMCHILLE,  DONEGAL"
    },
    {
        text: "S N NAOMH COLMCILLE,  MEATH"
    },
    {
        text: "S N NAOMH COLMCILLE,  LAOIS"
    },
    {
        text: "S N NAOMH COLMCILLE,  LONGFORD"
    },
    {
        text: "S N NAOMH COLM CILLE,  MAYO"
    },
    {
        text: "S N NAOMH DEAGLAN,  WATERFORD"
    },
    {
        text: "S N NAOMH EIGHNEACH,  DONEGAL"
    },
    {
        text: "S N NAOMH EIRC,  KERRY"
    },
    {
        text: "S N NAOMH EOIN,  OFFALY"
    },
    {
        text: "S N NAOMH EOIN,  ROSCOMMON"
    },
    {
        text: "S N NAOMH EOIN,  LAOIS"
    },
    {
        text: "S N NAOMH EOIN,  DUBLIN 22"
    },
    {
        text: "S N NAOMH EOIN B,  CORK"
    },
    {
        text: "S N NAOMH FEARGAL BOYS SENIOR,  DUBLIN 11"
    },
    {
        text: "S N NAOMH FEICHIN,  MAYO"
    },
    {
        text: "SN NAOMH FEIDHLIM BOYS SNR SCH,  CAVAN"
    },
    {
        text: "S N NAOMH FHIACH,  CARLOW"
    },
    {
        text: "S N NAOMH FHIONAIN,  LOUTH"
    },
    {
        text: "S N NAOMH FHURSA,  GALWAY"
    },
    {
        text: "S N NAOMH FINNIN,  DUBLIN 11"
    },
    {
        text: "S N NAOMH FIONTAN,  WESTMEATH"
    },
    {
        text: "S N NAOMH GOBNAIT,  KERRY"
    },
    {
        text: "S N NAOMH IOSEF,  GALWAY"
    },
    {
        text: "S N NAOMH IOSEF,  GALWAY"
    },
    {
        text: "S N NAOMH IOSEF,  SLIGO"
    },
    {
        text: "S N NAOMH IOSEF,  LIMERICK"
    },
    {
        text: "S N NAOMH IOSEPH,  KILDARE"
    },
    {
        text: "S N NAOMH LACHTIN,  CORK"
    },
    {
        text: "S N NAOMH LORCAIN,  KILDARE"
    },
    {
        text: "S N NAOMH LORCAN,  DUBLIN COUNTY"
    },
    {
        text: "S N NAOMH LORCAN,  LOUTH"
    },
    {
        text: "S N NAOMH MAODHOG,  CAVAN"
    },
    {
        text: "S N NAOMH MHICHIL,  KERRY"
    },
    {
        text: "S N  NAOMH MHUIRE,  DUBLIN COUNTY"
    },
    {
        text: "S N NAOMH MHUIRE,  SLIGO"
    },
    {
        text: "S N NAOMH MHUIRE,  WATERFORD"
    },
    {
        text: "S N NAOMH MHUIRE,  ROSCOMMON"
    },
    {
        text: "S N NAOMH MHUIRE,  LEITRIM"
    },
    {
        text: "S N NAOMH MHUIRE,  CORK"
    },
    {
        text: "S N NAOMH MHUIRE,  LONGFORD"
    },
    {
        text: "S N NAOMH MHUIRE,  LEITRIM"
    },
    {
        text: "S N NAOMH MOCHTA,  DUBLIN 15"
    },
    {
        text: "S N NAOMH  NAILLE,  DONEGAL"
    },
    {
        text: "S N NAOMH PADRAIG,  DONEGAL"
    },
    {
        text: "S N NAOMH PADRAIG,  WICKLOW"
    },
    {
        text: "S N NAOMH PADRAIG,  MEATH"
    },
    {
        text: "S N NAOMH PADRAIG,  ROSCOMMON"
    },
    {
        text: "S N NAOMH PADRAIG,  GALWAY"
    },
    {
        text: "S N NAOMH PADRAIG,  MAYO"
    },
    {
        text: "S N NAOMH PADRAIG,  LIMERICK"
    },
    {
        text: "S N NAOMH PADRAIG,  ROSCOMMON"
    },
    {
        text: "S N NAOMH PADRAIG,  GALWAY"
    },
    {
        text: "S N NAOMH PADRAIG,  ROSCOMMON"
    },
    {
        text: "S N NAOMH PADRAIG,  GALWAY"
    },
    {
        text: "S N NAOMH PADRAIG,  MAYO"
    },
    {
        text: "S N NAOMH PADRAIG,  MEATH"
    },
    {
        text: "S N NAOMH PADRAIG,  MEATH"
    },
    {
        text: "S N NAOMH PADRAIG,  LEITRIM"
    },
    {
        text: "S N NAOMH PADRAIG,  TIPPERARY"
    },
    {
        text: "S N NAOMH PADRAIG,  MAYO"
    },
    {
        text: "S N NAOMH PADRAIG BOYS SENIOR SCHOOL,  CORK"
    },
    {
        text: "S N NAOMH PADRAIGH,  KILKENNY"
    },
    {
        text: "S N NAOMH PADRAIG MXD,  KILKENNY"
    },
    {
        text: "S N NAOMH PADRAIG SAILE,  MAYO"
    },
    {
        text: "S N NAOMH PARTHALAN,  WATERFORD"
    },
    {
        text: "S N NAOMH PEADAR,  TIPPERARY"
    },
    {
        text: "S N NAOMH PEADAR,  LOUTH"
    },
    {
        text: "S N NAOMH PHILOMENA,  OFFALY"
    },
    {
        text: "SN NAOMH PROINNSIAS,  MAYO"
    },
    {
        text: "S N NAOMH RUADHAIN,  TIPPERARY"
    },
    {
        text: "SN NAOMH SAMHTHANN,  DONEGAL"
    },
    {
        text: "S N NAOMH SEOSAMH,  ROSCOMMON"
    },
    {
        text: "S N NAOMH SEOSAMH,  MEATH"
    },
    {
        text: "S N NAOMH SEOSAMH,  MAYO"
    },
    {
        text: "S N NAOMH SHEAMUIS,  CORK"
    },
    {
        text: "S N NAOMH SHEOSAIMH,  SLIGO"
    },
    {
        text: "S N NAOMH SHEOSAMH,  MAYO"
    },
    {
        text: "S N NAOMH SHEOSAMH,  TIPPERARY"
    },
    {
        text: "S N NAOMH TERESA,  DUBLIN COUNTY"
    },
    {
        text: "S N NAOMH THOMAIS,  GALWAY"
    },
    {
        text: "S N NAOMH TREASA,  GALWAY"
    },
    {
        text: "S N NAOMH TREASA,  GALWAY"
    },
    {
        text: "S N NAOMH TREASA,  MEATH"
    },
    {
        text: "S N NAOMH TREASA,  ROSCOMMON"
    },
    {
        text: "S N NAOMH TREASA,  LONGFORD"
    },
    {
        text: "S N NAOMH UINSEANN,  GALWAY"
    },
    {
        text: "S N NA RINNE,  WATERFORD"
    },
    {
        text: "S N NA SCAIRTE LEITHE,  CORK"
    },
    {
        text: "S N NA SCRINE,  WEXFORD"
    },
    {
        text: "S N NA SRONA,  KERRY"
    },
    {
        text: "S N NA TRIONOIDE NAOFA,  MEATH"
    },
    {
        text: "SN N BREANDAIN,  GALWAY"
    },
    {
        text: "S N NEILL MOR,  DONEGAL"
    },
    {
        text: "S N NICOLAIS NAOFA,  WICKLOW"
    },
    {
        text: "S N N MAOLMHAODHAGH B,  LOUTH"
    },
    {
        text: "S N N MAOLMHAODHAGH C,  LOUTH"
    },
    {
        text: "S N N MAOLMHAODHAGH N,  LOUTH"
    },
    {
        text: "S N NMH BRIGHDE,  WEXFORD"
    },
    {
        text: "S N NMH FHINGIN,  CARLOW"
    },
    {
        text: "S N NMH MHUIRE,  KILDARE"
    },
    {
        text: "S N NMH SEOSAIMH,  WEXFORD"
    },
    {
        text: "S N NMH SHEOSAMH,  MEATH"
    },
    {
        text: "SN NMH TREASA,  MAYO"
    },
    {
        text: "SN N SAILBHEASTAR NFA,  DUBLIN COUNTY"
    },
    {
        text: "S N ODHRAN NAOFA,  TIPPERARY"
    },
    {
        text: "S N O DUBHLAIN,  ROSCOMMON"
    },
    {
        text: "S N OILIBEAR BEANNAITHE,  LOUTH"
    },
    {
        text: "S N OILIBHEAR NAOFA,  KERRY"
    },
    {
        text: "S N OILIBHEAR PLUNGLEAD,  KILDARE"
    },
    {
        text: "SN OILIBHEIR,  DUBLIN 15"
    },
    {
        text: "S N OIR CHEANN,  CORK"
    },
    {
        text: "S N OLIBHEAR PLUINCEID,  DONEGAL"
    },
    {
        text: "S N OLIBHEIR BEANNUITHE,  WEXFORD"
    },
    {
        text: "S N OSMANN,  OFFALY"
    },
    {
        text: "S N PADRAIC NAOFA,  GALWAY"
    },
    {
        text: "S N PADRAIG,  WEXFORD"
    },
    {
        text: "S N PADRAIG,  CAVAN"
    },
    {
        text: "S N PADRAIG,  LOUTH"
    },
    {
        text: "S N PADRAIG NAOFA,  GALWAY"
    },
    {
        text: "S N PADRAIG NAOFA,  GALWAY"
    },
    {
        text: "S N PADRAIG NAOFA,  SLIGO"
    },
    {
        text: "S N PADRAIG NAOFA,  SLIGO"
    },
    {
        text: "S N PADRAIG NAOFA,  GALWAY"
    },
    {
        text: "S N PADRAIG NAOFA,  DUBLIN 18"
    },
    {
        text: "S N PADRAIG NAOFA,  MEATH"
    },
    {
        text: "S N PADRAIG NAOFA,  CORK"
    },
    {
        text: "S N PADRAIG NAOFA,  ROSCOMMON"
    },
    {
        text: "S N PADRAIG NAOFA,  GALWAY"
    },
    {
        text: "S N PADRAIG NAOFA,  KILKENNY"
    },
    {
        text: "S N PADRAIG NAOFA,  CORK"
    },
    {
        text: "S N PADRAIG NAOFA,  WICKLOW"
    },
    {
        text: "S N PADRAIG NAOFA,  MAYO"
    },
    {
        text: "S N PADRAIG NAOFA C,  CORK"
    },
    {
        text: "S N PÁDRAIG NFA,  WEXFORD"
    },
    {
        text: "S N PADRAIG NFA FANOIR,  CLARE"
    },
    {
        text: "S N PAIRC ARD,  SLIGO"
    },
    {
        text: "S N PAIRC NA SLINNE,  GALWAY"
    },
    {
        text: "S N PAROISTEACH,  ROSCOMMON"
    },
    {
        text: "SN PAROISTE MAITIU NFA,  DUBLIN 4"
    },
    {
        text: "S N PEADAR AGUS POL,  CARLOW"
    },
    {
        text: "S N PEADAR AGUS POL,  OFFALY"
    },
    {
        text: "S N PEADAR AGUS POL,  MAYO"
    },
    {
        text: "S N PEADAR AGUS POL N,  DUBLIN COUNTY"
    },
    {
        text: "S N PHADRAIG,  WESTMEATH"
    },
    {
        text: "S N PHADRAIG,  WEXFORD"
    },
    {
        text: "S N PHADRAIG,  OFFALY"
    },
    {
        text: "S N PHADRAIG NAOFA,  CARLOW"
    },
    {
        text: "S N PHADRAIG NAOFA,  TIPPERARY"
    },
    {
        text: "S N PHEADAIR AGUS PHOIL,  MEATH"
    },
    {
        text: "S N PHOIL NAOFA,  WESTMEATH"
    },
    {
        text: "S N PHROINSIAIS NAOFA,  OFFALY"
    },
    {
        text: "S N POL A TSOMAIS,  MAYO"
    },
    {
        text: "S N POL NAOFA,  ROSCOMMON"
    },
    {
        text: "S N RAE NA NDOIRI,  CORK"
    },
    {
        text: "S N RAITHIN,  WEXFORD"
    },
    {
        text: "S N RATH A BHARRAIGH,  CORK"
    },
    {
        text: "S N RATHAIN,  CORK"
    },
    {
        text: "S N RATH AN IUBHAIR,  WEXFORD"
    },
    {
        text: "S N RATH ARADH,  ROSCOMMON"
    },
    {
        text: "S N RATH BEAGAIN,  MEATH"
    },
    {
        text: "S N RATH CHAOMHGHIN,  TIPPERARY"
    },
    {
        text: "S N RATH CORR,  LOUTH"
    },
    {
        text: "S N RATH DUBH,  CORK"
    },
    {
        text: "S N RATH EILTE,  TIPPERARY"
    },
    {
        text: "S N RATH MOR,  KILDARE"
    },
    {
        text: "S N RATH RIAGAIN,  MEATH"
    },
    {
        text: "S N REALT NA MARA,  MAYO"
    },
    {
        text: "S N REALT NA MARA,  SLIGO"
    },
    {
        text: "S N RÉALT NA MARA,  KERRY"
    },
    {
        text: "S N REALT NA MARA (B),  MEATH"
    },
    {
        text: "S N REALT NA MARA (C),  MEATH"
    },
    {
        text: "S N RE NA SCRINE,  CORK"
    },
    {
        text: "S N RONAIN,  ROSCOMMON"
    },
    {
        text: "S N RONAIN,  GALWAY"
    },
    {
        text: "S N RONAIN NAOFA,  SLIGO"
    },
    {
        text: "S N ROS COM RUA,  OFFALY"
    },
    {
        text: "S N ROS DUMHACH,  MAYO"
    },
    {
        text: "SN SAILEARNA,  GALWAY"
    },
    {
        text: "S N SAN NIOCLAS,  LOUTH"
    },
    {
        text: "S N SAN NIOCLAS,  LOUTH"
    },
    {
        text: "S N SAN NIOCLAS,  KILKENNY"
    },
    {
        text: "S N SAN TREASA,  DUBLIN COUNTY"
    },
    {
        text: "S N SAN VINSEANN CAILIN,  DUBLIN 1"
    },
    {
        text: "S N SCEICHIN A RINCE,  TIPPERARY"
    },
    {
        text: "S N SCOIL TREASA,  KILDARE"
    },
    {
        text: "S N SEACHNAILL NAOFA,  MEATH"
    },
    {
        text: "S N SEAMUS NAOFA,  KILKENNY"
    },
    {
        text: "S N SEAMUS NAOFA,  GALWAY"
    },
    {
        text: "S N SEAN BAILE MOR,  CORK"
    },
    {
        text: "S N SEISEADH UI NEILL,  DONEGAL"
    },
    {
        text: "S N SEOSAMH,  OFFALY"
    },
    {
        text: "S N SEOSAMH,  OFFALY"
    },
    {
        text: "S N SEOSAMH COBH,  CORK"
    },
    {
        text: "S N SEOSAMH NA MBRATHAR,  DUBLIN 3"
    },
    {
        text: "S N SEOSAMH NAOFA,  GALWAY"
    },
    {
        text: "S N SEOSAMH NAOMTHA,  MEATH"
    },
    {
        text: "S N SEOSAMH NFA,  WEXFORD"
    },
    {
        text: "S N SHEAMAIS,  MAYO"
    },
    {
        text: "S N SHEANAIN,  LIMERICK"
    },
    {
        text: "S N SHEAN BHOTH,  WEXFORD"
    },
    {
        text: "S N SHEOSAIMH NAOFA,  OFFALY"
    },
    {
        text: "S N SLIABH A MHADRA,  KERRY"
    },
    {
        text: "S N TALAMH NA COILLE,  DONEGAL"
    },
    {
        text: "S N TALAMH NA MANACH,  LOUTH"
    },
    {
        text: "S N TAMHNACH DHUIBH,  CAVAN"
    },
    {
        text: "S N TAMHNIGHAN FHEADHA,  MAYO"
    },
    {
        text: "S N TAODHBHOG,  DONEGAL"
    },
    {
        text: "S N TEACH CAOIN,  MAYO"
    },
    {
        text: "S N TEAGHLAIGH NAOFA,  MAYO"
    },
    {
        text: "S N TEAMPALL DOIRE,  TIPPERARY"
    },
    {
        text: "S N TIGH AN LUBHAIR,  LOUTH"
    },
    {
        text: "S N TIGHEARNAIN NAOFA,  MAYO"
    },
    {
        text: "S N TIR AN FHIAIDH,  GALWAY"
    },
    {
        text: "S N TIR MOCHAIN,  KILDARE"
    },
    {
        text: "S N TIR NA CILLE,  GALWAY"
    },
    {
        text: "S N TOBAIR EOIN BAISDE,  KILKENNY"
    },
    {
        text: "S N TOIN NA GAOITHE,  MAYO"
    },
    {
        text: "S N TOINN AN TAIRBH,  LIMERICK"
    },
    {
        text: "S N TREASA NAOFA MXD,  KERRY"
    },
    {
        text: "S N TREASA NAOMHA,  CARLOW"
    },
    {
        text: "S N TREASA NFA,  WEXFORD"
    },
    {
        text: "S N TRIANTA,  DONEGAL"
    },
    {
        text: "SN TUAIRINI,  GALWAY"
    },
    {
        text: "S N TULACH ALUINN,  LOUTH"
    },
    {
        text: "S N TULACH RUAIN,  KILKENNY"
    },
    {
        text: "SN TULLACH UI CHADHAIN,  GALWAY"
    },
    {
        text: "S N UAIMH BHREANAINN,  KERRY"
    },
    {
        text: "SN UI CHEITHEARNAIGH,  GALWAY"
    },
    {
        text: "S N UILEOG DE BURCA,  MAYO"
    },
    {
        text: "S N ULTAIN NAOFA,  MEATH"
    },
    {
        text: "S N URSULA,  SLIGO"
    },
    {
        text: "S N URSULA NAOFA,  WATERFORD"
    },
    {
        text: "SOLAS HOSPITAL SCHOOL,  DUBLIN 12"
    },
    {
        text: "SONAS SPECIAL PRIMARY JUNIOR SCHOOL,  CORK"
    },
    {
        text: "SOOEY N S,  SLIGO"
    },
    {
        text: "SOUTH ABBEY NS,  CORK"
    },
    {
        text: "SOUTH LEE EDUCATE TOGETHER NATIONAL SCHOOL,  CORK"
    },
    {
        text: "SPA NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "SPRINGDALE N S,  DUBLIN 5"
    },
    {
        text: "ST. ABBEY'S NATIONAL SCHOOL,  CORK"
    },
    {
        text: "STACKALLEN N S,  MEATH"
    },
    {
        text: "ST. AIDAN'S C.B.S.,  DUBLIN 9"
    },
    {
        text: "ST AIDAN'S COMMUNITY COLLEGE,  CORK"
    },
    {
        text: "ST AIDAN'S COMMUNITY SCHOOL,  DUBLIN 24"
    },
    {
        text: "ST AIDANS COMPREHENSIVE SCHOOL,  CAVAN"
    },
    {
        text: "ST AIDANS NS,  CLARE"
    },
    {
        text: "ST AIDANS NS,  DUBLIN 24"
    },
    {
        text: "ST AIDANS N S,  KILKENNY"
    },
    {
        text: "ST AIDANS PARISH SCHOOL,  WEXFORD"
    },
    {
        text: "ST AILBE'S NATIONAL SCHOOL,  TIPPERARY"
    },
    {
        text: "ST. AILBE'S SCHOOL,  TIPPERARY"
    },
    {
        text: "ST ALOYSIUS COLLEGE,  CORK"
    },
    {
        text: "ST ALOYSIUS SCHOOL,  CORK"
    },
    {
        text: "ST ANDREWS COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "ST ANDREWS N S,  DUBLIN COUNTY"
    },
    {
        text: "ST ANDREWS N S,  DUBLIN COUNTY"
    },
    {
        text: "ST ANDREWS N S,  WICKLOW"
    },
    {
        text: "ST. ANGELA'S COLLEGE,  CORK"
    },
    {
        text: "ST ANGELA'S SECONDARY SCHOOL,  WATERFORD"
    },
    {
        text: "STANHOPE STREET PRIMARY SCHOOL,  DUBLIN 7"
    },
    {
        text: "ST ANNE'S COMMUNITY COLLEGE,  CLARE"
    },
    {
        text: "ST ANNES CON N S,  ROSCOMMON"
    },
    {
        text: "ST ANNES MXD N S,  DUBLIN COUNTY"
    },
    {
        text: "ST ANNES NATIONAL SCHOOL,  KILDARE"
    },
    {
        text: "ST. ANNE'S NATIONAL SCHOOL,  WESTMEATH"
    },
    {
        text: "ST ANNES PRIMARY SCHOOL,  DUBLIN 24"
    },
    {
        text: "ST. ANNE'S SECONDARY SCHOOL,  TIPPERARY"
    },
    {
        text: "ST ANNES SPECIAL SCH,  TIPPERARY"
    },
    {
        text: "ST ANNES SPECIAL SCHOOL,  KILDARE"
    },
    {
        text: "ST ANNES S S,  CLARE"
    },
    {
        text: "ST ANTHONYS SPECIAL SC,  MAYO"
    },
    {
        text: "STAPOLIN EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 13"
    },
    {
        text: "STAR OF THE SEA,  DUBLIN 4"
    },
    {
        text: "STAR OF THE SEA PRIMARY SCHOOL,  CORK"
    },
    {
        text: "ST ATTRACTA'S COMMUNITY SCHOOL,  SLIGO"
    },
    {
        text: "ST ATTRACTAS JUNIOR N S,  DUBLIN 16"
    },
    {
        text: "ST ATTRACTAS N S,  ROSCOMMON"
    },
    {
        text: "ST ATTRACTAS SENIOR N S,  DUBLIN 14"
    },
    {
        text: "ST AUDOENS NS,  DUBLIN 8"
    },
    {
        text: "ST AUGUSTINES COLLEGE,  WATERFORD"
    },
    {
        text: "ST AUGUSTINES SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "ST AUGUSTINES SPEC SCH,  LIMERICK"
    },
    {
        text: "ST BEACONS N S,  KILKENNY"
    },
    {
        text: "ST BENEDICTS AND ST MARYS NATIONAL SCHOOL,  DUBLIN 5"
    },
    {
        text: "ST BENEDICTS NATIONAL SCHOOL,  DUBLIN 15"
    },
    {
        text: "ST BENILDUS COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "ST BERNADETTES JUNIOR N S,  DUBLIN 22"
    },
    {
        text: "ST BERNADETTE SPEC SCH,  DONEGAL"
    },
    {
        text: "ST BERNADETTES SENIOR N S,  DUBLIN 22"
    },
    {
        text: "ST BERNADETTES SPEC SCH,  CORK"
    },
    {
        text: "ST BERNARDS MIXED N S,  LONGFORD"
    },
    {
        text: "ST. BRENDAN'S COLLEGE,  KERRY"
    },
    {
        text: "ST. BRENDAN'S COLLEGE,  MAYO"
    },
    {
        text: "ST.BRENDAN'S COMMUNITY SCHOOL,  OFFALY"
    },
    {
        text: "ST BRENDANS MIXED N S,  KILKENNY"
    },
    {
        text: "ST BRENDANS MONASTERY,  OFFALY"
    },
    {
        text: "ST BRENDANS NATIONAL SCHOOL,  CORK"
    },
    {
        text: "ST BRENDAN'S N.S.,  GALWAY"
    },
    {
        text: "ST BRENDANS NS,  SLIGO"
    },
    {
        text: "ST BRENDANS NS,  GALWAY"
    },
    {
        text: "ST BRENDANS N S,  GALWAY"
    },
    {
        text: "ST BRENDANS N S,  CARLOW"
    },
    {
        text: "ST BRENDANS N S,  KERRY"
    },
    {
        text: "ST BRENDANS N S,  GALWAY"
    },
    {
        text: "ST BRICIN'S COLLEGE,  CAVAN"
    },
    {
        text: "ST. BRIDE'S N.S.,  ROSCOMMON"
    },
    {
        text: "ST BRIDGETS MONASTERY BOYS SENIOR SCHOOL,  CARLOW"
    },
    {
        text: "ST BRIDS,  LEITRIM"
    },
    {
        text: "ST BRIDS SPECIAL SCH,  MAYO"
    },
    {
        text: "ST BRIGHIDS SPECIAL SCH,  LOUTH"
    },
    {
        text: "ST BRIGID'S AND ST PATRICK'S NATIONAL SCHOOL,  LOUTH"
    },
    {
        text: "ST BRIGIDS BOYS N S,  DUBLIN 18"
    },
    {
        text: "ST BRIGIDS BOYS N S,  DUBLIN 5"
    },
    {
        text: "ST  BRIGIDS COLLEGE,  GALWAY"
    },
    {
        text: "ST. BRIGID'S COLLEGE,  KILKENNY"
    },
    {
        text: "ST BRIGIDS CONVENT,  DUBLIN 11"
    },
    {
        text: "ST BRIGIDS CONVENT N S,  DUBLIN 5"
    },
    {
        text: "ST BRIGIDS GIRLS N S,  DUBLIN 18"
    },
    {
        text: "ST BRIGIDS INFANT N S,  DUBLIN 11"
    },
    {
        text: "ST BRIGID'S KILDARE TOWN PRIMARY SCHOOL,  KILDARE"
    },
    {
        text: "ST BRIGIDS MXD N S,  DUBLIN 15"
    },
    {
        text: "ST. BRIGID'S NATIONAL SCHOOL AUGHNAFARCON,  MONAGHAN"
    },
    {
        text: "ST BRIGIDS NS,  LIMERICK"
    },
    {
        text: "ST BRIGIDS N S,  KILDARE"
    },
    {
        text: "ST BRIGIDS N S,  DUBLIN COUNTY"
    },
    {
        text: "ST BRIGIDS N S,  KILDARE"
    },
    {
        text: "ST BRIGIDS N S,  DUBLIN 24"
    },
    {
        text: "ST BRIGID'S PRIMARY SCHOOL,  WESTMEATH"
    },
    {
        text: "ST BRIGIDS PRIMARY SCHOOL,  DUBLIN 8"
    },
    {
        text: "ST BRIGIDS SCHOOL,  WICKLOW"
    },
    {
        text: "ST. BRIGID'S SCHOOL,  GALWAY"
    },
    {
        text: "ST. BRIGID'S SECONDARY SCHOOL,  KERRY"
    },
    {
        text: "ST BRIGIDS SENIOR GIRLS,  DUBLIN 11"
    },
    {
        text: "ST BRIGIDS SPEC SCH,  WESTMEATH"
    },
    {
        text: "ST. BROGAN'S COLLEGE,  CORK"
    },
    {
        text: "ST CAIMIN'S COMMUNITY SCHOOL,  CLARE"
    },
    {
        text: "ST CANICES B N S,  DUBLIN 11"
    },
    {
        text: "ST CANICES CENTRAL N S,  KILKENNY"
    },
    {
        text: "ST CANICE S CONVENT,  WEXFORD"
    },
    {
        text: "ST CANICES G N S,  DUBLIN 11"
    },
    {
        text: "ST CANICES SPECIAL SCHOOL,  LIMERICK"
    },
    {
        text: "ST. CATHERINE OF SIENNA,  KILDARE"
    },
    {
        text: "ST CATHERINES INFANT SCHOOL,  DUBLIN 7"
    },
    {
        text: "ST CATHERINES SPECIAL SCHOOL,  WICKLOW"
    },
    {
        text: "ST. CATHERINE'S VOCATIONAL SCHOOL,  DONEGAL"
    },
    {
        text: "ST CATHERINES WEST N S,  DUBLIN 8"
    },
    {
        text: "ST. CECILIA'S SCHOOL,  SLIGO"
    },
    {
        text: "ST CHRISTOPHER'S NS,  DUBLIN 4"
    },
    {
        text: "ST CHRISTOPHERS S S,  LONGFORD"
    },
    {
        text: "ST CIARAN'S COMMUNITY SCHOOL,  MEATH"
    },
    {
        text: "ST CIARANS MIXED N S,  OFFALY"
    },
    {
        text: "ST CIARANS N S,  DUBLIN 15"
    },
    {
        text: "ST CIARANS SPEC SCH,  DUBLIN 11"
    },
    {
        text: "ST CLARE'S COLLEGE,  CAVAN"
    },
    {
        text: "ST. CLARE'S COMPREHENSIVE SCHOOL,  LEITRIM"
    },
    {
        text: "ST CLARES CONVENT N S,  DUBLIN 6W"
    },
    {
        text: "ST CLARE'S NATIONAL SCHOOL,  LEITRIM"
    },
    {
        text: "ST CLARES NS,  CAVAN"
    },
    {
        text: "ST CLARES PRIMARY SCHOOL,  CAVAN"
    },
    {
        text: "ST CLARES SPECIAL SCH,  CLARE"
    },
    {
        text: "ST CLEMENTS COLLEGE,  LIMERICK"
    },
    {
        text: "ST COEN'S NATIONAL SCHOOL,  WICKLOW"
    },
    {
        text: "ST COLEMAN'S NATIONAL SCHOOL,  LAOIS"
    },
    {
        text: "ST COLMAN'S COMMUNITY COLLEGE,  CORK"
    },
    {
        text: "ST COLMANS MXD N S,  GALWAY"
    },
    {
        text: "ST COLMANS NS,  MAYO"
    },
    {
        text: "ST COLMANS N S,  OFFALY"
    },
    {
        text: "ST. COLMCILLE'S COMMUNITY SCHOOL,  DUBLIN 16"
    },
    {
        text: "ST COLMCILLE SENIOR N S,  DUBLIN 16"
    },
    {
        text: "ST. COLMCILLE'S N.S.,  WESTMEATH"
    },
    {
        text: "ST COLMCILLES PRIMARY SCHOOL,  TIPPERARY"
    },
    {
        text: "ST COLUMBANUS NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "ST COLUMBA'S COLLEGE,  DUBLIN 16"
    },
    {
        text: "ST COLUMBAS COLLEGE,  DONEGAL"
    },
    {
        text: "ST COLUMBA'S COMPREHENSIVE SCHOOL,  DONEGAL"
    },
    {
        text: "ST COLUMBAS CON G & I,  DUBLIN 9"
    },
    {
        text: "ST COLUMBAS MXD N S,  LONGFORD"
    },
    {
        text: "ST COLUMBA'S NATIONAL SCHOOL,  GALWAY"
    },
    {
        text: "ST COLUMBAS N S,  CARLOW"
    },
    {
        text: "ST COLUMBAS N.S,  CORK"
    },
    {
        text: "ST COLUMBAS N.S.,  MAYO"
    },
    {
        text: "ST COLUMBAS N S MXD,  DUBLIN 3"
    },
    {
        text: "ST CÓMAN'S WOOD PRIMARY SCHOOL,  ROSCOMMON"
    },
    {
        text: "ST CONAIRES NS,  CLARE"
    },
    {
        text: "ST CONALS,  DONEGAL"
    },
    {
        text: "ST CONLETHS AND MARYS N S,  KILDARE"
    },
    {
        text: "ST CONLETHS COLLEGE,  DUBLIN 4"
    },
    {
        text: "ST CONLETH'S COMMUNITY COLLEGE,  KILDARE"
    },
    {
        text: "ST CONLETHS N S,  KILDARE"
    },
    {
        text: "ST CRONANS JUNIOR NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "ST CRONANS MIXED N S,  OFFALY"
    },
    {
        text: "ST. CUAN'S COLLEGE,  GALWAY"
    },
    {
        text: "ST. CYNOC'S NATIONAL SCHOOL,  OFFALY"
    },
    {
        text: "ST DAMIENS NS,  DUBLIN 12"
    },
    {
        text: "ST DAVADOGS N S,  DONEGAL"
    },
    {
        text: "ST. DAVID'S C.B.S.,  DUBLIN 5"
    },
    {
        text: "ST DAVID'S HOLY FAITH SECONDARY,  WICKLOW"
    },
    {
        text: "ST DAVIDS NS,  KILDARE"
    },
    {
        text: "ST DAVIDS N S,  DUBLIN 5"
    },
    {
        text: "ST DECLAN'S COLLEGE,  DUBLIN 7"
    },
    {
        text: "ST DECLAN'S COMMUNITY COLLEGE,  WATERFORD"
    },
    {
        text: "ST DECLANS SPECIAL SCH,  DUBLIN 4"
    },
    {
        text: "ST DOMINICS COLLEGE,  DUBLIN 7"
    },
    {
        text: "ST. DOMINIC'S COLLEGE BALLYFERMOT,  DUBLIN 10"
    },
    {
        text: "ST DOMINIC'S NATIONAL SCHOOL,  DUBLIN 24"
    },
    {
        text: "ST. DYMPNA'S NATIONAL SCHOOL,  MEATH"
    },
    {
        text: "ST DYMPNAS NS,  MONAGHAN"
    },
    {
        text: "ST DYMPNAS SPEC SCHOOL,  MAYO"
    },
    {
        text: "ST EDANS N S,  WEXFORD"
    },
    {
        text: "ST EDWARDS N S,  SLIGO"
    },
    {
        text: "ST EITHNES SENIOR GIRLS NS,  DUBLIN 5"
    },
    {
        text: "ST EMER'S NATIONAL SCHOOL,  LONGFORD"
    },
    {
        text: "ST ENDAS NS,  CORK"
    },
    {
        text: "ST ENDAS PRIMARY SCHOOL,  DUBLIN 8"
    },
    {
        text: "STEPASIDE EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 18"
    },
    {
        text: "STEPASIDE EDUCATE TOGETHER SECONDARY SCHOOL,  DUBLIN 18"
    },
    {
        text: "STEPPING STONES SPECIAL SCHOOL,  KILDARE"
    },
    {
        text: "ST ETCHENS NATIONAL SCHOOL,  WESTMEATH"
    },
    {
        text: "ST EUNAN'S COLLEGE,  DONEGAL"
    },
    {
        text: "STEWARTS SCHOOL,  DUBLIN 20"
    },
    {
        text: "ST FACHNAN & ST ATTRACTAS N S,  CLARE"
    },
    {
        text: "ST FARNAN'S POST PRIMARY SCHOOL,  KILDARE"
    },
    {
        text: "ST FEICHINS N SCHOOL,  GALWAY"
    },
    {
        text: "ST FEIGHANS MXD N S,  WESTMEATH"
    },
    {
        text: "ST FERGAL'S COLLEGE,  LAOIS"
    },
    {
        text: "ST FERGAL'S NATIONAL SCHOOL,  WICKLOW"
    },
    {
        text: "ST FERGUS PRIMARY SCHOOL,  LIMERICK"
    },
    {
        text: "ST FIACHRAS SEN N S,  DUBLIN 5"
    },
    {
        text: "ST FINIANS,  KERRY"
    },
    {
        text: "ST FINIAN'S COLLEGE,  WESTMEATH"
    },
    {
        text: "ST FINIANS COMMUNITY COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "ST. FINTAN'S HIGH SCHOOL,  DUBLIN 13"
    },
    {
        text: "ST FINTANS NS,  DUBLIN 13"
    },
    {
        text: "ST FINTANS N S,  LAOIS"
    },
    {
        text: "ST FLANNAN'S COLLEGE,  CLARE"
    },
    {
        text: "ST FRANCIS CAPUCHIN COLLEGE,  CORK"
    },
    {
        text: "ST FRANCIS JUNIOR NATIONAL SCHOOL,  DUBLIN 17"
    },
    {
        text: "ST FRANCIS NATIONAL SCHOOL,  TIPPERARY"
    },
    {
        text: "ST FRANCIS' NATIONAL SCHOOL,  DONEGAL"
    },
    {
        text: "ST. FRANCIS NATIONAL SCHOOL,  LOUTH"
    },
    {
        text: "ST FRANCIS NS,  DONEGAL"
    },
    {
        text: "ST FRANCIS N S,  WICKLOW"
    },
    {
        text: "ST. FRANCIS OF ASSISI NATIONAL SCHOOL,  DUBLIN 13"
    },
    {
        text: "ST FRANCIS SENIOR N S,  DUBLIN 17"
    },
    {
        text: "ST FRANCIS SPECIAL SCH,  KERRY"
    },
    {
        text: "ST FRANCIS S S,  LAOIS"
    },
    {
        text: "ST FRANCIS XAVIER J N S,  DUBLIN 15"
    },
    {
        text: "ST FRANCIS XAVIER SENIOR N S,  DUBLIN 15"
    },
    {
        text: "ST GABRIELS NS,  DUBLIN 10"
    },
    {
        text: "ST GABRIELS N S,  DUBLIN 7"
    },
    {
        text: "ST GABRIELS SCHOOL,  LIMERICK"
    },
    {
        text: "ST GABRIELS SPECIAL SCHOOL,  CORK"
    },
    {
        text: "ST GARVANS NS,  WEXFORD"
    },
    {
        text: "ST GARVANS N.S.,  DONEGAL"
    },
    {
        text: "ST GEORGES N S,  DUBLIN COUNTY"
    },
    {
        text: "ST. GERALDS COLLEGE,  MAYO"
    },
    {
        text: "ST. GERARD'S SCHOOL,  WICKLOW"
    },
    {
        text: "ST HELENS JUNIOR N S,  DUBLIN COUNTY"
    },
    {
        text: "ST HELENS SENIOR N S,  DUBLIN COUNTY"
    },
    {
        text: "ST HILDAS SP SCH,  WESTMEATH"
    },
    {
        text: "ST. HUGH'S N.S.,  LEITRIM"
    },
    {
        text: "ST IBERIUS N S,  WEXFORD"
    },
    {
        text: "ST ITAS SPECIAL SCHOOL,  LOUTH"
    },
    {
        text: "ST ITAS & ST JOSEPHS NS,  KERRY"
    },
    {
        text: "ST JAMES N S,  LIMERICK"
    },
    {
        text: "ST. JAMES'S PRIMARY SCHOOL,  DUBLIN 8"
    },
    {
        text: "ST. JARLATHS COLLEGE,  GALWAY"
    },
    {
        text: "ST JOHN BOSCO COMMUNITY COLLEGE,  CLARE"
    },
    {
        text: "ST JOHN OF GOD N S,  DUBLIN 5"
    },
    {
        text: "ST JOHN OF GOD SP SCH,  DUBLIN 8"
    },
    {
        text: "ST JOHNS,  TIPPERARY"
    },
    {
        text: "ST JOHNS COLLEGE DE LA SALLE,  DUBLIN 10"
    },
    {
        text: "ST JOHNS CONVENT,  LIMERICK"
    },
    {
        text: "ST. JOHNS GIRLS N S,  CORK"
    },
    {
        text: "ST JOHNS INFANTS N S,  KILKENNY"
    },
    {
        text: "ST. JOHN'S NATIONAL SCHOOL,  KILDARE"
    },
    {
        text: "ST. JOHN'S NATIONAL SCHOOL,,  LONGFORD"
    },
    {
        text: "ST. JOHNS NATIONAL SCHOOL,  LONGFORD"
    },
    {
        text: "ST JOHNS NS,  KERRY"
    },
    {
        text: "ST JOHNS  NS,  MAYO"
    },
    {
        text: "ST JOHNS N S,  MAYO"
    },
    {
        text: "ST JOHNS N S,  DUBLIN COUNTY"
    },
    {
        text: "ST JOHNS PAROCHIAL SCHOOL,  KERRY"
    },
    {
        text: "ST JOHNS SENIOR NS,  KILKENNY"
    },
    {
        text: "ST JOHN'S SENIOR SCHOOL,  WICKLOW"
    },
    {
        text: "ST JOHNS SPECIAL SCH,  WATERFORD"
    },
    {
        text: "ST. JOHN THE APOSTLE, KNOCKNACARRA NS,  GALWAY"
    },
    {
        text: "ST JOHN THE BAPTIST,  TIPPERARY"
    },
    {
        text: "ST JOHN THE BAPTIST BOYS N S,  LIMERICK"
    },
    {
        text: "ST JOHN THE BAPTIST BOYS SCHOOL,  TIPPERARY"
    },
    {
        text: "ST JOHN THE BAPTIST N S,  CORK"
    },
    {
        text: "ST. JOHN THE EVANGELIST NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "ST JOSEPH OF CLUNY SECONDARY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "ST JOSEPHS,  DUBLIN COUNTY"
    },
    {
        text: "ST JOSEPHS,  LIMERICK"
    },
    {
        text: "ST JOSEPH'S ADOLESCENT SCHOOL,  DUBLIN 3"
    },
    {
        text: "ST JOSEPHS B N S,  ROSCOMMON"
    },
    {
        text: "ST JOSEPHS BOYS N S,  DUBLIN 6"
    },
    {
        text: "ST JOSEPHS C.B.S.,  DUBLIN 3"
    },
    {
        text: "ST. JOSEPH'S C.B.S.,  LOUTH"
    },
    {
        text: "ST. JOSEPH'S CBS NENAGH,  TIPPERARY"
    },
    {
        text: "ST JOSEPH'S COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "ST JOSEPH'S COLLEGE,  GALWAY"
    },
    {
        text: "ST JOSEPHS COLLEGE,  TIPPERARY"
    },
    {
        text: "ST JOSEPH'S COMMUNITY COLLEGE,  CLARE"
    },
    {
        text: "ST JOSEPH'S COMMUNITY COLLEGE,  MAYO"
    },
    {
        text: "ST JOSEPHS CONVENT,  LIMERICK"
    },
    {
        text: "ST JOSEPHS CONVENT,  LONGFORD"
    },
    {
        text: "ST JOSEPHS CONVENT N S,  CORK"
    },
    {
        text: "ST JOSEPHS FOR BLIND NS,  DUBLIN 9"
    },
    {
        text: "ST JOSEPH'S GIRLS NATIONAL SCHOOL,  CORK"
    },
    {
        text: "ST JOSEPHS GIRLS N.S.,  LAOIS"
    },
    {
        text: "ST JOSEPHS G N S,  DUBLIN 11"
    },
    {
        text: "ST JOSEPHS JNR,  DUBLIN 11"
    },
    {
        text: "ST JOSEPHS MXD N S,  DUBLIN 3"
    },
    {
        text: "ST JOSEPH'S NATIONAL SCHOOL,  SLIGO"
    },
    {
        text: "ST JOSEPH'S NATIONAL SCHOOL,  MONAGHAN"
    },
    {
        text: "ST JOSEPHS NATIONAL SCHOOL,  LAOIS"
    },
    {
        text: "ST. JOSEPH'S NATIONAL SCHOOL,  MEATH"
    },
    {
        text: "ST JOSEPH'S NS,  MAYO"
    },
    {
        text: "ST JOSEPH'S N.S.,  WEXFORD"
    },
    {
        text: "ST JOSEPHS NS,  KILDARE"
    },
    {
        text: "ST JOSEPHS NS,  CARLOW"
    },
    {
        text: "ST JOSEPHS NS,  DUBLIN 17"
    },
    {
        text: "ST JOSEPHS N S,  MAYO"
    },
    {
        text: "ST JOSEPHS N S,  WEXFORD"
    },
    {
        text: "ST JOSEPHS N S,  LEITRIM"
    },
    {
        text: "ST JOSEPHS N S,  WICKLOW"
    },
    {
        text: "ST JOSEPHS N S,  LOUTH"
    },
    {
        text: "ST JOSEPHS N S,  MONAGHAN"
    },
    {
        text: "ST JOSEPHS N S,  CAVAN"
    },
    {
        text: "ST. JOSEPH'S N.S.,  OFFALY"
    },
    {
        text: "ST. JOSEPHS N. S.,  MAYO"
    },
    {
        text: "ST. JOSEPHS N.S.,  GALWAY"
    },
    {
        text: "ST JOSEPHS PRIMARY SCHOOL,  TIPPERARY"
    },
    {
        text: "ST JOSEPH'S SECONDARY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "ST JOSEPH'S SECONDARY SCHOOL,  WESTMEATH"
    },
    {
        text: "ST JOSEPH'S SECONDARY SCHOOL,  MAYO"
    },
    {
        text: "ST JOSEPH'S SECONDARY SCHOOL,  MAYO"
    },
    {
        text: "ST JOSEPHS SECONDARY SCHOOL,  DUBLIN 7"
    },
    {
        text: "ST. JOSEPH'S SECONDARY SCHOOL,  KERRY"
    },
    {
        text: "ST. JOSEPH'S SECONDARY SCHOOL,  CLARE"
    },
    {
        text: "ST. JOSEPH'S SECONDARY SCHOOL, NAVAN,,  MEATH"
    },
    {
        text: "ST. JOSEPH'S SECONDARY SCHOOL TULLA,  CLARE"
    },
    {
        text: "ST JOSEPHS SENIOR N S,  DUBLIN 11"
    },
    {
        text: "ST JOSEPHS SPECIAL SCH,  GALWAY"
    },
    {
        text: "ST JOSEPHS SPECIAL SCH,  WATERFORD"
    },
    {
        text: "ST JOSEPHS SPECIAL SCH,  SLIGO"
    },
    {
        text: "ST JOSEPHS SPECIAL SCH,  DUBLIN 24"
    },
    {
        text: "ST KEVIN'S BALLYCOOG NS,  WICKLOW"
    },
    {
        text: "ST KEVINS B N S,  DUBLIN 11"
    },
    {
        text: "ST KEVINS BOYS,  DUBLIN 24"
    },
    {
        text: "ST KEVINS COLLEGE,  DUBLIN 11"
    },
    {
        text: "ST KEVIN'S COMMUNITY COLLEGE,  WICKLOW"
    },
    {
        text: "ST. KEVIN'S COMMUNITY COLLEGE,  DUBLIN 22"
    },
    {
        text: "ST KEVINS GIRLS,  DUBLIN 24"
    },
    {
        text: "ST KEVINS JUNIOR N S,  DUBLIN 13"
    },
    {
        text: "ST KEVINS N S,  DUBLIN COUNTY"
    },
    {
        text: "ST KEVINS SCHOOL,  CORK"
    },
    {
        text: "ST KIERAN'S COLLEGE,  KILKENNY"
    },
    {
        text: "ST KIERANS SPEC SCHOOL,  WICKLOW"
    },
    {
        text: "ST. KILIAN'S COMMUNITY SCHOOL,  WICKLOW"
    },
    {
        text: "ST KILIAN'S DEUTSCHE SCHULE,  DUBLIN 14"
    },
    {
        text: "ST KILIANS JUNIOR SCHOOL,  DUBLIN 24"
    },
    {
        text: "ST KILIANS SENIOR N S,  DUBLIN 24"
    },
    {
        text: "ST KILLIAN'S COLLEGE (COLÁISTE CHILLEÁIN NAOFA),  GALWAY"
    },
    {
        text: "ST KILLIANS N S,  CAVAN"
    },
    {
        text: "ST KILLIANS SPEC  SCH,  CORK"
    },
    {
        text: "ST LASERIANS MXD NS,  CARLOW"
    },
    {
        text: "ST LASERIANS SPECIAL SC,  CARLOW"
    },
    {
        text: "ST LAURENCE COLLEGE,  DUBLIN 18"
    },
    {
        text: "ST LAURENCE O'TOOLE'S NATIONAL SCHOOL,  DUBLIN 1"
    },
    {
        text: "ST LAURENCE O'TOOLE'S NS,  WICKLOW"
    },
    {
        text: "ST LAURENCE'S NATIONAL SCHOOL,  DUBLIN 13"
    },
    {
        text: "ST LAURENCES NATIONAL SCHOOL,  KILDARE"
    },
    {
        text: "ST LAURENCES N S,  KILDARE"
    },
    {
        text: "ST LAURENCES N S,  WICKLOW"
    },
    {
        text: "ST LEONARDS N S,  WEXFORD"
    },
    {
        text: "ST. LEO'S COLLEGE,  CARLOW"
    },
    {
        text: "ST L O TOOLES 2 SPEC,  DUBLIN 1"
    },
    {
        text: "ST LOUIS COMMUNITY SCHOOL,  MAYO"
    },
    {
        text: "ST LOUISE DE MARILLAC PRIMARY SCHOOL,  DUBLIN 10"
    },
    {
        text: "ST. LOUIS GIRLS NATIONAL SCHOOL,  MONAGHAN"
    },
    {
        text: "ST LOUIS HIGH SCHOOL,  DUBLIN 6"
    },
    {
        text: "ST LOUIS INFANT SCHOOL,  MONAGHAN"
    },
    {
        text: "ST LOUIS N S,  MEATH"
    },
    {
        text: "ST LOUIS SECONDARY SCHOOL,  LOUTH"
    },
    {
        text: "ST LOUIS SECONDARY SCHOOL,  MONAGHAN"
    },
    {
        text: "ST. LOUIS SECONDARY SCHOOL,  MONAGHAN"
    },
    {
        text: "ST LUKES MXD N S,  CORK"
    },
    {
        text: "ST LUKES N S DOUGLAS,  CORK"
    },
    {
        text: "ST MACARTANS CENTRAL,  DONEGAL"
    },
    {
        text: "ST. MACARTAN'S COLLEGE,  MONAGHAN"
    },
    {
        text: "ST MAC DARA'S COMMUNITY COLLEGE,  DUBLIN 6W"
    },
    {
        text: "ST MALACHYS B N S,  DUBLIN 5"
    },
    {
        text: "ST MALACHYS NS,  DUBLIN 11"
    },
    {
        text: "ST MARGARETS N S,  DUBLIN COUNTY"
    },
    {
        text: "ST MARKS COMMUNITY SCHOOL,  DUBLIN 24"
    },
    {
        text: "ST MARKS JUNIOR MIXED,  DUBLIN 24"
    },
    {
        text: "ST MARKS SEN NS,  DUBLIN 24"
    },
    {
        text: "ST MARKS SPECIAL SCHOOL,  KILDARE"
    },
    {
        text: "ST MARTIN DE PORRES N S,  DUBLIN 24"
    },
    {
        text: "ST MARTINS SPECIAL SCH,  WATERFORD"
    },
    {
        text: "ST MARY'S ACADEMY CBS,  CARLOW"
    },
    {
        text: "ST MARYS BNS,  MONAGHAN"
    },
    {
        text: "ST. MARY'S C.B.S.,  LAOIS"
    },
    {
        text: "ST. MARY'S C.B.S.,  WEXFORD"
    },
    {
        text: "ST MARYS CENTRAL N S,  DUBLIN 6"
    },
    {
        text: "ST MARYS CENTRAL SCHOOL,  CORK"
    },
    {
        text: "ST MARY'S CHURCH OF IRELAND N.S,  CORK"
    },
    {
        text: "ST MARY'S COLLEGE,  LOUTH"
    },
    {
        text: "ST MARYS COLLEGE,  WICKLOW"
    },
    {
        text: "ST MARYS COLLEGE,  SLIGO"
    },
    {
        text: "ST. MARY'S COLLEGE,  GALWAY"
    },
    {
        text: "ST. MARY'S COLLEGE C.S.SP., RATHMINES,  DUBLIN 6"
    },
    {
        text: "ST MARYS CONVENT,  TIPPERARY"
    },
    {
        text: "ST MARYS CONVENT N S,  MEATH"
    },
    {
        text: "ST MARY'S DIOCESAN SCHOOL,  LOUTH"
    },
    {
        text: "ST MARY'S HIGH SCHOOL,  CORK"
    },
    {
        text: "ST MARYS JUNIOR N S,  DUBLIN 22"
    },
    {
        text: "ST MARY'S KNOCKBEG COLLEGE,  CARLOW"
    },
    {
        text: "ST MARYS MIXED N S,  LONGFORD"
    },
    {
        text: "ST MARY'S NATIONAL SCHOOL,  DONEGAL"
    },
    {
        text: "ST MARY'S NATIONAL SCHOOL,  KILKENNY"
    },
    {
        text: "ST MARY'S NATIONAL SCHOOL,  LIMERICK"
    },
    {
        text: "ST MARY'S NATIONAL SCHOOL,  MAYO"
    },
    {
        text: "ST MARYS NATIONAL SCHOOL,  CORK"
    },
    {
        text: "ST MARYS NATIONAL SCHOOL,  OFFALY"
    },
    {
        text: "ST. MARY'S NATIONAL SCHOOL,  DUBLIN 18"
    },
    {
        text: "ST MARY'S N.S.,  CAVAN"
    },
    {
        text: "ST MARYS NS,  WATERFORD"
    },
    {
        text: "ST MARYS NS,  WESTMEATH"
    },
    {
        text: "ST MARYS N S,  CARLOW"
    },
    {
        text: "ST MARYS N S,  MAYO"
    },
    {
        text: "ST MARYS N S,  TIPPERARY"
    },
    {
        text: "ST MARYS N S,  TIPPERARY"
    },
    {
        text: "ST MARYS N S,  CAVAN"
    },
    {
        text: "ST MARYS N S,  WEXFORD"
    },
    {
        text: "ST MARYS N S,  TIPPERARY"
    },
    {
        text: "ST MARYS N S,  WEXFORD"
    },
    {
        text: "ST MARYS N S,  DUBLIN COUNTY"
    },
    {
        text: "ST MARYS N S,  DUBLIN 3"
    },
    {
        text: "ST MARYS N.S.,  LONGFORD"
    },
    {
        text: "ST MARYS N S GRANGE,  WATERFORD"
    },
    {
        text: "ST. MARYS NS TOURANEENA,  WATERFORD"
    },
    {
        text: "ST MARYS PARISH PRIMARY SCHOOL,  LOUTH"
    },
    {
        text: "ST MARYS PAROCHIAL SCHOOL,  TIPPERARY"
    },
    {
        text: "ST MARY'S PRIMARY,  ROSCOMMON"
    },
    {
        text: "ST MARY'S PRIMARY SCHOOL,  DUBLIN 7"
    },
    {
        text: "ST. MARY'S PRIMARY SCHOOL,  MEATH"
    },
    {
        text: "ST MARYS SCHOOL,  DUBLIN 24"
    },
    {
        text: "ST MARY'S SECONDARY SCHOOL,  DUBLIN 11"
    },
    {
        text: "ST MARY'S SECONDARY SCHOOL,  CORK"
    },
    {
        text: "ST MARY'S SECONDARY SCHOOL,  CORK"
    },
    {
        text: "ST MARY'S SECONDARY SCHOOL,  TIPPERARY"
    },
    {
        text: "ST MARY'S SECONDARY SCHOOL,  OFFALY"
    },
    {
        text: "ST MARYS SECONDARY SCHOOL,  DUBLIN 13"
    },
    {
        text: "ST MARYS SECONDARY SCHOOL,  DUBLIN 5"
    },
    {
        text: "ST. MARY'S SECONDARY SCHOOL,  CORK"
    },
    {
        text: "ST. MARY'S SECONDARY SCHOOL,  WEXFORD"
    },
    {
        text: "ST. MARY'S SECONDARY SCHOOL,  MAYO"
    },
    {
        text: "ST. MARY'S SECONDARY SCHOOL,  TIPPERARY"
    },
    {
        text: "ST MARYS SEN N S,  DUBLIN 22"
    },
    {
        text: "ST MARYS SPECIAL SCH,  LOUTH"
    },
    {
        text: "ST MARYS SPECIAL SCHOOL,  MEATH"
    },
    {
        text: "ST MARYS SPEC SCH,  CORK"
    },
    {
        text: "ST MATTHEWS MIXED N.S,  LONGFORD"
    },
    {
        text: "ST MATTHIAS N S,  CORK"
    },
    {
        text: "ST. MEL'S COLLEGE,  LONGFORD"
    },
    {
        text: "ST MICHAELS,  LIMERICK"
    },
    {
        text: "ST MICHAEL'S AND ST PETER'S JUNIOR SCHOOL,  WICKLOW"
    },
    {
        text: "ST MICHAELS COLLEGE,  DUBLIN 4"
    },
    {
        text: "ST. MICHAEL'S COLLEGE,  KERRY"
    },
    {
        text: "ST MICHAEL'S COMMUNITY COLLEGE,  CLARE"
    },
    {
        text: "ST MICHAEL'S HOUSE SPECIAL NATIONAL SCHOOL FOXFIELD,  DUBLIN 5"
    },
    {
        text: "ST MICHAELS HOUSE SPECIAL SCHOOL,  DUBLIN 13"
    },
    {
        text: "ST MICHAELS HSE SPEC SC,  DUBLIN 18"
    },
    {
        text: "ST MICHAELS HSE SPEC SC,  DUBLIN 9"
    },
    {
        text: "ST MICHAELS HSE SP SCH,  DUBLIN 5"
    },
    {
        text: "ST MICHAELS NATIONAL SCHOOL,  KILKENNY"
    },
    {
        text: "ST MICHAELS NATIONAL SCHOOL,  CAVAN"
    },
    {
        text: "ST MICHAELS NS,  LIMERICK"
    },
    {
        text: "ST MICHAELS NS,  LEITRIM"
    },
    {
        text: "ST MICHAELS NS,  DUBLIN 10"
    },
    {
        text: "ST MICHAELS N. S.,  CAVAN"
    },
    {
        text: "ST MICHAELS SECONDARY SCHOOL,  DUBLIN 11"
    },
    {
        text: "ST MICHAELS SPEC SCH,  DUBLIN COUNTY"
    },
    {
        text: "ST MICHAELS SPEC SCHOOL,  DUBLIN 20"
    },
    {
        text: "ST. MOCHTA'S NATIONAL SCHOOL,  LOUTH"
    },
    {
        text: "ST MOCHULLAS N.S.,  CLARE"
    },
    {
        text: "ST. MOGUE'S COLLEGE,  CAVAN"
    },
    {
        text: "ST MOLAGA SENIOR NS,  DUBLIN COUNTY"
    },
    {
        text: "ST MONICAS N S,  DUBLIN 5"
    },
    {
        text: "ST MUNCHIN'S COLLEGE,  LIMERICK"
    },
    {
        text: "ST. MURA'S NATIONAL SCHOOL,  DONEGAL"
    },
    {
        text: "ST MURAS N S,  DONEGAL"
    },
    {
        text: "ST MUREDACHS COLLEGE,  MAYO"
    },
    {
        text: "ST NATHY'S COLLEGE,  ROSCOMMON"
    },
    {
        text: "ST. NICHOLAS CHURCH OF IRELAND SCHOOL,  LIMERICK"
    },
    {
        text: "ST NICHOLAS MONASTERY NS,  LOUTH"
    },
    {
        text: "ST. NICHOLAS PRIMARY SCHOOL,  MEATH"
    },
    {
        text: "ST NICHOLAS SPEC  SCH,  MAYO"
    },
    {
        text: "STOKANE NS,  SLIGO"
    },
    {
        text: "ST OLAF'S NATIONAL SCHOOL,  DUBLIN 16"
    },
    {
        text: "ST OLIVER PLUNKETT,  DUBLIN COUNTY"
    },
    {
        text: "ST OLIVER PLUNKETT NATIONAL SCHOOL,  GALWAY"
    },
    {
        text: "ST OLIVER PLUNKETT NS,  MEATH"
    },
    {
        text: "ST OLIVER PLUNKETT N S,  DUBLIN 11"
    },
    {
        text: "ST OLIVER PLUNKETTS NS,  GALWAY"
    },
    {
        text: "ST OLIVER PLUNKETTS NS,  TIPPERARY"
    },
    {
        text: "ST OLIVER PLUNKETT SP SC,  DUBLIN COUNTY"
    },
    {
        text: "ST OLIVER POST PRIMARY,  MEATH"
    },
    {
        text: "ST. OLIVER'S CHILD EDUCATION AND DEVELOPMENT CENTRE,  GALWAY"
    },
    {
        text: "ST OLIVER'S COMMUNITY COLLEGE,  LOUTH"
    },
    {
        text: "ST OLIVERS NS,  LOUTH"
    },
    {
        text: "ST OLIVERS NS,  KERRY"
    },
    {
        text: "STONEHALL N S,  CLARE"
    },
    {
        text: "STONEPARK N S,  LONGFORD"
    },
    {
        text: "ST ORANS N S,  DONEGAL"
    },
    {
        text: "ST PATRICK GNS,  DUBLIN 16"
    },
    {
        text: "ST PATRICKS,  DUBLIN 8"
    },
    {
        text: "ST PATRICKS,  CAVAN"
    },
    {
        text: "ST PATRICKS,  LONGFORD"
    },
    {
        text: "ST PATRICKS BNS,  KILDARE"
    },
    {
        text: "ST PATRICKS B N S,  CORK"
    },
    {
        text: "ST PATRICK'S BOYS' NATIONAL SCHOOL,  CORK"
    },
    {
        text: "ST PATRICKS BOYS NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "ST PATRICKS CATHEDRAL GRAMMAR SCHOOL,  DUBLIN 8"
    },
    {
        text: "ST PATRICKS CENTRAL NS,  MAYO"
    },
    {
        text: "ST PATRICK'S CLASSICAL SCHOOL,  MEATH"
    },
    {
        text: "ST PATRICK'S COLLEGE,  CAVAN"
    },
    {
        text: "ST PATRICKS COLLEGE,  CORK"
    },
    {
        text: "ST. PATRICK'S COLLEGE,  MAYO"
    },
    {
        text: "ST PATRICKS GIRLS NS,  DUBLIN 4"
    },
    {
        text: "ST PATRICKS GNS,  DUBLIN 18"
    },
    {
        text: "ST PATRICKS JNR MIXED,  DUBLIN COUNTY"
    },
    {
        text: "ST. PATRICK'S JUNIOR NS,  DUBLIN 15"
    },
    {
        text: "ST PATRICKS MXD N S,  CAVAN"
    },
    {
        text: "ST PATRICK'S NATIONAL SCHOOL,  WICKLOW"
    },
    {
        text: "ST PATRICK'S NATIONAL SCHOOL,  CORK"
    },
    {
        text: "ST PATRICK'S NATIONAL SCHOOL,  OFFALY"
    },
    {
        text: "ST PATRICK'S NATIONAL SCHOOL,  CARLOW"
    },
    {
        text: "ST  PATRICKS NS,  LEITRIM"
    },
    {
        text: "ST PATRICK'S NS,  CAVAN"
    },
    {
        text: "ST PATRICKS NS,  MAYO"
    },
    {
        text: "ST PATRICKS NS,  DUBLIN 20"
    },
    {
        text: "ST PATRICKS NS,  KILKENNY"
    },
    {
        text: "ST PATRICKS NS,  MONAGHAN"
    },
    {
        text: "ST PATRICKS NS,  KILDARE"
    },
    {
        text: "ST PATRICKS NS,  SLIGO"
    },
    {
        text: "ST PATRICKS NS,  DUBLIN 15"
    },
    {
        text: "ST PATRICKS N S,  WEXFORD"
    },
    {
        text: "ST PATRICKS N S,  KILDARE"
    },
    {
        text: "ST PATRICKS N S,  MEATH"
    },
    {
        text: "ST PATRICKS N S,  DONEGAL"
    },
    {
        text: "ST PATRICKS N S,  DONEGAL"
    },
    {
        text: "ST PATRICKS N S,  CARLOW"
    },
    {
        text: "ST PATRICKS N S,  MONAGHAN"
    },
    {
        text: "ST PATRICKS N S,  CAVAN"
    },
    {
        text: "ST PATRICKS N SCHOOL,  DUBLIN 9"
    },
    {
        text: "ST PATRICKS NS LONGFORD,  LONGFORD"
    },
    {
        text: "ST PATRICKS SENIOR SCHOOL,  DUBLIN 15"
    },
    {
        text: "ST PATRICKS SNR MIXED,  DUBLIN COUNTY"
    },
    {
        text: "ST PATRICKS SPEC SCH,  KILKENNY"
    },
    {
        text: "ST PATRICKS SPEC SCHOOL,  WEXFORD"
    },
    {
        text: "ST PAULS,  GALWAY"
    },
    {
        text: "ST PAULS B N S,  WATERFORD"
    },
    {
        text: "ST PAULS C.B.S.,  DUBLIN 7"
    },
    {
        text: "ST PAULS COLLEGE,  DUBLIN 5"
    },
    {
        text: "ST PAUL'S  COMMUNITY COLLEGE,  WATERFORD"
    },
    {
        text: "ST PAULS HOSPITAL SPECIAL SCHOOL,  DUBLIN 9"
    },
    {
        text: "ST PAULS JUNIOR NATIONAL SCHOOL,  DUBLIN 13"
    },
    {
        text: "ST PAUL'S NATIONAL SCHOOL,  MAYO"
    },
    {
        text: "ST PAULS NS,  MEATH"
    },
    {
        text: "ST PAULS N S,  LAOIS"
    },
    {
        text: "ST PAULS N S,  MEATH"
    },
    {
        text: "ST PAULS NS COLLOONEY,  SLIGO"
    },
    {
        text: "ST PAUL'S SCHOOL,  CORK"
    },
    {
        text: "ST PAULS SECONDARY SCHOOL,  DUBLIN 12"
    },
    {
        text: "ST PAULS SECONDARY SCHOOL,  KILDARE"
    },
    {
        text: "ST PAULS SENIOR NS,  LOUTH"
    },
    {
        text: "ST PAULS SEN NS,  DUBLIN 13"
    },
    {
        text: "ST PETER AND PAUL,  TIPPERARY"
    },
    {
        text: "ST PETER APOSTLE SEN NS,  DUBLIN 22"
    },
    {
        text: "ST PETER'S COLLEGE,  WEXFORD"
    },
    {
        text: "ST PETER'S COLLEGE,  MEATH"
    },
    {
        text: "ST PETER'S COMMUNITY SCHOOL,  CORK"
    },
    {
        text: "ST PETERS  NATIONAL SCHOOL,  MEATH"
    },
    {
        text: "ST PETERS NS,  MAYO"
    },
    {
        text: "ST PETERS NS,  DUBLIN 7"
    },
    {
        text: "ST PETERS N S SNR,  WESTMEATH"
    },
    {
        text: "ST. PETER'S PRIMARY SCHOOL,  WICKLOW"
    },
    {
        text: "ST PETERS SPECIAL SCH,  DUBLIN 6"
    },
    {
        text: "ST PHILIPS  SENIOR N S,  DUBLIN 15"
    },
    {
        text: "ST PHILIP THE APOSTLE JUNIOR N S,  DUBLIN 15"
    },
    {
        text: "ST PIUS X G N S,  DUBLIN 6W"
    },
    {
        text: "ST PIUS X N S BOYS,  DUBLIN 6W"
    },
    {
        text: "STRABAGGAN N S,  ROSCOMMON"
    },
    {
        text: "STRADBALLY C. OF IRE. NS,  WATERFORD"
    },
    {
        text: "STRAFFAN N S,  KILDARE"
    },
    {
        text: "ST RAPHAELAS N S,  DUBLIN COUNTY"
    },
    {
        text: "ST RAPHAELA'S SECONDARY SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "ST RAPHAELS COLLEGE,  GALWAY"
    },
    {
        text: "ST RAPHAELS NS,  DUBLIN 10"
    },
    {
        text: "ST RAPHAELS SPECIAL SCH,  KILDARE"
    },
    {
        text: "STRATFORD COLLEGE,  DUBLIN 6"
    },
    {
        text: "STRATFORD LODGE NS,  WICKLOW"
    },
    {
        text: "STRAWBERRY HILL B N S,  CORK"
    },
    {
        text: "STRAWBERRY HILL NATIONAL SCHOOL,  CORK"
    },
    {
        text: "STREAMSTOWN MIXED N S,  WESTMEATH"
    },
    {
        text: "ST RIAGHANS NS,  DONEGAL"
    },
    {
        text: "ST RONANS N S,  DUBLIN 22"
    },
    {
        text: "ST ROSES SPECIAL SCHOOL,  DUBLIN 24"
    },
    {
        text: "ST RYNAGHS N S,  OFFALY"
    },
    {
        text: "ST SAVIOURS NS,  WATERFORD"
    },
    {
        text: "ST SAVIOURS N S,  WICKLOW"
    },
    {
        text: "ST SENANS NATIONAL SCH,  WEXFORD"
    },
    {
        text: "ST. SENAN'S NATIONAL SCHOOL,  KILKENNY"
    },
    {
        text: "ST STEPHENS NS,  MEATH"
    },
    {
        text: "ST STEPHENS N S,  WATERFORD"
    },
    {
        text: "ST. TERESA'S SPECIAL SCHOOL,  GALWAY"
    },
    {
        text: "ST THOMAS' COMMUNITY COLLEGE,  WICKLOW"
    },
    {
        text: "ST THOMAS JUNIOR NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "ST THOMAS JUNIOR N S,  DUBLIN 24"
    },
    {
        text: "ST THOMAS SENIOR N S,  DUBLIN 24"
    },
    {
        text: "ST TIARNACH'S PRIMARY SCHOOL,  MONAGHAN"
    },
    {
        text: "ST. TIERNAN'S COLLEGE,  MAYO"
    },
    {
        text: "ST. TIERNAN'S COMMUNITY SCHOOL,  DUBLIN 16"
    },
    {
        text: "ST ULTANS NS,  DUBLIN 10"
    },
    {
        text: "ST ULTANS SPECIAL SCH,  MEATH"
    },
    {
        text: "ST VINCENT DE PAULS GIRLS SENIOR SCHOOL,  DUBLIN 9"
    },
    {
        text: "ST VINCENTS C.B.S. GLASNEVIN,  DUBLIN 11"
    },
    {
        text: "ST VINCENTS CONVENT INF N S,  DUBLIN 9"
    },
    {
        text: "ST VINCENTS CONVENT N S,  CORK"
    },
    {
        text: "ST VINCENTS HOME NS,  DUBLIN 7"
    },
    {
        text: "ST VINCENTS INF BOYS,  DUBLIN 1"
    },
    {
        text: "ST. VINCENT'S PRIMARY SCHOOL,  DUBLIN 11"
    },
    {
        text: "ST VINCENT'S SECONDARY SCHOOL,  CORK"
    },
    {
        text: "ST VINCENT'S SECONDARY SCHOOL,  LOUTH"
    },
    {
        text: "ST VINCENTS SP SCHOOL,  LIMERICK"
    },
    {
        text: "ST WOLSTAN'S COMMUNITY SCHOOL,  KILDARE"
    },
    {
        text: "SUMMERHILL COLLEGE,  SLIGO"
    },
    {
        text: "SUNDAYS WELL G N S,  CORK"
    },
    {
        text: "SUTTON PARK SCHOOL,  DUBLIN 13"
    },
    {
        text: "SWINFORD NS,  MAYO"
    },
    {
        text: "SWORDS BOROUGH N S,  DUBLIN COUNTY"
    },
    {
        text: "SWORDS COMMUNITY COLLEGE,  DUBLIN COUNTY"
    },
    {
        text: "SWORDS EDUCATE TOGETHER NS,  DUBLIN COUNTY"
    },
    {
        text: "TAHILLA COMMUNITY NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "TALBOT SENIOR NS,  DUBLIN 22"
    },
    {
        text: "TALLAGHT COMMUNITY NATIONAL SCHOOL,  DUBLIN 24"
    },
    {
        text: "TALLAGHT COMMUNITY SCHOOL,  DUBLIN 24"
    },
    {
        text: "TALLONSTOWN N S,  LOUTH"
    },
    {
        text: "TANEY N S,  DUBLIN 14"
    },
    {
        text: "TANG N S,  WESTMEATH"
    },
    {
        text: "TANKERSTOWN N S,  TIPPERARY"
    },
    {
        text: "TARBERT COMPREHENSIVE SCHOOL,  KERRY"
    },
    {
        text: "TARBERT NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "TARMON N S,  ROSCOMMON"
    },
    {
        text: "TASHINNY N S,  LONGFORD"
    },
    {
        text: "TAUGHMACONNELL N S,  ROSCOMMON"
    },
    {
        text: "TAUNAGH NATIONAL SCHOOL,  SLIGO"
    },
    {
        text: "TAVRANE CENTRAL NS,  MAYO"
    },
    {
        text: "TEMPLEBRADY N S,  CORK"
    },
    {
        text: "TEMPLE CARRIG SECONDARY SCHOOL,  WICKLOW"
    },
    {
        text: "TEMPLEGLANTINE N S,  LIMERICK"
    },
    {
        text: "TEMPLEMARY N S,  MAYO"
    },
    {
        text: "TEMPLEMICHAEL COLLEGE,  LONGFORD"
    },
    {
        text: "TEMPLEOGUE COLLEGE,  DUBLIN 6W"
    },
    {
        text: "TEMPLEORUM N S,  KILKENNY"
    },
    {
        text: "TEMPLETUOHY N S,  TIPPERARY"
    },
    {
        text: "TERENCE MAC SWINEY COMMUNITY COLLEGE,  CORK"
    },
    {
        text: "TERENURE COLLEGE,  DUBLIN 6W"
    },
    {
        text: "THE ABBEY SCHOOL,  TIPPERARY"
    },
    {
        text: "THE ADELAIDE AND MEATH HOSPITAL,  DUBLIN 24"
    },
    {
        text: "THE BILLIS NATIONAL SCHOOL,  MONAGHAN"
    },
    {
        text: "THE BLACK VALLEY NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "THE CHILDRENS ARK,  LIMERICK"
    },
    {
        text: "THE DONAHIES COMMUNITY SCHOOL,  DUBLIN 13"
    },
    {
        text: "THE GLEBE N.S.,  GALWAY"
    },
    {
        text: "THE HAROLD SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "THE HIGH SCHOOL,  DUBLIN 6"
    },
    {
        text: "THE HUNT N S,  LEITRIM"
    },
    {
        text: "THE INTERMEDIATE SCHOOL,  KERRY"
    },
    {
        text: "THE KINGS HOSPITAL,  DUBLIN 20"
    },
    {
        text: "THE KOLBE SPECIAL SCHOOL,  LAOIS"
    },
    {
        text: "THE ROWER MIXED N S,  KILKENNY"
    },
    {
        text: "THE ROYAL AND PRIOR SCHOOL,  DONEGAL"
    },
    {
        text: "THE SACRED HEART PRIMARY NS,  LONGFORD"
    },
    {
        text: "THE TERESIAN SCHOOL,  DUBLIN 4"
    },
    {
        text: "THOMASTOWN N S,  TIPPERARY"
    },
    {
        text: "THOMOND COMMUNITY COLLEGE,  LIMERICK"
    },
    {
        text: "THOMOND PRIMARY SCHOOL,  LIMERICK"
    },
    {
        text: "THORNLEIGH EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN COUNTY"
    },
    {
        text: "THREEMILEHOUSE N S,  MONAGHAN"
    },
    {
        text: "THREEN N S,  ROSCOMMON"
    },
    {
        text: "TIBOHINE N S,  ROSCOMMON"
    },
    {
        text: "TIERNABOUL N S,  KERRY"
    },
    {
        text: "TÍGH NAN DOOLEY CHILD EDUCATION AND DEVELOPMENT CENTRE,  GALWAY"
    },
    {
        text: "TIMOLEAGUE N S,  CORK"
    },
    {
        text: "TINAHELY N S,  WICKLOW"
    },
    {
        text: "TIPPERARY G N S,  TIPPERARY"
    },
    {
        text: "TIPPERARY JNR BN S,  TIPPERARY"
    },
    {
        text: "TIRELLAN HEIGHTS N S,  GALWAY"
    },
    {
        text: "TIRELTON N S MXD,  CORK"
    },
    {
        text: "TISRARA NATIONAL SCHOOL,  ROSCOMMON"
    },
    {
        text: "TOBAR AN LEINN,  LAOIS"
    },
    {
        text: "TOGALA MHUIRE,  GALWAY"
    },
    {
        text: "TOGHER N S,  CORK"
    },
    {
        text: "TOMBRACK N S,  WEXFORD"
    },
    {
        text: "TOMGRANEY N S,  CLARE"
    },
    {
        text: "TOONAGH N S,  CLARE"
    },
    {
        text: "TRAFRASK MIXED N S,  CORK"
    },
    {
        text: "TRALEE EDUCATE TOGETHER NS,  KERRY"
    },
    {
        text: "TRAMORE EDUCATE TOGETHER NATIONAL SCHOOL,  WATERFORD"
    },
    {
        text: "TRIM EDUCATE TOGETHER NATIONAL SCHOOL,  MEATH"
    },
    {
        text: "TRINITY COMPREHENSIVE SCHOOL,  DUBLIN 9"
    },
    {
        text: "TRINITY PRIMARY SCHOOL,  GALWAY"
    },
    {
        text: "TRUMMERA N S,  LAOIS"
    },
    {
        text: "TUAM EDUCATE TOGETHER NATIONAL SCHOOL,  GALWAY"
    },
    {
        text: "TUBBER N S,  CLARE"
    },
    {
        text: "TULLAMORE COLLEGE,  OFFALY"
    },
    {
        text: "TULLAMORE EDUCATE TOGETHER NS,  OFFALY"
    },
    {
        text: "TULLOHA NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "TULLOW COMMUNITY SCHOOL,  CARLOW"
    },
    {
        text: "TULLYLEASE MIXED N S,  CORK"
    },
    {
        text: "TULSK N S,  ROSCOMMON"
    },
    {
        text: "TWO MILE BORRIS N S,  TIPPERARY"
    },
    {
        text: "TWO MILE COMMUNITY NATIONAL SCHOOL,  KERRY"
    },
    {
        text: "TYNDALL COLLEGE,  CARLOW"
    },
    {
        text: "TYRRELSTOWN EDUCATE TOGETHER NATIONAL SCHOOL,  DUBLIN 15"
    },
    {
        text: "UGHTYNEILL N S,  MEATH"
    },
    {
        text: "UNION HALL MIXED N S,  CORK"
    },
    {
        text: "UPPER GLANMIRE N S,  CORK"
    },
    {
        text: "UPPER NEWTOWN N S,  TIPPERARY"
    },
    {
        text: "URBLESHANNY N S,  MONAGHAN"
    },
    {
        text: "URSAILLE NAOFA,  KILDARE"
    },
    {
        text: "URSULINE COLLEGE,  SLIGO"
    },
    {
        text: "URSULINE COLLEGE BLACKROCK,  CORK"
    },
    {
        text: "URSULINE SECONDARY SCHOOL,  TIPPERARY"
    },
    {
        text: "VALLEY N S,  MAYO"
    },
    {
        text: "VICARSTOWN MIXED N S,  CORK"
    },
    {
        text: "VILLIERS SECONDARY SCHOOL,  LIMERICK"
    },
    {
        text: "VILLIERSTOWN N S,  WATERFORD"
    },
    {
        text: "VIRGINIA COLLEGE,  CAVAN"
    },
    {
        text: "VIRGINIA MXD N S,  CAVAN"
    },
    {
        text: "VIRGIN MARY GIRLS NATIONAL SCHOOL,  DUBLIN 9"
    },
    {
        text: "WALSHESTOWN N S,  LOUTH"
    },
    {
        text: "WALTERSTOWN N S,  CORK"
    },
    {
        text: "WANDESFORDE MIXED N S,  KILKENNY"
    },
    {
        text: "WATERFORD EDUCATE TOGETHER NS,  WATERFORD"
    },
    {
        text: "WATERGRASSHILL N S,  CORK"
    },
    {
        text: "WATERPARK COLLEGE,  WATERFORD"
    },
    {
        text: "WATERPARK  N S,  WATERFORD"
    },
    {
        text: "WESLEY COLLEGE,  DUBLIN 16"
    },
    {
        text: "WESTPORT EDUCATE TOGETHER NATIONAL SCHOOL,  MAYO"
    },
    {
        text: "WEXFORD EDUCATE TOGETHER NATIONAL SCHOOL,  WEXFORD"
    },
    {
        text: "WHITECHURCH NAT SCHOOL,  DUBLIN 16"
    },
    {
        text: "WHITECHURCH N S CEAPACH,  WATERFORD"
    },
    {
        text: "WHITEGATE MIXED N S,  CORK"
    },
    {
        text: "WHITEHALL N S,  ROSCOMMON"
    },
    {
        text: "WICKLOW EDUCATE TOGETHER NS,  WICKLOW"
    },
    {
        text: "WICKLOW EDUCATE TOGETHER SECONDARY SCHOOL,  WICKLOW"
    },
    {
        text: "WILLOW PARK SCHOOL,  DUBLIN COUNTY"
    }
];
const registration_json = {
    title: 'PUMAS Registration Form',
    description:
    //     'Welcome to your profile setup page! Here, you can create your personalised profile by submitting some essential personal information. Setting up a profile allows you to fully engage with our platform and enjoy its benefits. By providing us with the necessary details, we can tailor your experience to meet your specific needs and preferences.',
        'Already have an account? <a href="https://pumas.activepeers.ai/login" target="_blank" rel="noopener"><b style="color: #1bb394;">LOG IN</b></a>',
    logo: '',
    logoHeight: '100px',
    logoFit: 'cover',
    logoPosition: 'right',
    pages: [
        {
            name: 'startPage',
            elements: [
                {
                    type: 'html',
                    name: 'welcomeMsg',
                    html: '<b style="font-size:20px;">Welcome to your profile setup page!</b><br /><br /><p style="font-size:20px;">Here, you can create your personalised profile by submitting some essential personal information. Setting up a profile allows you to fully engage with our platform and enjoy its benefits. By providing us with the necessary details, we can tailor your experience to meet your specific needs and preferences.</p>\n'
                }
            ]
        },
        {
            type: 'panel',
            name: 'personal-account',
            title: 'Account Setup',
            elements: [
                {
                    name: 'emailValidator',
                    type: 'multipletext',
                    title: 'Email',
                    //description: 'Specify a range of numbers',
                    isRequired: true,
                    items: [
                        {
                            name: 'username',
                            title: 'Email',
                            isRequired: true,
                            inputType: 'email',
                            validators: [{ type: 'text' }]
                        },
                        {
                            name: 'reUsername',
                            title: 'Re-type Email',
                            isRequired: true,
                            inputType: 'email',
                            validators: [{ type: 'text' }]
                        }
                    ],
                    validators: [
                        {
                            type: 'expression',
                            expression: '{emailValidator.username} == {emailValidator.reUsername}',
                            text: 'Email and Re-type Email are not the same!'
                        }
                        // {
                        //     type: 'regex',
                        //     regex: "/^[a-zA-Z0-9'_@.+-]+$/",
                        //     text: 'Email may contain only alphanumeric, apostrophe, _, @, +, . and - characters.'
                        // }
                    ]
                },
                {
                    name: 'passwordValidator',
                    type: 'multipletext',
                    title: 'Password',
                    //description: 'Specify a range of numbers',
                    isRequired: true,
                    items: [
                        {
                            name: 'password',
                            title: 'Password',
                            isRequired: true,
                            inputType: 'password',
                            validators: [{ type: 'text' }]
                        },
                        {
                            name: 'rePassword',
                            title: 'Re-type Password',
                            isRequired: true,
                            inputType: 'password',
                            validators: [{ type: 'text' }]
                        }
                    ],
                    validators: [
                        {
                            type: 'expression',
                            expression: '{passwordValidator.password} == {passwordValidator.rePassword}',
                            text: 'Password and Re-type password are not the same!'
                        }
                    ]
                }
            ]
        },
        {
            type: 'panel',
            name: 'personal_info',
            title: 'Personal Information',
            elements: [
                {
                    type: 'text',
                    name: 'first_name',
                    title: 'First name',
                    isRequired: true
                },
                {
                    type: 'text',
                    name: 'last_name',
                    startWithNewLine: false,
                    title: 'Last name',
                    isRequired: true
                },
                {
                    type: 'dropdown',
                    name: 'gender',
                    title: 'Gender',
                    isRequired: true,
                    choices: [
                        {
                            value: 'Male',
                            text: 'Male'
                        },
                        {
                            value: 'Female',
                            text: 'Female'
                        },
                        {
                            value: 'None-binary',
                            text: 'Non-binary'
                        },
                        {
                            value: 'Other',
                            text: 'Other'
                        },
                        {
                            value: 'Prefer not to say',
                            text: 'Prefer not to say'
                        }
                    ]
                },
                {
                    type: 'dropdown',
                    name: 'current_position',
                    title: 'Current Position',
                    description: 'Please choose the most fitting job title to describe your current position (i.e. Student, Chemist, etc.)',
                    itemComponent: 'position-item',
                    // choicesOrder: 'asc',
                    showOtherItem: true,
                    choices: UserService.position_list,
                    isRequired: true
                },
                {
                    type: 'panel',
                    name: 'companyOrSchool_panel',
                    elements: [
                        {
                            name: 'companyOrSchool',
                            type: 'radiogroup',
                            title: 'Company/School',
                            isRequired: true,
                            colCount: 2,
                            choices: ['Company', 'School']
                        },
                        {
                            type: 'dropdown',
                            name: 'company_or_school_2',
                            title: 'School',
                            placeholder: 'Search and Select',
                            description:
                                'Please search and select the name of your current school (i.e. Mayfield Community School, Coachford College, etc.) or search "Other (describe)" option. Please search with at least 5 letters.',
                            showOtherItem: true,
                            otherItem: 'Other',
                            visibleIf: '{companyOrSchool} = "School"',
                            isRequired: true,
                            // choices: school_list,
                            // separateSpecialChoices: true,
                            choicesLazyLoadEnabled: true,
                            choicesLazyLoadPageSize: 10
                        },
                        {
                            type: 'dropdown',
                            name: 'company_or_school_1',
                            title: 'Company',
                            placeholder: 'Search and Select',
                            description:
                                'Please search and select the name of your current workplace (i.e. Pfizer, Google, etc.) or search "Other (describe)" option. Please search with at least 5 letters',
                            showOtherItem: true,
                            visibleIf: '{companyOrSchool} = "Company"',
                            isRequired: true,
                            choicesLazyLoadEnabled: true,
                            choicesLazyLoadPageSize: 40
                        }
                    ]
                },
                {
                    type: 'text',
                    name: 'group',
                    description:
                        'Type the your school year/group name or your workplace department. (i.e. Transition Year, Accounting Dept., etc.)',
                    title: 'Department/Class/Group',
                    isRequired: true
                },
                {
                    type: 'dropdown',
                    name: 'role',
                    title: 'Role',
                    description: 'Select your role in this event.',
                    isRequired: true,
                    choices: [
                        {
                            value: '2',
                            text: 'Stakeholder'
                        },
                        {
                            value: '3',
                            text: 'Participant (i.e. Student, Mentee)'
                        },
                        {
                            value: '4',
                            text: 'Mentor'
                        }
                    ]
                },
                {
                    type: 'text',
                    name: 'dream_job',
                    title: 'Dream Job',
                    visibleIf: '{role} = "3"',
                    isRequired: true
                },
                {
                    type: 'panel',
                    name: 'strongSkills',
                    title: 'Strong Skills',
                    visibleIf: '{role} = "3"',
                    state: 'expanded',
                    elements: [
                        {
                            type: 'matrixdynamic',
                            name: 'strong_skills',
                            title: 'Add strong skills and rate their current levels: <br /><span style ="background-color: yellow">&#9888;</span><a style ="color: red; font-size: 12px">Min. 5 skills</a>',
                            rowCount: 5,
                            minRowCount: 5,
                            columns: [
                                {
                                    name: 'skill',
                                    cellType: 'dropdown',
                                    title: 'Skill',
                                    // choicesOrder: 'asc',
                                    isUnique: true,
                                    choices: UserService.skill_list,
                                    isRequired: true
                                },
                                {
                                    name: 'rate',
                                    cellType: 'dropdown',
                                    title: 'Rate/5',
                                    choices: [
                                        '1 - Basic',
                                        '2 - Intermediate',
                                        '3 - Proficient',
                                        '4 - Advanced',
                                        '5 - Expert'
                                        ///
                                    ],
                                    isRequired: true
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'panel',
                    name: 'interestedSkills',
                    title: 'Skills that I want to learn/improve',
                    visibleIf: '{role} = "3"',
                    state: 'expanded',
                    elements: [
                        {
                            type: 'matrixdynamic',
                            name: 'interest_skills',
                            title: 'Add interested skills and rate their current levels: <br /><span style ="background-color: yellow">&#9888;</span><a style ="color: red; font-size: 12px">Min. 3 skills</a>',
                            rowCount: 3,
                            minRowCount: 3,
                            columns: [
                                {
                                    name: 'skill',
                                    cellType: 'dropdown',
                                    title: 'Skill',
                                    // choicesOrder: 'asc',
                                    isUnique: true,
                                    choices: userService.skill_list,
                                    isRequired: true
                                },
                                {
                                    name: 'rate',
                                    cellType: 'dropdown',
                                    title: 'Rate/5',
                                    choices: [
                                        '1 - Basic',
                                        '2 - Intermediate',
                                        '3 - Proficient',
                                        '4 - Advanced',
                                        '5 - Expert'
                                        ///
                                    ],
                                    isRequired: true
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'panel',
            name: 'extra_info',
            title: 'Extra Information',
            elements: [
                // {
                //     type: 'dropdown',
                //     name: 'cvOrLinkedIn',
                //     title: 'Which one should go to your smart profile?',
                //     isRequired: true,
                //     choices: [
                //         {
                //             value: 'none',
                //             text: 'None'
                //         },
                //         {
                //             value: 'cv',
                //             text: 'CV/Resume'
                //         },
                //         {
                //             value: 'linkedin',
                //             text: 'LinkedIn'
                //         }
                //     ]
                // },
                // {
                //     type: 'text',
                //     visibleIf: '{cvOrLinkedIn} = "linkedin"',
                //     name: 'linkedIn_profile_url',
                //     title: 'LinkedIn Profile URL',
                //     isRequired: true
                // },
                // {
                //     type: 'radiogroup',
                //     visibleIf: '{cvOrLinkedIn} = "linkedin"',
                //     name: 'consent1',
                //     title: 'Do you consent to integrate my LinkedIn profile with PUMAS?',
                //     choices: ['Yes', 'No'],
                //     isRequired: true,
                //     colCount: 0
                // },
                // {
                //     type: 'file',
                //     visibleIf: '{cvOrLinkedIn} = "cv"',
                //     name: 'cvOrResume',
                //     title: 'Upload your resume',
                //     acceptedTypes: 'application/pdf',
                //     isRequired: true
                // },
                // {
                //     type: 'radiogroup',
                //     visibleIf: '{cvOrLinkedIn} = "cv"',
                //     name: 'consent2',
                //     title: 'Do you consent to integrate my resume/CV data with PUMAS?',
                //     choices: ['Yes', 'No'],
                //     isRequired: true,
                //     colCount: 0
                // },
                {
                    type: 'radiogroup',
                    name: 'consent3',
                    title: 'Do you allow us to collect and use your data solely to engage in <a href="https://www.activepeers.ai/pumas-work-process-map" target="_blank" rel="noopener">the PUMAS process</a>?',
                    choices: ['Yes', 'No'],
                    isRequired: true,
                    colCount: 0
                },
                {
                    type: 'radiogroup',
                    name: 'consent4',
                    title: 'Do you allow us to send you emails regarding <a href="https://www.activepeers.ai/pumas-work-process-map" target="_blank" rel="noopener">the PUMAS process</a>?',
                    choices: ['Yes', 'No'],
                    isRequired: true,
                    colCount: 0
                }
            ]
        }
    ],
    showQuestionNumbers: false,
    completedHtml: '<p>Processing...</p>',
    // showCompletedPage: false,
    completeText: 'Submit',
    widthMode: 'static',
    width: '800px'
};
export { registration_json };
