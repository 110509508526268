/* eslint-disable */
import React, { useState, useEffect } from 'react';
import UserService from 'services/user.service';
import {
    Box,
    Button,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow
    //
} from '@mui/material';
import Fab from '@mui/material/Fab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const role = (localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))['role']) || 'guest';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
export const ParticipantList = () => {
    const navigate = useNavigate();
    const { user: currentUser } = useSelector((state) => state.auth);
    const location = useLocation();
    const prevLocation = encodeURIComponent(window.location.origin + location.pathname + location.search);
    // alert(prevLocation);
    if (!currentUser) {
        navigate(`/login?redirectTo=${prevLocation}`);
    }
    const query = useQuery();
    // Get query parameters
    const project = parseInt(query.get('project'));
    const round = parseInt(query.get('round'));
    const userId = parseInt(query.get('userId'));
    if (isNaN(project) || isNaN(round)) {
        navigate('/');
    }
    const handleBackPage = () => {
        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate('/notification');
        }
    };
    const [content, setContent] = useState([]);
    const [errors, setErrors] = useState(null);
    //Get project data
    useEffect(() => {
        UserService.getProjects().then(
            (response) => {
                setContent(JSON.parse(response.data['project_data']));
                if (!JSON.parse(response.data['project_data']).find((p) => p.pk === project)) {
                    navigate('/');
                }
            },
            (error) => {
                const _errors =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                setErrors('FAILED TO GET DATA: ' + _errors);
            }
        );
    }, []);

    const project_data = content.find((p) => p.pk === project);
    const [teammates_data, setTeamMates] = useState(null);
    const [stakeholders_data, setStakeholders] = useState(null);
    //find teammates for current user.
    useEffect(() => {
        if (project_data && round && userId) {
            //find index of current user
            const userIndex = project_data.fields.participants.findIndex((user) => user.id == userId);
            if (userIndex === -1) {
                // Navigate to the root URL ('/')
                navigate('/');
            } else {
                const teammatesArr = [];
                
                let foundUserIndex = false; // Flag to check if userIndex is found
                project_data.fields.grouping_results[round - 1].forEach((item) => {
                    // console.log(item);
                    item.forEach((i) => {
                        if (i === userIndex) {
                            teammatesArr.push(...item.filter((teammate_i) => teammate_i !== i));
                            const teammatesData = teammatesArr.map((teammate_i) => project_data.fields.participants[teammate_i]);
                            const teammates_data_transformed = (teammates_data) => {
                                return teammates_data.map((person) => ({
                                    id: person.id,
                                    name: person.name,
                                    email: person.email,
                                    role: person.role,
                                    gender: person.gender,
                                    current_position: person['current position'],
                                    company_or_school: person['company or school'],
                                    department_or_class: person['department_or_class'],
                                    dateJoined: person.dateJoined,
                                    skill_number_init: person.skills.length,
                                    skills: person.skills.map((skill) => ({
                                        rate: '', // Set the initial rating to an empty string
                                        skill: skill
                                    })),
                                    comments: ''
                                }));
                            };
                            setTeamMates(teammates_data_transformed(teammatesData));
                            // Set the flag to true when userIndex is found
                            foundUserIndex = true;
                        }
                    });
                });
                const stakeholdersArr = project_data.fields.participants.filter((item) => item != null && item.role == 'Stakeholder');
                setStakeholders(stakeholdersArr);
                // Check if userIndex is not found, and handle the case
                if (!foundUserIndex) {
                    console.log('User not found in all rounds.');
                    navigate('/');
                }
            }
        } else {
            // navigate('/');
            console.log('Project/round not found.');
        }
    }, [project_data, round, userId]);
    if (errors) {
        return (
                <Grid item xs={8} sm={12} alignitems="center">
                    <br />
                    <Box sx={{ height: 640, width: '100%' }}>
                        <div style={{color: 'red'}}>{errors}</div>
                    </Box>
                </Grid>
            )
    }
    /// Render page
    if (project_data && teammates_data) {
        return (
            <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                <div>
                    <Fab
                        variant="extended"
                        size="small"
                        color="primary"
                        onClick={() => handleBackPage()}
                        sx={{ mb: 2, mt: 2 }}
                        //
                    >
                        <ArrowBackIcon />
                        Back
                    </Fab>
                    <h5>List of participants:</h5>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No</TableCell>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>School/Company</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teammates_data.map((teammate, teammateIndex) => (
                                <TableRow>
                                    <TableCell>{teammateIndex + 1}</TableCell>
                                    <TableCell>{teammate.id}</TableCell>
                                    <TableCell>{teammate.name}</TableCell>
                                    <TableCell>{teammate.company_or_school != '' ? teammate.company_or_school : ''}</TableCell>
                                    <TableCell>{teammate.role}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            ))}
                            {role == '4' && stakeholders_data.map((stakeholder, stakeholderIndex) => (
                                <TableRow>
                                    <TableCell>{stakeholderIndex + 1}</TableCell>
                                    <TableCell>{stakeholder.id}</TableCell>
                                    <TableCell>{stakeholder.name}</TableCell>
                                    <TableCell>{stakeholder.company_or_school != '' ? stakeholder.company_or_school : ''}</TableCell>
                                    <TableCell>{stakeholder.role}</TableCell>
                                    <TableCell>
                                    <Button
                                        aria-label="view"
                                        size="small"
                                        href={`/view-smart-profile?id=${stakeholder.id}`}
                                        startIcon={<VisibilityIcon fontSize="inherit" />}
                                    >
                                    </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </Box>
        );
    } else {
        return <div>Loading...</div>;
    }
};
