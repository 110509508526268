import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
const API_URL = backend_base_url;

const register = (username, email, password) => {
    return axios.post(API_URL + '/users/create', {
        username,
        email,
        password
    });
};

const login = (username, password) => {
    return axios
        .post(API_URL + '/login_token', {
            username,
            password
        })
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }

            return response.data;
        });
};

const logout = () => {
    localStorage.clear();
    window.location.href = '/login';
};

export default {
    register,
    login,
    logout
};
