import { lazy } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
import { Register } from 'pages/authentication/RegisterUser';
import { RegistrationForm } from 'pages/authentication/RegistrationForm';
import { Register_success } from 'pages/authentication/register_success';
import ResetPassword from 'pages/authentication/ResetPassword';
import EmailVerify from 'pages/authentication/EmailVerification';
import ResetPasswordConfirm from 'pages/authentication/ResetPasswordConfirm';
// ==============================|| AUTH ROUTING ||============================== //

const RedirectToLogin = () => {
    const location = useLocation();
    const prevLocation = encodeURIComponent(window.location.origin + location.pathname + location.search);
    return <Navigate to={`/login?redirectTo=${prevLocation}`} />;
};
const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: 'login',
            element: <AuthLogin />
        },
        {
            path: 'register1',
            element: <Register />
        },
        // {
        //     path: 'register1',
        //     element: <RegistrationForm />
        // },
        {
            path: 'register',
            element: <AuthRegister />
        },
        {
            path: 'register/stakeholder',
            element: <AuthRegister />
        },
        {
            path: 'register/mentor',
            element: <AuthRegister />
        },
        {
            path: 'register/participant',
            element: <AuthRegister />
        },
        {
            path: 'registration_submitted',
            element: <Register_success />
        },
        {
            path: 'forgot-password',
            element: <ResetPassword />
        },
        {
            path: 'password_reset',
            element: <ResetPasswordConfirm />
        },
        {
            path: 'email-verify',
            element: <EmailVerify />
        },
        {
            path: '*',
            element: <RedirectToLogin />
        }
    ]
};

export default LoginRoutes;
