import axios from 'axios';
import authHeader from './auth-header';

if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
const API_URL = backend_base_url;
// Axios instance with authorization header
const api = axios.create({
    baseURL: API_URL,
    headers: authHeader()
});

const getPublicContent = () => {
    return axios.get(API_URL + 'all');
};

const getUserBoard = () => {
    return axios.get(API_URL + '/current_user', { headers: authHeader() });
};

const getModeratorBoard = () => {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
};

const getAdminBoard = () => {
    return axios.get(API_URL + '/all_users', { headers: authHeader() });
};
const getAllUsers = () => {
    return axios.get(API_URL + '/all_users_combined', { headers: authHeader() });
};
const getProjects = () => {
    return axios.get(API_URL + '/all_projects', { headers: authHeader() });
};
const getFeedbacks = () => {
    return axios.get(API_URL + '/users/feedbacks', { headers: authHeader() });
};
const getAllFeedbacks = () => {
    return axios.get(API_URL + '/all_feedbacks', { headers: authHeader() });
};
const getAllMentorFeedbacks = () => {
    return axios.get(API_URL + '/all_mentor_feedbacks', { headers: authHeader() });
};
const getAllFeedbacks_project = (projectId) => {
    return axios.get(API_URL + '/all_feedbacks_project/' + projectId, { headers: authHeader() });
};
const getAllMentorFeedbacks_project = (projectId) => {
    return axios.get(API_URL + '/all_mentor_feedbacks_project/' + projectId, { headers: authHeader() });
};
const getMessages = () => {
    return axios.get(API_URL + '/users/messages', { headers: authHeader() });
};
const getProjectMessages = (projectId) => {
    return axios.get(API_URL + '/project/messages/' + projectId, { headers: authHeader() });
};
const getAllMessages = () => {
    return axios.get(API_URL + '/all_messages', { headers: authHeader() });
};
const get_project_participants = (projectId, round) => {
    return axios.get(API_URL + '/users_view/' + projectId + '/' + round, { headers: authHeader() });
};
const get_project_logs = (projectId) => {
    return axios.get(API_URL + '/project/' + projectId + '/logs/', { headers: authHeader() });
};
const get_project_participants_for_groupchat = (projectId, round) => {
    return axios.get(API_URL + '/users_view_for_groupchat/' + projectId + '/' + round, { headers: authHeader() });
};

const get_project_statistics = (projectId, round) => {
    return axios.get(API_URL + '/get_project_statistics/' + projectId + '/' + round, { headers: authHeader() });
};
const get_a_project = (projectId) => {
    return axios.get(API_URL + '/get_a_project/' + projectId, { headers: authHeader() });
};
const get_email_template = (templateId) => {
    return axios.get(API_URL + '/get_email_template/' + templateId, { headers: authHeader() });
};
const get_email_template_byType = (templateType) => {
    return axios.get(API_URL + '/get_email_template_byType/' + templateType, { headers: authHeader() });
};
const get_email_sent_log = () => {
    return axios.get(API_URL + '/completed-tasks/', { headers: authHeader() });
};
// const get_email_sent_log = () => {
//     return axios.get(API_URL + '/email-logs/', { headers: authHeader() });
// };
// const get_AWS_email_sent_log = () => {
//     return axios.get(API_URL + '/ses/event-webhook/', { headers: authHeader() });
// };
const getAPAIEventData_test = () => {
    return axios.get('http://0.0.0.0:5005/eventAPI', { timeout: 5000 });
};
const getAPAIEventData = () => {
    return axios.get('https://platform.activepeers.ai/eventAPI', { timeout: 5000 });
};
const getAPAIEventData_withId_test = (id) => {
    return axios.get(`http://0.0.0.0:5005/eventAPI_withId/${id}`, { timeout: 5000 });
};
const getAPAIEventData_withId = (id) => {
    return axios.get(`https://platform.activepeers.ai/eventAPI_withId/${id}`, { timeout: 5000 });
};

const get_event_history = (userId) => {
    return axios.get(API_URL + '/event_history/' + userId, { headers: authHeader() });
};
// const profile_view = (userId) => {
//     return axios.get(API_URL + '/profile_view/' + userId, { headers: authHeader() });
// };
const get_company_and_school_list = (filter_string) => {
    return axios.get(API_URL + '/get_company_and_school_list?filter=' + filter_string);
};
const get_skill_data = () => {
    return axios.get(API_URL + '/get_skill_data', { headers: authHeader() });
};
const get_skill_data_0 = (userId) => {
    return axios.get(API_URL + '/get_skill_data?userId=' + userId, { headers: authHeader() });
};
var Airtable = require('airtable');
var airtableBase = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_TOKEN }).base(process.env.REACT_APP_AIRTABLE_BASE_ID);
const getAirtableUserList = () => {
    return new Promise((resolve, reject) => {
        const airtableUserList = [];
        airtableBase(process.env.REACT_APP_AIRTABLE_TABLE_ID)
            .select({ view: 'Grid view' })
            .eachPage(
                function page(records, fetchNextPage) {
                    // Concatenate records to the user list
                    airtableUserList.push(...records);
                    fetchNextPage();
                },
                function done(err) {
                    if (err) {
                        console.error(err);
                        reject(err);
                    } else {
                        // Resolve the promise with the user list
                        resolve(airtableUserList);
                    }
                }
            );
    });
};
const get_airtable_recordId = async (userId) => {
    const userList = await getAirtableUserList();
    const foundRecord = userList.find((record) => {
        const record_id = record.get('ID');
        return record_id == userId;
    });
    if (foundRecord) {
        return foundRecord.id;
    } else {
        console.log('Record not found');
        return null;
    }
};
const rating_list = [
    '1 - Basic',
    '2 - Intermediate',
    '3 - Proficient',
    '4 - Advanced',
    '5 - Expert'
    ///
];

const skill_list = [
    '3D Printing',
    'Acceptance',
    'Account Management',
    'Accounting Softwares (QuickBooks, Xero, NetSuite, etc.)',
    'Active Listening',
    'Adaptability',
    'Administration',
    'Adobe Creative Suite (Illustrator, Photoshop, InDesign, etc.)',
    'Advocacy',
    'Agile',
    'Analytical Thinking',
    'Android Development',
    'Art',
    'Artificial Intelligence',
    'Attention To Detail',
    'Attentive',
    'Back-End Programming Languages (Python, Java, PHP, JavaScript, SQL, Ruby, C#, etc.)',
    'Big Data Analytics Tools (Hadoop, Cloudera, KNIME, etc.)',
    'Blockchain',
    'Bookkeeping',
    'Brainstorming',
    'Budgeting',
    'Business Analysis',
    'Business Ethics',
    'Business Intelligence',
    'Business Management',
    'Business Writing',
    'Change Management',
    'Cloud Computing Platforms (AWS, Azure, GCP, IBM Cloud, OCI)',
    'Coaching & Mentoring',
    'Collaboration',
    'Commercial Awareness',
    'Communication',
    'Compassion',
    'Computer Aided Manufacturing (CAM) Technology',
    'Computer Literacy',
    'Conceptual Thinking',
    'Confidence',
    'Conflict Management',
    'Consulting',
    'Content Management Systems (WordPress, Joomla, Drupal, etc.)',
    'Content Writing',
    'Cooperation',
    'Coordination',
    'Copywriting',
    'Creativity & Innovation',
    'Crisis Management',
    'Critical Thinking',
    'CRM Software (Salesforce, HubSpot, Mailchimp, Pipedrive, etc.)',
    'Cryptography',
    'Customer Service',
    'Data Analysis',
    'Data Analytics And Visualisation (Tableau, Microsoft Power BI, Qlik, etc.)',
    'Data Management',
    'Database System (JSON, MySQL, REST, RPC-db, etc.)',
    'Debating',
    'Decision Making',
    'Delegation',
    'Design Software Applications (AutoCAD, Figma, Sketch)',
    'Digital Skills',
    'Diplomacy',
    'Directing',
    'Disability Awareness',
    'Documenting',
    'Earning Trust',
    'Editing',
    'Email Marketing (ActiveCampaign, Litmus, SendGrid, etc.)',
    'Emotional Intelligence',
    'Empathy',
    'Employee Learning & Development',
    'Encryption Algorithms',
    'Energetic',
    'Engineering',
    'Engineering Math Software (Mathcad, MATLAB, LabVIEW, etc.)',
    'Enthusiastic',
    'Entrepreneurship',
    'Envisioning',
    'ERP Systems (SAP, Oracle, etc.)',
    'Evaluating Objectivity',
    'Fabrication Technology',
    'Finance Management Software (HedgeSphere, SimCorp, etc.)',
    'Financial Analysis',
    'Financial Modeling & Forecasting',
    'First Aid, CPR',
    'Flexibility',
    'Forecasting',
    'Foreign Languages',
    'Framework',
    'Front-End Framework (Bootstrap, Angular, Vue, Sementic UI, etc.)',
    'Front-End Programming Languages (HTML, CSS, JavaScript, React, etc.)',
    'General Management Skills',
    'Goal-Setting',
    'Graphic',
    'Healthcare Related Tools (MediTouch, EpicCare, Cerner, WebPT, etc.)',
    'HR Management Tools (SAP HRMS, Gtmhub, etc.)',
    'Influencing',
    'Information Security',
    'Initiative',
    'Inspirational',
    'Integrity',
    'Intercultural',
    'Interpersonal Skills/Relationship',
    'Inventory Management',
    'iOS App Development',
    'IT',
    'Leadership',
    'Leading Positive Change',
    'Lean Six Sigma',
    'Learning Management Systems (Canvas, Blackboard, EAGLE, etc.)',
    'Line Management',
    'Linguistics',
    'Logical Thinking',
    'Machine Learning',
    'Machining',
    'Managing Ambiguity',
    'Marketing Skills',
    'Marketing Tools (Google Analytics, Google Search Console, AdWords, Facebook Paid Ads, etc.)',
    'Mathematics',
    'Microsoft Dynamics',
    'Microsoft Office Suite (Excel, Word, PowerPoint, etc.)',
    'Multitasking',
    'Natural Language Processing',
    'Navigation',
    'Negotiation',
    'Network Monitoring Software (Wireshark, Datadog, WhatsUp Gold, etc.)',
    'Networking',
    'Observation',
    'Office Administration',
    'OLAP (Online Analytical Processing) Tools (HubSpot, Integrate.io, IBM Congnos, etc.)',
    'Open Source/Cross-Platform Software (Node.js, jQuery, etc.)',
    'Open-Mindedness',
    'Operating Systems (Windows, macOS, Linux, etc.)',
    'Organisation',
    'Persistence',
    'Persuasion',
    'Planning',
    'Presentation',
    'Problem-Solving',
    'Process Improvement',
    'Product Analytics Tools (Mixpanel, Google Analytics, etc.)',
    'Product Design',
    'Project Management',
    'Project Management Software (Trello, Jira, Asana, Zoho, etc.)',
    'Public Speaking ',
    'Quality Control',
    'Reliability',
    'Report Writing',
    'Reporting',
    'Research Skills',
    'Resilience',
    'Resourcefulness',
    'Responsibility',
    'Result-Oriented',
    'Risk Analysis And Management',
    'Robotics',
    'Sales',
    'SAS Business Analytics (SAS BA)',
    'Scrum',
    'Self-Awareness',
    'Self-Motivation',
    'SEO/SEM',
    'Shorthand',
    'Sign Language',
    'Social Media Marketing',
    'Software Development Tools (Git, Jenkins, Docker, etc.)',
    'Software Engineering',
    'Storytelling',
    'Strategic Thinking',
    'Streamlining',
    'Supervisiory Skills',
    'Tax Preparation Software (Lacerte, ProConnect, Drake, etc.)',
    'Teaching',
    'Team/Meeting Facilitation',
    'Teamwork',
    'Technological Savviness',
    'Time Management',
    'Training',
    'Troubleshooting',
    'UI/UX',
    'Video Editing Tools (Adobe Premiere, Adobe Creative Cloud Express, AVS, etc.)',
    'Video Production Software (Adobe Premiere Pro, etc.)',
    'Virus Protection Software (Ping Identity, etc.)',
    'Web Design/Development',
    'Work Ethics'
];

const position_list = [
    'Student',
    // 'Other',
    '3D Artist',
    'Academic Advisor',
    'Academic Tutor',
    'Accompanist',
    'Account Assistant',
    'Account Coordinator',
    'Account Director',
    'Account Executive',
    'Account Manager',
    'Account Officer',
    'Account Representative',
    'Account Specialist',
    'Accountant',
    'Accounting Analyst',
    'Accounting Assistant',
    'Accounting Clerk',
    'Accounting Manager',
    'Accounting Specialist',
    'Accounts Payable Specialist',
    'Accounts Receivable Specialist',
    'Actor',
    'Administration Employee',
    'Administrative Analyst',
    'Administrative Assistant',
    'Administrative Associate',
    'Administrative Coordinator',
    'Administrative Manager',
    'Administrative Officer',
    'Administrative Secretary',
    'Administrative Specialist',
    'Administrative Supervisor',
    'Admissions Counselor',
    'Aircraft Mechanic',
    'Analytics Consultant',
    'Analytics Manager',
    'Analytics Specialist',
    'Android Developer',
    'Animator',
    'Application Analyst',
    'Application Developer',
    'Application Engineer',
    'Application Specialist',
    'Architect',
    'Architectural Designer',
    'Architecture Specialist',
    'Art Director',
    'Art Specialist',
    'Art Teacher',
    'Artist',
    'Assistant to the Manager',
    'Athlete',
    'Athletic Trainer',
    'Attorney',
    'Audio Engineer',
    'Audio Visual Technician',
    'Audit Associate',
    'Audit Manager',
    'Audit',
    'Auditor',
    'Automation Engineer',
    'Automotive Technician',
    'Babysitter',
    'Back Office Employee',
    'Baker',
    'Bank Teller',
    'Banker',
    'Bar Manager',
    'Bar Staff',
    'Barista',
    'Bartender',
    'Basketball Coach',
    'Beauty Advisor',
    'Behavioral Health Technician',
    'Behavioral Therapist',
    'Benefits Specialist',
    'Billing Specialist',
    'Blogger',
    'Board Member',
    'Book Author',
    'Bookkeeper',
    'Brand Manager',
    'Brand Representative',
    'Brand Specialist',
    'Business Account Executive',
    'Business Administrator',
    'Business Analyst',
    'Business Consultant',
    'Business Development Consultant',
    'Business Development Executive',
    'Business Development Manager',
    'Business Development Representative',
    'Business Development Specialist',
    'Business Intelligence Analyst',
    'Business Intelligence Consultant',
    'Business Intelligence Developer',
    'Business Manager',
    'Business Operations Manager',
    'Business Partner',
    'Business Specialist',
    'Business System Analyst',
    'Busser',
    'Buyer',
    'Caddie',
    'Call Center Representative',
    'Camp Counselor',
    'Campus Ambassador',
    'Care Coordinator',
    'Care Manager',
    'Care Specialist',
    'Career Coach',
    'Career Specialist',
    'Carpenter',
    'Case Management Nurse',
    'Case Manager',
    'Cashier',
    'Category Manager',
    'Certified Nursing Assistant',
    'Chef',
    'Chemist',
    'Chief Executive Officer',
    'Chief Financial Officer',
    'Chief Information Officer',
    'Chief Marketing Officer',
    'Chief Of Staff',
    'Chief Operating Officer',
    'Chief Technology Officer',
    'Child Care Specialist',
    'Childcare Provider',
    'Civil Engineer',
    'Civil Engineering Professional',
    'Civil Servant',
    'Claims Adjuster',
    'Claims Specialist',
    'Client Advisor',
    'Client Manager',
    'Client Services Manager',
    'Client Services Representative',
    'Clinical Coordinator',
    'Clinical Director',
    'Clinical Fellow',
    'Clinical Instructor',
    'Clinical Manager',
    'Clinical Pharmacist',
    'Clinical Psychologist',
    'Clinical Research Associate',
    'Clinical Research Coordinator',
    'Clinical Social Worker',
    'Clinical Specialist',
    'Clinical Supervisor',
    'Clinician',
    'Co-Founder',
    'Co-Owner',
    'Collections Specialist',
    'College Teacher',
    'Commander',
    'Commerce Specialist',
    'Commercial Advisor',
    'Commercial Agent',
    'Commercial Analyst',
    'Commercial Assistant',
    'Commercial Attache',
    'Commercial Coordinator',
    'Commercial Director',
    'Commercial Manager',
    'Commercial Specialist',
    'Commercial Supervisor',
    'Commissioned Officer',
    'Communications Analyst',
    'Communications Assistant',
    'Communications Consultant',
    'Communications Coordinator',
    'Communications Manager',
    'Communications Officer',
    'Communications Specialist',
    'Community Assistant',
    'Community Coordinator',
    'Community Manager',
    'Community Specialist',
    'Company Secretary',
    'Compliance Analyst',
    'Compliance Manager',
    'Compliance Officer',
    'Compliance Specialist',
    'Computer Numerical Control Machinist',
    'Computer Operator',
    'Computer Technician',
    'Concierge',
    'Construction Manager',
    'Construction Project Manager',
    'Construction Specialist',
    'Construction Superintendent',
    'Construction Worker',
    'Consulting Specialist',
    'Content Creator',
    'Content Manager',
    'Content Producer',
    'Content Specialist',
    'Content Strategist',
    'Content Writer',
    'Contract Administrator',
    'Contract Manager',
    'Contract Specialist',
    'Control Engineer',
    'Cook',
    'Copyeditor',
    'Copywriter',
    'Corporate Specialist',
    'Correctional Officer',
    'Cosmetologist',
    'Courier',
    'Creative Director',
    'Creative Specialist',
    'Credit Analyst',
    'Credit Manager',
    'Customer Advisor',
    'Customer Assistant',
    'Customer Associate',
    'Customer Care Professional',
    'Customer Representative',
    'Customer Service Advisor',
    'Customer Service Analyst',
    'Customer Service Assistant',
    'Customer Service Manager',
    'Customer Service Officer',
    'Customer Service Representative',
    'Customer Service Specialist',
    'Customer Service Supervisor',
    'Customer Specialist',
    'Customer Success Manager',
    'Customer Support Specialist',
    'Cyber Security Analyst',
    'Cyber Security Specialist',
    'Dance Instructor',
    'Data Analyst',
    'Data Engineer',
    'Data Entry Clerk',
    'Data Entry Specialist',
    'Data Manager',
    'Data Scientist',
    'Data Specialist',
    'Database Administrator',
    'Deli Clerk',
    'Delivery Driver',
    'Delivery Manager',
    'Delivery Specialist',
    'Dental Assistant',
    'Dental Hygienist',
    'Dentist',
    'Design Consultant',
    'Design Director',
    'Design Engineer',
    'Design Manager',
    'Design Specialist',
    'Development Analyst',
    'Development Engineer',
    'Development Specialist',
    'DevOps Engineer',
    'Dietitian',
    'Digital Marketing Consultant',
    'Digital Marketing Executive',
    'Digital Marketing Manager',
    'Digital Marketing Specialist',
    'Digital Project Manager',
    'Digital Specialist',
    'Direct Support Professional',
    'Direction Assistant',
    'Director Of Administration',
    'Director Of Admissions',
    'Director Of Analytics',
    'Director Of Business Development',
    'Director Of Communications',
    'Director Of Development',
    'Director Of Education',
    'Director Of Engineering',
    'Director Of Information Technology',
    'Director Of Nursing',
    'Director Of Operations',
    'Director of Product Management',
    'Director Of Services',
    'Director of Software Engineering',
    'Director Of Technology',
    'Disk Jockey',
    'Distributor',
    'Dotnet Developer',
    'Driver',
    'Economist',
    'Editor',
    'Education Coordinator',
    'Education Instructor',
    'Education Professional',
    'Educational Consultant',
    'Electrical Engineer',
    'Electrical Technician',
    'Electrician',
    'Electronic Engineer',
    'Electronic Technician',
    'Elementary School Teacher',
    'Embedded Software Engineer',
    'Emergency Medical Technician',
    'Emergency Room Nurse',
    'Engagement Director',
    'Engagement Manager',
    'Engineering Manager',
    'Engineering Specialist',
    'Engineering Technician',
    'English Second Language Teacher',
    'English Teacher',
    'Enterprise Account Executive',
    'Entertainer',
    'Entrepreneur',
    'Environment, Health and Safety Manager',
    'Environmental Specialist',
    'Estate Agent',
    'Esthetician',
    'Event Coordinator',
    'Event Manager',
    'Event Specialist',
    'Executive Assistant To Chief Executive Officer',
    'Executive Assistant',
    'Facilities Manager',
    'Family Nurse Practitioner',
    'Farm Hand',
    'Fashion Designer',
    'Fashion Stylist',
    'Field Engineer',
    'Field Services Engineer',
    'Field Specialist',
    'Filmmaker',
    'Finance Executive',
    'Finance Manager',
    'Finance Officer',
    'Finance Specialist',
    'Financial Accountant',
    'Financial Administrative Assistant',
    'Financial Advisor',
    'Financial Analyst',
    'Financial Assistant',
    'Financial Consultant',
    'Financial Controller',
    'Financial Director',
    'Financial Professional',
    'Financial Representative',
    'Financial Services Manager',
    'Financial Services Professional',
    'Financial Services Representative',
    'Firefighter',
    'Fiscal Analyst',
    'Fiscal Assistant',
    'Fitness Instructor',
    'Flight Attendant',
    'Floor Manager',
    'Floor Supervisor',
    'Food And Beverage Assistant',
    'Food Server',
    'Food Service Worker',
    'Food Specialist',
    'Foreign Trade Manager',
    'Foreign Trade Specialist',
    'Foreperson',
    'Forklift Operator',
    'Founder',
    'Front Desk Representative',
    'Front Desk Staff',
    'Front House',
    'Frontend Developer',
    'Full Stack Engineer',
    'Functional Consultant',
    'General Counsel',
    'General Manager',
    'General',
    'Geologist',
    'Golfer',
    'Graphic Artist',
    'Graphic Design Specialist',
    'Graphic Designer',
    'Graphic Engineer',
    'Guest Services Associate',
    'Hairstylist',
    'Head Of Finance',
    'Head Of Human Resources',
    'Head Of Information Technology',
    'Head Of Marketing',
    'Head Of Operations',
    'Head of Product',
    'Head Of Sales',
    'Headmaster',
    'Health And Wellness Coach',
    'Health Coach',
    'Healthcare Assistant',
    'Healthcare Recruiter',
    'Healthcare Specialist',
    'Heavy Equipment Operator',
    'Help Desk Technician',
    'Home Care Provider',
    'Home Health Aide',
    'Host',
    'Hostess',
    'Housekeeper',
    'Human Resources Administrator',
    'Human Resources Advisor',
    'Human Resources Analyst',
    'Human Resources Assistant',
    'Human Resources Business Partner',
    'Human Resources Consultant',
    'Human Resources Coordinator',
    'Human Resources Director',
    'Human Resources Executive',
    'Human Resources Generalist',
    'Human Resources Manager',
    'Human Resources Officer',
    'Human Resources Specialist',
    'Human Resources Supervisor',
    'Illustrator',
    'Implementation Consultant',
    'Implementation Specialist',
    'Industrial Engineer',
    'Information Security Analyst',
    'Information Specialist',
    'Information Technology Administrator',
    'Information Technology Analyst',
    'Information Technology Consultant',
    'Information Technology Coordinator',
    'Information Technology Engineer',
    'Information Technology Executive',
    'Information Technology Manager',
    'Information Technology Project Manager',
    'Information Technology Recruiter',
    'Information Technology Specialist',
    'Information Technology Supervisor',
    'Information Technology Support Specialist',
    'Information Technology Support Technician',
    'Information Technology Technician',
    'Infrastructure Analyst',
    'Inside Sales Representative',
    'Inside Sales Specialist',
    'Installer',
    'Instructional Assistant',
    'Instructional Designer',
    'Insurance Advisor',
    'Insurance Agent',
    'Insurance Specialist',
    'Intelligence Analyst',
    'Intensive Care Nurse',
    'Interface Specialist',
    'Interior Designer',
    'Internal Auditor',
    'International Specialist',
    'Interpreter',
    'Interventionist',
    'Inventory Manager',
    'Inventory Specialist',
    'Investment Advisor',
    'Investment Analyst',
    'Investment Manager',
    'Investment Specialist',
    'iOS Developer',
    'Java Software Engineer',
    'Journalist',
    'Key Account Manager',
    'Keyholder',
    'Kitchen Manager',
    'Kitchen Staff',
    'Laboratory Assistant',
    'Laboratory Manager',
    'Laboratory Specialist',
    'Laboratory Technician',
    'Landscaper',
    'Language Teacher',
    'Law Clerk',
    'Lawyer',
    'Learning Specialist',
    'Leasing Consultant',
    'Leasing Specialist',
    'Lecturer',
    'Legal Advisor',
    'Legal Assistant',
    'Legal Counsel',
    'Legal Manager',
    'Legal Officer',
    'Legal Specialist',
    'Librarian',
    'Library Assistant',
    'Licensed Practical Nurse',
    'Lieutenant',
    'Life Coach',
    'Lifeguard',
    'Litigation Paralegal',
    'Loan Clerk',
    'Loan Officer',
    'Logistics Analyst',
    'Logistics Assistant',
    'Logistics Coordinator',
    'Logistics Manager',
    'Logistics Specialist',
    'Machine Operator',
    'Machinist',
    'Maintenance Engineer',
    'Maintenance Manager',
    'Maintenance Mechanic',
    'Maintenance Specialist',
    'Maintenance Supervisor',
    'Maintenance Technician',
    'Makeup Artist',
    'Management Accountant',
    'Management Analyst',
    'Management Consultant',
    'Management Information System Executive',
    'Manager of Sales',
    'Managerial Assistant',
    'Managing Director',
    'Managing Partner',
    'Manufacturing Engineer',
    'Manufacturing Specialist',
    'Manufacturing Technician',
    'Marine',
    'Market Manager',
    'Market Specialist',
    'Marketer',
    'Marketing Analyst',
    'Marketing Assistant',
    'Marketing Associate',
    'Marketing Communications Manager',
    'Marketing Communications Specialist',
    'Marketing Consultant',
    'Marketing Coordinator',
    'Marketing Director',
    'Marketing Executive',
    'Marketing Manager',
    'Marketing Officer',
    'Marketing Representative',
    'Marketing Specialist',
    'Marketing Strategist',
    'Massage Therapist',
    'Masters Student',
    'Material Handler',
    'Mathematics Teacher',
    'Mathematics Tutor',
    'Mechanic',
    'Mechanical Design Engineer',
    'Mechanical Designer',
    'Mechanical Engineer',
    'Mechanical Engineering Specialist',
    'Mechanical Technician',
    'Media Specialist',
    'Medical Assistant',
    'Medical Biller',
    'Medical Coder',
    'Medical Director',
    'Medical Doctor',
    'Medical Receptionist',
    'Medical Sales Representative',
    'Medical Scribe',
    'Medical Specialist',
    'Medical Student',
    'Member Of The Board Of Advisors',
    'Member Service Representative',
    'Member Services Specialist',
    'Mental Health Counselor',
    'Mental Health Specialist',
    'Mental Health Therapist',
    'Merchandise Manager',
    'Merchandiser',
    'Middle School Teacher',
    'Mission Head',
    'Mobile Engineer',
    'Mortgage Loan Officer',
    'Mortgage Loan Originator',
    'Music Director',
    'Music Producer',
    'Music Teacher',
    'Musician',
    'Nanny',
    'Network Administrator',
    'Network Engineer',
    'Network Specialist',
    'Notary Public',
    'Nurse Practitioner',
    'Nurse',
    'Nursing Manager',
    'Nursing Student',
    'Nutritionist',
    'Occupational Therapist',
    'Office Administrator',
    'Office Assistant',
    'Office Associate',
    'Office Coordinator',
    'Office Manager',
    'Online Marketing Manager',
    'Operational Specialist',
    'Operations Analyst',
    'Operations Assistant',
    'Operations Associate',
    'Operations Coordinator',
    'Operations Engineer',
    'Operations Executive',
    'Operations Manager',
    'Operations Officer',
    'Operations Supervisor',
    'Optician',
    'Oracle Database Administrator',
    'Outreach Coordinator',
    'Outreach Specialist',
    'Outside Sales Representative',
    'Owner',
    'Package Handler',
    'Painter',
    'Paralegal',
    'Paramedic',
    'Pastor',
    'Patient Access Representative',
    'Patient Care Assistant',
    'Patient Care Coordinator',
    'Patient Care Technician',
    'Patient Service Representative',
    'Payroll Specialist',
    'Personal Assistant',
    'Personal Banker',
    'Personal Care Assistant',
    'Personal Trainer',
    'Petty Officer',
    'Pharmacist',
    'Pharmacy Manager',
    'Pharmacy Specialist',
    'Pharmacy Technician',
    'Phlebotomist',
    'Photographer',
    'PHP Developer',
    'Physical Education Teacher',
    'Physical Therapist Assistant',
    'Physical Therapist',
    'Physician Assistant',
    'Physiotherapist',
    'Pilot',
    'Placement Coordinator',
    'Planning Analyst',
    'Planning Engineer',
    'Planning Manager',
    'Planning Specialist',
    'Plant Manager',
    'Plumber',
    'Police Officer',
    'Portfolio Manager',
    'Postdoctoral Researcher',
    'Preschool Teacher',
    'President',
    'Process Analyst',
    'Process Associate',
    'Process Engineer',
    'Process Executive',
    'Process Manager',
    'Process Specialist',
    'Procurement Manager',
    'Procurement Specialist',
    'Product Analyst',
    'Product Designer',
    'Product Engineer',
    'Product Manager',
    'Product Marketing Manager',
    'Product Owner',
    'Product Specialist',
    'Production Assistant',
    'Production Associate',
    'Production Coordinator',
    'Production Engineer',
    'Production Manager',
    'Production Operator',
    'Production Planner',
    'Production Specialist',
    'Production Supervisor',
    'Professor',
    'Program Assistant',
    'Program Associate',
    'Program Coordinator',
    'Program Manager',
    'Program Specialist',
    'Programming Analyst',
    'Project Associate',
    'Project Control Manager',
    'Project Engineer',
    'Project Management Officer',
    'Project Management Specialist',
    'Project Manager',
    'Project Specialist',
    'Promoter',
    'Property Manager',
    'Psychologist',
    'Psychotherapist',
    'Public Accountant',
    'Public Relations Manager',
    'Public Relations Specialist',
    'Publisher',
    'Purchase Specialist',
    'Purchasing Manager',
    'Quality Analyst',
    'Quality Assurance Analyst',
    'Quality Assurance Automation Engineer',
    'Quality Assurance Engineer',
    'Quality Assurance Manager',
    'Quality Assurance Specialist',
    'Quality Engineer',
    'Quality Specialist',
    'Quantity Surveyor',
    'Radiologic Technologist',
    'Real Estate Agent',
    'Real Estate Broker',
    'Real Estate Consultant',
    'Real Estate Investor',
    'Real Estate Salesperson',
    'Real Estate Specialist',
    'Receptionist',
    'Recreation Specialist',
    'Recruiter',
    'Recruitment Consultant',
    'Recruitment Coordinator',
    'Recruitment Manager',
    'Recruitment Specialist',
    'Registered Nurse',
    'Relationship Banker',
    'Relationship Manager',
    'Reporter',
    'Reporting Manager',
    'Research Analyst',
    'Research And Development Engineer',
    'Research And Development Manager',
    'Research And Development Specialist',
    'Research Assistant',
    'Research Engineer',
    'Research Technician',
    'Respiratory Therapist',
    'Restaurant Manager',
    'Retail Assistant',
    'Retail Associate',
    'Retail Manager',
    'Retail Salesperson',
    'Retail Specialist',
    'Risk Analyst',
    'Round Employee',
    'Safety Manager',
    'Safety Officer',
    'Safety Specialist',
    'Sales Account Manager',
    'Sales Administrator',
    'Sales Advisor',
    'Sales Agent',
    'Sales Analyst',
    'Sales Assistant',
    'Sales Consultant',
    'Sales Coordinator',
    'Sales Development Representative',
    'Sales Director',
    'Sales Engineer',
    'Sales Executive',
    'Sales Manager',
    'Sales Officer',
    'Sales Promoter',
    'Sales Specialist',
    'Sales Supervisor',
    'Sales Support Specialist',
    'Sales Team Lead',
    'Salesforce Administrator',
    'Salesperson',
    'SAP Consultant',
    'SAP Specialist',
    'School Counselor',
    'Science Teacher',
    'Scientific Officer',
    'Scrum Master',
    'Search Consultant',
    'Search Engine Optimization Analyst',
    'Search Engine Optimization Executive',
    'Search Engine Optimization Specialist',
    'Secretarial Assistant',
    'Secretary',
    'Security Analyst',
    'Security Engineer',
    'Security Guard',
    'Security Manager',
    'Security Officer',
    'Security Professional',
    'Security Supervisor',
    'Security Technician',
    'Sergeant',
    'Server',
    'Service Advisor',
    'Service Assistant',
    'Service Associate',
    'Service Coordinator',
    'Service Engineer',
    'Service Manager',
    'Service Project Manager',
    'Service Representative',
    'Service Specialist',
    'Service Supervisor',
    'Service Technician',
    'Sheriff',
    'Shop Manager',
    'Singer',
    'Site Engineer',
    'Soccer Coach',
    'Social Media Coordinator',
    'Social Media Director',
    'Social Media Manager',
    'Social Media Marketing Manager',
    'Social Media Marketing Specialist',
    'Social Work Specialist',
    'Social Worker',
    'Software Analyst',
    'Software Architect',
    'Software Engineer',
    'Software Engineering Manager',
    'Software Quality Assurance Engineer',
    'Software Specialist',
    'Software Test Engineer',
    'Software Tester',
    'Soldier',
    'Solicitor',
    'Solutions Architect',
    'Solutions Consultant',
    'Solutions Engineer',
    'Solutions Specialist',
    'Spanish Teacher',
    'Special Education Paraeducator',
    'Special Education Teacher',
    'Speech Language Pathologist',
    'Sports Specialist',
    'Stay-at-Home Parent',
    'Stock Clerk',
    'Store Associate',
    'Store Manager',
    'Storekeeper',
    'Strategy Consultant',
    'Strategy Manager',
    'Structural Engineer',
    'Student Manager',
    'Student Representative',
    'Supply Chain Analyst',
    'Supply Chain Manager',
    'Supply Chain Specialist',
    'Support Analyst',
    'Support Associate',
    'Support Engineer',
    'Support Manager',
    'Support Officer',
    'Support Specialist',
    'Support Technician',
    'Surgical Technologist',
    'Swim Instructor',
    'System Administrator',
    'System Analyst',
    'System Developer',
    'System Engineer',
    'System Manager',
    'System Specialist',
    'System Technician',
    'Talent Acquisition Manager',
    'Talent Acquisition Specialist',
    'Tax Accountant',
    'Tax Analyst',
    'Tax Associate',
    'Tax Consultant',
    'Tax Examiner',
    'Tax Preparer',
    'Tax Specialist',
    'Teacher',
    'Teaching Assistant',
    'Teaching Specialist',
    'Technical Advisor',
    'Technical Analyst',
    'Technical Architect',
    'Technical Assistant',
    'Technical Clerk',
    'Technical Consultant',
    'Technical Director',
    'Technical Head',
    'Technical Manager',
    'Technical Officer',
    'Technical Program Manager',
    'Technical Project Manager',
    'Technical Recruiter',
    'Technical Sales Representative',
    'Technical Sales Specialist',
    'Technical Specialist',
    'Technical Support Analyst',
    'Technical Support Engineer',
    'Technical Support Specialist',
    'Technical Team Lead',
    'Technical Writer',
    'Technology Analyst',
    'Technology Specialist',
    'Teller',
    'Territory Sales Manager',
    'Test Analyst',
    'Test Engineer',
    'Test Specialist',
    'Tour Guide',
    'Trading Specialist',
    'Training Coordinator',
    'Training Manager',
    'Training Specialist',
    'Translator',
    'Transportation Specialist',
    'Travel Consultant',
    'Treasurer',
    'Truck Driver',
    'Underwriter',
    'User Experience Designer',
    'User Interface Designer',
    'User Interface Engineer',
    'Veterinarian',
    'Veterinary Assistant',
    'Veterinary Technician',
    'Vice President Finance',
    'Vice President Human Resources',
    'Vice President Marketing',
    'Vice President Of Business Development',
    'Vice President of Sales',
    'Vice President Operations',
    'Video Editor',
    'Video Producer',
    'Videographer',
    'Visual Designer',
    'Visual Merchandiser',
    'Voice Over Artist',
    'Volleyball Coach',
    'Warehouse Associate',
    'Warehouse Manager',
    'Warehouse Specialist',
    'Warehouse Supervisor',
    'Wealth Management Advisor',
    'Web Designer',
    'Web Developer',
    'Webmaster',
    'Welder',
    'Writer',
    'Yoga Instructor',
    'Youth Specialist'
];
const company_or_school_list = [
    'Carrigaline Community School',
    'Christ King Secondary School',
    'Coachford College',
    'Coláiste Muire Crosshaven',
    'Colaiste Treasa',
    'Gaelcholáiste Charraig Uí Leighin',
    'Kinsale Community School',
    'Mayfield Community School',
    'Scoil Mhuire Gan Smal',
    'Scoil Phobail Bhéara',
    'Scoil Phobail Sliabh Luachra',
    "St Brogan's College, Bandon, Co. Cork",
    'Alcon',
    'Boston Scientific',
    'DePuy Synthes',
    'Eli Lilly',
    'Gilead',
    'Hovine',
    'Janssen',
    'PepsiCo',
    'Pfizer',
    'Sterling',
    'Thermo Fisher'
];
// Company management (CRUD operations)
const getCompanies = async (page = 1, searchTerm = '') => {
    const response = await api.get(`/companies/?page=${page}&search=${searchTerm}`);
    return response.data;
};

const addCompany = async (companyData) => {
    const response = await api.post('/companies/', companyData);
    return response.data;
};

const editCompany = async (companyId, companyData) => {
    const response = await api.put(`/companies/${companyId}/`, companyData);
    return response.data;
};
const deleteCompany = async (companyId) => {
    await api.delete(`/companies/${companyId}/`);
};
// School management (CRUD operations)
const getSchools = async (page = 1, searchTerm = '') => {
    const response = await api.get(`/schools/?page=${page}&search=${searchTerm}`);
    return response.data;
};

const addSchool = async (schoolData) => {
    const response = await api.post('/schools/', schoolData);
    return response.data;
};

const editSchool = async (schoolId, schoolData) => {
    const response = await api.put(`/schools/${schoolId}/`, schoolData);
    return response.data;
};
const deleteSchool = async (schoolId) => {
    await api.delete(`/schools/${schoolId}/`);
};

const userService = {
    rating_list,
    skill_list,
    position_list,
    company_or_school_list,
    airtableBase,
    // profile_view,
    get_a_project,
    get_email_template,
    get_email_template_byType,
    get_email_sent_log,
    // get_AWS_email_sent_log,
    get_event_history,
    get_skill_data,
    get_skill_data_0,
    getAirtableUserList,
    get_airtable_recordId,
    get_company_and_school_list,
    getProjects,
    getFeedbacks,
    getAllFeedbacks,
    getAllMentorFeedbacks,
    getAllFeedbacks_project,
    getAllMentorFeedbacks_project,
    getMessages,
    getProjectMessages,
    getAllMessages,
    getPublicContent,
    getUserBoard,
    getModeratorBoard,
    getAllUsers,
    getAdminBoard,
    getAPAIEventData,
    getAPAIEventData_withId,
    getAPAIEventData_withId_test,
    getAPAIEventData_test,
    get_project_participants,
    get_project_logs,
    get_project_participants_for_groupchat,
    get_project_statistics,
    getCompanies,
    addCompany,
    editCompany,
    deleteCompany,
    getSchools,
    addSchool,
    editSchool,
    deleteSchool
};

export default userService;
