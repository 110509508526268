/* eslint-disable */
import React, { useState, useEffect } from 'react';

import {
    Box,
    Button,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import GrapeJsEmailEdiorPage_default from 'pages/grapejs-newsletter-editor/EditorView_default';

function TabPanel(props) {
    const { children, value, index, isFullScreen, handleFullScreenToggle, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        p: 3,
                        width: isFullScreen ? '100vw' : '100%',
                        height: isFullScreen ? '100vh' : 'auto',
                        position: isFullScreen ? 'fixed' : 'relative',
                        top: 0,
                        left: 0,
                        zIndex: isFullScreen ? 1300 : 'auto',
                        backgroundColor: isFullScreen ? 'white' : 'transparent',
                        padding: isFullScreen ? '20px' : '0',
                        overflowY: isFullScreen ? 'auto' : 'visible',
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={handleFullScreenToggle}
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                    >
                        {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
                    </Button>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`
    };
}
export default function Email_Template() {
    const [value, setValue] = React.useState(1);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleFullScreenToggle = () => {
        setIsFullScreen(!isFullScreen);
    };

    return (
        <div style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ccc' }}>
            <h5>Email Template</h5>
            <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 524, overflow: 'auto' }}
                className="MuiBox-root"
            >
                
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    <Typography variant="h6" style={{ textDecoration: 'underline' }}><strong>Meeting Details</strong></Typography>
                    <Tab label="Participants" {...a11yProps(1)} />
                    <Tab label="Mentors" {...a11yProps(2)} />
                    <Tab label="Stakeholders" {...a11yProps(3)} />
                    <Typography variant="h6" style={{ textDecoration: 'underline' }}><strong>Feedback Email</strong></Typography>
                    <Tab label="Participants and Mentors" {...a11yProps(5)} />
                    <Tab label="Stakeholders" {...a11yProps(6)} />
                </Tabs>
                <TabPanel value={value} index={0}>Email Template</TabPanel>
                <TabPanel value={value} index={1} isFullScreen={isFullScreen} handleFullScreenToggle={handleFullScreenToggle}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <GrapeJsEmailEdiorPage_default defaultTemplateId={11} />
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2} isFullScreen={isFullScreen} handleFullScreenToggle={handleFullScreenToggle}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <GrapeJsEmailEdiorPage_default defaultTemplateId={12} />
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={3} isFullScreen={isFullScreen} handleFullScreenToggle={handleFullScreenToggle}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                            <GrapeJsEmailEdiorPage_default defaultTemplateId={13} />
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={5} isFullScreen={isFullScreen} handleFullScreenToggle={handleFullScreenToggle}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                            <GrapeJsEmailEdiorPage_default defaultTemplateId={20} />
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={6} isFullScreen={isFullScreen} handleFullScreenToggle={handleFullScreenToggle}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                            <GrapeJsEmailEdiorPage_default defaultTemplateId={23} />
                    </Box>
                </TabPanel>
            </Box>
        </div>
    );
}
