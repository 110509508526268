import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
//var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001';
if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
export const Logout = () => {
    const token = localStorage.getItem('access_token');
    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.post(
                    `${backend_base_url}/logout/`,
                    { refresh_token: localStorage.getItem('refresh_token') },
                    // { headers: { 'Content-Type': 'application/json' } },
                    { headers: { Authorization: `Bearer ${token}` } },
                    { withCredentials: true }
                );
                localStorage.clear();
                // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                navigate('/login');
            } catch (e) {
                console.log('logout failed: ', e);
            }
        })();
    }, [token]);
    return <div></div>;
};
