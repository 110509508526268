/* eslint-disable */
import {
    Body,
    Button,
    Container,
    Head,
    Heading,
    Hr,
    Html,
    Img,
    Link,
    Preview,
    Section,
    Column,
    Row,
    Text
    ///
} from '@react-email/components';
import * as React from 'react';

export const StakeholderNoti_MD = () => (
    <Html>
        <Head />
        <Body style={main}>
            <Container style={container}>
                <Section style={box}>
                    <Heading style={heading}>PUMAS</Heading>
                    <Hr style={hr} />
                    <Text style={paragraph}>
                        Dear <strong>[Participant Name]</strong>,
                    </Text>
                    <Text style={paragraph}>You’ve got new updates on your project. The team details on the event called <strong>[Event]</strong>, <strong>[Round Number]</strong> have been shared with the participants. Please check it out on PUMAS now.</Text>
                    <br />
                    <Button
                        pX={10}
                        pY={10}
                        style={button}
                        href="https://pumas.activepeers.ai/team-building-history"
                        //
                    >
                        PUMAS projects
                    </Button>
                    <Text style={paragraph}>Regards,</Text>
                    <Text style={paragraph}>The PUMAS team at Active Peers AI</Text>
                    <Hr style={hr} />
                    <div style={redBox}>
                        <p style={redBoxParagraph}>
                            Please do not reply to this email directly. <br/>
                            For any assistance, please fill out this form <a href="https://www.activepeers.ai/support-centre" style={{ color: "red" }}>here</a>.
                        </p>
                    </div>
                    <Text style={footer}>Hayes Culleton Group, Greenway Hub, TU Dublin, Grangegorman Lower, Dublin 7, Ireland</Text>
                </Section>
            </Container>
        </Body>
    </Html>
);

export default StakeholderNoti_MD;

const main = {
    backgroundColor: '#f6f9fc',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif'
};

const container = {
    backgroundColor: '#ffffff',
    margin: '0 auto',
    padding: '20px 0 48px',
    marginBottom: '64px'
};

const box = {
    padding: '0 48px'
};

const hr = {
    borderColor: '#16a085',
    margin: '20px 0'
};

const paragraph = {
    color: '#525f7f',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left'
};
const paragraph1 = {
    color: '#525f7f',
    fontSize: '16px',
    lineHeight: '24px'
    // textAlign: 'left'
};

const button = {
    backgroundColor: '#16a085',
    borderRadius: '5px',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    textDecoration: 'none',
    textAlign: 'center',
    display: 'block',
    width: '100%'
};

const footer = {
    color: '#8898aa',
    fontSize: '12px',
    lineHeight: '16px'
};
const heading = {
    fontSize: '24px',
    letterSpacing: '-0.5px',
    lineHeight: '1.3',
    fontWeight: '400',
    color: '#16a085',
    padding: '17px 0 0'
};
const redBox = {
    border: '1px solid red',
    margin: '10px auto',
    padding: '5px',
    display: 'block',
    textAlign: 'center',
    width: '80%',
    boxSizing: 'border-box'
};

const redBoxParagraph = {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#8898aa',
    margin: '0'
};