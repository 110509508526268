/* eslint-disable */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import React from 'react';
import UserService from 'services/user.service';
import MillisecondsToDate from 'pages/group-matching/dateformat.js';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// material-ui
import {
    Avatar,
    Box,
    Button,
    Grid,
    CircularProgress,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Link,
    Modal,
    Badge,
    Tooltip as MuiTooltip
} from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import IconButton from '@mui/material/IconButton';
// project import
import MainCard from 'components/MainCard';
import avatar1 from 'assets/images/users/avatar-1.png';

// ==============================|| PROFILE PAGE ||============================== //
const role = (localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))['role']) || 'guest';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

const ProfilePageView = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    if (!currentUser) {
        navigate('/login');
    }
    if (role == 3) {
        alert('Permission required!');
        navigate('/smart-profile');
    }
    const query = useQuery();
    // Get query parameters
    const userId = parseInt(query.get('id'));
    if (isNaN(userId)) {
        navigate('/all-users');
    }
    const [userData, setUser] = useState(null);
    const [profileData, setProfile] = useState(null);
    const [errors, setErrors] = useState('');
    useEffect(() => {
        UserService.getAllUsers().then(
            (response) => {
                if (response.data['error']) {
                    setErrors('An error occurred: ' + response.data['error'] + ' Please contact the administrator!');
                } else if (response.data['user_data']) {
                    const userData = response.data['user_data'].filter((p) => p.id == userId);
                    setUser(userData)
                    setProfile(userData[0]);
                }
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(_content);
                // navigate('/login');
            }
        );
    }, []);

    if ((role == 3)) {
        alert('Permission required!');
        navigate('/smart-profile');
    }
    if ((role == 1 || role == 2 || role == 4) && userData && !userData?.map(obj => obj.id).includes(userId)) {
        alert('Permission required!');
        navigate('/smart-profile');
    }

    //Get feedback data
    const [feedbackData, setFeedback] = useState([]);
    const [mentorFeedbackData, setMentorFeedback] = useState([]);
    useEffect(() => {
        UserService.getAllFeedbacks().then(
            (response) => {
                setFeedback(JSON.parse(response.data.feedback_data).filter((p) => p.fields.created_by == userId));
            },
            (error) => {
                const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('error2: ' + _events);
            }
        );
    }, []);
    useEffect(() => {
        UserService.getAllMentorFeedbacks().then(
            (response) => {
                setMentorFeedback(JSON.parse(response.data.feedback_data).filter((p) => p.fields.created_by == userId));
            },
            (error) => {
                const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('error3: ', _events);
            }
        );
    }, []);

    const [strong_skill_data, setStrongSkillData] = useState([]);
    const [strong_skill_data_d, setStrongSkillData_d] = useState([]);
    // const [strong_skill_data_profileOwnerOnly, setstrong_skill_data_profileOwnerOnly] = useState([]);
    useEffect(() => {
        UserService.get_skill_data_0(userId).then(
            (response) => {
                const skill_data = response.data;
                setStrongSkillData(skill_data.average_data);
                setStrongSkillData_d(skill_data.hist_data);
                // setstrong_skill_data_profileOwnerOnly(skill_data.profile_owner_data);
            },
            (error) => {
                const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('error2: ' + _events);
            }
        );
    }, []);

    const interest_skills = profileData != null ? profileData['interest_skills'] : [];

    const [content, setContent] = useState([]);

    //Get project data
    useEffect(() => {
        UserService.getProjects().then(
            (response) => {
                setContent(JSON.parse(response.data['project_data']));
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                // alert(_content);
                console.log('error3: ' + _content);
            }
        );
    }, []);
    const [projects_joined, setProjects_joined] = useState(null);
    //Get event history
    useEffect(() => {
        UserService.get_event_history(userId).then(
            (response) => {
                // console.log('response', response);
                if (response.data['error']) {
                    setErrors(response.data['error']);
                } else {
                    const project_data = response.data['project_data'];
                    project_data.sort((a, b) => b.project_id - a.project_id);
                    setProjects_joined(project_data);
                }
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                setErrors('FAILED TO GET PROJECT DATA: ' + _content + '. Please contact the administrator!');
            }
        );
    }, []);
    // // //Get APAI event list
    // const [events, setEvents] = useState([]);
    // useEffect(() => {
    //     UserService.getAPAIEventData().then(
    //         (response) => {
    //             setEvents(JSON.parse(response.data));
    //         },
    //         (error) => {
    //             const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    //             setEvents(_events);
    //         }
    //     );
    // }, []); // Empty dependency array, runs only once during initial render

    const [open, setOpen] = useState(false);
    const [selectedSkill, setSelectedSkill] = useState(null);
 
    const handleOpen = (skill) => {
        setSelectedSkill(skill);
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedSkill(null);
        setOpen(false);
    };
    let continuousIndex = 1;
    if (errors) {
        return (
            <Grid item xs={8} alignitems="center">
                <Grid>
                    <div>
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <CircularProgress color="primary" />
                                <Typography>{errors}</Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    } else if (profileData != null) {
        return (
            <Grid item xs={8} alignitems="center">
                <Grid>
                    <div>
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <Avatar alt={profileData['name']} src={avatar1} sx={{ width: 56, height: 56 }} />
                                <h2>{profileData['name']}</h2>
                                <h6>{profileData['role']}</h6>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={7} lg={8}>
                    <Grid container alignitems="center" justifycontent="space-between">
                        <Grid item>
                            <Typography variant="h5">Profile Details</Typography>
                        </Grid>
                    </Grid>
                    <MainCard content={false} sx={{ mt: 1.5 }}>
                        <Box sx={{ pt: 1, pr: 2 }}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Full Name</TableCell>
                                        <TableCell>{profileData['name']}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Email</TableCell>
                                        <TableCell>{profileData['email']}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Gender</TableCell>
                                        <TableCell>{profileData['gender']}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Current Position</TableCell>
                                        <TableCell>{profileData['current_position']}</TableCell>
                                    </TableRow>
                                    {profileData['role'] == 'Participant' ? (
                                        <TableRow>
                                            <TableCell>Dream Job</TableCell>
                                            <TableCell>{profileData['dream_job']}</TableCell>
                                        </TableRow>
                                    ) : null}
                                    <TableRow>
                                        <TableCell>Department/Class/Group</TableCell>
                                        <TableCell>{profileData['group']}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Company/School</TableCell>
                                        <TableCell>{profileData['company_or_school']}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </MainCard>
                </Grid>
                <br />
                <br />
                <Grid item xs={12} md={7} lg={8}>
                    <Grid container alignitems="center" justifycontent="space-between">
                        <Grid item>
                            <Typography variant="h5">Strong Skills</Typography>
                        </Grid>
                    </Grid>
                    <MainCard content={false} sx={{ mt: 1.5 }}>
                        <Box sx={{ pt: 1, pr: 2 }}>
                            <section id="skills" className="skills section-bg">
                                <div className="container">
                                    <div className="section-title">
                                        <p>What your greatest strengths are: </p>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>No</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Rate/5</TableCell>
                                                    <TableCell>View Details</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {strong_skill_data?.map((skill, index) => (
                                                    <TableRow>
                                                        <TableCell>{index + 1}. </TableCell>
                                                        <TableCell>
                                                            <Badge color="primary" badgeContent={skill.new_skill == 1 ? 'New' : null}>
                                                                {skill.skill}
                                                            </Badge>
                                                        </TableCell>
                                                        <TableCell>
                                                            {skill.average_rate_detail}
                                                            {skill.average_change > 0 & skill.new_skill != 1 ? (
                                                                <Typography color="primary">
                                                                    {skill.average_change}
                                                                    <ArrowUpwardIcon color="primary" />
                                                                </Typography>
                                                            ) : skill.average_change < 0 & skill.new_skill != 1 ? (
                                                                <Typography color="error">
                                                                    {Math.abs(skill.average_change)}
                                                                    <ArrowDownwardIcon color="error" />
                                                                </Typography>
                                                            ) : null}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => handleOpen(skill.skill)}>View</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {selectedSkill ? (
                                                    <Modal
                                                        open={open}
                                                        onClose={handleClose}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                        style={{ overflow: 'auto' }}
                                                    >
                                                        <Box sx={style}>
                                                            <Button
                                                                onClick={() => handleClose()}
                                                                sx={{ position: 'fixed', top: 0, right: 0, zIndex: 2000 }}
                                                            >
                                                                <CloseIcon color="error" />                                                              
                                                            </Button>
                                                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                                                Skill Name: {selectedSkill}
                                                            </Typography>
                                                            <br></br>
                                                            <Box sx={{ height: "200px", overflow: "auto" }}>
                                                                <Table stickyHeader>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>No</TableCell>
                                                                            <TableCell>Rate/5</TableCell>
                                                                            <TableCell>Created_Date</TableCell>
                                                                            <TableCell>Source</TableCell>
                                                                            <TableCell>Project</TableCell>
                                                                            <TableCell>Provider</TableCell>
                                                                            <TableCell>Avg. Rate</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {strong_skill_data_d?.map((skill_d, index) =>
                                                                            skill_d.skill === selectedSkill ? (
                                                                                <TableRow key={index}>
                                                                                    <TableCell>{continuousIndex++}</TableCell>
                                                                                    <TableCell>{skill_d.rate}</TableCell>
                                                                                    <TableCell>
                                                                                        {new Date(skill_d.created_date).toLocaleString()}
                                                                                    </TableCell>
                                                                                    <TableCell>{skill_d.source}</TableCell>
                                                                                    <TableCell>
                                                                                        {skill_d.project != 0
                                                                                            ?  `${skill_d.project} - ${
                                                                                                          content.find((row) => row.pk === skill_d.project)?.fields
                                                                                                              ?.title
                                                                                                      }`
                                                                                            : '-'}
                                                                                    </TableCell>
                                                                                    <TableCell>{skill_d.provider}</TableCell>
                                                                                    <TableCell>{skill_d.average_rate}</TableCell>
                                                                                </TableRow>
                                                                            ) : null
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </Box>
                                                            <br></br>
                                                            <Box>
                                                                <Typography id="modal-modal-title" variant="h5" component="h2">
                                                                        The change of Avg. Rate of this skill
                                                                </Typography>
                                                                <LineChart
                                                                    width={500}
                                                                    height={300}
                                                                    data={strong_skill_data_d
                                                                        ?.filter((item) => item.skill === selectedSkill)
                                                                        .sort((a, b) => new Date(a.created_date) - new Date(b.created_date))
                                                                        .reduce((accumulator, currentItem) => {
                                                                            const currentDate = new Date(currentItem.created_date).toLocaleDateString();
                                                                            // Check if the date already exists in accumulator
                                                                            const existingData = accumulator.find(item => item.date === currentDate);
                                                                            // If the date doesn't exist, add the current item
                                                                            if (!existingData) {
                                                                                accumulator.push({
                                                                                    date: currentDate,
                                                                                    latestData: currentItem
                                                                                });
                                                                            } else {
                                                                                // If the date exists, update with the latest data
                                                                                if (new Date(currentItem.created_date) > new Date(existingData.latestData.created_date)) {
                                                                                    existingData.latestData = currentItem;
                                                                                }
                                                                            }
                                                                            return accumulator;
                                                                        }, [])
                                                                        .map(item => item.latestData)
                                                                        .map((item) => ({
                                                                            ...item,
                                                                            // Change date format using toLocaleString
                                                                            created_date_formatted: new Date(item.created_date).toLocaleDateString()
                                                                    }))}
                                                                    margin={{
                                                                        top: 10,
                                                                        right: 30,
                                                                        left: 30,
                                                                        bottom: 10
                                                                    }}
                                                                >
                                                                    <CartesianGrid strokeDasharray="3 3" />
                                                                    <XAxis dataKey="created_date_formatted" label={{ value: 'Date', dy: 20 }} />
                                                                    {/* <YAxis label={{ value: 'AVERAGE of Rating', angle: -90, position: 'insideLeft', dy: -10 }} tick={false} /> */}
                                                                    <YAxis type="number" domain={[0, 5]} />
                                                                    <Tooltip />
                                                                    <Legend layout="horizontal" verticalAlign="top" align="center" />
                                                                    <Line type="monotone" dataKey="average_rate" stroke="#16a085" />
                                                                </LineChart>
                                                            </Box>                                                        
                                                        </Box>
                                                    </Modal>
                                                ) : null}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </section>
                        </Box>
                    </MainCard>
                </Grid>            
                <br />
                <br />
                <Grid item xs={12} md={7} lg={8}>
                    <Grid container alignitems="center" justifycontent="space-between">
                        <Grid item>
                            <Typography variant="h5">Skills that I want to learn/improve</Typography>
                        </Grid>
                    </Grid>
                    <MainCard content={false} sx={{ mt: 1.5 }}>
                        <Box sx={{ pt: 1, pr: 2 }}>
                            <section id="interested-skills" className="skills section-bg">
                                <div className="container">
                                    <div className="section-title">
                                        <p>What you are interested in developing: </p>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>No</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Rate/5</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {interest_skills?.map((skills, index) => (
                                                    <TableRow>
                                                        <TableCell>{index + 1}. </TableCell>
                                                        <TableCell>{skills.skill}</TableCell>
                                                        <TableCell>{skills.rate}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </section>
                        </Box>
                    </MainCard>
                </Grid>

                <br />
                <br />
                <Grid item xs={12} md={7} lg={8}>
                    <Grid container alignitems="center" justifycontent="space-between">
                        <Grid item>
                            <Typography variant="h5">Event history</Typography>
                        </Grid>
                    </Grid>
                    <MainCard content={false} sx={{ mt: 1.5 }}>
                        <Box sx={{ pt: 1, pr: 2 }}>
                            <section id="skills" className="skills section-bg">
                                <div className="container">
                                    <div className="section-title" style={{ overflow: 'auto' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>No</TableCell>
                                                    {/* <TableCell>Platform</TableCell>
                                                    <TableCell>Event Name</TableCell> */}
                                                    <TableCell>Project Name</TableCell>
                                                    {/* <TableCell>Event Date</TableCell> */}
                                                    <TableCell>Round</TableCell>
                                                    <TableCell>Participants</TableCell>
                                                    {/* <TableCell>Mentors</TableCell> */}
                                                    <TableCell>Feedback</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {projects_joined && projects_joined.length > 0 ? (
                                                    projects_joined?.map((p, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{index + 1}. </TableCell>
                                                            <TableCell>{p.project_id + ' - ' + p.project_name}</TableCell>
                                                            <TableCell>{p.round}</TableCell>
                                                            <TableCell>
                                                                <Link
                                                                    href={`/participant_list?userId=${userId}&project=${p.project_id}&round=${p.round}`}
                                                                >
                                                                    List
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>
                                                            {(feedbackData.length > 0 && feedbackData.filter(feedback => feedback.fields.project === p.project_id && feedback.fields.round === p.round).length > 0) ? (
                                                                <Box>
                                                                    {profileData['role'] == 'Participant' ? (
                                                                        <Link href={`/review-feedback?pk=${feedbackData.filter(feedback => feedback.fields.project === p.project_id && feedback.fields.round === p.round)[feedbackData.filter(feedback => feedback.fields.project === p.project_id && feedback.fields.round === p.round).length - 1].pk}`}>Feedback sent |</Link>
                                                                    ) : null}
                                                                    {profileData['role'] == 'Mentor' && mentorFeedbackData.length > 0 && mentorFeedbackData.filter(feedback => feedback.fields.project === p.project_id && feedback.fields.round === p.round).length > 0 ? (
                                                                        <Link href={`/review-feedback-mentor?pk=${mentorFeedbackData.filter(feedback => feedback.fields.project === p.project_id && feedback.fields.round === p.round)[mentorFeedbackData.filter(feedback => feedback.fields.project === p.project_id && feedback.fields.round === p.round).length - 1].pk}`}>Feedback sent |</Link>
                                                                    ) : null}
                                                                    <Link
                                                                        href={`/feedback-from-peers?user=${userId}&projectId=${p.project_id}&round=${p.round}`}
                                                                    >
                                                                        Feedback received
                                                                    </Link>
                                                                </Box>
                                                            ) : (
                                                                <MuiTooltip title="Feedback must be submitted to review the history for this particular event.">
                                                                    <IconButton>
                                                                        <PriorityHighIcon />
                                                                    </IconButton>
                                                              </MuiTooltip>
                                                            )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={8}>
                                                            No data
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                                {/* {feedbackData && feedbackData.length > 0 ? (
                                                    feedbackData?.map((feedback, index) => (
                                                        <TableRow>
                                                            <TableCell>{index + 1}. </TableCell>
                                                            <TableCell>
                                                                {content &&
                                                                feedback &&
                                                                feedback.fields &&
                                                                feedback.fields.project !== undefined
                                                                    ? `${feedback.fields.project} - ${
                                                                          content.find((row) => row.pk === feedback.fields.project)?.fields
                                                                              ?.title
                                                                      }`
                                                                    : 'NaN'}
                                                            </TableCell>
                                                            <TableCell>{feedback.fields.round}</TableCell>
                                                            <TableCell>
                                                                <Link
                                                                    href={`/participant_list?userId=${userId}&project=${feedback.fields.project}&round=${feedback.fields.round}`}
                                                                >
                                                                    List
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Link href={`/review-feedback?pk=${feedback.pk}`}>Feedback sent</Link>
                                                                {' | '}
                                                                <Link
                                                                    href={`/feedback-from-peers?user=${userId}&projectId=${feedback.fields.project}&round=${feedback.fields.round}`}
                                                                >
                                                                    Feedback received
                                                                </Link>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow></TableRow>
                                                )}
                                                {mentorFeedbackData && mentorFeedbackData.length > 0 ? (
                                                    mentorFeedbackData?.map((feedback, index) => (
                                                        <TableRow>
                                                            <TableCell>{index + 1}. </TableCell>
                                                            <TableCell>
                                                                {content &&
                                                                feedback &&
                                                                feedback.fields &&
                                                                feedback.fields.project !== undefined
                                                                    ? `${feedback.fields.project} - ${
                                                                          content.find((row) => row.pk === feedback.fields.project)?.fields
                                                                              ?.title
                                                                      }`
                                                                    : 'NaN'}
                                                            </TableCell>
                                                            <TableCell>{feedback.fields.round}</TableCell>
                                                            <TableCell>
                                                                <Link
                                                                    href={`/participant_list?userId=${userId}&project=${feedback.fields.project}&round=${feedback.fields.round}`}
                                                                >
                                                                    List
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Link href={`/review-feedback-mentor?pk=${feedback.pk}`}>
                                                                    Feedback sent
                                                                </Link>
                                                                {' | '}
                                                                <Link
                                                                    href={`/feedback-from-peers?user=${userId}&projectId=${feedback.fields.project}&round=${feedback.fields.round}`}
                                                                >
                                                                    Feedback received
                                                                </Link>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow></TableRow>
                                                )} */}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </section>
                        </Box>
                    </MainCard>
                </Grid>
                <br />
                <br />
            </Grid>
        );
    } else {
        return (
            <Grid item xs={8} alignitems="center">
                <Grid>
                    <div>
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <CircularProgress color="primary" />
                                <Typography>Loading ...</Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    }
};

export default ProfilePageView;
