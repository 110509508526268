import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import {
    Alert,
    Container,
    Row,
    Col,
    Form,
    FormControl
    //
} from 'react-bootstrap';
import {
    // Alert,
    Card,
    Box,
    Button,
    Link
    //
} from '@mui/material';
if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ResetPasswordConfirm = () => {
    const query = useQuery();
    const uid = query.get('uid');
    const token = query.get('token');
    console.log(uid);
    console.log(token);
    const [newPassword, setNewPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const onSaveClick = () => {
        setPasswordError('');
        setStatus('');
        if (newPassword == '') {
            setPasswordError('Please enter your new password!');
        } else {
            const data = {
                new_password: newPassword
            };
            axios
                .post(`${backend_base_url}/auth/password/reset/${uid}/${token}/`, data)
                .then((response) => {
                    console.log(response);
                    if (response.data.status === 200) {
                        setStatus('success');
                    } else {
                        setStatus('error');
                        setMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data.hasOwnProperty('new_password')) {
                        setPasswordError(error.response.data['new_password']);
                    } else {
                        setStatus('error');
                        setMessage(error.message);
                    }
                });
        }
    };

    const errorAlert = (
        <Alert variant="danger">
            {message ? <Alert.Heading>{message}</Alert.Heading> : <Alert.Heading>Problem during new password set </Alert.Heading>}
            <ul>
                {message == 'Network Error' ? (
                    <li>
                        Please try &nbsp;
                        <Link variant="h6" href="/forgot-password" to="" color="primary">
                            reset password
                        </Link>
                        &nbsp; again later.
                    </li>
                ) : (
                    <li>
                        Please try &nbsp;
                        <Link variant="h6" href="/forgot-password" to="" color="primary">
                            reset password
                        </Link>
                        &nbsp; again to get a new reset link.
                    </li>
                )}
                <li>
                    Or &nbsp;
                    <Link
                        variant="h6"
                        href="https://airtable.com/appNm0943dsfrMAX9/shrwj4RjVSnpVvpin"
                        to=""
                        color="primary"
                        target="_blank"
                        //
                    >
                        contact service support
                    </Link>
                    &nbsp; for further help.
                </li>
            </ul>
        </Alert>
    );

    const successAlert = (
        <Alert variant="success">
            <Alert.Heading>New Password Set</Alert.Heading>
            <p>
                You can &nbsp;
                <Link variant="h6" href="/login" to="" color="primary">
                    Login
                </Link>
                &nbsp; to your account with the new password.
            </p>
        </Alert>
    );

    const form = (
        <div>
            <Form>
                <Form.Group controlId="emailId">
                    <Form.Label>Your New Password</Form.Label>
                    <Form.Control
                        isInvalid={passwordError}
                        type="password"
                        name="new_password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <FormControl.Feedback type="invalid">{passwordError}</FormControl.Feedback>
                </Form.Group>
            </Form>
            <br></br>
            <Button
                variant="contained"
                color="primary"
                onClick={onSaveClick}
                ///
            >
                Save
            </Button>
        </div>
    );

    let alert = '';
    if (status === 'error') {
        alert = errorAlert;
    } else if (status === 'success') {
        alert = successAlert;
    }

    return (
        <Container>
            <Row>
                <Col md="6">
                    <h1>Set a New Password</h1>
                    {alert}
                    {status !== 'success' && message !== 'Invalid user ID.' && message !== 'Invalid reset token.' && form}
                </Col>
            </Row>
        </Container>
    );
};

export default ResetPasswordConfirm;
