/* eslint-disable */
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import React, { useState, useEffect } from 'react';
import UserService from 'services/user.service';
import {
    Box,
    Button,
    NativeSelect,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TextField
} from '@mui/material';
import Fab from '@mui/material/Fab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
const steps = [
    {
        label: '',
        description: `Thank you for your participation in our event. We sincerely hope that your experience was enjoyable and valuable, just as it was for us to organize and host.`
    },
    {
        label: 'Experience Evaluation',
        description: 'In this section we will ask you to reflect on your experience.'
    },
    {
        label: 'Putting Your Learning into Action',
        description: 'In this final section, reflect on how you will turn this learning into action.'
    }
];
const usefulness_questions = [
    'How satisfied were you with your overall experience as a mentor during this initiative?',
    'How effective do you feel your guidance and support were in helping your mentee(s) achieve their goals?',
    "To what extent did you feel equipped and prepared to provide valuable feedback and insights to enhance your mentee(s)' learning and development during the initiative?",
    "How well do you believe you understood your mentee(s)' needs and were able to tailor your guidance to meet their specific requirements?",
    'How likely are you to recommend being a mentor in similar initiatives to other professionals in your field?',
    'How did you feel the training and support offered by this initiative supported your own personal development?'
];
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
export const ViewFeedback_mentor = () => {
    const navigate = useNavigate();
    const { user: currentUser } = useSelector((state) => state.auth);
    const location = useLocation();
    const prevLocation = encodeURIComponent(window.location.origin + location.pathname + location.search);
    // const role = (localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile'))['role']) || 'guest';

    if (!currentUser) {
        navigate(`/login?redirectTo=${prevLocation}`);
    }
    const [errors, setErrors] = useState(null);
    const query = useQuery();
    // Get query parameters
    const feedbackId = parseInt(query.get('pk'));
    if (isNaN(feedbackId)) {
        navigate('/smart-profile');
    }
    const handleBackPage = () => {
        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate('/notification');
        }
    };
    // const id = parseInt(query.get('id'));

    // if (currentUser.id != parseInt(id)) {
    //     navigate(`/login?redirectTo=${prevLocation}`);
    // }
    // console.log(project);

    // const [errors, setErrors] = useState([]);
    // const loading = false;

    // const dispatch = useDispatch();
    // const skill_list = UserService.skill_list;
    // const rating_list = UserService.rating_list;
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    //Get feedback data
    const [feedbackData, setFeedback] = useState(null);
    // useEffect(() => {
    //     UserService.getFeedbacks().then(
    //         (response) => {
    //             setFeedback(JSON.parse(response.data.mentor_feedback_data).find((p) => p.pk === feedbackId));
    //             console.log(
    //                 '1 :',
    //                 JSON.parse(response.data.mentor_feedback_data)
    //                 //
    //             );
    //         },
    //         (error) => {
    //             const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    //             setUser(_events);
    //         }
    //     );
    // }, []);
    useEffect(() => {
        UserService.getAllMentorFeedbacks().then(
            (response) => {
                setFeedback(JSON.parse(response.data.feedback_data).find((p) => p.pk === feedbackId));
            },
            (error) => {
                const _errors = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                setErrors('FAILED TO GET FEEDBACK DATA: ' + _errors);
            }
        );
    }, []);
    //console.log('feedback:', feedbackData);

    // const [content, setContent] = useState([]);
    // const [profileContent, setProfileContent] = useState([]);

    // useEffect(() => {
    //     UserService.getAdminBoard().then(
    //         (response) => {
    //             setContent(JSON.parse(response.data['user_data']));
    //             setProfileContent(JSON.parse(response.data['profiles_data']));
    //         },
    //         (error) => {
    //             const _content =
    //                 (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    //             setContent(_content);
    //         }
    //     );
    // }, []);
    // const usersDictionary = content.reduce((acc, value) => {
    //     if (value.pk != '1') {
    //         const profile = profileContent.find((p) => p.fields.user === value.pk);
    //         acc[value.pk] = {
    //             email: value.fields.username,
    //             name: value.fields.first_name + ' ' + value.fields.last_name,
    //             company_or_school: profile ? profile.fields.company_or_school : '-'
    //         };
    //     }
    //     return acc;
    // }, {});
    /// Render page
    if (errors) {
        return (
                <Grid item xs={8} sm={12} alignitems="center">
                    <br />
                    <Box sx={{ height: 640, width: '100%' }}>
                        <div style={{color: 'red'}}>{errors}</div>
                    </Box>
                </Grid>
            )
    }
    if (feedbackData) {
        return (
            <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    onClick={() => handleBackPage()}
                    sx={{ mb: 2 }}
                >
                    <ArrowBackIcon />
                    Back
                </Fab>
                <h2>Time To Reflect, Review And Move Forward</h2>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ bgcolor: 'primary.main', display: 'flex', justifyContent: 'center', padding: 0 }}>
                                <h4 style={{ color: 'white' }}>{steps[activeStep].label}</h4>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    backgroundColor: 'white',
                                    borderBottom: activeStep != 2 ? '2px solid primary.main' : null,
                                    borderColor: activeStep != 2 ? 'primary.main' : null
                                    //
                                }}
                            >
                                {steps[activeStep].description}
                            </TableCell>
                        </TableRow>
                        {/* {activeStep == 2 ? (
                            <TableRow>
                                <TableCell
                                    sx={{
                                        backgroundColor: 'white',
                                        borderBottom: '2px solid primary.main',
                                        borderColor: 'primary.main'
                                        //
                                    }}
                                >
                                    <b>Please note! Your mentees will see your comments below reflected on their SMART profiles.</b>
                                </TableCell>
                            </TableRow>
                        ) : null} */}
                    </TableBody>
                </Table>
                {/* {activeStep != 0 ? <hr style={{ color: '#14a085' }} /> : null} */}
                <form>
                    <Box sx={{ height: '100%', maxWidth: '100%', width: '100%', p: 2 }}>
                        {activeStep == 0 && (
                            <div>
                                <br></br>
                                <b>Reflection offers a range of benefits:</b>
                                <ol>
                                    <li>Gain a profound understanding of your personal growth</li>
                                    <li>Improve interview readiness</li>
                                    <li>Encourage honest peer feedback</li>
                                    <li>Foster personal growth and development in others</li>
                                    <li>Identify areas for improvement</li>
                                    <li>Cultivate better team communication and collaboration</li>
                                </ol>
                            </div>
                        )}
                        {activeStep == 1 && (
                            <div>
                                <b>Q1. Rate the usefulness of this event in helping you reaching your goal.</b>
                                <br></br>
                                <div>
                                    <Table style={{ width: '80%', margin: 'auto' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Questions</TableCell>
                                                <TableCell>Rate/5</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {feedbackData?.fields.usefulness_ratings.map((item, itemIndex) => (
                                                <TableRow>
                                                    <TableCell>{item.question}</TableCell>
                                                    <TableCell>
                                                        <NativeSelect
                                                            defaultValue={item.rate !== undefined ? item.rate : ''}
                                                            disabled
                                                            //onChange={(e) => handleRateChange_u(itemIndex, e.target.value)}
                                                            // inputProps={{
                                                            //     name: 'rate_skill_s'
                                                            // }}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value={'1'}>1 (Lowest)</option>
                                                            <option value={'2'}>2</option>
                                                            <option value={'3'}>3</option>
                                                            <option value={'4'}>4</option>
                                                            <option value={'5'}>5 (Highest)</option>
                                                        </NativeSelect>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                                <br></br>
                                <br></br>
                                <b>
                                    Q2. Can you provide specific examples where your guidance had a significant impact on your mentee(s)
                                    during the initiative?
                                </b>
                                <br></br>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="guidance_examples"
                                    id="q2"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    // inputRef={assumptionsRef}
                                    //onChange={handleChange}
                                    defaultValue={feedbackData.fields.guidance_examples}
                                    disabled
                                />
                                <br></br>
                                <br></br>
                                <b>Q3. What did you consider to be the strengths of the mentorship program in this initiative?</b>
                                <br></br>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="mentorship_strengths"
                                    id="q3"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    // inputRef={assumptionsRef}
                                    //onChange={handleChange}
                                    defaultValue={feedbackData.fields.mentorship_strengths}
                                    disabled
                                />
                                <br></br>
                                <br></br>
                                <b>Q4. How did you establish rapport and effective communication with your mentee(s)?</b>
                                <br></br>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="jobs_done"
                                    id="q4"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    // inputRef={assumptionsRef}
                                    //onChange={handleChange}
                                    defaultValue={feedbackData.fields.jobs_done}
                                    disabled
                                />
                                <br></br>
                                <br></br>
                                <b>Q5. Did you face any challenges as a mentor? If yes, how did you overcome them?</b>
                                <br></br>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="challenges"
                                    id="q5"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    // inputRef={assumptionsRef}
                                    //onChange={handleChange}
                                    defaultValue={feedbackData.fields.challenges}
                                    disabled
                                />
                                <br></br>
                                <br></br>
                                <b>Q6. What suggestions do you have to improve the mentorship experience in future initiatives?</b>
                                <br></br>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="suggestions"
                                    id="q6"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    // inputRef={assumptionsRef}
                                    //onChange={handleChange}
                                    defaultValue={feedbackData.fields.suggestions}
                                    disabled
                                />
                            </div>
                        )}
                        {/* {activeStep == 2 && (
                            <div>
                                <br></br>
                                <b>Q7. How would you rate your mentees' proficiency in each of the skills listed on their Smart Profile?</b>
                                {feedbackData.fields.mentees.map((teammate, teammateIndex) => (
                                    <div>
                                        <br></br>
                                        <b>
                                            {teammate.id} - {usersDictionary[teammate.id]?.name} -
                                            {usersDictionary[teammate.id]?.company_or_school != ''
                                                ? usersDictionary[teammate.id]?.company_or_school
                                                : ''}
                                        </b>
                                        <TableContainer style={{ width: '80%', margin: 'auto' }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>No</TableCell>
                                                        <TableCell>Skill</TableCell>
                                                        <TableCell>Rate/5</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {teammate.skills.map((skill, skillIndex) => (
                                                        <TableRow>
                                                            <TableCell>{skillIndex + 1}. </TableCell>
                                                            <TableCell>
                                                                {skill.skill.trim()}
                                                            </TableCell>
                                                            <TableCell>
                                                                <NativeSelect
                                                                    defaultValue={skill.rate !== undefined ? skill.rate : ''}
                                                                    disabled
                                                                >
                                                                    <option value="">Select</option>
                                                                    {rating_list.map((item, index) => (
                                                                        <option key={skillIndex + '-' + index} value={item}>
                                                                            {item}
                                                                        </option>
                                                                    ))}
                                                                </NativeSelect>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                            <br></br>
                                            <br></br>
                                            <b>Where have you seen evidence of their personal growth and development?</b>
                                            <TextField
                                                sx={{ width: '100%' }}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                name={`comment1_${teammateIndex}`}
                                                id={`comment1_${teammateIndex}`}
                                                type="text"
                                                placeholder="Your answer"
                                                inputProps={{ maxLength: 500 }}
                                                //onChange={handleChange}
                                                //inputRef={actionRef}
                                                defaultValue={teammate.comment1}
                                                disabled
                                            />
                                            <br></br>
                                            <br></br>
                                            <b>How can they develop these skills further?</b>
                                            <TextField
                                                sx={{ width: '100%' }}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                name={`comment2_${teammateIndex}`}
                                                id={`comment2_${teammateIndex}`}
                                                type="text"
                                                placeholder="Your answer"
                                                inputProps={{ maxLength: 500 }}
                                                //onChange={handleChange}
                                                //inputRef={actionRef}
                                                defaultValue={teammate.comment2}
                                                disabled
                                            />
                                            <br></br>
                                            <br></br>
                                            <b>
                                                What other skills have you seen them display, that they didn't put on their Smart Profile?
                                            </b>
                                            <TextField
                                                sx={{ width: '100%' }}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                name={`comment3_${teammateIndex}`}
                                                id={`comment3_${teammateIndex}`}
                                                type="text"
                                                placeholder="Your answer"
                                                inputProps={{ maxLength: 500 }}
                                                //onChange={handleChange}
                                                //inputRef={actionRef}
                                                defaultValue={teammate.comment3}
                                                disabled
                                            />
                                        </TableContainer>
                                    </div>
                                ))}
                            </div>
                        )} */}
                        {activeStep == 2 && (
                            <div>
                                <b>
                                    Q7. How will you apply the learning that you've taken from this experience? How can you build on this
                                    for your own and others' benefit?
                                </b>
                                <br></br>
                                <i style={{ color: '#14a085', margin: '1em' }}>
                                    Example. "Reflecting on this experience, I intend to further refine my guidance and transfer what I've
                                    learned from this into my day-to-day job. I'm also happy to share what I've learned with other mentors
                                    and to help foster a more collaborative culture."
                                </i>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="actions"
                                    id="actions"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    //onChange={handleChange}
                                    //inputRef={actionRef}
                                    defaultValue={feedbackData.fields.actions}
                                    disabled
                                />
                                <i>"The most effective way to do it, is to do it"</i>
                                <br></br>
                                <b>— Amelia Earhart</b>
                            </div>
                        )}
                    </Box>
                    <MobileStepper
                        variant="text"
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                                variant="contained"
                                color="primary"
                                ///
                            >
                                Next
                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                        }
                        backButton={
                            <Button
                                size="small"
                                onClick={handleBack}
                                disabled={activeStep === 0}
                                variant="outlined"
                                //color="primary"
                            >
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                Back
                            </Button>
                        }
                    />
                </form>
            </Box>
        );
    } else {
        <div>Loading ...</div>;
    }
};
