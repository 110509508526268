/* eslint-disable */
import axios from 'axios';

if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}

let isRefreshing = null;
axios.interceptors.response.use(
    function (resp) {
        return resp;
    },
    function (error) {
        const originalRequest = error.config;
        //console.log(isRefreshing);
        if (error.response?.status === 401 && !originalRequest._retry) {
            if (!isRefreshing) {
                originalRequest._retry = true;
                isRefreshing = true;
                const refreshToken = window.localStorage.getItem('refresh_token');
                return new Promise((resolve, reject) => {
                    axios
                        .post(`${backend_base_url}/login_token/refresh/`, { refresh: refreshToken })
                        .then((response) => {
                            //console.log('fasle456');
                            //console.log(response);
                            window.localStorage.setItem('access_token', response.data.access);
                            window.localStorage.setItem('refresh_token', response.data.refresh);
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access;
                            originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access;
                            // processQueue(null, response.data.access);
                            resolve(axios(originalRequest));
                            window.location.reload();
                        })
                        .catch((err) => {
                            //console.log('123' + err);
                            alert('Your session has timed out - please login again!');
                            // processQueue(err, null);
                            reject(err);
                            window.localStorage.clear();
                            window.location = '/login';
                            //throw refreshError;
                        })
                        .then(() => {
                            //console.log('fasle123');
                            isRefreshing = null;
                        });
                });
            }
            // else {
            //     console.log('hehe');
            //     return new Promise(function (resolve, reject) {
            //         failedQueue.push({ resolve, reject });
            //     })
            //         .then((token) => {
            //             originalRequest.headers['Authorization'] = 'Bearer ' + token;
            //             return axios(originalRequest);
            //         })
            //         .catch((err) => {
            //             console.log('123');
            //             isRefreshing = false;
            //             return Promise.reject(err);
            //         });
            // }
        }
        return Promise.reject(error);
    }
);