import React, { Component, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { WelcomeEmail } from 'email/welcomeEmail';
import { render } from '@react-email/render';
import axios from 'axios';
if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const EmailVerify = () => {
    //render html
    // const emailHtml = render(<WelcomeEmail />);
    const [valid, setValid] = useState(0);
    const [error, setError] = useState('');
    const query = useQuery();
    const uid = query.get('uid');
    const token = query.get('token');
    axios.get(`${backend_base_url}/auth/users/validate-email-token/${uid}/${token}/`).then(
        (response) => {
            if (response && response.data && response.data.status && response.data.message) {
                if (response.data.status == 200) {
                    setValid(1);
                } else {
                    setValid(2);
                    setError(response.data.message);
                    console.log(response.data.message);
                }
            }
        },
        (error) => {
            console.log(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            setValid(2);
        }
    );

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="custom-alert">
                        {valid === 1 && (
                            <div className="alert alert-success" role="alert">
                                <p>
                                    Email Verification Done. <br></br>
                                    <br></br>You can <a href="/login">login</a> now to see and amend your Smart Profile, review your
                                    feedback and read your notifications.
                                </p>
                            </div>
                        )}
                        {valid === 2 && (
                            <div className="alert alert-danger" role="alert">
                                <p>Email Verification Failed. Email already verified or the link is broken (i.e: {error})</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailVerify;
