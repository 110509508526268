/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import UserService from 'services/user.service';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { MenuItem, FormControl, Select} from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CheckIcon from '@mui/icons-material/Check';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Button, ButtonGroup } from '@mui/material';
import AnimateButton from 'components/@extended/AnimateButton';
import {
    Input,
    InputLabel
    ///
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'id_int',
        numeric: true,
        disablePadding: true,
        label: 'ID'
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Name'
    },
    {
        id: 'role',
        numeric: false,
        disablePadding: false,
        label: 'Role'
    },
    {
        id: 'dateJoined',
        numeric: false,
        disablePadding: false,
        label: 'Date Joined'
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email'
    },
    {
        id: 'gender',
        numeric: false,
        disablePadding: false,
        label: 'Gender'
    },
    {
        id: 'company_or_school',
        numeric: false,
        disablePadding: false,
        label: 'Company or School'
    },
    {
        id: 'current_position',
        numeric: false,
        disablePadding: false,
        label: 'Current Position'
    },
    {
        id: 'group',
        numeric: false,
        disablePadding: false,
        label: 'Department or Class'
    }
];
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, numFiltered, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all users'
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    numFiltered: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

export function EnhancedTableToolbar(props) {
    const { numSelected, selectedData, reselectionToDataTable } = props;

    return (
        <Toolbar
            sx={{
                ml: 1,
                mr: 1,
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                // ...(numSelected > 0 && {
                //     bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
                // })
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%', ml: 1, mr: 2 }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    ///
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                    ///
                >
                    List of Users
                </Typography>
            )}
            {' '}
            {numSelected > 0 ? (
                <AnimateButton>
                    <ButtonGroup>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => reselectionToDataTable(JSON.stringify(selectedData))}
                            //
                        >
                            <CheckIcon sx={{ mr: 1 }} />
                            Confirm
                        </Button>
                    </ButtonGroup>
                </AnimateButton>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    selectedData: PropTypes.array.isRequired,
    reselectionToDataTable: PropTypes.func.isRequired
};
function cleanParticipants(participants, allUsers) {
    const allUserIds = allUsers.map((user) => user.id);
    return participants.filter((participant) => participant != null && allUserIds.includes(participant.id));
}
export function ReselectionTable({ project_id, reselectionToDataTable, onSelectedChange, onFilteredDataChange }) {
    const query = useQuery();
    // Get query parameters
    const projectId = parseInt(query.get('projectId')) ? parseInt(query.get('projectId')) : project_id;

    // start Get project data
    // const [projectContent, setProjectContent] = useState([]);
    const [selected, setSelected] = React.useState([]);
    // console.log('selected', selected);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        UserService.getAllUsers().then(
            (response) => {
                const content = response.data['user_data'];
                const filteredContent = content.filter(row => row.is_active === true);
                // Create an array to hold both id and id_int values
                const augmentedContent = filteredContent.map((row) => ({
                    ...row,
                    id_int: row.id, // Convert the integer representation to a string
                    id: row.id.toString() // Convert the original id to a string
                }));
                // Set the state with the augmented content
                setRows(augmentedContent);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('error: ', _content);
                // navigate('/login');
            }
        );
    }, []);
    useEffect(() => {
        UserService.getProjects().then(
            (response) => {
                const a = JSON.parse(response.data['project_data']).find((p) => p.pk === parseInt(projectId));
                // setProjectContent(a);
                if (localStorage.getItem('selectedUserIds_project' + projectId) != null) {
                    setSelected(JSON.parse(localStorage.getItem('selectedUserIds_project' + projectId)));
                } else {
                    const participants = cleanParticipants(a.fields.participants, rows);
                    const b = participants.map((item) => item.id);
                    setSelected(b);
                }
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                console.log('error: ', _content);
            }
        );
    }, [rows]);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('id_int');

    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = filteredData.map((n) => n.id);
            setSelected(newSelected);
        } else {
            setSelected([]); // Deselect all items
            localStorage.removeItem('selectedUserIds_project' + projectId); // Remove stored selection
        }
    };
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const handleClick = (event, id, index) => {
        const isShiftClick = event.shiftKey;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [...selected];
        if (isShiftClick && lastSelectedIndex !== null) {            
            const startIndex = Math.min(lastSelectedIndex, index);
            const endIndex = Math.max(lastSelectedIndex, index);
            for (let i = startIndex; i <= endIndex; i++) {
                if (!newSelected.includes(visibleRows[i].id)) {
                    newSelected.push(visibleRows[i].id);
                }
            }
            setSelected(newSelected);
        } else {
            if (selectedIndex === -1) {
                // If the row is not selected, add it to the selection
                newSelected.push(id);
            } else {
                // If the row is selected, remove it from the selection
                newSelected.splice(selectedIndex, 1);
            }
            setSelected(newSelected);
        }
        setLastSelectedIndex(index);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => {
        if (selected && selected.length > 0) {
            return selected.indexOf(id) !== -1;
        }
        return false; // Return false if selected is not defined or empty
    };
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    //Search bar
    const initialDate = new Date('2023-08-01'); // Replace with your desired date
    const [searchQuery, setSearchQuery] = useState('');
    const [emailQuery, setEmailQuery] = useState('');
    const today = new Date();
    // Set end date to the end of the current date
    const endOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59);

    const [startDate, setStartDate] = useState(initialDate);
    const [endDate, setEndDate] = useState(endOfToday);
    const [roleQuery, setRoleQuery] = useState('');
    const [genderQuery, setGenderQuery] = useState('');
    const [companyOrSchoolQuery, setCompanyOrSchoolQuery] = useState('');

    const [distinctRoles, setDistinctRoles] = useState([]);
    const [distinctGenders, setDistinctGenders] = useState([]);
    // const [distinctCompanyOrSchool, setDistinctCompanyOrSchool] = useState([]);

    useEffect(() => {
        // Extract distinct values for role and gender
        const roles = [...new Set(rows.map(item => item.role))];
        const genders = [...new Set(rows.map(item => item.gender))];
        // const companyOrSchool = [...new Set(rows.map(item => item.company_or_school))];
        setDistinctRoles(roles);
        setDistinctGenders(genders);
        // setDistinctCompanyOrSchool(companyOrSchool);
    }, [rows]);
    // Filtered data based on searchQuery and date range
    const filteredData = rows.filter((row) => {
        // Filter by search query (if provided)
        const matchesSearch = !searchQuery || row.name.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesEmailSearch = !emailQuery || row.email.toLowerCase().includes(emailQuery.toLowerCase());
        // Filter by date range (if provided)
        const isWithinDateRange =
            (!startDate || new Date(row.dateJoined) >= startDate) && (!endDate || new Date(row.dateJoined) <= endDate);
        // return matchesSearch && isWithinDateRange;
        const matchesRole = !roleQuery || row.role === roleQuery;
        const matchesGender = !genderQuery || row.gender === genderQuery;
        const matchesCompanyOrSchool = !companyOrSchoolQuery || row.company_or_school.toLowerCase().includes(companyOrSchoolQuery.toLowerCase());
        return matchesSearch && matchesEmailSearch && isWithinDateRange && matchesRole && matchesGender && matchesCompanyOrSchool;
    });

    const visibleRows = React.useMemo(() =>
        stableSort(filteredData, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );
    useEffect(() => {
        // Notify parent when selected data changes
        onSelectedChange(selected);
    }, [selected, onSelectedChange]);

    useEffect(() => {
        // Notify parent when filtered data changes
        onFilteredDataChange(filteredData);
    }, [filteredData, onFilteredDataChange]);
    if (selected && visibleRows && headCells) {
        return (
            <Box
                sx={{ width: '90%' }}
                alignItems="center"
                margin="auto"
                padding="auto"
                //
            >
                {/* Search Box */}
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 2, sm: 3, md: 4 }}
                    sx={{ mb: 2 }} // Add bottom margin for spacing between stacks
                >
                    <Item sx={{ flex: 1 }}>
                        <Input
                            fullWidth
                            type="text"
                            placeholder="Search by Name"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </Item>
                    <Item sx={{ flex: 1 }}>
                        <FormControl fullWidth>
                            <InputLabel id="role-label">Search by Role</InputLabel>
                            <Select
                                labelId="role-label"
                                value={roleQuery}
                                onChange={(e) => setRoleQuery(e.target.value)}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {distinctRoles.map(role => (
                                    <MenuItem key={role} value={role}>
                                        {role}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Item>
                    <Item sx={{ flex: 1 }}>
                        <InputLabel htmlFor="startDate">From Date Joined:</InputLabel>
                        <Input
                            fullWidth
                            type="datetime-local"
                            id="startDate"
                            value={startDate.toISOString().slice(0, -8)}
                            onChange={(e) => setStartDate(new Date(e.target.value))}
                        />
                    </Item>
                    <Item sx={{ flex: 1 }}>
                        <InputLabel htmlFor="endDate">To Date Joined:</InputLabel>
                        <Input
                            fullWidth
                            type="datetime-local"
                            id="endDate"
                            value={endDate.toISOString().slice(0, -8)}
                            onChange={(e) => setEndDate(new Date(e.target.value))}
                        />
                    </Item>
                </Stack>

                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 2, sm: 3, md: 4 }}
                    sx={{ mb: 2 }} // Additional margin for spacing
                >
                    <Item sx={{ flex: 1 }}>
                        <Input
                            fullWidth
                            type="text"
                            placeholder="Search by Email"
                            value={emailQuery}
                            onChange={(e) => setEmailQuery(e.target.value)}
                        />
                    </Item>
                    <Item sx={{ flex: 1 }}>
                        <FormControl fullWidth>
                            <InputLabel id="gender-label">Search by Gender</InputLabel>
                            <Select
                                labelId="gender-label"
                                value={genderQuery}
                                onChange={(e) => setGenderQuery(e.target.value)}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {distinctGenders.map(gender => (
                                    <MenuItem key={gender} value={gender}>
                                        {gender}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Item>
                    <Item sx={{ flex: 1 }}>
                        <Input
                            fullWidth
                            type="text"
                            placeholder="Search by Company or School"
                            value={companyOrSchoolQuery}
                            onChange={(e) => setCompanyOrSchoolQuery(e.target.value)}
                        />
                    </Item>
                </Stack>
                <br />
                <br />
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            ///
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                numFiltered={filteredData.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.id, index)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                padding="none"
                                                ///
                                            >
                                                {row.id_int}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell>{row.role}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {new Date(row.dateJoined).toLocaleDateString()} 
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {row.email}
                                            </TableCell>
                                            <TableCell>{row.gender}</TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {row['company or school']}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {row['current position']}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                                {row['department or class']}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <br />
                        {filteredData.length} filtered rows
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                    //
                /> */}
                {/* <EnhancedTableToolbar
                    numSelected={selected?.length}
                    numFiltered={filteredData?.length}
                    selectedData={selected}
                    reselectionToDataTable={reselectionToDataTable}
                /> */}
            </Box>
        );
    }
}
