/* eslint-disable */
import { TextareaAutosize } from '@mui/base';
// import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import React, { Component, useState, useRef, useEffect } from 'react';
import { useCallback } from 'react';
import UserService from 'services/user.service';
import {
    Alert,
    Box,
    Button,
    Card,
    Fab,
    CardActions,
    CardContent,
    Container,
    Divider,
    Form,
    FormControl,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    MenuItem,
    Select,
    NativeSelect,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableFooter,
    TableContainer,
    TextField
} from '@mui/material';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';

const steps = [
    {
        label: 'Welcome',
        description: ``
    },
    {
        label: 'Account Setup'
    },
    {
        label: 'Personal Information',
    },
    {
        label: 'Extra Information',
    }
];

function SkillList(strongSkills) {
    if (!strongSkills) {
        return []; // Return an empty array if strongSkills is falsy (e.g., null, undefined)
    }
    return strongSkills.map((item) => ' ' + item.skill);
}

function updateTables(tables, indexMapping) {
    const mappedTables = tables.map((table) => table.map((row) => row.map((originalIndex) => indexMapping[originalIndex])));
    const filteredTables = mappedTables.map((round) => round.map((table) => table.filter((participant) => participant != null)));
    return filteredTables;
}
export const RegistrationForm = () => {
    const [errors, setErrors] = useState([]);
    const loading = false;

    const dispatch = useDispatch();
    const skill_list = UserService.skill_list;
    const rating_list = UserService.rating_list;
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps.length;

    const handleNext = () => {
        const duplicateSkills_s = findDuplicateSkills(strongSkills_data);
        const duplicateSkills_i = findDuplicateSkills(interestSkills_data);
        const nullobjects_s = findObjectsWithNullSkillAndRate(strongSkills_data);
        const nullobjects_i = findObjectsWithNullSkillAndRate(interestSkills_data);
        if (activeStep == 2) {
            if (duplicateSkills_s != '') {
                alert('There is duplicate skill. Please re-check!');
            } else {
                if (nullobjects_s != '') {
                    alert('Skill/Rate has not been selected. Please re-check!');
                } else {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
            }
        } else if (activeStep == 3) {
            if (duplicateSkills_i != '') {
                alert('There is duplicate skill. Please re-check!');
            } else {
                if (nullobjects_i != '') {
                    alert('Skill/Rate has not been selected. Please re-check!');
                } else {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
            }
        } else if (activeStep == 1) {
            // if (feedback['assumptions'] == '') {
            //     alert('Please give us your answer!');
            // } else {
            //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
            // }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep == 4) {
            if (feedback['supports'] == '') {
                alert('Please give us your answer!');
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [strongSkills_data, setStrongSkill] = useState([]);
    const [interestSkills_data, setInterestSkill] = useState([]);
    const [roleId, setRole] = useState(3);
    //Get skill data
    useEffect(() => {
        UserService.getUserBoard().then(
            (response) => {
                const strongSkills = [];
                response.data.profile.strong_skills.forEach((skill, index) => {
                    if (skill_list.includes(skill.skill)) {
                        strongSkills.push(skill);
                    }
                });
                setStrongSkill(strongSkills);
                // setStrongSkill(response.data.profile.strong_skills);
                const interestSkills = [];
                response.data.profile.interest_skills.forEach((skill, index) => {
                    if (skill_list.includes(skill.skill)) {
                        interestSkills.push(skill);
                    }
                });
                setInterestSkill(interestSkills);
                // setInterestSkill(response.data.profile.interest_skills);
                setRole(response.data.profile.role);
            },
            (error) => {
                const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                alert(_events);
            }
        );
    }, []);
    console.log(strongSkills_data);
    const [content, setContent] = useState([]);

    const handleDelete_S = (skillIndex) => {
        setStrongSkill((prevSkills) => prevSkills.filter((_, index) => index !== skillIndex));
    };
    const handleDelete_I = (skillIndex) => {
        setInterestSkill((prevSkills) => prevSkills.filter((_, index) => index !== skillIndex));
    };
    const handleAddRow_s = () => {
        // Add a new row with initial data to the table
        setStrongSkill((prevSkills) => [...prevSkills, { skill: '', rate: '' }]);
    };
    const handleAddRow_i = () => {
        // Add a new row with initial data to the table
        setInterestSkill((prevSkills) => [...prevSkills, { skill: '', rate: '' }]);
    };
    const handleSkillChange_s = (skillIndex, newValue) => {
        setStrongSkill((prevData) => {
            const updatedData = [...prevData];
            updatedData[skillIndex].skill = newValue;
            return updatedData;
        });
    };

    const handleRateChange_s = (skillIndex, newValue) => {
        setStrongSkill((prevData) => {
            const updatedData = [...prevData];
            updatedData[skillIndex].rate = newValue;
            return updatedData;
        });
    };
    const handleSkillChange_i = (skillIndex, newValue) => {
        setInterestSkill((prevData) => {
            const updatedData = [...prevData];
            updatedData[skillIndex].skill = newValue;
            return updatedData;
        });
    };
    const handleRateChange_i = (skillIndex, newValue) => {
        setInterestSkill((prevData) => {
            const updatedData = [...prevData];
            updatedData[skillIndex].rate = newValue;
            return updatedData;
        });
    };

    const handleDelete_t = (personIndex, skillIndex) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].skills = updatedData[personIndex].skills.filter((_, index) => index !== skillIndex);
            return updatedData;
        });
    };
    const handleAddRow_t = (personIndex) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].skills = [...updatedData[personIndex].skills, { skill: '', rate: '' }];
            return updatedData;
        });
    };
    const handleSkillChange_t = (personIndex, skillIndex, newValue) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].skills[skillIndex].skill = newValue;
            return updatedData;
        });
    };
    const handleRateChange_t = (personIndex, skillIndex, newValue) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].skills[skillIndex].rate = newValue;
            return updatedData;
        });
    };

    const findDuplicateSkills = (data) => {
        const skillIndices = data.reduce((acc, item, index) => {
            const { skill } = item;
            if (!acc[skill]) {
                acc[skill] = [index];
            } else {
                acc[skill].push(index);
            }
            return acc;
        }, {});
        const duplicateSkills = Object.keys(skillIndices).filter((skill) => skillIndices[skill].length > 1);
        return duplicateSkills;
    };
    const findObjectsWithNullSkillAndRate = (data) => {
        const objectsWithNullSkillAndRate = data.filter(
            (item) => item.skill === '' || item.skill === null || item.rate === '' || item.rate === null
        );
        return objectsWithNullSkillAndRate;
    };

    async function handleSubmit(event) {
        event.preventDefault();
        setErrors([]);

        if (errors.length > 0) {
            return errors;
        }
    }
    /// Render page
    // if () {
    return (
        <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
            <h2>PUMAS Registration Form</h2>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ bgcolor: 'primary.main', display: 'flex', justifyContent: 'center', padding: 0 }}>
                            <h4 style={{ color: 'white' }}>{steps[activeStep].label}</h4>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            sx={{
                                backgroundColor: 'white',
                                borderBottom: activeStep != 6 ? '2px solid primary.main' : null,
                                borderColor: activeStep != 6 ? 'primary.main' : null
                                //
                            }}
                        >
                            {steps[activeStep].description}
                        </TableCell>
                    </TableRow>
                    {activeStep == 6 ? (
                        <TableRow>
                            <TableCell
                                sx={{
                                    backgroundColor: 'white',
                                    borderBottom: '2px solid primary.main',
                                    borderColor: 'primary.main'
                                    //
                                }}
                            >
                                <b>Please note! Your peers will see your anonymous comments below reflected on their SMART profiles.</b>
                            </TableCell>
                        </TableRow>
                    ) : null}
                </TableBody>
            </Table>
            {/* {activeStep != 0 ? <hr style={{ color: '#14a085' }} /> : null} */}
            <form onSubmit={handleSubmit}>
                <Box sx={{ height: '100%', maxWidth: '100%', width: '100%', p: 2 }}>
                    {activeStep == 0 && (
                        <Container>
                            <br></br>
                            <b>Welcome to your profile setup page!</b>
                            <br></br>
                            <br></br>
                            <p>Here, you can create your personalised profile by submitting some essential personal information. Setting up a profile allows you to fully engage with our platform and enjoy its benefits. By providing us with the necessary details, we can tailor your experience to meet your specific needs and preferences.</p>
                        </Container>
                    )}
                    {activeStep == 1 && (
                        <FormControl>
                            <Table className="table" sx={{ margin: 'auto' }}>
                                <TableBody className="text-capitalize">
                                    <TableRow style={{ color: 'white' }}>
                                        <TableCell>First Name</TableCell>
                                        <TableCell>
                                            <Input
                                                name="first_name"
                                                id="first_name"
                                                type="text"
                                                inputRef={first_nameRef}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' && e.preventDefault();
                                                }}
                                                ///
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ color: 'white' }}>
                                        <TableCell>Last Name</TableCell>
                                        <TableCell>
                                            <Input
                                                name="last_name"
                                                id="last_name"
                                                type="text"
                                                inputRef={last_nameRef}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' && e.preventDefault();
                                                }}
                                                ///
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ color: 'white' }}>
                                        <TableCell>Gender</TableCell>
                                        <TableCell>
                                            {gender_init.map((gender, index) => (
                                                <NativeSelect
                                                    ref={genderRef}
                                                    onChange={onChange}
                                                    defaultValue={gender}
                                                    inputProps={{
                                                        name: 'gender'
                                                    }}
                                                    onKeyPress={(e) => {
                                                        e.key === 'Enter' && e.preventDefault();
                                                    }}
                                                >
                                                    <option value="">Select</option>
                                                    <option key="g1" value={'Male'}>
                                                        Male
                                                    </option>
                                                    <option key="g2" value={'Female'}>
                                                        Female
                                                    </option>
                                                    <option key="g3" value={'Non-binary'}>
                                                        Non-binary
                                                    </option>
                                                    <option key="g4" value={'Other'}>
                                                        Other
                                                    </option>
                                                    <option key="g5" value={'Prefer not to say'}>
                                                        Prefer not to say
                                                    </option>
                                                </NativeSelect>
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{ color: 'white' }}>
                                        <TableCell>Current Position</TableCell>
                                        <TableCell>
                                            {current_position_init.map((position, index) => (
                                                <NativeSelect
                                                    onChange={onChange}
                                                    defaultValue={position}
                                                    inputProps={{
                                                        name: 'current_position'
                                                    }}
                                                    onKeyPress={(e) => {
                                                        e.key === 'Enter' && e.preventDefault();
                                                    }}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Other">Other</option>
                                                    {position_list.map((item, index) => (
                                                        <option key={index} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </NativeSelect>
                                            ))}
                                        </TableCell>
                                        {state['current_position'] === 'Other' && (
                                            <TableCell>
                                                <Input
                                                    name="current_position_Comment"
                                                    id="current_position"
                                                    type="text"
                                                    value={state['current_position_Comment']}
                                                    onChange={onChange}
                                                />
                                            </TableCell>
                                        )}
                                    </TableRow>
                                    <TableRow></TableRow>
                                    <TableRow style={{ color: 'white' }}>
                                        <TableCell>Dream Job</TableCell>
                                        <TableCell>
                                            <Input
                                                name="dream_job"
                                                id="dream_job"
                                                type="text"
                                                inputRef={dream_jobRef}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' && e.preventDefault();
                                                }}
                                                ///
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow></TableRow>
                                    <TableRow style={{ color: 'white' }}>
                                        <TableCell>Company/School</TableCell>
                                        <TableCell>
                                            <FormHelperText>search with at least 5 letters</FormHelperText>
                                            {company_or_school_init.map((company_or_school, index) => (
                                                <AsyncCreatableSelect
                                                    cacheOptions
                                                    defaultOptions
                                                    defaultInputValue={company_or_school}
                                                    loadOptions={promiseOptions}
                                                    onChange={onChange_companyOrSchool}
                                                    name="company_or_school"
                                                    placeholder={'Search & Select'}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: 'lightgrey',
                                                            primary: 'grey'
                                                        }
                                                    })}
                                                    onKeyDown={(e) => {
                                                        e.key === 'Enter' && e.preventDefault();
                                                    }}
                                                    ///
                                                />
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow></TableRow>
                                    <TableRow style={{ color: 'white' }}>
                                        <TableCell>Department/Class/Group</TableCell>
                                        <TableCell>
                                            <Input
                                                name="group"
                                                id="group"
                                                type="text"
                                                inputRef={groupRef}
                                                onKeyPress={(e) => {
                                                    e.key === 'Enter' && e.preventDefault();
                                                }}
                                                ///
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <br></br>
                            <div>
                                <Table style={{ width: '80%', margin: 'auto' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>No</TableCell>
                                            <TableCell>Skills</TableCell>
                                            <TableCell>Rate/5</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {strongSkills_data.map((skill, i) => (
                                            <TableRow>
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell>{skill.skill}</TableCell>
                                                <TableCell>{skill.rate}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </FormControl>
                    )}
                    {activeStep == 2 && (
                        <div>
                            <b>
                                Q1 & Q2. How can you amend your Smart Profile, as a result of this learning experience? What additional
                                skills, knowledge and perspectives did you take from the event? Use <i>Add Row</i> button below the list
                                to add new skills to your Smart Profile.
                            </b>
                            <b style={{ display: 'block', textAlign: 'center', textDecoration: 'underline' }}>
                                Strong skills on your Smart Profile
                            </b>
                            <TableContainer style={{ width: '80%', margin: 'auto' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>No</TableCell>
                                            <TableCell>Skill</TableCell>
                                            <TableCell>Rate/5</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {strongSkills_data.map((skills, skillIndex) => (
                                            <TableRow>
                                                <TableCell>{skillIndex + 1}. </TableCell>
                                                <TableCell>
                                                    <NativeSelect
                                                        defaultValue={skills.skill}
                                                        onChange={(e) => handleSkillChange_s(skillIndex, e.target.value)}
                                                        // inputProps={{
                                                        //     name: 'skill_s'
                                                        // }}
                                                    >
                                                        <option value="">Select</option>
                                                        {skill_list.map((item, index) => (
                                                            <option key={skillIndex + '-' + index} value={item}>
                                                                {item}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </TableCell>
                                                <TableCell>
                                                    <NativeSelect
                                                        defaultValue={skills.rate}
                                                        onChange={(e) => handleRateChange_s(skillIndex, e.target.value)}
                                                        // inputProps={{
                                                        //     name: 'rate_skill_s'
                                                        // }}
                                                    >
                                                        <option value="">Select</option>
                                                        {rating_list.map((item, index) => (
                                                            <option key={skillIndex + '-' + index} value={item}>
                                                                {item}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        onClick={() => handleDelete_S(skillIndex)}
                                                        ///
                                                    >
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <FormControl>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddRow_s}
                                        sx={{ margin: '1em' }}
                                        //
                                    >
                                        Add row
                                    </Button>
                                </FormControl>
                            </TableContainer>
                        </div>
                    )}
                    {activeStep == 3 && (
                        <div>
                            <b>
                                Q3. How would you like to amend the skills that you want to learn or improve, as a result of this
                                experience?
                            </b>
                            <br></br>
                            <b style={{ display: 'block', textAlign: 'center', textDecoration: 'underline' }}>
                                Your Skills to Learn or Improve
                            </b>
                            <TableContainer style={{ width: '80%', margin: 'auto' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>No</TableCell>
                                            <TableCell>Skill</TableCell>
                                            <TableCell>Rate/5</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {interestSkills_data.map((skills, skillIndex) => (
                                            <TableRow>
                                                <TableCell>{skillIndex + 1}. </TableCell>
                                                <TableCell>
                                                    <NativeSelect
                                                        defaultValue={skills.skill}
                                                        onChange={(e) => handleSkillChange_i(skillIndex, e.target.value)}
                                                        // inputProps={{
                                                        //     name: 'skill_i'
                                                        // }}
                                                    >
                                                        <option value="">Select</option>
                                                        {skill_list.map((item, index) => (
                                                            <option key={skillIndex + '-' + index} value={item}>
                                                                {item}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </TableCell>
                                                <TableCell>
                                                    <NativeSelect
                                                        defaultValue={skills.rate !== undefined ? skills.rate : ''}
                                                        onChange={(e) => handleRateChange_i(skillIndex, e.target.value)}
                                                        // inputProps={{
                                                        //     name: 'rate_skill_i'
                                                        // }}
                                                    >
                                                        <option value="">Select</option>
                                                        {rating_list.map((item, index) => (
                                                            <option key={skillIndex + '-' + index} value={item}>
                                                                {item}
                                                            </option>
                                                        ))}
                                                    </NativeSelect>
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        onClick={() => handleDelete_I(skillIndex)}
                                                        //
                                                    >
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <FormControl>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddRow_i}
                                        sx={{ margin: '1em' }}
                                        //
                                    >
                                        Add row
                                    </Button>
                                </FormControl>
                            </TableContainer>
                            <br></br>
                            <i>
                                "Change will not come if we wait for some other person or some other time. We are the ones we have been
                                waiting for. We are the change that we seek."
                            </i>
                            <br></br>
                            <b>- Barack Obama</b>
                        </div>
                    )}
                    {activeStep == 4 && (
                        <div>
                            <b>
                                Q4. What resources and/or support have helped your skill development most? What would help you progress
                                further?
                            </b>
                            <br></br>
                            <i style={{ color: '#14a085', margin: '1em' }}>
                                i.e. "I found asking effective questions and learning from getting things wrong the first time to be the
                                most helpful"
                            </i>
                            <TextField
                                sx={{ width: '100%' }}
                                multiline
                                rows={4}
                                variant="outlined"
                                name="supports"
                                id="supports"
                                type="text"
                                placeholder="Your answer"
                                inputProps={{ maxLength: 500 }}
                                onChange={handleChange}
                                // inputRef={supportsRef}
                                defaultValue={feedback['supports']}
                            />
                        </div>
                    )}
                    {activeStep == 6 && (
                        <div>
                            <br></br>
                            <b>Q6. How would you rate your peer's skills listed on their Smart Profile?</b>
                        </div>
                    )}
                    {activeStep == 5 && (
                        <div>
                            <b>
                                Q5. How will you turn your learnings into action? How could you practice your strong skills more
                                mindfully to achieve your goals in any area of your life?
                            </b>
                            <i>"The most effective way to do it, is to do it"</i>
                            <br></br>
                            <b>— Amelia Earhart</b>
                        </div>
                    )}
                </Box>
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                            variant="contained"
                            color="primary"
                            ///
                        >
                            Next
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={handleBack}
                            disabled={activeStep === 0}
                            variant="outlined"
                            //color="primary"
                        >
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            Back
                        </Button>
                    }
                />
                {errors.length > 0 ? (
                    <Alert severity="error" aria-live="assertive">
                        {errors.map((error, index) => {
                            return <p key={`alert-${index}`}>{error}</p>;
                        })}
                    </Alert>
                ) : (
                    <></>
                )}
                {activeStep != maxSteps - 1 ? null : (
                    <FormControl style={{ display: 'flex', justifyContent: 'flex-end', mt: '4em', mb: '4em' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ margin: 'auto' }}
                            type="submit"
                            //
                        >
                            Submit
                        </Button>
                    </FormControl>
                )}
            </form>
        </Box>
    );
    // } else {
    //     return (
    //         <Box>
    //             <h4>Loading...</h4>
    //         </Box>
    //     );
    // }
};
