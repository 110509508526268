// project import
import pages from './pages';
import dashboard from './dashboard';
import utilities from './utilities';
import support from './support';
import smartprofile from './profile_pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [dashboard, smartprofile, pages, utilities, support]
    items: [smartprofile]
};

export default menuItems;
