/* eslint-disable */
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import React, { useState, useEffect } from 'react';
import UserService from 'services/user.service';
import { FeedbackNotificationEmail } from 'email/feedbackNotification.js';
import { render } from '@react-email/render';
import {
    Alert,
    Box,
    Button,
    FormControl,
    Link,
    NativeSelect,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    TextField,
    Stack,
    Badge,
    Chip
} from '@mui/material';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import DataTable from 'pages/authentication/auth-forms/dataTable';
import DataTable_team from 'pages/authentication/auth-forms/dataTable_teammate';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Axios from 'axios';

if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
const steps = [
    {
        label: '',
        description: `Thank you for your participation in our event. We sincerely hope that your experience was enjoyable and valuable, just as it was for us to organize and host.`
    },
    {
        label: 'Reviewing Your Strong Skills',
        // label: 'Identifying Your Assumptions',
        description: 'In this section we will ask you to reflect on your assumptions coming in to the experience.'
    },
    {
        label: 'Reviewing Your Strong Skills',
        description: 'In this section, we invite you to review your strong skills listed on your SMART Profile.'
    },
    {
        label: 'Improving Your Skills',
        description: 'In this section, we will ask you to evaluate your progress in developing skills you wanted to improve.'
    },
    {
        label: 'Evaluating Your Experience',
        description: 'In this section, we prompt you to reflect on your learning journey.'
    },
    {
        label: 'Putting Your Learning into Action',
        description: 'In this section, reflect on how you will turn this learning into action.'
    },
    {
        label: "Supporting Your Peers' Development",
        description: `In this section, we prompt you to reflect on your peers' growth and development.`
    },
    {
        label: "Mentorship Feedback",
        description: `In this final section, we prompt you to reflect on your experience with your mentor.`
    }
];
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
function SkillList(strongSkills) {
    if (!strongSkills) {
        return []; // Return an empty array if strongSkills is falsy (e.g., null, undefined)
    }
    return strongSkills.map((item) => ' ' + item.skill);
}

function cleanParticipants(participants, allUsers) {
    const allUserIds = allUsers.map((user) => user.id);
    return participants.filter((participant) => participant != null && allUserIds.includes(participant.id));
}
function cleanParticipants_withIds(participants, allUsers) {
    const allUserIds = allUsers.map((user) => user.id);
    return participants.filter((participant) => participant != null && allUserIds.includes(participant));
}

function updateTables(tables, indexMapping) {
    const mappedTables = tables.map((table) => table.map((row) => row.map((originalIndex) => indexMapping[originalIndex])));
    const filteredTables = mappedTables.map((round) => round.map((table) => table.filter((participant) => participant != null)));
    return filteredTables;
}
export const Feedback = () => {
    const emailHtml = render(<FeedbackNotificationEmail />);
    const navigate = useNavigate();
    const { user: currentUser } = useSelector((state) => state.auth);
    const location = useLocation();
    const prevLocation = encodeURIComponent(window.location.origin + location.pathname + location.search);

    if (!currentUser) {
        navigate(`/login?redirectTo=${prevLocation}`);
    }
    const query = useQuery();
    const project = parseInt(query.get('project'));
    const round = parseInt(query.get('round'));
    if (isNaN(project) || isNaN(round)) {
        navigate('/');
    }

    //Get feedback data
    const [feedback_count, setFeedbackCount] = useState(0);
    useEffect(() => {
        UserService.getFeedbacks().then(
            (response) => {
                const feedback = JSON.parse(response.data.feedback_data).filter((feedback) => feedback.fields.project === project && feedback.fields.round === round);
                if (feedback.length > 0) {
                    setFeedbackCount(1);
                }
            },
            (error) => {
                const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('error2: ' + _events);
            }
        );
    }, []);
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);

    const skill_list = UserService.skill_list;
    const rating_list = UserService.rating_list;
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        const duplicateSkills_s = findDuplicateSkills(strongSkills_data);
        const duplicateSkills_s_new = findDuplicateSkills([...strongSkills_data, ...new_strongSkills_data]);
        const duplicateSkills_i = findDuplicateSkills(interestSkills_data);
        const nullobjects_s = findObjectsWithNullSkillAndRate(strongSkills_data);
        const nullobjects_s_new = findObjectsWithNullSkillAndRate(new_strongSkills_data);
        const nullobjects_i = findObjectsWithNullSkillAndRate(interestSkills_data);
        if (activeStep == 1) {
            if (duplicateSkills_s != '') {
                alert('There is duplicate skill. Please re-check!');
            } else {
                if (nullobjects_s != '') {
                    alert('Skill/Rate has not been selected. Please re-check!');
                } else {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
            }
        } else if (activeStep == 2) {
            if (duplicateSkills_s_new != '') {
                alert('There is duplicate skill. Please re-check!');
            } else {
                if (nullobjects_s_new != '') {
                    alert('Skill/Rate has not been selected. Please re-check!');
                } else {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
            }
        } else if (activeStep == 3) {
            if (duplicateSkills_i != '') {
                alert('There is duplicate skill. Please re-check!');
            } else {
                if (nullobjects_i != '') {
                    alert('Skill/Rate has not been selected. Please re-check!');
                } else {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
            }
        } else if (activeStep == 6) {
            let dup_or_null = false;
            teammates_data?.filter(teammate => teammate.role === "Participant").forEach((person, personIndex) => {
                const duplicateSkills_s = findDuplicateSkills(person.skills);
                const nullobjects_s = findObjectsWithNullSkillAndRate(person.skills);
                const words = person.comments.split(/\s+/);
                if (duplicateSkills_s != '') {
                    alert('There is duplicate skill. Please re-check (teammate No: ' + (personIndex + 1) + ', name: ' + person.name + ').');
                    dup_or_null = true;
                } else if (nullobjects_s != '') {
                    alert(
                        'Skill/Rate has not been selected. Please re-check (teammate No: ' +
                            (personIndex + 1) +
                            ', name: ' +
                            person.name +
                            ').'
                    );
                    dup_or_null = true;
                } else if (words.length < 10) {
                    alert('Please add a comment of at least 10 words for your teammate (teammate No: ' + (personIndex + 1) + ', name: ' + person.name + ').');
                    dup_or_null = true;
                    setSubmitting(false);
                }
            });
            if (dup_or_null == false) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (activeStep == 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep == 4) {
            if (feedback['supports'] == '') {
                alert('Please give us your answer!');
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (activeStep == 5) {
            if (feedback['actions'] == '') {
                alert('Please give us your answer!');
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [strongSkills_data, setStrongSkill] = useState([]);
    const [new_strongSkills_data, setNewStrongSkill] = useState([]);
    const [interestSkills_data, setInterestSkill] = useState([]);
    const [roleId, setRole] = useState(3);
    //Get skill data
    useEffect(() => {
        UserService.getUserBoard().then(
            (response) => {
                const strongSkills = [];
                response?.data?.profile?.strong_skills?.forEach((skill, index) => {
                    if (skill_list.includes(skill.skill)) {
                        strongSkills.push(skill);
                    }
                });
                setStrongSkill(strongSkills);
                const interestSkills = [];
                response.data.profile.interest_skills.forEach((skill, index) => {
                    if (skill_list.includes(skill.skill)) {
                        interestSkills.push(skill);
                    }
                });
                setInterestSkill(interestSkills);
                setRole(response.data.profile.role);
            },
            (error) => {
                const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                alert(_events);
            }
        );
    }, []);
    const [content, setContent] = useState([]);

    //Get project data
    useEffect(() => {
        UserService.getProjects().then(
            (response) => {
                setContent(JSON.parse(response.data['project_data']));
                if (!JSON.parse(response.data['project_data']).find((p) => p.pk === project)) {
                    navigate('/');
                }
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                setContent(_content);
            }
        );
    }, []);
    //Get allUser
    const [allUsers, setAllUsers] = useState([]);
    useEffect(() => {
        UserService.getAdminBoard().then(
            (response) => {
                const userData = JSON.parse(response.data['user_data']);
                const profileData = JSON.parse(response.data['profiles_data']);
                const usersDictionary = userData.reduce((acc, value) => {
                    if (value.pk != '1') {
                        const profile = profileData.find((p) => p.fields.user === value.pk);
                        acc[value.pk] = {
                            email: value.fields.username,
                            name: value.fields.first_name + ' ' + value.fields.last_name,
                            gender: profile ? profile.fields.gender : '-',
                            'current position': profile ? profile.fields.current_position : '-',
                            'department or class': profile ? profile.fields.group : '-',
                            'company or school': profile ? profile.fields.company_or_school : '-',
                            role: profile ? profile.fields.role : '-',
                            skills: profile ? SkillList(profile.fields.strong_skills) : '-',
                            dateJoined: value.fields.date_joined,
                            lastLogin: value.fields.last_login
                        };
                    }
                    return acc;
                }, {});
                const usersArray = Object.entries(usersDictionary).map(([key, value]) => ({
                    id: key,
                    ...value
                }));
                const updatedUsersArray = usersArray.map((user) => {
                    if (user.role == '4') {
                        return { ...user, role: 'Mentor' };
                    } else if (user.role == '3') {
                        return { ...user, role: 'Participant' };
                    } else if (user.role == '2') {
                        return { ...user, role: 'Stakeholder' };
                    } else if (user.role == '1') {
                        return { ...user, role: 'Admin' };
                    }
                    return user;
                });
                setAllUsers(updatedUsersArray);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                console.log('error: ', _content);
                navigate('/login');
            }
        );
    }, []);
    // useEffect(() => {
    //     UserService.getAllUsers().then(
    //         (response) => {
    //             if (response.data['error']) {
    //                 setErrors('An error occurred: ' + response.data['error'] + ' Please contact the administrator!');
    //             } else if (response.data['user_data']) {
    //                 const userData = response.data['user_data'];
    //                 setAllUsers(userData);
    //             }
    //         },
    //         (error) => {
    //             const _content =
    //                 (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    //             console.log(_content);
    //             navigate('/login');
    //         }
    //     );
    // }, []);
    const project_data = content.find((p) => p.pk === project);
    const [teammates_data, setTeamMates] = useState(null);
    // console.log('teammates_data', teammates_data);
    //find teammates for current user.
    useEffect(() => {
        if (allUsers.length > 0 && project_data && round) {
            //-----clean data ----
            // Tables based on indexes
            const grouping_results_original = project_data.fields.grouping_results;
            const participants_userId_original = project_data.fields.participants_userId;
            // Original list of participants
            const participants_original = project_data.fields.participants;
            // Remove null elements from the participants
            const participants = cleanParticipants(participants_original, allUsers);
            // Create a mapping between original indices and new indices
            const indexMapping = {};
            participants.forEach((participant, newIndex) => {
                indexMapping[participants_original.indexOf(participant)] = newIndex;
            });
            // Update the tables with the new indices
            const grouping_results = updateTables(grouping_results_original, indexMapping);
            // Update participants & results with Ids
            const participants_userId =
                participants_userId_original.length > 0
                    ? participants_userId_original.map((roundParticipants) => cleanParticipants_withIds(roundParticipants, allUsers))
                    : [];

            let updatedParticipants = [];
            if (participants_userId.length > 0) {
                updatedParticipants = participants_userId[round - 1].map((participantId) => {
                    const user = allUsers.find((user) => user.id === participantId);
                    return user ? user : null;
                });
            } else {
                updatedParticipants = participants
                    .map((p) => p.id)
                    .map((participantId) => {
                        const user = allUsers.find((user) => user.id === participantId);
                        return user ? user : null;
                    });
            }

            //find index of current user
            const userIndex = updatedParticipants.findIndex((user) => user.id == currentUser.id);

            if (userIndex === -1) {
                navigate('/');
            } else if (roleId != 3) {
                alert('You should be a participant/mentee to access this form! Please check your role!');
                navigate('/');
            } else {
                const teammatesArr = [];
                let foundUserIndex = false; // Flag to check if userIndex is found
                grouping_results[round - 1].forEach((item) => {
                    item.forEach((i) => {
                        if (i === userIndex) {
                            teammatesArr.push(...item.filter((teammate_i) => teammate_i !== i));
                            const teammatesData = teammatesArr.map((teammate_i) => updatedParticipants[teammate_i]);
                            const teammates_data_transformed = (teammates_data) => {
                                return teammates_data.map((person) => {
                                    let skillArr = [];
                                    if (person.role === 'Mentor') {
                                        // If person is a Mentor, set skills array to empty
                                        skillArr = [];
                                    } else {
                                        // Filter and map skills based on skill_list
                                        skillArr = person.skills
                                            .filter((skill) => skill_list.includes(skill.trim()))
                                            .map((skill) => ({
                                                rate: '', // Set the initial rating to an empty string
                                                skill: skill
                                            }));
                                    }
                                    return {
                                        id: person.id,
                                        name: person.name,
                                        email: person.email,
                                        role: person.role,
                                        gender: person.gender,
                                        current_position: person['current position'],
                                        company_or_school: person['company or school'],
                                        department_or_class: person['department_or_class'],
                                        dateJoined: person.dateJoined,
                                        skill_number_init: person.skills.filter((skill) => skill_list.includes(skill.trim())).length,
                                        // skills: person.skills
                                        //     .filter((skill) => skill_list.includes(skill.trim()))
                                        //     .map((skill) => ({
                                        //         rate: '', // Set the initial rating to an empty string
                                        //         skill: skill
                                        //     })),
                                        skills: skillArr, // Assign the filtered and mapped skills array
                                        comments: ''
                                    }
                                });
                            };
                            setTeamMates(teammates_data_transformed(teammatesData));
                            // Set the flag to true when userIndex is found
                            foundUserIndex = true;
                        }
                    });
                });
                // Check if userIndex is not found, and handle the case
                if (!foundUserIndex) {
                    //console.log('User not found in all rounds.');
                    alert('Sorry! This feedback form is no longer available for you.');
                    navigate('/');
                }
            }
        } else {
            // navigate('/');
            console.log('Project/round not found.');
        }
    }, [project_data, round, currentUser.id, allUsers]);
    // Count mentors
    const mentorCount = teammates_data?.filter(teammate => teammate.role === "Mentor").length;
    const maxSteps = mentorCount > 0 ? steps.length : steps.length - 1;

    const handleDelete_S = (skillIndex) => {
        setStrongSkill((prevSkills) => prevSkills.filter((_, index) => index !== skillIndex));
    };
    const handleDelete_S_new = (skillIndex) => {
        setNewStrongSkill((prevSkills) => prevSkills.filter((_, index) => index !== skillIndex));
    };
    const handleDelete_I = (skillIndex) => {
        setInterestSkill((prevSkills) => prevSkills.filter((_, index) => index !== skillIndex));
    };

    const handleAddRow_s_new = () => {
        // Add a new row with initial data to the table
        setNewStrongSkill((prevSkills) => [...prevSkills, { skill: '', rate: '' }]);
    };
    const handleAddRow_i = () => {
        // Add a new row with initial data to the table
        setInterestSkill((prevSkills) => [...prevSkills, { skill: '', rate: '' }]);
    };
    const handleSkillChange_s = (skillIndex, newValue) => {
        setStrongSkill((prevData) => {
            const updatedData = [...prevData];
            updatedData[skillIndex].skill = newValue.label;
            return updatedData;
        });
    };
    const handleSkillChange_s_new = (skillIndex, newValue) => {
        setNewStrongSkill((prevData) => {
            const updatedData = [...prevData];
            updatedData[skillIndex].skill = newValue.label;
            return updatedData;
        });
    };

    const handleRateChange_s = (skillIndex, newValue) => {
        setStrongSkill((prevData) => {
            const updatedData = [...prevData];
            updatedData[skillIndex].rate = newValue;
            return updatedData;
        });
    };
    const handleRateChange_s_new = (skillIndex, newValue) => {
        setNewStrongSkill((prevData) => {
            const updatedData = [...prevData];
            updatedData[skillIndex].rate = newValue;
            return updatedData;
        });
    };
    const handleSkillChange_i = (skillIndex, newValue) => {
        setInterestSkill((prevData) => {
            const updatedData = [...prevData];
            updatedData[skillIndex].skill = newValue.label;
            return updatedData;
        });
    };
    const handleRateChange_i = (skillIndex, newValue) => {
        setInterestSkill((prevData) => {
            const updatedData = [...prevData];
            updatedData[skillIndex].rate = newValue;
            return updatedData;
        });
    };

    const handleDelete_t = (personIndex, skillIndex) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].skills = updatedData[personIndex].skills.filter((_, index) => index !== skillIndex);
            return updatedData;
        });
    };
    const handleAddRow_t = (personIndex) => {
        console.log(teammates_data);

        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].skills = [...updatedData[personIndex].skills, { skill: '', rate: '' }];
            return updatedData;
        });
    };
    const handleSkillChange_t = (personIndex, skillIndex, newValue) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].skills[skillIndex].skill = newValue.label;
            return updatedData;
        });
    };
    const handleRateChange_t = (personIndex, skillIndex, newValue) => {
        console.log('newRate', newValue);
        console.log('skillIndex', skillIndex);
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].skills[skillIndex].rate = newValue;
            return updatedData;
        });
    };
    const handleCommentChange_t = (personIndex, newValue) => {
        setTeamMates((prevData) => {
            const updatedData = [...prevData];
            updatedData[personIndex].comments = newValue;
            return updatedData;
        });
    };
    const findDuplicateSkills = (data) => {
        const skillIndices = data.reduce((acc, item, index) => {
            //const { skill } = item;
            const skill = item.skill.trim(); // Corrected object destructuring
            if (!acc[skill]) {
                acc[skill] = [index];
            } else {
                acc[skill].push(index);
            }
            return acc;
        }, {});
        const duplicateSkills = Object.keys(skillIndices).filter((skill) => skillIndices[skill].length > 1);
        return duplicateSkills;
    };
    const findObjectsWithNullSkillAndRate = (data) => {
        const objectsWithNullSkillAndRate = data.filter(
            (item) => item.skill === '' || item.skill === null || item.rate === '' || item.rate === null
        );
        return objectsWithNullSkillAndRate;
    };

    const [feedback, setFeedback] = useState({
        assumptions: '',
        supports: '',
        actions: ''
    });
    // Update specific input field
    const handleChange = (e) => setFeedback((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));

    async function handleSubmit(event) {
        event.preventDefault();
        if (isSubmitting) {
            return;
        }
        setSubmitting(true);
        setErrors([]);
        
        // Add 'new' field to each element in array a
        const strongSkills = strongSkills_data.map((item) => ({ ...item, new: 0 }));
        // Add 'new' field to each element in array b
        const new_strongSkills = new_strongSkills_data.map((item) => ({ ...item, new: 1 }));
        let dup_or_null = false;
        teammates_data.forEach((person, personIndex) => {
            const duplicateSkills_s = findDuplicateSkills(person.skills);
            const nullobjects_s = findObjectsWithNullSkillAndRate(person.skills);
            const words = person.comments.split(/\s+/);
            if (duplicateSkills_s != '') {
                alert('There is duplicate skill. Please re-check (teammate No: ' + (personIndex + 1) + ', name: ' + person.name + ').');
                dup_or_null = true;
                setSubmitting(false);
            } else if (nullobjects_s != '') {
                alert(
                    'Skill/Rate has not been selected. Please re-check (teammate No: ' +
                        (personIndex + 1) +
                        ', name: ' +
                        person.name +
                        ').'
                );
                dup_or_null = true;
                setSubmitting(false);
            } else if ((person.role ==="Participant" || person.role ==="Mentor") && words.length < 10) {
                alert('Please add a comment of at least 10 words for your teammate (No: ' + (personIndex + 1) + ', name: ' + person.name + ').');
                dup_or_null = true;
                setSubmitting(false);
            }
        });
        if (dup_or_null == false) {
            if (errors.length > 0) {
                setSubmitting(false);
                return;
            }
            const teammates_data_transformed2 = (teammates_data) => {
                return teammates_data.map((person) => ({
                    id: person.id,
                    skills: person.skills,
                    comments: person.comments
                }));
            };
            const payload = {
                emailHtml: emailHtml,
                feedbackLink: `https://pumas.activepeers.ai/feedback-from-peers?projectId=${project}&round=${round}`,
                project: project,
                round: round,
                assumptions: feedback['assumptions'],
                supports: feedback['supports'],
                // strong_skills: strongSkills_data,
                strong_skills: [...strongSkills, ...new_strongSkills],
                // strong_skills: { skills: strongSkills_data, skills_new: new_strongSkills_data },
                interest_skills: interestSkills_data,
                teammates: teammates_data_transformed2(teammates_data),
                actions: feedback['actions']
            };
            Axios.post(`${backend_base_url}/feedbacks/create`, payload, {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
            })
                .then((response) => {
                    if (response) {
                        if (response['data']) {
                            if (response['data']['response']) {
                                if (response['data']['response'] == 'error') {
                                    setSubmitting(false);
                                    return setErrors([
                                        'An error occurred (' + JSON.stringify(response['data']['message']) + ') and we could not save the data.'
                                    ]);
                                } else {
                                    navigate('/feedback_submitted');
                                }
                            } else {
                                setSubmitting(false);
                                return setErrors(['An error occurred (' + JSON.stringify(response['data']) + ').']);
                            }
                        } else {
                            setSubmitting(false);
                            return setErrors(['An error occurred (' +  JSON.stringify(response) + ').']);
                        }
                    } else {
                        setSubmitting(false);
                        return setErrors(['An error occurred (no response).']);
                    }
                })
                .catch((error) => {
                    // Handle error response
                    //console.error('Error while submitting feedback:', error);
                    setSubmitting(false);
                    return setErrors(['An error occurred while submitting the feedback (' + error + '). Please try again later.']);
                });
        }
    }
    /// Render page
    if (feedback_count === 0 && project_data && teammates_data && teammates_data != null) {
        return (
            <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                <h2>Time To Reflect, Review And Move Forward</h2>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ bgcolor: 'primary.main', display: 'flex', justifyContent: 'center', padding: 0 }}>
                                <h4 style={{ color: 'white' }}>{steps[activeStep].label}</h4>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    backgroundColor: 'white',
                                    borderBottom: activeStep != 6 ? '2px solid primary.main' : null,
                                    borderColor: activeStep != 6 ? 'primary.main' : null
                                    //
                                }}
                            >
                                {steps[activeStep].description}
                            </TableCell>
                        </TableRow>
                        {activeStep == 6 ? (
                            <TableRow>
                                <TableCell
                                    sx={{
                                        backgroundColor: 'white',
                                        borderBottom: '2px solid primary.main',
                                        borderColor: 'primary.main'
                                        //
                                    }}
                                >
                                    <b>Please note! Your peers will see your anonymous comments below reflected on their SMART profiles.</b>
                                </TableCell>
                            </TableRow>
                        ) : null}
                        {activeStep == 7 ? (
                            <TableRow>
                                <TableCell
                                    sx={{
                                        backgroundColor: 'white',
                                        borderBottom: '2px solid primary.main',
                                        borderColor: 'primary.main'
                                        //
                                    }}
                                >
                                    <b>Please note! Your mentor will see your anonymous comments below.</b>
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </Table>
                {/* {activeStep != 0 ? <hr style={{ color: '#14a085' }} /> : null} */}
                <form onSubmit={handleSubmit}>
                    <Box sx={{ height: '100%', maxWidth: '100%', width: '100%', p: 2 }}>
                        {activeStep == 0 && (
                            <div>
                                <br></br>
                                <b>Reflection offers a range of benefits:</b>
                                <ol>
                                    <li>Gain a profound understanding of your personal growth</li>
                                    <li>Improve interview readiness</li>
                                    <li>Encourage honest peer feedback</li>
                                    <li>Foster personal growth and development in others</li>
                                    <li>Identify areas for improvement</li>
                                    <li>Cultivate better team communication and collaboration</li>
                                </ol>
                            </div>
                        )}
                        {activeStep == 1 && (
                            <div>
                                <b>Q1. How can you amend your Smart Profile, as a result of this learning experience?</b>
                                <b style={{ display: 'block', textAlign: 'center', textDecoration: 'underline' }}>
                                    Strong skills on your Smart Profile
                                </b>
                                <Stack spacing={2} sx={{ m: 5 }}>
                                    <DataTable
                                        skillsData={strongSkills_data}
                                        handleSkillChange={handleSkillChange_s}
                                        handleRateChange={handleRateChange_s}
                                        handleDelete={handleDelete_S}
                                    />
                                </Stack>
                                {/* <Box sx={{ pt: 1, pr: 2, overflow: "auto" }}>
                                    <TableContainer style={{ width: '80%', margin: 'auto' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>No</TableCell>
                                                    <TableCell>Skill</TableCell>
                                                    <TableCell>Rate/5</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {strongSkills_data.map((skills, skillIndex) => (
                                                    <TableRow>
                                                        <TableCell>{skillIndex + 1}. </TableCell>
                                                        <TableCell sx={{ minWidth: '300px' }}>
                                                            <NativeSelect
                                                                defaultValue={skills.skill}
                                                                onChange={(e) => handleSkillChange_s(skillIndex, e.target.value)}
                                                                // inputProps={{
                                                                //     name: 'skill_s'
                                                                // }}
                                                            >
                                                                <option value="">Select</option>
                                                                {skill_list.map((item, index) => (
                                                                    <option key={skillIndex + '-' + index} value={item}>
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </NativeSelect>
                                                        </TableCell>
                                                        <TableCell sx={{ minWidth: '150px' }}>
                                                            <NativeSelect
                                                                defaultValue={skills.rate}
                                                                onChange={(e) => handleRateChange_s(skillIndex, e.target.value)}
                                                                // inputProps={{
                                                                //     name: 'rate_skill_s'
                                                                // }}
                                                            >
                                                                <option value="">Select</option>
                                                                {rating_list.map((item, index) => (
                                                                    <option key={skillIndex + '-' + index} value={item}>
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </NativeSelect>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={() => handleDelete_S(skillIndex)}
                                                                ///
                                                            >
                                                                Delete
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box> */}
                            </div>
                        )}
                        {activeStep == 2 && (
                            <div>
                                <b>
                                    Q2. What additional skills, knowledge and perspectives did you take from the event? Use <i>Add Row </i>
                                    button below the list to add new skills to your Smart Profile.
                                </b>
                                <b style={{ display: 'block', textAlign: 'center', textDecoration: 'underline' }}>
                                    Strong skills on your Smart Profile
                                </b>
                                <TableContainer style={{ width: '90%', margin: 'auto' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>No</TableCell>
                                                <TableCell>Skill</TableCell>
                                                <TableCell>Rate/5</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {strongSkills_data.map((skill, i) => (
                                                <TableRow>
                                                    <TableCell>{i + 1}</TableCell>
                                                    <TableCell>{skill.skill}</TableCell>
                                                    <TableCell>{skill.rate}</TableCell>
                                                </TableRow>
                                            ))}
                                            {/* {new_strongSkills_data.map((skills, skillIndex) => (
                                                <TableRow>
                                                    <TableCell>{skillIndex + strongSkills_data.length + 1}. </TableCell>
                                                    <TableCell>
                                                        <NativeSelect
                                                            defaultValue={skills.skill}
                                                            onChange={(e) => handleSkillChange_s_new(skillIndex, e.target.value)}
                                                            // inputProps={{
                                                            //     name: 'skill_s'
                                                            // }}
                                                        >
                                                            <option value="">Select</option>
                                                            {skill_list.map((item, index) => (
                                                                <option key={skillIndex + '-' + index} value={item}>
                                                                    {item}
                                                                </option>
                                                            ))}
                                                        </NativeSelect>
                                                    </TableCell>
                                                    <TableCell>
                                                        <NativeSelect
                                                            defaultValue={skills.rate}
                                                            onChange={(e) => handleRateChange_s_new(skillIndex, e.target.value)}
                                                            // inputProps={{
                                                            //     name: 'rate_skill_s'
                                                            // }}
                                                        >
                                                            <option value="">Select</option>
                                                            {rating_list.map((item, index) => (
                                                                <option key={skillIndex + '-' + index} value={item}>
                                                                    {item}
                                                                </option>
                                                            ))}
                                                        </NativeSelect>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="outlined"
                                                            color="error"
                                                            onClick={() => handleDelete_S_new(skillIndex)}
                                                            ///
                                                        >
                                                            Delete
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))} */}
                                        </TableBody>
                                    </Table>
                                    <Stack spacing={2} sx={{ m: 5 }}>
                                        <DataTable
                                            skillsData={new_strongSkills_data}
                                            handleSkillChange={handleSkillChange_s_new}
                                            handleRateChange={handleRateChange_s_new}
                                            handleDelete={handleDelete_S_new}
                                        />
                                    </Stack>
                                    <FormControl>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleAddRow_s_new}
                                            sx={{ margin: '1em' }}
                                            //
                                        >
                                            Add row
                                        </Button>
                                    </FormControl>
                                </TableContainer>
                            </div>
                        )}
                        {activeStep == 3 && (
                            <div>
                                <b>
                                    Q3. How would you like to amend the skills that you want to learn or improve, as a result of this
                                    experience?
                                </b>
                                <br></br>
                                <b style={{ display: 'block', textAlign: 'center', textDecoration: 'underline' }}>
                                    Your Skills to Learn or Improve
                                </b>
                                <Stack spacing={2} sx={{ m: 5 }}>
                                    <DataTable
                                        skillsData={interestSkills_data}
                                        handleSkillChange={handleSkillChange_i}
                                        handleRateChange={handleRateChange_i}
                                        handleDelete={handleDelete_I}
                                    />
                                </Stack>
                                {/* <Box sx={{ pt: 1, pr: 2, overflow: "auto" }}>
                                    <TableContainer style={{ width: '80%', margin: 'auto' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>No</TableCell>
                                                    <TableCell>Skill</TableCell>
                                                    <TableCell>Rate/5</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {interestSkills_data.map((skills, skillIndex) => (
                                                    <TableRow>
                                                        <TableCell>{skillIndex + 1}. </TableCell>
                                                        <TableCell sx={{ minWidth: '300px' }}>
                                                            <NativeSelect
                                                                defaultValue={skills.skill}
                                                                onChange={(e) => handleSkillChange_i(skillIndex, e.target.value)}
                                                                // inputProps={{
                                                                //     name: 'skill_i'
                                                                // }}
                                                            >
                                                                <option value="">Select</option>
                                                                {skill_list.map((item, index) => (
                                                                    <option key={skillIndex + '-' + index} value={item}>
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </NativeSelect>
                                                        </TableCell>
                                                        <TableCell sx={{ minWidth: '150px' }}>
                                                            <NativeSelect
                                                                defaultValue={skills.rate !== undefined ? skills.rate : ''}
                                                                onChange={(e) => handleRateChange_i(skillIndex, e.target.value)}
                                                                // inputProps={{
                                                                //     name: 'rate_skill_i'
                                                                // }}
                                                            >
                                                                <option value="">Select</option>
                                                                {rating_list.map((item, index) => (
                                                                    <option key={skillIndex + '-' + index} value={item}>
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </NativeSelect>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={() => handleDelete_I(skillIndex)}
                                                                //
                                                            >
                                                                Delete
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <FormControl>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleAddRow_i}
                                                sx={{ margin: '1em' }}
                                                //
                                            >
                                                Add row
                                            </Button>
                                        </FormControl>
                                    </TableContainer>
                                </Box> */}
                                <FormControl>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddRow_i}
                                        sx={{ margin: '1em' }}
                                        //
                                    >
                                        Add row
                                    </Button>
                                </FormControl>
                                <br></br>
                                <i>
                                    "Change will not come if we wait for some other person or some other time. We are the ones we have been
                                    waiting for. We are the change that we seek."
                                </i>
                                <br></br>
                                <b>- Barack Obama</b>
                            </div>
                        )}
                        {activeStep == 4 && (
                            <div>
                                <b>
                                    Q4. What resources and/or support have helped your skill development most? What would help you progress
                                    further?
                                </b>
                                <br></br>
                                <i style={{ color: '#14a085', margin: '1em' }}>
                                    Example: "I found asking effective questions and learning from getting things wrong the first time to be the
                                    most helpful"
                                </i>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="supports"
                                    id="supports"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    onChange={handleChange}
                                    // inputRef={supportsRef}
                                    defaultValue={feedback['supports']}
                                />
                            </div>
                        )}
                        {activeStep == 6 && (
                            <div>
                                <br></br>
                                <b>Q6. How would you rate your peer's skills listed on their Smart Profile?</b>
                                {/* <b>Q5. How would you rate your peer's proficiency in each of the skills listed on their Smart Profile?</b> */}
                                {teammates_data?.map((teammate, teammateIndex) => (
                                    teammate.role === "Participant" && (
                                        <div>
                                            <br></br>
                                            <Chip
                                                label={teammate.name + (teammate.company_or_school !== '' ? ' - ' + teammate.company_or_school : '')}
                                                color="secondary"
                                                variant="outlined"
                                                icon={<PermIdentityIcon />}
                                            >
                                                {/* {teammate.name} - {teammate.company_or_school != '' ? teammate.company_or_school : ''} */}
                                            </Chip>
                                            <Box sx={{ pt: 1, pr: 2, overflow: "auto" }}>
                                                <TableContainer style={{ width: '95%', margin: 'auto' }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>No</TableCell>
                                                                <TableCell>Skill</TableCell>
                                                                <TableCell>Rate/5</TableCell>
                                                                <TableCell></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {teammate.skills.map((skill, skillIndex) => (
                                                                skillIndex <= (teammate.skill_number_init - 1) && (
                                                                    <TableRow key={skillIndex}>
                                                                        <TableCell>{skillIndex + 1}. </TableCell>
                                                                        <TableCell>
                                                                            {skill.skill.trim()}
                                                                            {/* <NativeSelect
                                                                                defaultValue={skill.skill.trim()}
                                                                                onChange={(e) =>
                                                                                    handleSkillChange_t(teammateIndex, skillIndex, e.target.value)
                                                                                }
                                                                                disabled={skillIndex <= teammate.skill_number_init - 1} // Corrected disabled attribute
                                                                                // inputProps={{
                                                                                //     name: 'skill_i'
                                                                                // }}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {skill_list.map((item, index) => (
                                                                                    <option key={skillIndex + '-' + index} value={item}>
                                                                                        {item}
                                                                                    </option>
                                                                                ))}
                                                                            </NativeSelect> */}
                                                                        </TableCell>
                                                                        <TableCell sx={{ minWidth: '150px' }}>
                                                                            <NativeSelect
                                                                                defaultValue={skill.rate !== undefined ? skill.rate : ''}
                                                                                onChange={(e) =>
                                                                                    handleRateChange_t(teammateIndex, skillIndex, e.target.value)
                                                                                }
                                                                                // inputProps={{
                                                                                //     name: 'rate_skill_i'
                                                                                // }}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {rating_list.map((item, index) => (
                                                                                    <option key={skillIndex + '-' + index} value={item}>
                                                                                        {item}
                                                                                    </option>
                                                                                ))}
                                                                            </NativeSelect>
                                                                        </TableCell>
                                                                        {/* <TableCell>
                                                                            {skillIndex <= teammate.skill_number_init - 1 ? null : (
                                                                                <Button
                                                                                    variant="outlined"
                                                                                    color="error"
                                                                                    onClick={() => handleDelete_t(teammateIndex, skillIndex)}
                                                                                    //
                                                                                >
                                                                                    Delete
                                                                                </Button>
                                                                            )}
                                                                        </TableCell> */}
                                                                    </TableRow>
                                                                )
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                    <Stack spacing={2} sx={{ m: 5 }}>
                                                        <DataTable_team
                                                            skillsData={teammate.skills}
                                                            teammateIndex={teammateIndex}
                                                            skillNumberInit={teammate.skill_number_init}
                                                            handleSkillChange={handleSkillChange_t}
                                                            handleRateChange={handleRateChange_t}
                                                            handleDelete={(handleDelete_t)}
                                                        />
                                                    </Stack>
                                                    <FormControl>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleAddRow_t(teammateIndex)}
                                                            sx={{ margin: '1em' }}
                                                            //
                                                        >
                                                            Add row
                                                        </Button>
                                                    </FormControl>
                                                    <br></br>
                                                </TableContainer>
                                            </Box>
                                            <TableContainer style={{ width: '95%', margin: 'auto' }}>
                                                <i style={{ color: '#14a085', margin: '1em' }}>
                                                    Example: "Zoe showed great enthusiasm when leading in discussions. However, it seemed like she
                                                    often had difficulty staying on point."
                                                </i>
                                                <TextField
                                                    sx={{ width: '100%' }}
                                                    multiline
                                                    rows={3}
                                                    variant="outlined"
                                                    name={`comments_${teammateIndex}`}
                                                    id={`comments_${teammateIndex}`}
                                                    type="text"
                                                    placeholder="Your answer (at least 10 words)"
                                                    inputProps={{ maxLength: 500 }}
                                                    onChange={(e) => handleCommentChange_t(teammateIndex, e.target.value)}
                                                    //inputRef={summaryRef}
                                                    defaultValue={teammate.comments}
                                                />
                                            </TableContainer>
                                        </div>
                                    )
                                ))}
                            </div>
                        )}
                        {mentorCount > 0 && activeStep == 7 && (
                            <div>
                                <br></br>
                                <b>Q7. Please provide feedback on your mentor's guidance and support during this mentoring relationship.</b>
                                {teammates_data?.map((teammate, teammateIndex) => (
                                    teammate.role === "Mentor" && (
                                        <div>
                                            <br></br>
                                            <Chip
                                                label={teammate.name + (teammate.company_or_school !== '' ? ' - ' + teammate.company_or_school : '')}
                                                color="secondary"
                                                variant="outlined"
                                                icon={<PermIdentityIcon />}
                                            >
                                                {/* {teammate.name} - {teammate.company_or_school != '' ? teammate.company_or_school : ''} */}
                                            </Chip>
                                            <TableContainer style={{ width: '95%', margin: 'auto' }}>
                                                <i style={{ color: '#14a085', margin: '1em' }}>
                                                    Example: "My mentor, John, effectively communicated and explained in detail what I needed to do to overcome my struggles in public speaking."
                                                </i>
                                                <TextField
                                                    sx={{ width: '100%' }}
                                                    multiline
                                                    rows={3}
                                                    variant="outlined"
                                                    name={`comments_${teammateIndex}`}
                                                    id={`comments_${teammateIndex}`}
                                                    type="text"
                                                    placeholder="Your answer (at least 10 words)"
                                                    inputProps={{ maxLength: 500 }}
                                                    onChange={(e) => handleCommentChange_t(teammateIndex, e.target.value)}
                                                    defaultValue={teammate.comments}
                                                />
                                            </TableContainer>
                                        </div>
                                    )
                                ))}
                            </div>
                        )}
                        {activeStep == 5 && (
                            <div>
                                <b>
                                    Q5. How will you turn your learnings into action? How could you practice your strong skills more
                                    mindfully to achieve your goals in any area of your life?
                                </b>
                                <br></br>
                                <i style={{ color: '#14a085', margin: '1em' }}>
                                    Example: "I found leading this task tough as I needed to work in some intense situations where others were
                                    turning to me for direction. However, I also loved it! Therefore, I'm going to put my hand up for other
                                    leadership opportunities. Now that I know how it feels to be under this type of pressure, I will also
                                    work at understanding other people's leadership styles so I can support them more and learn from them
                                    too."
                                </i>
                                <TextField
                                    sx={{ width: '100%' }}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="actions"
                                    id="actions"
                                    type="text"
                                    placeholder="Your answer"
                                    inputProps={{ maxLength: 500 }}
                                    onChange={handleChange}
                                    //inputRef={actionRef}
                                    defaultValue={feedback['actions']}
                                />
                                <i>"The most effective way to do it, is to do it"</i>
                                <br></br>
                                <b>— Amelia Earhart</b>
                            </div>
                        )}
                    </Box>
                    <MobileStepper
                        variant="text"
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                                variant="contained"
                                color="primary"
                                ///
                            >
                                Next
                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                        }
                        backButton={
                            <Button
                                size="small"
                                onClick={handleBack}
                                disabled={activeStep === 0}
                                variant="outlined"
                                //color="primary"
                            >
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                Back
                            </Button>
                        }
                    />
                    {errors.length > 0 ? (
                        <Alert severity="error" aria-live="assertive">
                            {errors.map((error, index) => {
                                return <p key={`alert-${index}`}>{error}</p>;
                            })}
                        </Alert>
                    ) : (
                        <></>
                    )}
                    {activeStep != maxSteps - 1 ? null : (
                        <FormControl style={{ display: 'flex', justifyContent: 'flex-end', mt: '4em', mb: '4em' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                // onClick={() => handleAddRow_t(teammateIndex)}
                                sx={{ margin: 'auto' }}
                                type="submit"
                                disabled={isSubmitting}
                                //
                            >
                                Submit
                            </Button>
                        </FormControl>
                    )}
                </form>
            </Box>
        );
    } else if (feedback_count > 0) {
        return (
            <Box>
                <h6>You've already submitted the feedback.</h6>
                <Link
                    href={'/smart-profile'}
                >
                    Back to Smart Profile
                </Link>
            </Box>
        );
    } else {
        return (
            <Box>
                <h6>Loading...</h6>
            </Box>
        );
    }
};
