/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import UserService from 'services/user.service';
import Axios from 'axios';
import {
    Autocomplete,
    Box,
    Collapse,
    Button,
    Divider,
    FormControl,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    TextField,
    ButtonGroup,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
    Tooltip,
    Container
} from '@mui/material';
import AnimateButton from 'components/@extended/AnimateButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const skill_list = UserService.skill_list;
if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
const TeamBuildingPage_View = () => {
    const query = useQuery();
    // Get query parameters
    const projectId = parseInt(query.get('id'));

    const [skillSet, setSkillSet] = React.useState([]);
    //console.log(skillSet);
    const handleChange = (i) => (event, value) => {
        setSkillSet(prevSkillSet => {
            const updatedSkillSet = [...prevSkillSet]; // Copy the current skillSet
            updatedSkillSet[i] = value || []; // Update the skillSet for round i + 1
            return updatedSkillSet;
        });
    };

    const [participantIdsArray, setParticipantIdsArray] = useState([]);
    const [participantsArray, setParticipantsArray] = useState([]);

    useEffect(() => {
        UserService.getProjects().then(
            (response) => {
                const projects = JSON.parse(response.data['project_data']);
                const project = projects.find((p) => p.pk === projectId);
                setSelectedProject(project);
                const participantId_list =
                    project.fields.participants_userId.length > 0
                        ? project.fields.participants_userId
                        : Array.from({ length: project.fields.grouping_results.length }, () => [
                              ...project.fields.participants.map((user) => user != null && user.id)
                          ]);
                setParticipantIdsArray(participantId_list);
                setSkillSet(project.fields.skillset);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
        );
    }, []);

    const [selectedProject, setSelectedProject] = useState({ pk: 'undefined' });

    const tableRows = [
        'ID',
        'Email',
        'Name',
        'Gender',
        'Current Position',
        'Department or Class',
        'Company or School',
        'Role',
        'Skills',
        'Date Joined'
    ];

    useEffect(() => {
        UserService.getAllUsers().then(
            (response) => {
                const userData = response.data['user_data'];
                const filledParticipantArray = participantIdsArray?.map((participants) =>
                    participants.map((participantId) => {
                        const user = userData.find((user) => user.id == participantId);
                        return user ? user : null;
                    })
                );
                setParticipantsArray(filledParticipantArray);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(_content);
            }
        );
    }, [participantIdsArray]);
    //Get feedback data
    const [feedbackData, setFeedback] = useState([]);
    const [mentorFeedbackData, setMentorFeedback] = useState({});
    useEffect(() => {
        UserService.getAllFeedbacks_project(projectId).then(
            (response) => {
                setFeedback(JSON.parse(response.data.feedback_data));
            },
            (error) => {
                const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('error2: ', _events);
            }
        );
    }, []);
    useEffect(() => {
        UserService.getAllMentorFeedbacks_project(projectId).then(
            (response) => {
                setMentorFeedback(JSON.parse(response.data.feedback_data));
            },
            (error) => {
                const _events = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log('error4: ', _events);
            }
        );
    }, []);

    const [expandedGroups, setExpandedGroups] = useState([]);
    const handleToggle = (index) => {
        setExpandedGroups(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };
    const [open, setOpen] = React.useState([]);

    const handleOpen = (i) => () => {
        const updatedOpen = [...open];
        updatedOpen[i] = true;
        setOpen(updatedOpen);
    };
    const handleClose = (i) => () => {
        const updatedOpen = [...open];
        updatedOpen[i] = false;
        setOpen(updatedOpen);
    };
    //navigate
    let navigate = useNavigate();
    const handleUpdateSkillSetAndViewGraph = (i) => {
        Axios.post(
            `${backend_base_url}/update_project_skillset/${projectId}/`,
            {
                skillset: skillSet
            },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
            }
        ).then((response) => {
            if (response['data']['response'] == 'error') {
                alert('An error occurred (' + response['data']['message'] + ') and we could not save the data.');
            } else {
                alert('Skillset updated!');
                navigate(`/graph?id=${projectId}&round=${i + 1}`);
            }
        });
    };
    const handleCancel = (i) => {
        // reset skillset data
        UserService.get_a_project(projectId).then(
            (response) => {
                const project = response.data['project_data'];
                setSkillSet(project.skillset);
            },
            (error) => {
                const _content =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
        );
        // handle Close
        const updatedOpen = [...open];
        updatedOpen[i] = false;
        setOpen(updatedOpen);
    }
    const { user: currentUser } = useSelector((state) => state.auth);
    if (!currentUser) {
        return <Navigate to="/login" />;
    }
    return (
        <Container>
            {selectedProject.pk != 'undefined' && (
                <div>
                    <h4>PUMAS Team Building</h4>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/team-building-history')}
                        //
                    >
                        <ArrowBackIcon />
                        Back
                    </Button>
                    <br></br>
                    <br></br>
                    <h5 style={{ textAlign: 'center' }}>The Project's Details</h5>
                    <p style={{ textAlign: 'center' }}>Title: <strong>{selectedProject.fields.title}</strong></p>
                    <p style={{ textAlign: 'center' }}>Description: <strong>{selectedProject.fields.description}</strong></p>
                    <p style={{ textAlign: 'center' }}>Number of Rounds: <strong>{selectedProject.fields.grouping_results.length}</strong></p>
                    <br></br>
                    <br></br>
                    {participantsArray.length == selectedProject.fields.grouping_results.length &&
                        selectedProject.fields.grouping_results_userId.length > 0 &&
                        selectedProject.fields.grouping_results_userId.map((tables, i) => {
                            return (
                                <Box
                                    sx={{ border: '1px solid grey', borderColor: 'primary.main' }}
                                    alignItems="center"
                                    margin="auto"
                                    padding="10px"
                                    //
                                >
                                    <ButtonGroup
                                        sx={{
                                            margin: 'auto',
                                            padding: '10px',
                                            display: 'flex',
                                            justifyContent: 'space-between' // Align items horizontally
                                        }}
                                    >
                                        <h5>Round {i + 1}</h5>
                                        <span><i>Mentors: {participantsArray[i].filter(item => item != null && item.role === 'Mentor').length}</i></span>
                                        <span><i>Participants: {participantsArray[i].filter(item => item != null && item.role === 'Participant').length}</i></span>
                                        <div>
                                            <Button
                                                variant="contained"
                                                href={`/data-visualisation?id=${projectId}&round=${i + 1}`}
                                                sx={{ marginRight: '5px' }}
                                                //sx={{ mr: 0, mt: 1 }}
                                            >
                                                Dashboard
                                            </Button>
                                            <Button
                                                variant="contained"
                                                //href={`/graph?id=${projectId}&round=${i + 1}`}
                                                onClick={handleOpen(i)}
                                                //sx={{ mr: 0, mt: 1 }}
                                                //
                                            >
                                                knowledge Graph
                                            </Button>
                                            <Dialog
                                                open={open[i] || false}
                                                onClose={handleClose(i)}
                                                scroll={'paper'}
                                                aria-labelledby="scroll-dialog-title"
                                                aria-describedby="scroll-dialog-description"
                                                fullWidth
                                                //maxWidth="sm" // Adjust the maxWidth as needed
                                            >
                                                <DialogTitle id="scroll-dialog-title">Knowledge Graph Settings</DialogTitle>
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={handleClose(i)}
                                                    sx={{
                                                        position: 'absolute',
                                                        right: 8,
                                                        top: 8,
                                                        color: (theme) => theme.palette.grey[500]
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                                <Divider />
                                                <DialogContent dividers={scroll === 'paper'}>
                                                    <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        <FormControl sx={{ m: 1, minWidth: '100%' }}>
                                                            <Autocomplete
                                                                multiple
                                                                id="size-small-standard-multi"
                                                                size="small"
                                                                options={skill_list}
                                                                getOptionLabel={(option) => option}
                                                                //defaultValue={[top100Films[13]]}
                                                                onChange={handleChange(i)}
                                                                value={skillSet.length > i && skillSet[i] != null ? skillSet[i] : []} // Set the value of Autocomplete for the current round
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="standard"
                                                                        label="Skill(s)"
                                                                        //placeholder="Favorites"
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </DialogContent>
                                                <DialogActions>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            fullWidth
                                                            size="medium"
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => handleCancel(i)}
                                                            ///
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </AnimateButton>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            fullWidth
                                                            size="medium"
                                                            //type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            // href={`/graph?id=${projectId}&round=${i + 1}`}
                                                            onClick={() => handleUpdateSkillSetAndViewGraph(i)}
                                                            ///
                                                        >
                                                            Create
                                                        </Button>
                                                    </AnimateButton>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    </ButtonGroup>
                                    
                                    <Container sx={{ bgcolor: '' }}>
                                        <Grid container spacing={2}>
                                            {tables.length > 0 &&
                                                tables.map((table, tableIndex) => (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                        lg={3}
                                                        //xl={2.4}
                                                        className="table borderd"
                                                        key={tableIndex}
                                                        // style={getListStyle(snapshot.isDraggingOver)}
                                                        /////////////////////////////
                                                    >
                                                        <ListItemText
                                                            primary={`Group ${tableIndex + 1}`}
                                                            primaryTypographyProps={{
                                                                color: 'black',
                                                                fontWeight: 600,
                                                                variant: 'body1'
                                                            }}
                                                        />
                                                        <List
                                                            sx={{
                                                                width: '100%',
                                                                minWidth: 220,
                                                                //minHeight: 50,
                                                                bgcolor: 'background.paper',
                                                                //border: '1px solid #ccc',
                                                                borderRadius: '4px'
                                                            }}
                                                        >
                                                            {table?.map((user, index) => (
                                                                <ListItem
                                                                    sx={{
                                                                        m: '0px',
                                                                        width: '100%', // Set the ListItem width to fill the available space
                                                                        display: 'flex' // Ensure the ListItem expands to fit the contents
                                                                    }}
                                                                >
                                                                    {participantsArray[i].map((value, index) => {
                                                                        if (value != null && value.id == user) {
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    <ListItemText
                                                                                        primaryTypographyProps={{
                                                                                            style:
                                                                                                value.role === 'Mentor'
                                                                                                    ? {
                                                                                                          backgroundColor: 'yellow'
                                                                                                      }
                                                                                                    : null
                                                                                        }}
                                                                                        primary={value.id + ' - ' + value.name}
                                                                                    />
                                                                                    <ListItemIcon>
                                                                                        {feedbackData?.filter(
                                                                                            (p) =>
                                                                                                p.fields.project == selectedProject.pk &&
                                                                                                p.fields.round == i + 1 &&
                                                                                                p.fields.created_by == value.id
                                                                                        ).length > 0 ||
                                                                                        mentorFeedbackData?.filter(
                                                                                            (p) =>
                                                                                                p.fields.project == selectedProject.pk &&
                                                                                                p.fields.round == i + 1 &&
                                                                                                p.fields.created_by == value.id
                                                                                        ).length > 0 ? (
                                                                                            <CircleIcon style={{ color: 'green' }} />
                                                                                        ) : (
                                                                                            <CircleIcon style={{ color: 'grey' }} />
                                                                                        )}
                                                                                    </ListItemIcon>
                                                                                </React.Fragment>
                                                                            );
                                                                        }
                                                                        // else if (index == user && value == null) {
                                                                        //     return (
                                                                        //         <Tooltip title="This participant may no longer exist in PUMAS.">
                                                                        //             <ListItemText
                                                                        //                 primary={'undefined (*)'}
                                                                        //                 primaryTypographyProps={{
                                                                        //                     style: { color: 'red' }
                                                                        //                 }}
                                                                        //             />
                                                                        //         </Tooltip>
                                                                        //     );
                                                                        // }
                                                                    })}
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Grid>
                                                ))}
                                        </Grid>
                                        <br />
                                        <Typography variant="h5" onClick={() => handleToggle(i)}>
                                            <Tooltip title="Click/Unclick to Show/Hide tables in details.">
                                                <Button>Show/Hide Group Details</Button>
                                            </Tooltip>
                                        </Typography>
                                        <Collapse in={expandedGroups[i]} timeout="auto" unmountOnExit>
                                            <Grid>
                                                {tables.map((table, index) => (
                                                    <TableContainer
                                                        sx={{
                                                            maxHeight: 440,
                                                            border: '2px dashed grey',
                                                            borderColor: 'primary.main'
                                                        }}
                                                    >
                                                        <Typography variant="h5" component="h6">
                                                            Group {index + 1}:
                                                        </Typography>
                                                        <Table key={index} className="table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {tableRows.map((rows, index) => {
                                                                        return <TableCell key={index}>{rows}</TableCell>;
                                                                    })}
                                                                </TableRow>
                                                            </TableHead>
                                                            {table.map((user) => (
                                                                <TableBody>
                                                                    {participantsArray[i]?.map((row, index) => {
                                                                        if (row != null && row.id == user) {
                                                                            return (
                                                                                <TableRow key={index}>
                                                                                    <TableCell>{row.id}</TableCell>
                                                                                    <TableCell>{row.email}</TableCell>
                                                                                    <TableCell>{row.name}</TableCell>
                                                                                    <TableCell>{row.gender}</TableCell>
                                                                                    <TableCell>{row['current position']}</TableCell>
                                                                                    <TableCell>{row['department or class']}</TableCell>
                                                                                    <TableCell>{row['company or school']}</TableCell>
                                                                                    <TableCell>{row.role}</TableCell>
                                                                                    <TableCell>{row.skills.join(', ')}</TableCell>
                                                                                    <TableCell>{row.dateJoined}</TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        }
                                                                    })}
                                                                </TableBody>
                                                            ))}
                                                        </Table>
                                                    </TableContainer>
                                                ))}
                                            </Grid>
                                        </Collapse>
                                    </Container>
                                    <br />
                                    <br />
                                </Box>
                            );
                        })}
                    {participantsArray.length == selectedProject.fields.grouping_results.length &&
                        selectedProject.fields.grouping_results_userId.length == 0 &&
                        selectedProject.fields.grouping_results.map((tables, i) => {
                            return (
                                <Box
                                    sx={{ border: '1px solid grey', borderColor: 'primary.main' }}
                                    alignItems="center"
                                    margin="auto"
                                    padding="10px"
                                    //
                                >
                                    <ButtonGroup
                                        sx={{
                                            margin: 'auto',
                                            padding: '10px',
                                            display: 'flex',
                                            justifyContent: 'space-between' // Align items horizontally
                                        }}
                                    >
                                        <h5>Round {i + 1}</h5>
                                        <span><i>Mentors: {participantsArray[i].filter(item => item.role === 'Mentor').length}</i></span>
                                        <span><i>Participants: {participantsArray[i].filter(item => item.role === 'Participant').length}</i></span>
                                        <div>
                                            <Button
                                                variant="contained"
                                                href={`/data-visualisation?id=${projectId}&round=${i + 1}`}
                                                sx={{ marginRight: '5px' }}
                                                //sx={{ mr: 0, mt: 1 }}
                                            >
                                                Dashboard
                                            </Button>
                                            <Button
                                                variant="contained"
                                                //href={`/graph?id=${projectId}&round=${i + 1}`}
                                                onClick={handleOpen(i)}
                                                //sx={{ mr: 0, mt: 1 }}
                                                //
                                            >
                                                knowledge Graph
                                            </Button>
                                            <Dialog
                                                open={open[i] || false}
                                                onClose={handleClose(i)}
                                                scroll={'paper'}
                                                aria-labelledby="scroll-dialog-title"
                                                aria-describedby="scroll-dialog-description"
                                                fullWidth
                                                //maxWidth="sm" // Adjust the maxWidth as needed
                                            >
                                                <DialogTitle id="scroll-dialog-title">Knowledge Graph Settings</DialogTitle>
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={handleClose(i)}
                                                    sx={{
                                                        position: 'absolute',
                                                        right: 8,
                                                        top: 8,
                                                        color: (theme) => theme.palette.grey[500]
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                                <Divider />
                                                <DialogContent dividers={scroll === 'paper'}>
                                                    <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        <FormControl sx={{ m: 1, minWidth: '100%' }}>
                                                            <Autocomplete
                                                                multiple
                                                                id="size-small-standard-multi"
                                                                size="small"
                                                                options={skill_list}
                                                                getOptionLabel={(option) => option}
                                                                //defaultValue={[top100Films[13]]}
                                                                onChange={handleChange(i)}
                                                                value={skillSet.length > i && skillSet[i] != null ? skillSet[i] : []} // Set the value of Autocomplete for the current round
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="standard"
                                                                        label="Skill(s)"
                                                                        //placeholder="Favorites"
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </DialogContent>
                                                <DialogActions>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            fullWidth
                                                            size="medium"
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => handleCancel(i)}
                                                            ///
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </AnimateButton>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            fullWidth
                                                            size="medium"
                                                            //type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            // href={`/graph?id=${projectId}&round=${i + 1}`}
                                                            onClick={() => handleUpdateSkillSetAndViewGraph(i)}
                                                            ///
                                                        >
                                                            Create
                                                        </Button>
                                                    </AnimateButton>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    </ButtonGroup>
                                    
                                    <Container sx={{ bgcolor: '' }}>
                                        <Grid container spacing={2}>
                                            {tables.length > 0 &&
                                                tables.map((table, tableIndex) => (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                        lg={3}
                                                        //xl={2.4}
                                                        className="table borderd"
                                                        key={tableIndex}
                                                        // style={getListStyle(snapshot.isDraggingOver)}
                                                        /////////////////////////////
                                                    >
                                                        <ListItemText
                                                            primary={`Group ${tableIndex + 1}`}
                                                            primaryTypographyProps={{
                                                                color: 'black',
                                                                fontWeight: 600,
                                                                variant: 'body1'
                                                            }}
                                                        />
                                                        <List
                                                            sx={{
                                                                width: '100%',
                                                                minWidth: 220,
                                                                //minHeight: 50,
                                                                bgcolor: 'background.paper',
                                                                //border: '1px solid #ccc',
                                                                borderRadius: '4px'
                                                            }}
                                                        >
                                                            {table?.map((user, index) => (
                                                                <ListItem
                                                                    sx={{
                                                                        m: '0px',
                                                                        width: '100%', // Set the ListItem width to fill the available space
                                                                        display: 'flex' // Ensure the ListItem expands to fit the contents
                                                                    }}
                                                                >
                                                                    {participantsArray[i].map((value, index) => {
                                                                        if (index == user && value != null) {
                                                                            return (
                                                                                <React.Fragment key={index}>
                                                                                    <ListItemText
                                                                                        primaryTypographyProps={{
                                                                                            style:
                                                                                                value.role === 'Mentor'
                                                                                                    ? {
                                                                                                          backgroundColor: 'yellow'
                                                                                                      }
                                                                                                    : null
                                                                                        }}
                                                                                        primary={value.id + ' - ' + value.name}
                                                                                    />
                                                                                    <ListItemIcon>
                                                                                        {feedbackData?.filter(
                                                                                            (p) =>
                                                                                                p.fields.project == selectedProject.pk &&
                                                                                                p.fields.round == i + 1 &&
                                                                                                p.fields.created_by == value.id
                                                                                        ).length > 0 ||
                                                                                        mentorFeedbackData?.filter(
                                                                                            (p) =>
                                                                                                p.fields.project == selectedProject.pk &&
                                                                                                p.fields.round == i + 1 &&
                                                                                                p.fields.created_by == value.id
                                                                                        ).length > 0 ? (
                                                                                            <CircleIcon style={{ color: 'green' }} />
                                                                                        ) : (
                                                                                            <CircleIcon style={{ color: 'grey' }} />
                                                                                        )}
                                                                                    </ListItemIcon>
                                                                                </React.Fragment>
                                                                            );
                                                                        }
                                                                        // else if (index == user && value == null) {
                                                                        //     return (
                                                                        //         <Tooltip title="This participant may no longer exist in PUMAS.">
                                                                        //             <ListItemText
                                                                        //                 primary={'undefined (*)'}
                                                                        //                 primaryTypographyProps={{
                                                                        //                     style: { color: 'red' }
                                                                        //                 }}
                                                                        //             />
                                                                        //         </Tooltip>
                                                                        //     );
                                                                        // }
                                                                    })}
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Grid>
                                                ))}
                                        </Grid>
                                        <br />
                                        <Typography variant="h5" onClick={() => handleToggle(i)}>
                                            <Tooltip title="Click/Unclick to Show/Hide tables in details.">
                                                <Button>Show/Hide Group Details</Button>
                                            </Tooltip>
                                        </Typography>
                                        <Collapse in={expandedGroups[i]} timeout="auto" unmountOnExit>
                                            <Grid>
                                                {tables.map((table, index) => (
                                                    <TableContainer
                                                        sx={{
                                                            maxHeight: 440,
                                                            border: '2px dashed grey',
                                                            borderColor: 'primary.main'
                                                        }}
                                                    >
                                                        <Typography variant="h5" component="h6">
                                                            Group {index + 1}:
                                                        </Typography>
                                                        <Table key={index} className="table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {tableRows.map((rows, index) => {
                                                                        return <TableCell key={index}>{rows}</TableCell>;
                                                                    })}
                                                                </TableRow>
                                                            </TableHead>
                                                            {table.map((user) => (
                                                                <TableBody>
                                                                    {participantsArray[i]?.map((row, index) => {
                                                                        if (row != null && index == user) {
                                                                            return (
                                                                                <TableRow key={index}>
                                                                                    <TableCell>{row.id}</TableCell>
                                                                                    <TableCell>{row.email}</TableCell>
                                                                                    <TableCell>{row.name}</TableCell>
                                                                                    <TableCell>{row.gender}</TableCell>
                                                                                    <TableCell>{row['current position']}</TableCell>
                                                                                    <TableCell>{row['department or class']}</TableCell>
                                                                                    <TableCell>{row['company or school']}</TableCell>
                                                                                    <TableCell>{row.role}</TableCell>
                                                                                    <TableCell>{row.skills.join(', ')}</TableCell>
                                                                                    <TableCell>{row.dateJoined}</TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        }
                                                                    })}
                                                                </TableBody>
                                                            ))}
                                                        </Table>
                                                    </TableContainer>
                                                ))}
                                            </Grid>
                                        </Collapse>
                                    </Container>
                                    <br />
                                    <br />
                                </Box>
                            );
                        })}
                    <br />
                    {/* <FirstModal /> */}
                </div>
            )}
        </Container>
    );
};

export default TeamBuildingPage_View;
