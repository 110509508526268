/* eslint-disable */
import * as React from 'react';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
export const Feedback_success = () => {
    return (
        <Alert icon={<CheckIcon />} variant="outlined" severity="primary" fontSize="40">
            <b>Your feedback has been submitted successfully.</b>
            <br></br><br></br>
            <p>Now, you can check the feedback you've received from others in the <b>"Event History"</b> section.</p>
            <p>
                <KeyboardDoubleArrowRightIcon />
                Go to <a href="/event-history">Event History</a>
            </p>
        </Alert>
    );
}