import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Papa from 'papaparse';
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Input,
    Select,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    TableSortLabel,
    InputLabel,
    Badge,
    Grid,
    List,
    Divider,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    ListItem,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
    Typography,
    Container,
    Item
} from '@mui/material';
import AnimateButton from 'components/@extended/AnimateButton';

if (window.location.href.includes('pumas.activepeers.ai/')) {
    var backend_base_url = 'https://pumas.activepeers.ai/api';
} else {
    var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001/api';
}
//var backend_base_url = window.location.href.split(':')[0] + ':' + window.location.href.split(':')[1] + ':8001';
function GroupMatchingPage() {
    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? '#16a085' : 'darkgrey',
        // width: '21%',
        margin: 'auto'
    });
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        background: isDragging ? 'darkgrey' : 'white',
        color: isDragging ? 'white' : 'black',
        padding: isDragging ? '0%' : '2%',
        // paddingLeft: '2%',
        margin: '0%',
        // fontSize: '17px',
        // borderBottom: '0.5px solid gray',
        // styles we need to apply on draggables
        ...draggableStyle
    });
    const onDownload = () => {
        const link = document.createElement('a');
        link.download = `example_participantList.csv`;
        link.href = 'https://drive.google.com/file/d/1btEovESSwsW6S32f6gToLtTaw-9v5ORa/view?usp=sharing';
        link.click();
    };
    const data = [
        { id: 'activity-history', name: 'Activity History' },
        { id: 'gender', name: 'Gender' },
        { id: 'current-position', name: 'Current Position' },
        { id: 'school-company', name: 'School/Company' },
        { id: 'class-department-group', name: 'Class/Department/Group' },
        { id: 'skills', name: 'Skills' }
    ];
    const [users, setUsers] = useState(data);
    const [orders, setOrders] = useState(Object.values(data).map((a) => a.id));
    console.log(orders);
    const onDragEnd = (e) => {
        console.log(e);
        if (!e.destination) return; // No valid drop destination

        const updatedRows = [...users];
        const [movedRow] = updatedRows.splice(e.source.index, 1);
        updatedRows.splice(e.destination.index, 0, movedRow);
        setUsers(updatedRows);
        console.log(updatedRows);
        let items = [];
        updatedRows.forEach((data, index) =>
            items.push(
                data.id
                /////
            )
        );
        setOrders(items);
        console.log(items);
    };
    const defaultData = {
        'activity-history': '0',
        gender: '0',
        'current-position': '0',
        'school-company': '0',
        'class-department-group': '0',
        skills: '0'
    };
    const [state, setState] = useState(defaultData);
    const onChange = (e) => {
        const { name, value, checked, type } = e.target;
        const newVal = type === 'checkbox' ? checked : value;
        setState({
            ...state,
            [name]: newVal
        });
    };
    // State to store parsed data
    const [parsedData, setParsedData] = useState([]);

    //State to store table Column name
    const [tableRows, setTableRows] = useState([]);

    //State to store the values
    const [values, setValues] = useState([]);

    const changeHandler = (event) => {
        // Passing file data (event.target.files[0]) to parse using Papa.parse
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                const rowsArray = [];
                const valuesArray = [];

                // Iterating data to get column name and their values
                results.data.map((d) => {
                    rowsArray.push(Object.keys(d));
                    valuesArray.push(Object.values(d));
                });

                // Parsed Data Response in array format
                setParsedData(results.data);

                // Filtered Column Names
                setTableRows(rowsArray[0]);

                // Filtered Values
                setValues(valuesArray);
            }
        });
    };
    console.log(parsedData);
    console.log(state);
    //State to store the response
    const [responseDataR1, setResponseR1] = useState([]);
    //State to store the response-message
    const [tablesR1, setTablesR1] = useState([]);
    const [actHist, setHist] = useState([]);
    const handleSubmitR1 = (event) => {
        var constraints_dict_value = [
            state[orders[0]],
            state[orders[1]],
            state[orders[2]],
            state[orders[3]],
            state[orders[4]],
            state[orders[5]]
        ];
        var table = state['table'];
        var mentor = state['mentor'] ? state['mentor'] : 1;
        console.log(mentor);
        if (parsedData.length > 0) {
            if (state['table']) {
                Axios.post(
                    `${backend_base_url}/group_matching/`,
                    {
                        data: {
                            csvData: parsedData,
                            // value 0- Ignore, 1- Common Only, 2 - Diversity Only, 3- Don't overlap at all
                            constraints_dict_name: orders,
                            constraints_dict_value: constraints_dict_value,
                            table: table,
                            mentor: mentor,
                            activity_hist: []
                        }
                    },
                    { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
                ).then((response) => {
                    console.log(response.data);
                    setResponseR1(response.data);
                    if (response.data['response'] == 'Success') {
                        setTablesR1(JSON.parse(response.data['message']));
                    }
                    setTablesR2([]);
                    setTablesR3([]);
                });
            } else {
                alert('Please enter the desired number of tables!');
            }
        } else {
            alert('Please upload your data!');
        }
    };
    console.log(tablesR1);
    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        const sourceListIndex = parseInt(source.droppableId);
        const destinationListIndex = parseInt(destination.droppableId);

        const updatedLists = [...tablesR1];
        const [removed] = updatedLists[sourceListIndex].splice(source.index, 1);
        updatedLists[destinationListIndex].splice(destination.index, 0, removed);

        setTablesR1(updatedLists);
        console.log(tablesR1);
    };
    //State to store the response
    const [responseDataR2, setResponseR2] = useState([]);
    //State to store the response-message
    const [tablesR2, setTablesR2] = useState([]);
    const handleSubmitR2 = (event) => {
        var constraints_dict_value = [
            state[orders[0]],
            state[orders[1]],
            state[orders[2]],
            state[orders[3]],
            state[orders[4]],
            state[orders[5]]
        ];
        var table = state['table'];
        var mentor = state['mentor'] ? state['mentor'] : 1;
        console.log(mentor);
        var activity_hist = [];
        activity_hist.push(tablesR1);
        console.log(activity_hist);
        if (parsedData.length > 0) {
            if (state['table']) {
                Axios.post(
                    `${backend_base_url}/group_matching/`,
                    {
                        data: {
                            csvData: parsedData,
                            // value 0- Ignore, 1- Common Only, 2 - Diversity Only, 3- Don't overlap at all
                            constraints_dict_name: orders,
                            // constraints_dict_priority_order: constraints_dict_priority_order,
                            constraints_dict_value: constraints_dict_value,
                            table: table,
                            mentor: mentor,
                            activity_hist: activity_hist
                        }
                    },
                    { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
                ).then((response) => {
                    console.log(response.data);
                    setResponseR2(response.data);
                    if (response.data['response'] == 'Success') {
                        setTablesR2(JSON.parse(response.data['message']));
                    }
                    setTablesR3([]);
                });
            } else {
                alert('Please enter the desired number of tables!');
            }
        } else {
            alert('Please upload your data!');
        }
    };
    //State to store the response
    const [responseDataR3, setResponseR3] = useState([]);
    //State to store the response-message
    const [tablesR3, setTablesR3] = useState([]);
    const handleSubmitR3 = (event) => {
        var constraints_dict_value = [
            state[orders[0]],
            state[orders[1]],
            state[orders[2]],
            state[orders[3]],
            state[orders[4]],
            state[orders[5]]
        ];
        var table = state['table'];
        var mentor = state['mentor'] ? state['mentor'] : 1;
        var activity_hist = [];
        activity_hist.push(tablesR1);
        activity_hist.push(tablesR2);
        setHist(activity_hist);
        if (parsedData.length > 0) {
            if (state['table']) {
                Axios.post(
                    `${backend_base_url}/group_matching/`,
                    {
                        data: {
                            csvData: parsedData,
                            // value 0- Ignore, 1- Common Only, 2 - Diversity Only, 3- Don't overlap at all
                            constraints_dict_name: orders,
                            // constraints_dict_priority_order: constraints_dict_priority_order,
                            constraints_dict_value: constraints_dict_value,
                            table: table,
                            mentor: mentor,
                            activity_hist: activity_hist
                        }
                    },
                    { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
                ).then((response) => {
                    console.log(response.data);
                    setResponseR3(response.data);
                    if (response.data['response'] == 'Success') {
                        setTablesR3(JSON.parse(response.data['message']));
                    }
                });
            } else {
                alert('Please enter the desired number of tables!');
            }
        } else {
            alert('Please upload your data!');
        }
    };
    console.log(actHist);
    const { user: currentUser } = useSelector((state) => state.auth);
    if (!currentUser) {
        return <Navigate to="/login" />;
    }
    return (
        <Container>
            <Box
                display="flex"
                justifycontent="flex-end"
                alignitems="flex-end"
                ////
            >
                <Button
                    onClick={onDownload}
                    variant="contained"
                    color="primary"
                    target="_blank"
                    justifycontent="flex-end"
                    sx={{ margin: 0 }}
                >
                    Example Data
                </Button>
            </Box>
            <InputLabel>1. Upload your data</InputLabel>
            <Input
                type="file"
                name="file"
                onChange={changeHandler}
                accept=".csv"
                style={{ display: 'block', margin: '10px auto' }}
                ////////////////////////////////////
            />
            <br />
            {/* Table */}
            <TableContainer sx={{ maxHeight: 440, border: '2px dashed grey', borderColor: 'primary.main' }}>
                <Table className="table" stickyHeader aria-label="sticky table">
                    <TableHead sx={{ bgcolor: 'background.paper' }}>
                        <TableRow>
                            {tableRows.map((rows, index) => {
                                return <TableCell key={index}>{rows}</TableCell>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values.map((value, index) => {
                            return (
                                <TableRow key={index}>
                                    {value.map((val, i) => {
                                        return <TableCell key={i}>{val}</TableCell>;
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <br />
            <InputLabel mt={10}>2. Prioritize and assign a value to each constraint in accordance with the order</InputLabel>
            <br />
            <TableContainer sx={{ border: '2px dashed grey', borderColor: 'primary.main' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Table className="table" sx={{ margin: 'auto' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Constraint Name</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <Droppable droppableId="droppable-1" key="droppable-1">
                            {(provider, snapshot) => (
                                <TableBody
                                    className="text-capitalize"
                                    ref={provider.innerRef}
                                    {...provider.droppableProps}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    /////////////////////////////
                                >
                                    {users?.map((user, index) => (
                                        <Draggable
                                            draggableId={'' + user.id}
                                            index={index}
                                            key={'' + user.id}
                                            //////////////////////
                                        >
                                            {(provider, snapshot) => (
                                                <TableRow
                                                    {...provider.draggableProps}
                                                    {...provider.dragHandleProps}
                                                    ref={provider.innerRef}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provider.draggableProps.style
                                                        ///////////////////////
                                                    )}
                                                >
                                                    {/* <TableCell {...provider.dragHandleProps}>==</TableCell> */}
                                                    <TableCell>{user.name}</TableCell>
                                                    <TableCell>
                                                        {user.id == 'activity-history' ? (
                                                            <Select name={user.id} onChange={onChange} defaultValue={0}>
                                                                <MenuItem value="0">Ignore</MenuItem>
                                                                <MenuItem value="3">Don't overlap at all</MenuItem>
                                                            </Select>
                                                        ) : (
                                                            <Select name={user.id} onChange={onChange} defaultValue={0}>
                                                                <MenuItem value="0">Ignore</MenuItem>
                                                                <MenuItem value="1">Common Only</MenuItem>
                                                                <MenuItem value="2">Diversity Only</MenuItem>
                                                            </Select>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provider.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </Table>
                </DragDropContext>
                {/* File Uploader */}
                <Table className="table" sx={{ margin: 'auto' }}>
                    <TableBody className="text-capitalize">
                        <TableRow style={{ color: 'white' }}>
                            <TableCell>Mentor</TableCell>
                            <TableCell>
                                <Select name="mentor" onChange={onChange} defaultValue={1}>
                                    <MenuItem value="1">At least one mentor per group</MenuItem>
                                    <MenuItem value="0">Doesn't matter</MenuItem>
                                </Select>
                            </TableCell>
                        </TableRow>
                        <TableRow></TableRow>
                        <TableRow style={{ color: 'white' }}>
                            <TableCell>Desired number of tables (*):</TableCell>
                            <TableCell>
                                <Input name="table" type="number" onChange={onChange} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <br />
            <InputLabel>3. Build team</InputLabel>
            <br />
            <AnimateButton>
                <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitR1}
                    ////
                >
                    Build Team - Round 1
                </Button>
            </AnimateButton>
            <Stack mt={2}>
                Building Status: {responseDataR1.response}
                {/* {responseDataR1.response == 'Success' && (
                    <Badge badgeContent={'Success'} color="success">
                        Success
                    </Badge>
                )} */}
                {responseDataR1.response != 'Success' && <div>{responseDataR1.message}</div>}
            </Stack>

            {tablesR1.length > 0 && (
                <div>
                    <Container sx={{ bgcolor: '' }}>
                        <Grid container spacing={2}>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                {tablesR1?.map((table, tableIndex) => (
                                    <Droppable droppableId={`${tableIndex}`} key={tableIndex}>
                                        {(provider, snapshot) => (
                                            <Grid
                                                item
                                                xs={3}
                                                className="table borderd"
                                                ref={provider.innerRef}
                                                {...provider.droppableProps}
                                                // style={getListStyle(snapshot.isDraggingOver)}
                                                /////////////////////////////
                                            >
                                                <ListItemText
                                                    primary={`Table ${tableIndex + 1}`}
                                                    primaryTypographyProps={{
                                                        color: 'black',
                                                        fontWeight: 600,
                                                        variant: 'body1'
                                                    }}
                                                />
                                                {table.map((user, index) => (
                                                    <List sx={{ width: '100%', maxWidth: 360, height: 50, bgcolor: 'background.paper' }}>
                                                        <Draggable
                                                            draggableId={`${tableIndex}-${index}`}
                                                            index={index}
                                                            key={`${tableIndex}-${index}`}
                                                            //////////////////////
                                                        >
                                                            {(provider, snapshot) => (
                                                                <ListItem
                                                                    {...provider.draggableProps}
                                                                    {...provider.dragHandleProps}
                                                                    ref={provider.innerRef}
                                                                    // style={getItemStyle(
                                                                    //     snapshot.isDragging,
                                                                    //     provider.draggableProps.style
                                                                    //     ///////////////////////
                                                                    // )}
                                                                >
                                                                    {/* {user} */}
                                                                    {values.map((value, index) => {
                                                                        if (index == user) {
                                                                            return <ListItemText primary={value[2]} />;
                                                                        }
                                                                    })}
                                                                </ListItem>
                                                            )}
                                                        </Draggable>
                                                        {provider.placeholder}
                                                    </List>
                                                ))}
                                            </Grid>
                                        )}
                                    </Droppable>
                                ))}
                            </DragDropContext>
                        </Grid>
                        <br />
                        <Typography variant="h4" component="h5">
                            Table list:
                        </Typography>
                        <Grid>
                            {tablesR1.map((table, index) => (
                                <TableContainer sx={{ maxHeight: 440, border: '2px dashed grey', borderColor: 'primary.main' }}>
                                    <Typography variant="h5" component="h6">
                                        Table {index + 1}:
                                    </Typography>
                                    <Table key={index} className="table">
                                        <TableHead>
                                            <TableRow>
                                                {tableRows.map((rows, index) => {
                                                    return <TableCell key={index}>{rows}</TableCell>;
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        {table.map((user) => (
                                            <TableBody>
                                                {values.map((value, index) => {
                                                    if (index == user) {
                                                        return (
                                                            <TableRow key={index}>
                                                                {value.map((val, i) => {
                                                                    return <TableCell key={i}>{val}</TableCell>;
                                                                })}
                                                            </TableRow>
                                                        );
                                                    }
                                                })}
                                            </TableBody>
                                        ))}
                                    </Table>
                                </TableContainer>
                            ))}
                        </Grid>
                    </Container>
                    <br />
                    <br />
                    <AnimateButton>
                        <Button
                            disableElevation
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmitR2}
                            ////
                        >
                            Build Team - Round 2
                        </Button>
                    </AnimateButton>
                    <Stack mt={2}>
                        Building Status: {responseDataR2.response}
                        {responseDataR2.response != 'Success' && <div>{responseDataR2.message}</div>}
                    </Stack>
                </div>
            )}

            {tablesR2.length > 0 && (
                <div>
                    <Grid container spacing={2}>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            {tablesR2?.map((table, tableIndex) => (
                                <Droppable droppableId={`${tableIndex}`} key={tableIndex}>
                                    {(provider, snapshot) => (
                                        <Grid
                                            item
                                            xs={3}
                                            className="table borderd"
                                            ref={provider.innerRef}
                                            {...provider.droppableProps}
                                            // style={getListStyle(snapshot.isDraggingOver)}
                                            /////////////////////////////
                                        >
                                            <ListItemText
                                                primary={`Table ${tableIndex + 1}`}
                                                primaryTypographyProps={{
                                                    color: 'black',
                                                    fontWeight: 600,
                                                    variant: 'body1'
                                                }}
                                            />
                                            {table.map((user, index) => (
                                                <List sx={{ width: '100%', maxWidth: 360, height: 50, bgcolor: 'background.paper' }}>
                                                    <Draggable
                                                        draggableId={`${tableIndex}-${index}`}
                                                        index={index}
                                                        key={`${tableIndex}-${index}`}
                                                        //////////////////////
                                                    >
                                                        {(provider, snapshot) => (
                                                            <ListItem
                                                                {...provider.draggableProps}
                                                                {...provider.dragHandleProps}
                                                                ref={provider.innerRef}
                                                                // style={getItemStyle(
                                                                //     snapshot.isDragging,
                                                                //     provider.draggableProps.style
                                                                //     ///////////////////////
                                                                // )}
                                                            >
                                                                {/* {user} */}
                                                                {values.map((value, index) => {
                                                                    if (index == user) {
                                                                        return <ListItemText primary={value[2]} />;
                                                                    }
                                                                })}
                                                            </ListItem>
                                                        )}
                                                    </Draggable>
                                                    {provider.placeholder}
                                                </List>
                                            ))}
                                        </Grid>
                                    )}
                                </Droppable>
                            ))}
                        </DragDropContext>
                    </Grid>
                    <br />
                    <Typography variant="h4" component="h5">
                        Table list:
                    </Typography>
                    <div>
                        {tablesR2.map((table, index) => (
                            <TableContainer sx={{ maxHeight: 440, border: '2px dashed grey', borderColor: 'primary.main' }}>
                                <Typography variant="h5" component="h6">
                                    Table {index + 1}:
                                </Typography>
                                <Table key={index} className="table">
                                    <TableHead>
                                        <TableRow>
                                            {tableRows.map((rows, index) => {
                                                return <TableCell key={index}>{rows}</TableCell>;
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    {table.map((user) => (
                                        <TableBody>
                                            {values.map((value, index) => {
                                                if (index == user) {
                                                    return (
                                                        <TableRow key={index}>
                                                            {value.map((val, i) => {
                                                                return <TableCell key={i}>{val}</TableCell>;
                                                            })}
                                                        </TableRow>
                                                    );
                                                }
                                            })}
                                        </TableBody>
                                    ))}
                                </Table>
                            </TableContainer>
                        ))}
                    </div>
                    <br />
                    <br />
                    <AnimateButton>
                        <Button
                            disableElevation
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmitR3}
                            ////
                        >
                            Build Team - Round 3
                        </Button>
                    </AnimateButton>
                    <div>
                        Building Status: {responseDataR3.response}
                        {responseDataR3.response != 'Success' && <div>{responseDataR3.message}</div>}
                    </div>
                </div>
            )}
            {tablesR3.length > 0 && (
                <div>
                    <Grid container spacing={2}>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            {tablesR3?.map((table, tableIndex) => (
                                <Droppable droppableId={`${tableIndex}`} key={tableIndex}>
                                    {(provider, snapshot) => (
                                        <Grid
                                            item
                                            xs={3}
                                            className="table borderd"
                                            ref={provider.innerRef}
                                            {...provider.droppableProps}
                                            // style={getListStyle(snapshot.isDraggingOver)}
                                            /////////////////////////////
                                        >
                                            <ListItemText
                                                primary={`Table ${tableIndex + 1}`}
                                                primaryTypographyProps={{
                                                    color: 'black',
                                                    fontWeight: 600,
                                                    variant: 'body1'
                                                }}
                                            />
                                            {table.map((user, index) => (
                                                <List sx={{ width: '100%', maxWidth: 360, height: 50, bgcolor: 'background.paper' }}>
                                                    <Draggable
                                                        draggableId={`${tableIndex}-${index}`}
                                                        index={index}
                                                        key={`${tableIndex}-${index}`}
                                                        //////////////////////
                                                    >
                                                        {(provider, snapshot) => (
                                                            <ListItem
                                                                {...provider.draggableProps}
                                                                {...provider.dragHandleProps}
                                                                ref={provider.innerRef}
                                                                // style={getItemStyle(
                                                                //     snapshot.isDragging,
                                                                //     provider.draggableProps.style
                                                                //     ///////////////////////
                                                                // )}
                                                            >
                                                                {/* {user} */}
                                                                {values.map((value, index) => {
                                                                    if (index == user) {
                                                                        return <ListItemText primary={value[2]} />;
                                                                    }
                                                                })}
                                                            </ListItem>
                                                        )}
                                                    </Draggable>
                                                    {provider.placeholder}
                                                </List>
                                            ))}
                                        </Grid>
                                    )}
                                </Droppable>
                            ))}
                        </DragDropContext>
                    </Grid>
                    <br />
                    <Typography variant="h4" component="h5">
                        Table list:
                    </Typography>
                    <div>
                        {tablesR3.map((table, index) => (
                            <TableContainer sx={{ maxHeight: 440, border: '2px dashed grey', borderColor: 'primary.main' }}>
                                <Typography variant="h5" component="h6">
                                    Table {index + 1}:
                                </Typography>
                                <Table key={index} className="table">
                                    <TableHead>
                                        <TableRow>
                                            {tableRows.map((rows, index) => {
                                                return <TableCell key={index}>{rows}</TableCell>;
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    {table.map((user) => (
                                        <TableBody>
                                            {values.map((value, index) => {
                                                if (index == user) {
                                                    return (
                                                        <TableRow key={index}>
                                                            {value.map((val, i) => {
                                                                return <TableCell key={i}>{val}</TableCell>;
                                                            })}
                                                        </TableRow>
                                                    );
                                                }
                                            })}
                                        </TableBody>
                                    ))}
                                </Table>
                            </TableContainer>
                        ))}
                    </div>
                </div>
            )}
        </Container>
    );
}

export default GroupMatchingPage;
